import styled from 'styled-components';

export const UserPhoto = styled.div`
  //margin-right: 10px;
`;

export const AuthorInfo = styled.div<{ isClient: boolean }>`
  display: flex;
  flex-direction: row;
  align-self: ${(props) => (props.isClient ? 'flex-start' : 'flex-end')};
  margin-bottom: 5px;
`;

export const StyledAuthorName = styled.div<{ isClient: boolean }>`
  font-size: 1rem;
  letter-spacing: 0.5px;
  max-width: 400px;
  font-weight: 700;
  word-wrap: break-word;
  align-self: center !important;
  color: ${(props) => (props.isClient ? '#82BA92' : '#050d42')};
`;
