import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const Message = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
`;
