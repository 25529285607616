export enum InboxStatesNames {
  OPEN = 'open',
  OPEN_NEW = 'open.new',
  OPEN_IN_PROGRESS = 'open.inProgress',
  FOLLOWUP = 'followup',
  ESCALATED = 'escalated',
  RESOLVED = 'resolved',
}

export { InboxStatesNames as default }
