import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Imitieren from './components/Imitieren';
import AccountDataContext from './context/AccountDataContext';
import FBAccountDataContext from './context/FBAccountDataContext';
import GlobalLoadingContext from './context/GlobalLoadingContext';
import store from './app/store';
import { Provider } from 'react-redux';
import JwtTokenContext from './context/JwtTokenContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
      <GlobalLoadingContext>
        <JwtTokenContext>
          <FBAccountDataContext>
            <AccountDataContext>
              <Imitieren />
              <App />
            </AccountDataContext>
          </FBAccountDataContext>
        </JwtTokenContext>
      </GlobalLoadingContext>
    </Provider>
  </>
);
