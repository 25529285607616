import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';

export const Modal = styled.div`
  text-align: center;
  width: 100%;
  max-width: 544px;
  background-color: #fff;
  padding: 40px 20px;
  @media ${mediaQueries.desktop} {
    padding: 40px 70px;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
  }
`;

export const ModalTitle = styled.h3`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: normal;
`;
