import styled from 'styled-components';

export const LastError = styled.div`
  display: block;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #f00;
  text-transform: uppercase;
  margin-top: 3px;
`;
