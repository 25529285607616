import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Alert from '../Alert';
import Button from '@mui/material/Button';
import { Table, Td, Th, Tr } from '../Table';
import useSortableData from '../../modules/useSortableData';
import Checkbox from '@mui/material/Checkbox';
import { getDataByUrl } from '../../modules/fetch';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDeleteStoreMutation } from '../../api/storeAPI';
import { fromNowSeconds, toFromNow } from '../../modules/renderDate';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment/moment';
import { AlertTitle, ButtonsContainer, Container, Logo, Text, } from './style';
import { Link, useNavigate } from 'react-router-dom';

export default function DataGridDemo({ data, handleRegistrationPopup = null }) {
  const navigate = useNavigate();
  const intl = useIntl();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [currentItemId,] = useState(false);
  const { items, requestSort, sortConfig } = useSortableData(data);
  const [deleteStore] = useDeleteStoreMutation();

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handleStoreView = (storeId) => () => {
    navigate(`/store/${storeId}`);
  }

  const handleDelete = async () => {
    await deleteStore(currentItemId);
    setShowDeletePopup(false);
    return true;
  };

  const handleCheck = (id) => {
    console.debug('/components/StoresList/index.js:handleCheck', id);
  };

  const handleRedirect = (storeAuthUrl) => {
    getDataByUrl(storeAuthUrl)
      .then((data) => {
        window.location = data.data.redirect;
      })
      .catch((err) => {
        console.error(err);
      });

    return true;
  };

  return (
    <>
      {showDeletePopup && (
        <Alert handleClose={() => setShowDeletePopup(false)}>
          <AlertTitle>
            <FormattedMessage
              id="storeManagement.storeList.alert.title"
              defaultMessage="Are you sure you want to delete this store?"
            />
          </AlertTitle>
          <ButtonsContainer>
            <Button variant="outlined" onClick={() => setShowDeletePopup(false)}>
              <FormattedMessage
                id="storeManagement.storeList.alert.button.no"
                defaultMessage="No"
              />
            </Button>
            <Button variant="contained" color="primary" onClick={handleDelete}>
              <FormattedMessage
                id="storeManagement.storeList.alert.button.yes"
                defaultMessage="Yes"
              />
            </Button>
          </ButtonsContainer>
        </Alert>
      )}

      <Container>
        <Table>
          <thead>
          <Tr>
            <Th></Th>
            <Th>
              <FormattedMessage
                id="storeManagement.storeList.table.platform"
                defaultMessage="Platform"
              />
            </Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('name')}
                className={getClassNamesFor('name')}
              >
                <FormattedMessage
                  id="storeManagement.storeList.table.shop"
                  defaultMessage="Shop Name"
                />
              </button>
            </Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('status')}
                className={getClassNamesFor('status')}
              >
                <FormattedMessage
                  id="storeManagement.storeList.table.status"
                  defaultMessage="Connection Status"
                />
              </button>
            </Th>
            <Th>
              <FormattedMessage
                id="storeManagement.storeList.table.actions.label"
                defaultMessage="Actions"
              />
            </Th>
          </Tr>
          </thead>
          <tbody>
          {items.map((item, index) => (
            <Tr key={`table-tr-${index}`}>
              <Td>
                <Checkbox color="primary" value="agree" onChange={() => handleCheck(item?._id?.$oid)} />
              </Td>
              <Td>
                <Logo>
                  <img src={`/pics/${item.provider ?? 'default'}_small.png`} alt="" />
                </Logo>
              </Td>
              <Td>
                <Link to={`/store/${item?._id?.$oid}`}>
                  {item.name}&nbsp;
                  {item.countryCode.toUpperCase()}
                </Link>
              </Td>
              <Td>
                <Text status={item.connectionStatus}>{item.connectionMessage}</Text>
                {item.connectionStatus !== 'connected' && item.nextConnectionURL !== '' ? (
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      handleRedirect(item.nextConnectionURL);
                    }}
                  >
                    <FormattedMessage
                      id="storeManagement.storeList.table.connect"
                      defaultMessage="Connect"
                    />
                  </Button>
                ) : (
                  ''
                )}
                {item.provider === 'whatsapp' && ''}
                {item.provider === 'shopee' && Object.keys(item.apps).map((connector, index) => {

                  const isExpired = (moment().unix() - (86400 * 3)) > item.apps[connector].rawResponse?.sellerInfoResponse?.expire_time;

                  if (isExpired) {
                    return (
                      <ListItemText
                        key={'store_management_store_list_table_expires-' + '-connector-' + index}
                        sx={{
                          padding: 0,
                          margin: 0,
                          color: 'red',
                        }}
                        primary={(index + 1) + '. ' + intl.formatMessage({
                          id: 'storeManagement.storeList.table.expires.' + connector,
                          defaultMessage: connector,
                        }, {
                          expire: (item.apps[connector].rawResponse?.sellerInfoResponse?.expire_time ? fromNowSeconds(item.apps[connector].rawResponse?.sellerInfoResponse?.expire_time) : 'unknown')
                        })} />
                    )
                  }

                  return (
                    <ListItemText
                      key={'store_management_store_list_table_expires-' + '-connector-' + index}
                      sx={{
                        padding: 0,
                        margin: 0,
                      }}
                      primary={(index + 1) + '. ' + intl.formatMessage({
                        id: 'storeManagement.storeList.table.expires.' + connector,
                        defaultMessage: connector,
                      }, {
                        expire: (item.apps[connector].rawResponse?.sellerInfoResponse?.expire_time ? fromNowSeconds(item.apps[connector].rawResponse?.sellerInfoResponse?.expire_time) : 'unknown')
                      })} />
                  )
                })}
                {item.provider === 'lazada' && Object.keys(item.apps).map((connector, index) => {

                  const isExpired = (moment().unix() - (86400 * 3)) > (item.apps[connector].auth.refreshExpires ?? 0) + moment(item.apps[connector].auth.retrievedAt.date).unix();

                  if (isExpired) {
                    return (
                      <ListItemText
                        key={'store_management_store_list_table_expires-' + '-connector-' + index}
                        sx={{
                          padding: 0,
                          margin: 0,
                          color: 'red',
                        }}
                        primary={(index + 1) + '. ' + intl.formatMessage({
                          id: 'storeManagement.storeList.table.expires.' + connector,
                          defaultMessage: connector,
                        }, {
                          expire: toFromNow((item.apps[connector].auth.refreshExpires ?? 0), moment(item.apps[connector].auth.retrievedAt.date).unix())
                        })} />
                    )
                  }

                  return (
                    <ListItemText
                      key={'store_management_store_list_table_expires-' + '-connector-' + index}
                      sx={{
                        padding: 0,
                        margin: 0,
                      }}
                      primary={(index + 1) + '. ' + intl.formatMessage({
                        id: 'storeManagement.storeList.table.expires.' + connector,
                        defaultMessage: connector,
                      }, {
                        expire: toFromNow((item.apps[connector].auth.refreshExpires ?? 0), moment(item.apps[connector].auth.retrievedAt.date).unix())
                      })} />
                  )
                })}
              </Td>
              <Td>
                {/* <IconButton aria-label="hide" onClick={handleHide}>
                  <VisibilityOffIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="settings" onClick={handleSettings}>
                  <SettingsIcon fontSize="small" />
                </IconButton> */}
                {item.provider === 'whatsapp' && (
                  <>
                    <IconButton aria-label="settings" onClick={handleRegistrationPopup(item?._id?.$oid)}>
                      <AppRegistrationIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="export" onClick={() => navigate(`/store/export/${item?._id?.$oid}`)}>
                      <ImportExportIcon fontSize="small" />
                    </IconButton>
                  </>
                )}
                {/*<IconButton*/}
                {/*  aria-label="delete"*/}
                {/*  onClick={() => {*/}
                {/*    handleBtnDelete(item?._id?.$oid);*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <DeleteOutlineIcon fontSize="small" />*/}
                {/*</IconButton>*/}
                <IconButton aria-label="settings" onClick={handleStoreView(item?._id?.$oid)}>
                  <ArrowCircleRightOutlinedIcon fontSize="small" />
                </IconButton>
              </Td>
            </Tr>
          ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
}
