export const CheckGreenIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#5FD58C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1958 9.54509C5.45688 9.25906 5.88016 9.25906 6.14123 9.54509L8.8153 12.4749C9.07638 12.7609 9.07638 13.2247 8.8153 13.5107C8.55423 13.7967 8.13095 13.7967 7.86988 13.5107L5.1958 10.5809C4.93473 10.2949 4.93473 9.83113 5.1958 9.54509Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1633 6.61589C14.4243 6.90193 14.4243 7.36568 14.1633 7.65171L8.81507 13.5112C8.554 13.7972 8.13071 13.7972 7.86963 13.5112C7.60856 13.2252 7.60856 12.7614 7.86963 12.4754L13.2178 6.61589C13.4789 6.32986 13.9022 6.32986 14.1633 6.61589Z" fill="white"/>
  </svg>
);

export const CheckGreyIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#F3F3F3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1958 9.54509C5.45688 9.25906 5.88016 9.25906 6.14123 9.54509L8.8153 12.4749C9.07638 12.7609 9.07638 13.2247 8.8153 13.5107C8.55423 13.7967 8.13095 13.7967 7.86988 13.5107L5.1958 10.5809C4.93473 10.2949 4.93473 9.83113 5.1958 9.54509Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1633 6.61589C14.4243 6.90193 14.4243 7.36568 14.1633 7.65171L8.81507 13.5112C8.554 13.7972 8.13071 13.7972 7.86963 13.5112C7.60856 13.2252 7.60856 12.7614 7.86963 12.4754L13.2178 6.61589C13.4789 6.32986 13.9022 6.32986 14.1633 6.61589Z" fill="white"/>
  </svg>
);

export const StoreIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#F3F3F3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1958 9.54509C5.45688 9.25906 5.88016 9.25906 6.14123 9.54509L8.8153 12.4749C9.07638 12.7609 9.07638 13.2247 8.8153 13.5107C8.55423 13.7967 8.13095 13.7967 7.86988 13.5107L5.1958 10.5809C4.93473 10.2949 4.93473 9.83113 5.1958 9.54509Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1633 6.61589C14.4243 6.90193 14.4243 7.36568 14.1633 7.65171L8.81507 13.5112C8.554 13.7972 8.13071 13.7972 7.86963 13.5112C7.60856 13.2252 7.60856 12.7614 7.86963 12.4754L13.2178 6.61589C13.4789 6.32986 13.9022 6.32986 14.1633 6.61589Z" fill="white"/>
  </svg>
);
