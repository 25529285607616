import { Connection, Session, Subscription } from 'autobahn';
import { WAMP_URL } from '../config';
import { getData } from './fetch';
import { retrieve } from './storage';
import { logMessage } from '../logger';

let subscriptions = {};
export const defaultConnectionHandlers = {
  onStart: ({ connection, host, realm, channel, handle }) => {
    logMessage('1{ connection, host, realm, channel, handle }', {
      connection,
      host,
      realm,
      channel,
      handle,
    });
  },
  onOpen: ({ connection, session, host, realm, channel, handle }) => {
    logMessage(
      '2WAMP: onOpen{ connection, session, host, realm, channel, handle }',
      connection,
      session,
      host,
      realm,
      channel,
      handle,
    );
  },
  onClose: (reason, details) => {
    logMessage('3WAMP: onClose', reason, details);
  },
  onUserError: (error, customErrorMessage) => {
    logMessage('4WAMP: onUserError', error, customErrorMessage);
  },
  onInternalError: (error, customErrorMessage) => {
    logMessage('5WAMP: onInternalError', error, customErrorMessage);
  },
};

export const wamp = (
  host = WAMP_URL,
  realm,
  channel,
  handle,
  { onStart, onOpen, onClose, onUserError, onInternalError },
) => {
  return new Promise((resolve, reject) => {
    const connectionWamp = new Connection({
      url: host,
      realm: realm,
      authid: retrieve.string('uniqueId'),
      authmethods: ['chain'],
      autoping_interval: 3,
      autoping_timeout: 3,
      autoping_size: 4,
      max_retries: -1,
      retry_if_unreachable: true,
      on_user_error: function (error, customErrorMessage) {
        onUserError && onUserError(error, customErrorMessage);
        console.warn('connection.on_user_error: ', error, customErrorMessage);
        reject({ error, customErrorMessage });
      },
      on_internal_error: function (error, customErrorMessage) {
        onInternalError && onInternalError(error, customErrorMessage);
        console.warn('connection.on_internal_error: ', error, customErrorMessage);
        reject({ error, customErrorMessage });
      },
      onchallenge: function (): any {
        // onchallenge: function (session, method, extra): any {
        // console.log('onchallenge: session, method, extra', session, method, extra);
        return [{ token: retrieve.string('token') }];
      },
    });

    connectionWamp.onopen = function (session: Session) {
      session.subscribe(channel, handle).then((subscription: Subscription) => {
        if (!('subscription.session.realm' in subscriptions)) {
          subscriptions[subscription.session.realm] = { [channel]: {} };
        }
        subscriptions[subscription.session.realm][channel] = subscription;
      });
      onOpen && onOpen({ connectionWamp, session, host, realm, channel, handle });
      resolve({ connectionWamp, session, host, realm, channel, handle });
    };

    connectionWamp.onclose = function (reason: string, details): any {
      onClose && onClose(reason, details);
      logMessage('connection.onclose: reason, details', reason, details);
    };

    connectionWamp.open();
    onStart && onStart({ connectionWamp, host, realm, channel, handle });
  });
};

export const setupWampHandler = async (
  handlers,
  { onStart, onOpen, onClose, onUserError, onInternalError } = {
    onStart: null,
    onOpen: null,
    onClose: null,
    onUserError: null,
    onInternalError: null,
  },
) => {
  const data = await getData(`/wamp/config`);
  const configSet: any[] = data?.data?.config ? Object.values(data?.data?.config) : [];
  let installed = [];
  configSet.map((config) => {
    return config.map((values) => {
      const topics: string[] = Object.values(values.topics);
      if (topics.length > 0) {
        return topics.map((topic: string) => {
          let promise = null;
          const valueHost = String(values.host);
          const host = valueHost[valueHost.length - 1] === '/' ? values.host : values.host + '/';
          promise = wamp(
            host + values.realm + '/' + topic + '?jwt=' + retrieve.string('token') + '&imitieren=' + retrieve.string('imitieren', null),
            'system',
            topic,
            handlers['__byType'],
            {
              onStart,
              onOpen,
              onClose,
              onUserError,
              onInternalError,
            },
          );
          const handler = {
            [values.realm]: {
              [topic]: {
                host: host,
                realm: values.realm,
                topic: topic,
                handlers: handlers[topic],
                promise: promise,
              },
            },
          };
          installed.push(handler);
          return handler;
        });
      }
      return null;
    });
  });
  return installed;
};

export const destroyWampHandler = (installed) => {
  console.trace('destroyWampHandler');
  installed.map((realms) => {
    Object.entries(realms).forEach(([realmId, realm]) => {
      Object.entries(realm).forEach(([topicId, topic]) => {
        const subscription: Subscription = subscriptions?.[realmId]?.[topicId];
        if (subscription) {
          subscription.unsubscribe();
          delete subscriptions[realmId][topicId];
          console.log('unsubscribe: realmId, topicId,', realmId, topicId);
        }
        delete topic.promise;
      });
    });
  });
};
