import { getData } from './modules/fetch';

export const WithoutToken = [
  '/sign-up',
  '/sign-in',
  '/confirm-email',
  '/create-password',
  '/reset-password',
];
export const LEVELS: Record<number, string[]> = {
  5: ['ROLE_USER'],
  4: ['ROLE_SUPPORT'],
  3: ['ROLE_MANAGER'],
  2: ['ROLE_OWNER'],
  1: ['ROLE_ADMIN'],
  0: ['ROLE_SUPER_ADMIN'],
};

export const getAccountData = (retrieved) => {
  return getData('/account/read', false)
    .then((response) => {
      if (response) {
        console.debug(
          '/security.js:getAccountData',
          'Welcome ',
          response?.data?.name,
          response?.data?.surname,
          response?.data?.company,
        );
        response?.data && retrieved(response?.data);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getCurrentLevel = (currentUser) => {
  let level = -1;
  if (currentUser && currentUser?.roles) {
    for (let k in Object.keys(LEVELS)) {
      for (let r in currentUser?.roles) {
        level = +(LEVELS[k].includes(currentUser?.roles[r]) ? k : -1);
        if (level > -1) {
          return level;
        }
      }
    }
  }

  return level;
};
