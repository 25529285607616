import { FormattedMessage } from 'react-intl';
import {
  useAddAutoReplyMutation,
  useFetchTemplateQuery,
  useUpdateAutoReplyMutation,
} from '../../api/autoReplyAPI';
import { BackButton, ButtonsContainer, Container, SaveButton } from './styles';
import AutoReplyFormRenderContent from '../../components/OutOfOfficeFormRenderContent';
import Error from '../../components/Error';

const AutoReplyForm = ({
  inputValues,
  requiredFields,
  setInputValues,
  setShowAutoReplyPopup
}) => {
  const { data: templateAutoReply, error: errorTemplate } = useFetchTemplateQuery(true);
  const [addAutoReply] = useAddAutoReplyMutation();
  const [updateAutoReply] = useUpdateAutoReplyMutation();

  const handleCancelPopup = () => {
    setInputValues({});
    setShowAutoReplyPopup(false);
  };
  const handleAutoReplyFormSubmit = async (e) => {
    e.preventDefault();
    inputValues.platforms = inputValues?.platforms?.map(option => option?.value ?? option);
    if ('isEditable' in inputValues) {
      const id = inputValues?._id?.$oid;
      delete inputValues._id;
      delete inputValues.ownerId;
      delete inputValues.status;
      delete inputValues.createdAt;
      delete inputValues.updatedAt;
      delete inputValues.isEditable;
      await updateAutoReply({
        id: id,
        ...inputValues,
      });
    } else {
      await addAutoReply({ autoReply: {...inputValues} });
    }
    setShowAutoReplyPopup(false);
  };

  return (
    <Container>
      {(errorTemplate && 'error' in errorTemplate && <Error message={errorTemplate.error} />) || (
        <form autoComplete="off" onSubmit={handleAutoReplyFormSubmit}>
          <AutoReplyFormRenderContent
            inputValues={inputValues}
            requiredFields={requiredFields}
            setInputValues={setInputValues}
            autoReplyFormData={templateAutoReply?.data}
          />
          <ButtonsContainer>
            <BackButton variant="outlined" onClick={handleCancelPopup}>
              <FormattedMessage id="auto-reply.popup.button.cancel" />
            </BackButton>
            <SaveButton variant="contained" onClick={handleAutoReplyFormSubmit}>
              <FormattedMessage id="auto-reply.popup.button.save" />
            </SaveButton>
          </ButtonsContainer>
        </form>
      )}
    </Container>
  );
};

export default AutoReplyForm;
