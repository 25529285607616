import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import Error from '../../components/Error';
import TextField from '@mui/material/TextField';
import { EmailInput, PasswordInput } from '../../components/Input';
import Button from '@mui/material/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUpdateAccountSettingsMutation } from '../../api/accountAPI';
import { AccountDataContext } from '../../context/AccountDataContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 20px;
  background: #fff;
  border-radius: 6px;
  padding: 0 20px;
  @media ${mediaQueries.desktop} {
    padding: 20px 40px;
    margin: 24px 30px;
  }
`;
const StyledBlock = styled.div`
  margin-bottom: 40px;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;
const StyledTitle = styled.div`
  padding: 20px;
  color: #050d42;
  background: #ecf3f9;
  border-radius: 15px 15px 0 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
`;
const StyledBody = styled.div`
  padding: 20px;
  color: #050d42;
`;
const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  > div {
    margin-right: 20px;
    :last-child {
      margin-right: 0;
    }
  }
  @media ${mediaQueries.desktop} {
    flex-direction: row;
  }
`;

const TextFieldContainer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  > div {
    flex-grow: 1;
    &:nth-child(2) {
      margin-top: 20px;
    }
  }
  @media ${mediaQueries.desktop} {
    flex-direction: row;
    max-width: 50%;
    > div:nth-child(2) {
      margin: 0 0 0 15px;
    }
  }
`;

const Buttons = styled.div`
  button {
    width: 100%;
    @media ${mediaQueries.desktop} {
      width: auto;
    }
  }
`;

const General = ({ showDetails, userData }) => {
  const [, setComplete] = useState(false);
  const [values, setValues] = useState({
    name: '',
    company: '',
    address: '',
    city: '',
    zip: '',
    country: '',
    phone: '',
    email: '',
    password: '',
    password_confirm: '',
  });
  const [serverError, setServerError] = useState('');
  const intl = useIntl();
  const [updateAccountSettings] = useUpdateAccountSettingsMutation();
  const [, , , , reloadAccount] = useContext(AccountDataContext);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    renderData(userData);
  }, [userData]);

  const renderData = (user) => {
    setValues({
      name: user?.settings?.general?.contact?.name ?? `${user?.name} ${user?.surname}`,
      company: user?.company,
      address: user?.settings?.general?.address?.street,
      city: user?.settings?.general?.address?.city,
      zip: user?.settings?.general?.address?.zip,
      country: user?.settings?.general?.address?.country,
      phone: user?.settings?.general?.contact?.phone ?? user?.telephone,
      email: user?.settings?.general?.contact?.email ?? user?.email,
      password: '',
      password_confirm: '',
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await updateAccountSettings({
      account: {
        settings: {
          general: values,
        },
      },
    });
    setServerError('');
    setComplete(true);
    reloadAccount();
  };

  return (
    <Container>
      {serverError && <Error message={serverError} />}

      <form autoComplete="off" onSubmit={handleFormSubmit}>
        {showDetails && (
          <>
            <StyledBlock>
              <StyledTitle>
                <FormattedMessage id="settings.general.details.title" defaultMessage="Details:" />
              </StyledTitle>
              <StyledBody>
                <InputsContainer>
                  <TextFieldContainer>
                    <TextField
                      id="company"
                      label={intl.formatMessage({
                        id: 'settings.general.details.company_name',
                        defaultMessage: 'settings.general.details.company_name',
                      })}
                      value={values.company}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      autoComplete="none"
                      onChange={handleChange('company')}
                    />
                  </TextFieldContainer>
                  <TextFieldContainer>
                    <TextField
                      id="name"
                      label={intl.formatMessage({
                        id: 'settings.general.details.full_name',
                        defaultMessage: 'settings.general.details.full_name',
                      })}
                      value={values.name}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      autoComplete="none"
                      onChange={handleChange('name')}
                    />
                  </TextFieldContainer>
                </InputsContainer>
                <InputsContainer>
                  <TextFieldContainer>
                    <TextField
                      id="address"
                      label={intl.formatMessage({
                        id: 'settings.general.details.company_address',
                        defaultMessage: 'settings.general.details.company_address',
                      })}
                      value={values.address}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      autoComplete="none"
                      onChange={handleChange('address')}
                    />
                  </TextFieldContainer>
                  <TextFieldContainer>
                    <TextField
                      id="city"
                      label={intl.formatMessage({
                        id: 'settings.general.details.city',
                        defaultMessage: 'settings.general.details.city',
                      })}
                      value={values.city}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      autoComplete="none"
                      onChange={handleChange('city')}
                    />
                  </TextFieldContainer>
                </InputsContainer>
                <InputsContainer>
                  <TextFieldContainer>
                    <TextField
                      id="zip"
                      label={intl.formatMessage({
                        id: 'settings.general.details.zip',
                        defaultMessage: 'settings.general.details.zip',
                      })}
                      value={values.zip}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      autoComplete="none"
                      onChange={handleChange('zip')}
                    />
                  </TextFieldContainer>
                  <TextFieldContainer>
                    <TextField
                      id="country"
                      label={intl.formatMessage({
                        id: 'settings.general.details.country',
                        defaultMessage: 'settings.general.details.country',
                      })}
                      value={values.country}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      autoComplete="none"
                      onChange={handleChange('country')}
                    />
                  </TextFieldContainer>
                  <TextFieldContainer>
                    <TextField
                      id="phone"
                      label={intl.formatMessage({
                        id: 'settings.general.details.phone',
                        defaultMessage: 'settings.general.details.phone',
                      })}
                      value={values.phone}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      autoComplete="none"
                      onChange={handleChange('phone')}
                    />
                  </TextFieldContainer>
                </InputsContainer>
              </StyledBody>
            </StyledBlock>
            <StyledBlock>
              <StyledTitle>
                <FormattedMessage id="settings.general.email.title" defaultMessage="Email:" />
              </StyledTitle>
              <StyledBody>
                <InputsContainer>
                  <TextFieldContainer>
                    <EmailInput
                      id="email"
                      label={intl.formatMessage({
                        id: 'settings.general.details.email',
                        defaultMessage: 'settings.general.details.email',
                      })}
                      value={values.email}
                      autoComplete="none"
                      handleChange={handleChange('email')}
                    />
                  </TextFieldContainer>
                </InputsContainer>
              </StyledBody>
            </StyledBlock>
            <StyledBlock>
              <StyledTitle>
                <FormattedMessage
                  id="settings.general.password.title"
                  defaultMessage="Change password:"
                />
              </StyledTitle>
              <StyledBody>
                <InputsContainer>
                  <TextFieldContainer>
                    <PasswordInput
                      id="password"
                      value={values.password}
                      autoComplete="off"
                      handleChange={handleChange('password')}
                      label={intl.formatMessage({
                        id: 'settings.general.details.password_enter',
                        defaultMessage: 'settings.general.details.password_enter',
                      })}
                    />
                  </TextFieldContainer>
                  <TextFieldContainer>
                    <PasswordInput
                      id="password_confirm"
                      value={values.password_confirm}
                      autoComplete="off"
                      handleChange={handleChange('password_confirm')}
                      label={intl.formatMessage({
                        id: 'settings.general.details.password_repeat',
                        defaultMessage: 'settings.general.details.password_repeat',
                      })}
                    />
                  </TextFieldContainer>
                </InputsContainer>
              </StyledBody>
            </StyledBlock>
          </>
        )}
        <Buttons>
          <Button size="large" variant="contained" color="primary" type="submit">
            <FormattedMessage id="settings.general.details.button" defaultMessage="Update" />
          </Button>
        </Buttons>
      </form>
    </Container>
  );
};

export default General;
