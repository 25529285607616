import { ItemHead } from '../styles';
import {
  VoucherList,
  VoucherListBody,
  VoucherListInfo,
  VoucherListPrice,
  VoucherListPriceText,
  VoucherListPriceDate,
  VoucherListSKU,
  VoucherListTitle,
} from './styles';
import { FormattedMessage } from 'react-intl';
import moment from 'moment/moment';

export default ({ voucher, isAList = false }) => {
  return (
    <VoucherList isAList={isAList}>
      <ItemHead>
        <FormattedMessage
          id="messages.chat.messages.voucher_code"
          defaultMessage="Your Voucher # {code}"
          values={{
            code: voucher?.voucherId ?? '',
          }}
        />
      </ItemHead>
      <VoucherListBody key={`${voucher?.voucherId}-voucherItem`}>
        <VoucherListInfo>
          <VoucherListTitle>{voucher?.name ?? ''}</VoucherListTitle>
          <VoucherListSKU>
            <FormattedMessage
              id="messages.chat.messages.voucher_voucherId"
              defaultMessage="Voucher Id: {voucherId}"
              values={{
                voucherId: voucher?.voucherId ?? '',
              }}
            />
          </VoucherListSKU>
          <VoucherListPrice>
            <VoucherListPriceText>
              <FormattedMessage
                id="messages.chat.messages.voucher_start_date"
                defaultMessage="Start: "
              />
            </VoucherListPriceText>
            <VoucherListPriceDate>
              {!!voucher?.startAt?.date
                ? moment(voucher?.startAt?.date).format('DD.MM.YYYY h:mm A ([GMT] Z)')
                : ''}
            </VoucherListPriceDate>
          </VoucherListPrice>
          <VoucherListPrice>
            <VoucherListPriceText>
              <FormattedMessage
                id="messages.chat.messages.voucher_end_date"
                defaultMessage="End: "
              />
            </VoucherListPriceText>
            <VoucherListPriceDate>
              {!!voucher?.endAt?.date
                ? moment(voucher?.endAt?.date).format('DD.MM.YYYY h:mm A ([GMT] Z)')
                : ''}
            </VoucherListPriceDate>
          </VoucherListPrice>
        </VoucherListInfo>
      </VoucherListBody>
    </VoucherList>
  );
};
