import { BackButton, SaveButton, Step, Steps, StyledButton, Title } from './styles';
import { ButtonsContainer } from '../../containers/ReportForm/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import BroadcastFormRenderContent from '../BroadcastFormRenderContent';

export const BroadcastFormSteps = ({
  handleFormSubmit,
  inputValues,
  requiredFields,
  setInputValues,
  inputLabels,
  setInputLabels,
  broadcastFormData,
  isPreview,
  setIsPreview,
  currentStep,
  setCurrentStep,
  isPreviewTicket,
}) => {
  const intl = useIntl();

  const handleNextStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep + 1);
  };
  const showPreview = (e) => {
    e.preventDefault();
    setIsPreview(true);
  };

  return (
    <>
      {isPreview ? (
        <Title>
          <FormattedMessage id="broadcast.form.title.preview" />
        </Title>
      ) : (
        <>
          <Title
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id: 'broadcast.form.title.fill',
                  defaultMessage: 'broadcast.form.title.fill',
                },
                {
                  text: intl.formatMessage({
                    id: 'broadcast.form.title.fill.text',
                    defaultMessage: 'broadcast.form.title.fill.text',
                  }),
                },
              ),
            }}
          />
          <Steps>
            {broadcastFormData.map((step, index) => (
              <Step
                $isActive={index === currentStep}
                $isComplete={index < currentStep}
                $step={index + 1}
                key={`step-${index}`}
              >
                <FormattedMessage id={step.stepName} />
              </Step>
            ))}
          </Steps>
        </>
      )}

      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <BroadcastFormRenderContent
          inputValues={inputValues}
          requiredFields={requiredFields}
          setInputValues={setInputValues}
          inputLabels={inputLabels}
          setInputLabels={setInputLabels}
          broadcastFormData={broadcastFormData}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isPreview={isPreview}
          setIsPreview={setIsPreview}
          isPreviewTicket={isPreviewTicket}
        />
        {!isPreviewTicket && (
          <ButtonsContainer>
            {currentStep === broadcastFormData.length - 1 ? (
              <>
                <BackButton
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentStep(currentStep - Number(!isPreview));
                    setIsPreview(false);
                  }}
                >
                  <FormattedMessage
                    id="broadcast.form.button.back"
                    defaultMessage="Back"
                  />
                </BackButton>
                {!isPreview && (
                  <StyledButton onClick={showPreview}>
                    <FormattedMessage
                      id="broadcast.form.button.preview"
                      defaultMessage="Preview"
                    />
                  </StyledButton>
                )}
                <SaveButton type="submit" onClick={handleFormSubmit}>
                  <FormattedMessage
                    id="broadcast.form.button.save"
                    defaultMessage="Save"
                  />
                </SaveButton>
              </>
            ) : (
              <>
                {currentStep > 0 ? (
                  <BackButton
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    <FormattedMessage
                      id="broadcast.form.button.back"
                      defaultMessage="Back"
                    />
                  </BackButton>
                ) : (
                  <div></div>
                )}
                <SaveButton onClick={handleNextStep}>
                  <FormattedMessage
                    id="broadcast.form.button.continue"
                    defaultMessage="Continue"
                  />
                </SaveButton>
              </>
            )}
          </ButtonsContainer>
        )}
      </form>
    </>
  );
};

export default BroadcastFormSteps;
