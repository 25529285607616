import { createContext, useRef } from 'react';

export const MessageInputRefContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const messageInputRef = useRef();

  return (
    <MessageInputRefContext.Provider value={messageInputRef}>
      {children}
    </MessageInputRefContext.Provider>
  );
};
