import styled from 'styled-components';
import { AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';

export const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  .accordion {
    border: 2px solid #f8f8f8;
    border-radius: 8px;
  }
  .accordion .form-row-0,
  .accordion .form-row-1,
  .accordion .form-row-2 {
    max-width: 100%;
    justify-content: space-between;
    > div {
      min-width: 48% !important;
      max-width: 48% !important;
    }
  }
`;
export const StyledAccordionItemHeading = styled(AccordionItemHeading)`
  .accordion__button {
    font-size: 1rem;
    font-weight: 600;
    line-height: 20px;
    background: #f8f8f8;
    text-transform: none;
  }
`;
export const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  padding: 20px;
  background: #f8f8f8;
  label {
    background: #f8f8f8;
  }
`;
