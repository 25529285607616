import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 20px;
  background: #fff;
  border-radius: 6px;
  padding: 0 20px;
  @media ${mediaQueries.desktop} {
    padding: 20px 40px;
    margin: 24px 30px;
  }
`;
const StyledBlock = styled.div`
  margin-bottom: 40px;
  color: #050d42;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  :first-child {
    tr:last-child {
      border-bottom: 1px solid #ccc;
    }
  }
`;
const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #ecf3f9;
  border-radius: 15px 15px 0 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
`;
const StyledLabel = styled.div``;
const StyledBody = styled.div``;
const StyledBodyCard = styled.div`
  padding: 20px 20px 0 0;
  color: #050d42;
`;

const Status = styled.div<{ type: string }>`
  text-transform: uppercase;
  font-weight: 500;
  ${(props) => props.type === 'pending' && `color: #EEBE12;`}
`;
const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;
const SwitchLabel = styled.div`
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
`;
const Switcher = styled.label`
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  i {
    position: relative;
    display: inline-block;
    margin-right: 0.5rem;
    width: 46px;
    height: 26px;
    background-color: #e6e6e6;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
    ::before {
      content: '';
      position: absolute;
      left: 0;
      width: 42px;
      height: 22px;
      background-color: #fff;
      border-radius: 11px;
      transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
      transition: all 0.25s linear;
    }
    ::after {
      content: '';
      position: absolute;
      left: 0;
      width: 22px;
      height: 22px;
      background-color: #fff;
      border-radius: 11px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
      transform: translate3d(2px, 2px, 0);
      transition: all 0.2s ease-in-out;
    }
  }
  :active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
  }
  :active input:checked + i::after {
    transform: translate3d(16px, 2px, 0);
  }
  input {
    display: none;
  }
  input:checked + i {
    background-color: #4bd763;
  }
  input:checked + i::before {
    transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
  }
  input:checked + i::after {
    transform: translate3d(22px, 2px, 0);
  }
`;

const Buttons = styled.div`
  margin: 20px;
  button {
    margin-right: 10px;
  }
  button {
    width: 100%;
    margin-bottom: 15px;
    @media ${mediaQueries.desktop} {
      width: auto;
      margin-bottom: 0;
    }
  }
`;

const CardsContainer = styled.div`
  margin-left: 20px;
  border: 1px solid #ccc;
  display: flex;
`;
const Card = styled.div`
  flex: 1;
  padding: 14px 30px 20px;
  border-right: 1px solid #ccc;
`;
const CardsTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #000;
  strong {
    margin-left: 5px;
  }
`;
const Visa = styled.div`
  width: 40px;
  height: 40px;
  background: url('/icon_visa.png') no-repeat 0 0 / cover;
  margin-right: 5px;
`;
const CardInfo = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: rgba(126, 126, 126, 1);
`;
const Expires = styled.div`
  flex: 1;
  padding: 20px 30px;
`;
const Date = styled.div`
  color: rgba(126, 126, 126, 1);
  margin-top: 10px;
`;
const TableContainer = styled.div`
  overflow-x: auto;
  @media ${mediaQueries.desktop} {
    overflow-x: auto;
  }
`;
const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid #ccc;
  }
  tr:last-child {
    border-bottom: none;
  }
  td,
  th {
    font-size: 1rem;
    letter-spacing: 0.5px;
    padding: 10px 15px;
    text-align: center;
  }
  th {
    font-weight: 500;
  }
`;

const Billing = ({ userData }) => {
  return (
    <Container>
      <StyledBlock>
        <StyledTitle>
          <FormattedMessage id="settings.billing.title" defaultMessage="Current plan" />
        </StyledTitle>
        <StyledBody>
          <TableContainer>
            <Table>
              <tbody>
                <tr>
                  <th>
                    <FormattedMessage
                      id="settings.billing.table.subscription_tier"
                      defaultMessage="Subscription tier"
                    />
                  </th>
                  <th>
                    <FormattedMessage id="settings.billing.table.status" defaultMessage="Status" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="settings.billing.table.agents"
                      defaultMessage="Number of agents"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="settings.billing.table.stores"
                      defaultMessage="Number of stores"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="settings.billing.table.price"
                      defaultMessage="Total price"
                    />
                  </th>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage
                      id="settings.billing.table.business"
                      defaultMessage="Business"
                    />
                  </td>
                  <td>
                    <Status type="pending">
                      <FormattedMessage
                        id="settings.billing.table.payment"
                        defaultMessage="Pending Payment"
                      />
                    </Status>
                  </td>
                  <td>6</td>
                  <td>10</td>
                  <td>$199/month</td>
                </tr>
              </tbody>
            </Table>
          </TableContainer>
          <Buttons>
            <Button size="large" variant="contained" color="primary">
              <FormattedMessage
                id="settings.billing.table.change_tier"
                defaultMessage="Change tier"
              />
            </Button>
            <Button size="large" variant="outlined">
              <FormattedMessage
                id="settings.billing.table.cancel_subscription"
                defaultMessage="Cancel subscription"
              />
            </Button>
          </Buttons>
        </StyledBody>
      </StyledBlock>
      <StyledBlock>
        <StyledTitle>
          <StyledLabel>
            <FormattedMessage
              id="settings.billing.table.upcoming_changes"
              defaultMessage="Upcoming changes"
            />
          </StyledLabel>
          <SwitchContainer>
            <SwitchLabel>
              <FormattedMessage
                id="settings.billing.table.auto_renew"
                defaultMessage="Auto-renew"
              />
            </SwitchLabel>
            <Switcher>
              <input type="checkbox" />
              <i />
            </Switcher>
          </SwitchContainer>
        </StyledTitle>
        <StyledBody>
          <TableContainer>
            <Table>
              <tbody>
                <tr>
                  <th>
                    <FormattedMessage id="settings.billing.table.date" defaultMessage="Date" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="settings.billing.table.billing_number"
                      defaultMessage="Billing number"
                    />
                  </th>
                  <th>
                    <FormattedMessage id="settings.billing.table.tier" defaultMessage="Tier" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="settings.billing.table.subtotal"
                      defaultMessage="Subtotal"
                    />
                  </th>
                  <th>
                    <FormattedMessage id="settings.billing.table.total" defaultMessage="Total" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="settings.billing.table.next_payment"
                      defaultMessage="Next Payment Attempt"
                    />
                  </th>
                </tr>
                <tr>
                  <td>December 15th 2021</td>
                  <td>832791673</td>
                  <td>Seller</td>
                  <td>199 USD</td>
                  <td>199 USD</td>
                  <td>December 15th 2021</td>
                </tr>
              </tbody>
            </Table>
          </TableContainer>
        </StyledBody>
      </StyledBlock>
      <StyledBlock>
        <StyledTitle>
          <FormattedMessage id="settings.billing.table.active_card" defaultMessage="Active card:" />
        </StyledTitle>
        <StyledBodyCard>
          <CardsContainer>
            <Card>
              <CardsTitle>
                <Visa />
                <strong>7351</strong>
              </CardsTitle>
              <CardInfo>
                <FormattedMessage
                  id="settings.billing.table.user_name"
                  defaultMessage="{name} {surname}"
                  values={{
                    name: userData?.name,
                    surname: userData?.surname,
                  }}
                />
                <br />
                <FormattedMessage
                  id="settings.billing.table.user_address"
                  defaultMessage="{address}"
                  values={{ address: userData?.address }}
                />
              </CardInfo>
            </Card>
            <Expires>
              <CardsTitle>
                <FormattedMessage id="settings.billing.table.expires" defaultMessage="Expires:" />
              </CardsTitle>
              <Date>09/2028</Date>
            </Expires>
          </CardsContainer>
          <Buttons>
            <Button size="large" variant="contained" color="primary">
              <FormattedMessage
                id="settings.billing.table.new_card"
                defaultMessage="Add new card"
              />
            </Button>
            <Button size="large" variant="outlined">
              <FormattedMessage
                id="settings.billing.table.remove_card"
                defaultMessage="Remove card"
              />
            </Button>
          </Buttons>
        </StyledBodyCard>
      </StyledBlock>
      <StyledBlock>
        <StyledTitle>
          <FormattedMessage id="settings.billing.table.invoices" defaultMessage="Invoices" />
        </StyledTitle>
        <StyledBody>
          <TableContainer>
            <Table>
              <tbody>
                <tr>
                  <th>
                    <FormattedMessage id="settings.billing.table.date" defaultMessage="Date" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="settings.billing.table.subscription"
                      defaultMessage="Subscription"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="settings.billing.table.agents"
                      defaultMessage="Number of Agents"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="settings.billing.table.receipt"
                      defaultMessage="Download receipt"
                    />
                  </th>
                </tr>
                <tr>
                  <td>November 15th 2021</td>
                  <td>
                    <FormattedMessage
                      id="settings.billing.table.premium"
                      defaultMessage="Premium (monthly billing)"
                    />
                  </td>
                  <td>3</td>
                  <td>
                    <a href=".">
                      <FormattedMessage
                        id="settings.billing.table.download"
                        defaultMessage="Download as PDF"
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>October 15th 2021</td>
                  <td>
                    <FormattedMessage
                      id="settings.billing.table.premium"
                      defaultMessage="Premium (monthly billing)"
                    />
                  </td>
                  <td>2</td>
                  <td>
                    <a href=".">
                      <FormattedMessage
                        id="settings.billing.table.download"
                        defaultMessage="Download as PDF"
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>September 15th 2021</td>
                  <td>
                    <FormattedMessage
                      id="settings.billing.table.starter"
                      defaultMessage="Starter (monthly billing)"
                    />
                  </td>
                  <td>0</td>
                  <td>
                    <a href=".">
                      <FormattedMessage
                        id="settings.billing.table.download"
                        defaultMessage="Download as PDF"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </TableContainer>
        </StyledBody>
      </StyledBlock>
    </Container>
  );
};

export default Billing;
