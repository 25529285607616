import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  width: 100%;
  box-shadow: 8px 0 18px rgb(0 0 0 / 6%);
  @media ${mediaQueries.desktop} {
    max-width: 367px;
  }
`;
const Item = styled.div<{ isActive: boolean }>`
  padding: 15px 20px;
  background: ${(props) => (props.isActive ? '#fff' : 'rgba(196, 196, 196, 0.2)')};
  position: relative;
  cursor: pointer;
  transition: background 0.2s;
  margin: 20px 15px 0;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;
const Info = styled.div`
  display: flex;
`;
const InfoItem = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Image = styled.img`
  height: 50px;
  margin-right: 15px;
`;
const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;
const Copy = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
`;
const Heading = styled.div`
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 700;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
`;
const InboxItems = ({ handleChatOpen, isFAQ }) => {
  return (
    <Container>
      <Heading>
        <FormattedMessage id="support.inbox.title" defaultMessage="Support" />
      </Heading>
      <Item
        key={`faq`}
        isActive={isFAQ}
        onClick={() => {
          handleChatOpen('faq');
        }}
      >
        <Info>
          <Image src="/img/question.svg" alt="Omcom FAQ" />
          <InfoItem>
            <Title>
              <FormattedMessage
                id="support.inbox.faq.title"
                defaultMessage="Frequently Asked Questions"
              />
            </Title>
            <Copy>
              <FormattedMessage
                id="support.inbox.faq.text"
                defaultMessage="Find a answer in our knowledge base!"
              />
            </Copy>
          </InfoItem>
        </Info>
      </Item>
      <Item
        key={`chat`}
        isActive={!isFAQ}
        onClick={() => {
          handleChatOpen('chat');
        }}
      >
        <Info>
          <Image src="/logo.png" alt="Omcom Support" />
          <InfoItem>
            <Title>
              <FormattedMessage id="support.inbox.chat.title" defaultMessage="Omcom Support Chat" />
            </Title>
            <Copy>
              <FormattedMessage
                id="support.inbox.chat.text"
                defaultMessage="Write us, we are online!"
              />
            </Copy>
          </InfoItem>
        </Info>
      </Item>
    </Container>
  );
};

export default InboxItems;
