export const faqData = [{
  title: 'support.faqData.registration.title',
  copy: 'support.faqData.registration.text',
},{
  title: 'support.faqData.reset_password.title',
  copy: 'support.faqData.reset_password.text',
},{
  title: 'support.faqData.new_user.title',
  video: '/faq/how_to_add_a_user.mp4',
  copy: 'support.faqData.new_user.text',
},{
  title: 'support.faqData.new_store.title',
  copy: 'support.faqData.new_store.text',
},{
  title: 'support.faqData.user_role.title',
  video: '/faq/user_access.mp4',
  copy: 'support.faqData.user_role.text',
},{
  title: 'support.faqData.folders_logic.title',
  video: '/faq/folders_logic.mp4',
  copy: 'support.faqData.folders_logic.text',
},{
  title: 'support.faqData.filters.title',
  video: '/faq/filters.mp4',
  copy: 'support.faqData.filters.text',
},{
  title: 'support.faqData.follow_ups.title',
  video: '/faq/follow_ups.mp4',
  copy: 'support.faqData.follow_ups.text',
},{
  title: 'support.faqData.assign_ticket.title',
  video: '/faq/assign_ticket.mp4',
  copy: 'support.faqData.assign_ticket.text',
},{
  title: 'support.faqData.tags.title',
  video: '/faq/tags.mp4',
  copy: 'support.faqData.tags.text',
}];
