import { Content, ItemHead, Message } from '../styles';
import { ProductsList } from './styles';
import DeliveryStatus from '../../DeliveryStatus';
import LastErrorMessage from '../../LastErrorMessage';
import Avatar from '../../Avatar';
import ProductItem from '../Product/item';
import { FormattedMessage } from 'react-intl';
import ErrorMessage from '../../ErrorMessage';

export default ({ client, message, customerDataStoreTimezone, more = null }) => {
  return (
    <Message isClient={client} key={message?._id?.$oid + message?.sendId}>
      <Content>
        <ProductsList>
          <Avatar message={message} isClient={client} />
          <ItemHead>
            <FormattedMessage
              id="messages.chat.messages.products_list_title"
              defaultMessage="Product List"
            />
          </ItemHead>
          {Array.isArray(message?.body?.products)
            ? message?.body?.products?.map((item, index) => (
                <ProductItem
                  product={item}
                  key={`${item?.productId}-productItem-${index}`}
                  isAList={true}
                  isACart={false}
                />
              ))
            : ''}
        </ProductsList>
        {more}
        <DeliveryStatus
          isClient={client}
          message={message}
          customerDataStoreTimezone={customerDataStoreTimezone}
        />
        <LastErrorMessage message={message} />
        <ErrorMessage message={message?.rawResponse?.process_msg ?? ''} />
      </Content>
    </Message>
  );
};
