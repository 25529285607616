import { useState } from 'react';
import SurveysList from '../../components/SurveysList';
import { FormattedMessage } from 'react-intl';
import Popup from '../../components/Popup';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback, ErrorBoundaryHandler } from '../../components/ErrorBoundary';
import Error from '../../components/Error';
import Header from '../../components/Header';
import { Body, PageContainer } from '../../components/Layout';
import Nav from '../../components/Nav';
import {
  BackButton,
  ButtonsContainer,
  Container,
  MainContainer,
  ModalTitle,
  SaveButton,
  StyledButton,
  Text,
  Title,
  TitleContainer,
} from './styles';
import {
  useDeleteSurveyMutation,
  useFetchTemplateSurveyQuery,
  useSurveysQuery,
  useUpdateStatusSurveyMutation,
} from '../../api/surveyAPI';
import SurveysForm from '../../containers/SurveyForm';
import { setValuesFormTemplate } from '../../api/setValuesFormTemplate';

const Surveys = () => {
  const [currentSurveyId, setCurrentSurveyId] = useState(null);
  const [showSurveyPopup, setShowSurveyPopup] = useState(false);
  const [showUneditableAlert, setShowUneditableAlert] = useState(false);
  const [showConfirmActivation, setShowConfirmActivation] = useState(false);
  const [showConfirmInactivation, setShowConfirmInactivation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [requiredFields, setRequiredFields] = useState({});
  const { data: surveys, error: errorQuery } = useSurveysQuery(true);
  const { data: templateSurvey, error: errorTemplate } = useFetchTemplateSurveyQuery(true);
  const [updateStatusSurvey, { error: errorUpdate }] = useUpdateStatusSurveyMutation();
  const [deleteSurvey, { error: errorDelete }] = useDeleteSurveyMutation();
  const [isPreview, setIsPreview] = useState(false);

  const handleFormOpen = () => {
    let temp = setValuesFormTemplate(templateSurvey?.data, null, () =>
      Object.assign(
        {},
        {
          id: 1,
          questionText: '',
          answerType: '1-5',
        },
      ),
    );
    setInputValues(temp.inputValues);
    setRequiredFields(temp.requiredFields);
    setShowSurveyPopup(true);
  };
  const closePopupAndClear = () => {
    setCurrentSurveyId(null);
    setShowConfirmActivation(false);
    setShowUneditableAlert(false);
    setShowDeleteConfirmation(false);
    setIsPreview(false);
    setShowSurveyPopup(false);
  };
  const handleStatus = async (status) => {
    status === 'active' ? setShowConfirmActivation(false) : setShowConfirmInactivation(false);
    setShowSurveyPopup(false);
    await updateStatusSurvey({
      id: currentSurveyId,
      survey: {
        status: status,
      },
    });
  };
  const handleDelete = () => {
    deleteSurvey(currentSurveyId);
    closePopupAndClear();
  };

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
        <Header />
      </ErrorBoundary>
      <PageContainer>
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
          <Nav currentStep="surveys" />
        </ErrorBoundary>
        <Body>
          <MainContainer>
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
              {showSurveyPopup && (
                <Popup
                  isPreviewTicket={false}
                  setIsPreviewTicket={() => {}}
                  handleClose={closePopupAndClear}
                >
                  <ModalTitle>
                    <FormattedMessage id="surveys.form.title" defaultMessage="Create chat survey" />
                  </ModalTitle>
                  {(showConfirmActivation && (
                    <>
                      <Text>
                        <FormattedMessage id="surveys.modal.active.confirm" />
                      </Text>
                      <ButtonsContainer>
                        <BackButton variant="outlined" onClick={closePopupAndClear}>
                          <FormattedMessage id="surveys.popup.button.back" />
                        </BackButton>
                        <SaveButton variant="contained" onClick={() => handleStatus('active')}>
                          <FormattedMessage id="surveys.popup.button.save" />
                        </SaveButton>
                      </ButtonsContainer>
                    </>
                  )) ||
                    (showConfirmInactivation && (
                      <>
                        <Text>
                          <FormattedMessage id="surveys.modal.inactive.confirm" />
                        </Text>
                        <ButtonsContainer>
                          <BackButton variant="outlined" onClick={closePopupAndClear}>
                            <FormattedMessage id="surveys.popup.button.back" />
                          </BackButton>
                          <SaveButton variant="contained" onClick={() => handleStatus('inactive')}>
                            <FormattedMessage id="surveys.popup.button.save" />
                          </SaveButton>
                        </ButtonsContainer>
                      </>
                    )) ||
                    (showUneditableAlert && (
                      <>
                        <Text>
                          <FormattedMessage id="surveys.modal.alert.uneditable" />
                        </Text>
                        <ButtonsContainer>
                          <SaveButton variant="contained" onClick={closePopupAndClear}>
                            <FormattedMessage id="surveys.modal.button.ok" />
                          </SaveButton>
                        </ButtonsContainer>
                      </>
                    )) ||
                    (showDeleteConfirmation && (
                      <>
                        <Text>
                          <FormattedMessage id="surveys.modal.delete.confirm" />
                        </Text>
                        <ButtonsContainer>
                          <BackButton variant="outlined" onClick={closePopupAndClear}>
                            <FormattedMessage id="surveys.popup.button.no" />
                          </BackButton>
                          <SaveButton variant="contained" color="primary" onClick={handleDelete}>
                            <FormattedMessage id="surveys.popup.button.yes" />
                          </SaveButton>
                        </ButtonsContainer>
                      </>
                    )) || (
                      <SurveysForm
                        inputValues={inputValues}
                        requiredFields={requiredFields}
                        setInputValues={setInputValues}
                        setShowSurveyPopup={setShowSurveyPopup}
                        isPreview={isPreview}
                      />
                    )}
                </Popup>
              )}
              <Container>
                <TitleContainer>
                  <Title>
                    <FormattedMessage id="surveys.title.label" />
                  </Title>
                  <StyledButton variant="contained" onClick={handleFormOpen}>
                    <FormattedMessage id="surveys.title.button" />
                  </StyledButton>
                </TitleContainer>
                {/*<Total>*/}
                {/*  <TotalItem>*/}
                {/*    <TotalItemNumber>8.0</TotalItemNumber>*/}
                {/*    <TotalItemText>NPS</TotalItemText>*/}
                {/*  </TotalItem>*/}
                {/*  <TotalItem>*/}
                {/*    <TotalItemNumber>9.5</TotalItemNumber>*/}
                {/*    <TotalItemText>24 hrs NPS</TotalItemText>*/}
                {/*  </TotalItem>*/}
                {/*  <TotalItem>*/}
                {/*    <TotalItemNumber>35%</TotalItemNumber>*/}
                {/*    <TotalItemText>Competiton</TotalItemText>*/}
                {/*  </TotalItem>*/}
                {/*</Total>*/}
                {(errorTemplate && 'error' in errorTemplate && (
                  <Error message={errorTemplate.error} />
                )) ||
                  (errorUpdate && 'error' in errorUpdate && (
                    <Error message={errorUpdate.error} />
                  )) ||
                  (errorDelete && 'error' in errorDelete && (
                    <Error message={errorDelete.error} />
                  )) ||
                  (errorQuery && 'error' in errorQuery && <Error message={errorQuery.error} />)}
                <SurveysList
                  surveys={surveys?.data ?? []}
                  setInputValues={setInputValues}
                  setRequiredFields={setRequiredFields}
                  setCurrentSurveyId={setCurrentSurveyId}
                  setShowSurveyPopup={setShowSurveyPopup}
                  setShowConfirmActivation={setShowConfirmActivation}
                  setShowConfirmInactivation={setShowConfirmInactivation}
                  setShowDeleteConfirmation={setShowDeleteConfirmation}
                  setShowUneditableAlert={setShowUneditableAlert}
                  setIsPreview={setIsPreview}
                />
              </Container>
            </ErrorBoundary>
          </MainContainer>
        </Body>
      </PageContainer>
    </>
  );
};

export default Surveys;
