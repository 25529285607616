import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalTitle } from '../../components/Modal';
import { EmailInput, PasswordInput } from '../../components/Input';
import Error from '../../components/Error';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { mediaQueries } from '../../constants/breakpoints';
import { FormattedMessage, useIntl } from 'react-intl';
import Header from '../../components/Header';
import postAccountRegister from '../../api/postAccountRegister';
import { TailSpin } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

const Container = styled.div`
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.desktop} {
    background-color: #f5f6f8;
  }
`;
const SubText = styled.p`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #c4c4c4;
`;
const Note = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  text-align: left;
  margin-top: 15px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  button,
  a {
    min-width: 100%;
    margin-bottom: 30px;
    @media ${mediaQueries.desktop} {
      //noinspection CssInvalidPropertyValue
      min-width: 48%;
    }
  }
`;
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 20px -10px;
`;
const Copy = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
`;
const TextFieldContainer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;

  > div {
    flex-grow: 1;

    &:nth-child(2) {
      margin-top: 20px;
    }
  }

  @media ${mediaQueries.desktop} {
    flex-direction: row;
    > div:nth-child(2) {
      margin: 0 0 0 15px;
    }
  }
`;

const SignUp = () => {
  const [complete, setComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: '',
    surname: '',
    company: '',
    telephone: '',
    email: '',
    password: '',
    password_confirm: '',
    terms: '',
    captcha: '',
  });

  useEffect(() => {
    if ('email' in values) {
      const trimmedEmail = String(values.email).replaceAll(' ', '');
      setValues({ ...values, email: trimmedEmail });
    }
  }, [values]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const intl = useIntl();

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if ('email' in fieldValues) {
      fieldValues.email = String(fieldValues.email).replaceAll(' ', '');
      temp.email = fieldValues.email ? '' : 'This field is required.';

      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ''
          : 'Email is not valid.';
    }

    if ('name' in fieldValues) temp.name = fieldValues.name ? '' : 'This field is required.';

    if ('surname' in fieldValues)
      temp.surname = fieldValues.surname ? '' : 'This field is required.';

    if ('company' in fieldValues)
      temp.company = fieldValues.company ? '' : 'This field is required.';

    if ('password' in fieldValues)
      temp.password = fieldValues.password ? '' : 'This field is required.';

    if ('password_confirm' in fieldValues)
      temp.password_confirm = fieldValues.password_confirm ? '' : 'This field is required.';

    setErrors({
      ...temp,
    });
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.email &&
      fieldValues.name &&
      fieldValues.surname &&
      fieldValues.company &&
      fieldValues.password &&
      fieldValues.password_confirm &&
      Object.values(errors).every((x) => x === '');
    return isValid;
  };

  const handleCheckboxChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    validate();
    const isValid = Object.values(errors).every((x) => x === '') && formIsValid();
    if (isValid) {
      setLoading(true);
      const userEmail = String(values.email).replaceAll(' ', '');
      postAccountRegister(
        {
          user: {
            username: userEmail,
            email: userEmail,
            name: values.name,
            surname: values.surname,
            company: values.company,
            telephone: values.telephone,
            agreement: true,
            password: values.password,
            password_confirm: values.password_confirm,
          },
        },
        setComplete,
        setServerError,
        setLoading,
      );
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Modal>
          {complete ? (
            <ModalTitle>
              <FormattedMessage
                id="signup.modal.confirm"
                defaultMessage="Email with confirmation link was sent. Please check your inbox."
              />
            </ModalTitle>
          ) : (
            <>
              <ModalTitle>
                <FormattedMessage id="signup.modal.title" defaultMessage="Nice to have you here" />
              </ModalTitle>
              <SubText>
                <FormattedMessage
                  id="signup.modal.text"
                  defaultMessage="Join our community right now!"
                />
              </SubText>
              {serverError && <Error message={serverError} />}
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <TextFieldContainer>
                  <TextField
                    id="name"
                    label={intl.formatMessage({
                      id: 'signup.form.name',
                      defaultMessage: 'signup.form.name',
                    })}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    error={errors['name']}
                    autoComplete="none"
                    onChange={handleChange('name')}
                    {...(errors['name'] && {
                      error: true,
                      helperText: errors['name'],
                    })}
                  />
                  <TextField
                    id="surname"
                    label={intl.formatMessage({
                      id: 'signup.form.surname',
                      defaultMessage: 'signup.form.surname',
                    })}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    error={errors['surname']}
                    autoComplete="none"
                    onChange={handleChange('surname')}
                    {...(errors['surname'] && {
                      error: true,
                      helperText: errors['surname'],
                    })}
                  />
                </TextFieldContainer>
                <TextFieldContainer>
                  <TextField
                    id="company"
                    label={intl.formatMessage({
                      id: 'signup.form.company',
                      defaultMessage: 'signup.form.company',
                    })}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    error={errors['company']}
                    autoComplete="none"
                    onChange={handleChange('company')}
                    {...(errors['company'] && {
                      error: true,
                      helperText: errors['company'],
                    })}
                  />
                </TextFieldContainer>
                <TextFieldContainer>
                  <EmailInput
                    id="email"
                    value={values.email}
                    label={intl.formatMessage({
                      id: 'signup.form.email',
                      defaultMessage: 'signup.form.email',
                    })}
                    type="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors['email']}
                    autoComplete="none"
                    handleChange={handleChange('email')}
                    {...(errors['email'] && {
                      error: true,
                      helperText: errors['email'],
                    })}
                  />
                </TextFieldContainer>
                <TextFieldContainer>
                  <PasswordInput
                    id="password"
                    value={values.password}
                    error={errors['password']}
                    autoComplete="none"
                    handleChange={handleChange('password')}
                    label={intl.formatMessage({
                      id: 'signup.form.password',
                      defaultMessage: 'signup.form.password',
                    })}
                    {...(errors['password'] && {
                      error: true,
                      helperText: errors['password'],
                    })}
                  />
                </TextFieldContainer>
                <TextFieldContainer>
                  <PasswordInput
                    id="password_confirm"
                    value={values.password_confirm}
                    error={errors['password_confirm']}
                    autoComplete="none"
                    handleChange={handleChange('password_confirm')}
                    label={intl.formatMessage({
                      id: 'signup.form.repeat_password',
                      defaultMessage: 'signup.form.repeat_password',
                    })}
                    {...(errors['password_confirm'] && {
                      error: true,
                      helperText: errors['password_confirm'],
                    })}
                  />
                </TextFieldContainer>
                <Note>
                  <FormattedMessage
                    id="createPassword.form.note"
                    defaultMessage="Note: Protect your data confidentiality. Do not share your password with anyone for any reason."
                  />
                </Note>
                <CheckboxContainer>
                  <Checkbox
                    color="primary"
                    value="agree"
                    onChange={handleCheckboxChange('terms')}
                  />
                  <Copy
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        {
                          id: 'signup.form.termspolicy.title',
                          defaultMessage: 'signup.form.termspolicy.title',
                        },
                        {
                          terms: intl.formatMessage({
                            id: 'signup.form.termspolicy.terms',
                            defaultMessage: 'signup.form.termspolicy.terms',
                          }),
                          policy: intl.formatMessage({
                            id: 'signup.form.termspolicy.policy',
                            defaultMessage: 'signup.form.termspolicy.policy',
                          }),
                        },
                      ),
                    }}
                  />
                </CheckboxContainer>
                {/* TODO: Update key with production one  */}
                {/* <GoogleReCaptchaProvider reCaptchaKey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI">
                <GoogleReCaptcha
                  onVerify={token => {
                    setValues({ ...values, captcha: token });
                  }}
                />
              </GoogleReCaptchaProvider> */}
                <ButtonsContainer>
                  {isLoading ? (
                    <Button component={Link} to="#" size="large" variant="contained" color="primary">
                      <TailSpin color="#fff" width="20" height="20" />
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!values.terms}
                    >
                      <FormattedMessage
                        id="signup.form.button.create"
                        defaultMessage="Create Account"
                      />
                    </Button>
                  )}

                  <Copy
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        {
                          id: 'signup.form.link_login',
                          defaultMessage: 'signup.form.link_login',
                        },
                        {
                          label: intl.formatMessage({
                            id: 'signup.form.link_login.label',
                            defaultMessage: 'signup.form.link_login.label',
                          }),
                        },
                      ),
                    }}
                  />
                </ButtonsContainer>
              </form>
            </>
          )}
        </Modal>
      </Container>
    </>
  );
};

export default SignUp;
