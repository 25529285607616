import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthenticationHeaders } from '../modules/fetch';
import { API } from '../config';

export const broadcastApi = createApi({
  reducerPath: 'broadcastApi',
  baseQuery: fetchBaseQuery({ baseUrl: API }),
  tagTypes: ['Broadcast'],
  endpoints: (builder) => ({
    broadcast: builder.query({
      query: ({ page = 1, perPage = 100000 }) => ({
        url: `/broadcast/listings/${page ?? 1},${perPage ?? 100}`,
        headers: getAuthenticationHeaders('application/json'),
      }),
      providesTags: ['Broadcast'],
    }),
    fetchTemplate: builder.query({
      query: () => ({
        url: '/broadcast/template',
        headers: getAuthenticationHeaders('application/json'),
      }),
    }),
    addBroadcast: builder.mutation({
      query: (broadcast) => ({
        url: '/broadcast/create',
        method: 'POST',
        headers: getAuthenticationHeaders('application/json'),
        body: broadcast,
      }),
      invalidatesTags: ['Broadcast'],
    }),
    updateBroadcast: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/broadcast/update/${id}`,
        method: 'PUT',
        headers: getAuthenticationHeaders('application/json'),
        body: { broadcast: rest },
      }),
      invalidatesTags: ['Broadcast'],
    }),
    deleteBroadcast: builder.mutation({
      query: (id) => ({
        url: `/broadcast/remove/${id}`,
        method: 'DELETE',
        headers: getAuthenticationHeaders('application/json'),
      }),
      invalidatesTags: ['Broadcast'],
    }),
  }),
});

export const {
  useBroadcastQuery,
  useFetchTemplateQuery,
  useAddBroadcastMutation,
  useUpdateBroadcastMutation,
  useDeleteBroadcastMutation,
} = broadcastApi;
