import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { mediaQueries } from '../../constants/breakpoints';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays, format } from 'date-fns';
import Button from '@mui/material/Button';
import { getInNewWindow } from '../../modules/fetch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AccountDataContext } from '../../context/AccountDataContext';
import { useUpdateAccountSettingsMutation } from '../../api/accountAPI';
import Error from '../../components/Error';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  max-width: 90%;
  width: 100%;
  margin: 0px auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  //position: relative;
  //top: 50%;
  //transform: translateY(-50%);
  @media ${mediaQueries.tablet} {
    max-width: 548px;
  }

  .rdrDayPassive .rdrDayNumber span {
    color: transparent;
  }
`;

const Heading = styled.div`
  background-color: #ecf3f9;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
`;

const Copy = styled.div`
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  margin: 30px 0;
  max-width: 370px;
  color: #000;
`;

const DateContainer = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

const DateInput = styled.div`
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 4px;
  padding: 8px 12px;
  color: #000;
  font-size: 1rem;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 16px;
    position: relative;
    top: -1px;
  }
`;

const StyledCalendarTodayIcon = styled(CalendarTodayIcon)`
  margin-right: 5px;
`;

const StyledDateRange = styled(DateRange)`
  position: absolute;
  left: 0;
  top: 35px;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px 30px;
`;

const SupportReports = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [serverError, setServerError] = useState('');
  const [updateAccountSettings] = useUpdateAccountSettingsMutation();
  const [currentAccount, , , , reloadAccount] = useContext(AccountDataContext);
  const [report, setReport] = useState({
    period: '',
    email: ''
  });
  const [date, setDate] = useState([
    {
      startDate: addDays(new Date(), -6),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const intl = useIntl();

  const handleDateRangeChange = (range) => {
    setDate([range.selection]);
    if (range.selection.startDate !== range.selection.endDate) {
      setShowCalendar(false);
    }
  };
  const handleGenerate = () => {
    getInNewWindow(
      '/tickets/export',
      `dateFrom=${format(date[0].startDate, 'yyyy-MM-dd')} 00:00:00` +
      `&dateTo=${format(date[0].endDate, 'yyyy-MM-dd')} 23:59:59`,
    );
  };

  useEffect(() => {
    setReport(currentAccount.settings?.report?.automatically ?? {
      period: '',
      email: ''
    })
  }, []);

  useEffect(() => {
    console.log('currentAccount.settings', currentAccount.settings);
    setReport(currentAccount.settings?.report?.automatically ?? {
      period: '',
      email: ''
    })
  }, [currentAccount]);

  const handleChange = (prop) => (event) => {
    const automatically = {
      [prop]: event.target.value
    };
    setReport({ ...report, ...automatically });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setServerError('Updating');
    await updateAccountSettings({
      account: {
        settings: {
          report: { automatically: report },
        },
      },
    });
    setServerError('');
    reloadAccount();
  };

  return (
    <>
      {serverError && <Error message={serverError} />}

      <Container>
        <Heading>
          <Title>
            <FormattedMessage
              id="reports.title.customer_support_reports"
              defaultMessage="Customer Support Reports"
            />
          </Title>
        </Heading>
        <Actions>
          <Copy>
            <FormattedMessage
              id="reports.title.actions"
              defaultMessage="Please select the date range to generate the customer support report"
            />
          </Copy>
          <DateContainer>
            <DateInput onClick={() => setShowCalendar(!showCalendar)}>
              <StyledCalendarTodayIcon />
              {format(date[0].startDate, 'd MMMM y')} - {format(date[0].endDate, 'd MMMM y')}
            </DateInput>
            {showCalendar && (
              <StyledDateRange
                editableDateInputs={true}
                onChange={handleDateRangeChange}
                moveRangeOnFirstSelection={false}
                ranges={date}
                showDateDisplay={false}
              />
            )}
          </DateContainer>
          <Button variant="contained" color="primary" onClick={handleGenerate}>
            <FormattedMessage id="reports.actions.button" defaultMessage="Generate" />
          </Button>
        </Actions>
      </Container>
      <br/>
      <Container>
        <Heading>
          <Title>
            <FormattedMessage
              id="reports.title.automatically_generated_reports"
              defaultMessage="Automatically Generated Reports"
            />
          </Title>
        </Heading>
        <Actions>
          <Copy>
            <FormattedMessage
              id="reports.title.automatically.actions"
              defaultMessage="Please select the period generate the customer support report"
            />
          </Copy>
          <DateContainer>
            <Box sx={{ minWidth: 300 }}>
              <FormControl fullWidth>
                <InputLabel id="period-select-label">
                  <FormattedMessage id="reports.title.automatically.period.label" defaultMessage="Period" />
                </InputLabel>
                <Select
                  labelId="period-select-label"
                  id={'period-select'}
                  label="period-select"
                  value={report.period}
                  onChange={handleChange('period')}
                >
                  <MenuItem value={'1w'}><FormattedMessage id="reports.title.automatically.period.1w" defaultMessage="Once a week" /></MenuItem>
                  <MenuItem value={'2w'}><FormattedMessage id="reports.title.automatically.period.2w" defaultMessage="Every 2 weeks" /></MenuItem>
                  <MenuItem value={'2m'}><FormattedMessage id="reports.title.automatically.period.1m" defaultMessage="Once a month" /></MenuItem>
                </Select>
              </FormControl>
              <br /><br />
              <FormControl fullWidth>
                <TextField
                  label={intl.formatMessage({
                    id: 'reports.title.automatically.email.label',
                    defaultMessage: 'E-Mail',
                  })}
                  id={'email-input'}
                  type={'email'}
                  value={report.email}
                  onChange={handleChange('email')}
                />
              </FormControl>
            </Box>
          </DateContainer>
          <Button variant="contained" color="primary" onClick={handleFormSubmit}>
            <FormattedMessage id="reports.actions.automatically.button" defaultMessage="Update" />
          </Button>
        </Actions>
      </Container>
    </>
  );
};

export default SupportReports;
