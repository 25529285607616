import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateRange } from 'react-date-range';
import { mediaQueries } from '../../constants/breakpoints';

export const Container = styled.div`
  display: flex;
  width: 100%;
  @media ${mediaQueries.mobile} {
    width: auto;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
`;

export const SearchInputContainer = styled.div`
  flex: 1;
  margin-right: 5px;
  background: #fff;
  display: flex;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0 8px !important;
`;
export const Image = styled.img`
  color: #6f6f6f;
  cursor: pointer;
`;
export const StyledMenu = styled(Menu)`
  overflow-y: visible !important;

  .MuiPaper-root.MuiMenu-paper {
    overflow-x: auto !important;
    overflow-y: visible !important;
    z-index: 1;
  }
`;
export const Filter = styled.div`
  display: flex;

  .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
    color: #5abb7a !important;
  }
`;
export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;
export const RowContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;

  .MuiAutocomplete-root {
    width: 100%;
  }

  .MuiAutocomplete-root fieldset,
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #6f6f6f !important;
  }

  .MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
    color: #006876 !important;
  }

  .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #006876 !important;
  }

  .MuiChip-root {
    border-radius: 10px !important;
  }
`;
export const StyledAvatar = styled(Avatar)`
  width: 20px !important;
  height: 15px !important;
  margin-left: 10px !important;
  border-radius: 0 !important;

  span {
    font-size: 1rem;
  }
`;
export const StyledAvatarChannel = styled(StyledAvatar)`
  width: 15px !important;
`;
export const Title = styled.div<{ bold?: boolean }>`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 20px 24px 0;
`;

export const FilterTitle = styled(Title)`
  padding: 20px 0;
`;

export const Label = styled.label<{ bold?: boolean }>`
  font-size: 1rem;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 35px;

  .MuiButton-root {
    margin-left: 15px;
    padding: 5px 30px;
    text-transform: none;
    font-size: 1rem;
    color: #fff;
    background: #006876;
    border-radius: 8px;
  }

  .reset-button {
    color: #006876;
    background: #fff;
    border: 1px solid #006876;
  }

  .MuiButton-root:hover {
    background: #006876;
  }

  .reset-button:hover {
    background: #fff;
  }
`;

export const DateContainer = styled.div`
  position: relative;

  .rdrCalendarWrapper {
    font-size: 1rem;
  }
`;
export const DateInput = styled.div`
  border: 1px solid #6f6f6f;
  background-color: #fff;
  border-radius: 4px;
  padding: 18px 12px;
  color: #6f6f6f;
  font-size: 1rem;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 16px;
    position: relative;
    top: -1px;
  }
`;
export const StyledCalendarTodayIcon = styled(CalendarTodayIcon)`
  margin-right: 5px;
`;

export const StyledDateRange = styled(DateRange)`
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  z-index: 2000;

  .rdrNextPrevButton {
    background: #fff;
  }

  .rdrNextPrevButton:hover {
    background: #eff2f7;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    color: #006876 !important;
  }

  .rdrSelected,
  .rdrInRange {
    color: #ecf3f9 !important;
  }

  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
    color: #fff !important;
    font-weight: 700 !important;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #454a5f !important;
    font-weight: 400 !important;
  }

  .rdrDayToday .rdrDayNumber span:after {
    background: #006876 !important;
  }

  .rdrDayNumber span {
    color: #454a5f !important;
  }

  .rdrDayPassive span {
    color: transparent !important;
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 10px !important;
  padding: 8px 10px !important;
  color: #000 !important;
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  background-color: #eeeeee !important;

  :active {
    background-color: #e1fde6 !important;
    transition: none !important;
  }
`;
