import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, ModalTitle } from '../../components/Modal';
import { mediaQueries } from '../../constants/breakpoints';
import Error from '../../components/Error';
import Header from '../../components/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import postAccountConfirm from '../../api/postAccountConfirm';

const Container = styled.div`
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.desktop} {
    background-color: #f5f6f8;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  button {
    min-width: 100%;
    margin-bottom: 30px;
    @media ${mediaQueries.desktop} {
      //noinspection CssInvalidPropertyValue
      min-width: 48%;
    }
  }
`;
const Copy = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
`;

const ConfirmEmail = () => {
  const [complete, setComplete] = useState(false);
  const [serverError, setServerError] = useState('');
  const intl = useIntl();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    postAccountConfirm({ verifyCode: urlParams.get('code') }, setComplete, setServerError);
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Modal>
          {complete ? (
            <>
              <ModalTitle>
                <FormattedMessage
                  id="confirmEmail.modal.title"
                  defaultMessage="Email successfully confirmed"
                />
              </ModalTitle>
              <ButtonsContainer>
                <Copy
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      {
                        id: 'confirmEmail.modal.link',
                        defaultMessage: 'confirmEmail.modal.link',
                      },
                      {
                        label: intl.formatMessage({
                          id: 'confirmEmail.modal.link.label',
                          defaultMessage: 'confirmEmail.modal.link.label',
                        }),
                      },
                    ),
                  }}
                />
              </ButtonsContainer>
            </>
          ) : (
            <>
              {serverError ? (
                <Error message={serverError} />
              ) : (
                <ModalTitle>
                  <FormattedMessage id="confirmEmail.modal.loading" defaultMessage="Loading..." />
                </ModalTitle>
              )}
            </>
          )}
        </Modal>
      </Container>
    </>
  );
};

export default ConfirmEmail;
