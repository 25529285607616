import styled from 'styled-components';
import { AuthorName } from '../styles';

export const CartList = styled.div`
  width: 100%;
  max-width: 400px;
  border-radius: 5px !important;
`;
export const CartTotal = styled.div`
  padding: 10px 20px;
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 700;
  line-height: 18px;
  text-align: right;
  text-transform: uppercase;
  color: #050d42;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  border-radius: 0 0 5px 5px;
`;
export const StyledAuthorName = styled(AuthorName)`
  align-self: center !important;
`;
