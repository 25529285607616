import renderDate from '../../modules/renderDate';
import { Time } from './styles';

const Timestamp = ({ showDate, customerDataStoreTimezone }) => {
  return (
    <Time>
      {showDate && customerDataStoreTimezone?.name && customerDataStoreTimezone?.offset
        ? `${renderDate(showDate, customerDataStoreTimezone?.name)}, ${
          customerDataStoreTimezone?.name
        }, UTC ${customerDataStoreTimezone?.offset}`
        : '...'}
    </Time>
  );
};

export default Timestamp;
