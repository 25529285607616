import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';
import { FormattedMessage, useIntl } from 'react-intl';

const Container = styled.div`
  width: 100%;
`;

const Head = styled.div`
  display: flex;
`;
const Logo = styled.div`
  width: 33px;
  height: 33px;
  margin-right: 11px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const HeadInfo = styled.div`
  flex: 1;
  h3 {
    font-size: 16px;
    margin: 0;
  }
  div {
    font-size: 1rem;
    letter-spacing: 0.5px;
    color: #979797;
  }
  svg {
    font-size: 12px;
    color: #109cf1;
    margin-right: 5px;
  }
`;
const Details = styled.div`
  margin-top: 16px;
`;
const Order = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px;
  font-size: 1rem;
  letter-spacing: 0.5px;
`;
const OrderItem = styled.div``;
const Price = styled.div`
  color: #f96e6f;
`;
const Item = styled.div`
  display: flex;
  font-size: 1rem;
  letter-spacing: 0.5px;
  align-items: center;
  margin-bottom: 7px;
  width: 70%;
  div {
    :first-child {
      color: #9e9c9c;
      width: 50%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      svg {
        font-size: 14px;
        margin-right: 5px;
      }
    }
  }
`;
const StyledTabList = styled(TabList)`
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 20px 0 0;
`;

const StyledTab = styled(Tab)`
  width: 50%;
  color: #4a4747;
  font-size: 1rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 0 17px;
  background-color: #f4f4f4;
  cursor: pointer;
  border-bottom: 3px solid #f4f4f4;
  &.react-tabs__tab--selected {
    background: #fff;
    border-bottom: 3px solid #2f80ed;
  }
`;
const StyledTabs = styled(Tabs)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
const StyledTabPanel = styled(TabPanel)`
  flex: 1;
  display: none;
  &.react-tabs__tab-panel--selected {
    display: block;
  }
`;

const Textarea = styled.textarea`
  font-size: 1rem;
  letter-spacing: 0.5px;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #2f80ed;
  width: 100%;
  min-height: 112px;
`;
const Buttons = styled.div`
  display: flex;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Message = ({ type }) => {
  const intl = useIntl();
  return (
    <Container>
      <Head>
        <Logo>
          <img src="/pics/customer-logo.png" alt="" />
        </Logo>
        <HeadInfo>
          <h3>
            <FormattedMessage
              id="customer.customerList.message.user"
              defaultMessage="Name Surname"
            />
          </h3>
          <div>
            <FileCopyIcon />
            NINHKL827613
          </div>
        </HeadInfo>
      </Head>
      <Order>
        <OrderItem>1 x PowerBank 10000 Mah, CX300</OrderItem>
        <Price>
          <FormattedMessage
            id="customer.customerList.message.price"
            defaultMessage="Total spend: "
          />
          $450.30
        </Price>
      </Order>
      <Details>
        <Item>
          <div>
            <CalendarTodayIcon />
            <FormattedMessage id="customer.customerList.message.created" defaultMessage="CREATED" />
          </div>
          <div>29.04.2021 09:15am</div>
        </Item>
        <Item>
          <div>
            <CalendarTodayIcon />
            <FormattedMessage
              id="customer.customerList.message.track_number"
              defaultMessage="TRACKING NUMBER"
            />
          </div>
          <div>DE14526377290AT</div>
        </Item>
        <Item>
          <div>
            <CalendarTodayIcon />
            <FormattedMessage
              id="customer.customerList.message.exp_delivery"
              defaultMessage="EXPECTED DELIVERY"
            />
          </div>
          <div>05.05.2021</div>
        </Item>
        <Item>
          <div>
            <LocalShippingIcon />
            <FormattedMessage
              id="customer.customerList.message.delivered"
              defaultMessage="DELIVERED BY"
            />
          </div>
          <div>Ninjavan</div>
        </Item>
      </Details>
      <StyledTabs defaultIndex={type === 'note' ? 1 : 0}>
        <StyledTabList>
          <StyledTab>
            <FormattedMessage
              id="customer.customerList.message.send"
              defaultMessage="Send a message"
            />
          </StyledTab>
          <StyledTab>
            <FormattedMessage
              id="customer.customerList.message.note"
              defaultMessage="Make a note"
            />
          </StyledTab>
        </StyledTabList>
        <StyledTabPanel>
          <Textarea
            placeholder={intl.formatMessage({
              id: 'customer.customerList.tab.placeholder',
              defaultMessage: 'customer.customerList.tab.placeholder',
            })}
          />
        </StyledTabPanel>
        <StyledTabPanel>
          <Textarea
            placeholder={intl.formatMessage({
              id: 'customer.customerList.tab.placeholder',
              defaultMessage: 'customer.customerList.tab.placeholder',
            })}
          />
        </StyledTabPanel>
      </StyledTabs>
      <Buttons>
        <a href="/">
          <FormattedMessage
            id="customer.customerList.message.button.chat"
            defaultMessage="Go to chat &gt;&gt;&gt;"
          />
        </a>
        <Button variant="contained" color="primary">
          <FormattedMessage id="customer.customerList.message.button.send" defaultMessage="Send" />
        </Button>
      </Buttons>
    </Container>
  );
};

export default Message;
