import { useState } from 'react';
import styled from 'styled-components';

import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledInputLabel = styled(InputLabel)`
  background-color: #fff;
  padding: 0 5px !important;
  left: -5px !important;
`;

export const PasswordInput = ({ id, value, handleChange, label, ...props }) => {
  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <StyledFormControl variant="outlined">
      <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>
      <OutlinedInput
        id={id}
        name={id}
        autoComplete="nofill"
        type={values.showPassword ? 'text' : 'password'}
        value={value}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <VpnKeyIcon />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        }
        // labelWidth={160}
        {...props}
      />
    </StyledFormControl>
  );
};
