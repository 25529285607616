import { useState } from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormattedMessage, useIntl } from 'react-intl';

const Container = styled.div``;

const StoresContainer = styled.div`
  max-height: 380px;
  overflow-y: auto;
  position: relative;
  left: -10px;
`;

const Shops = ({ stores, onStoreChange, selectedStores }) => {
  const [storeFilter, setStoreFilter] = useState('');
  const [storesToShow, setStoresToShow] = useState(3);
  const intl = useIntl();
  const handleStoreFilter = (e) => {
    setStoreFilter(e.target.value);
  };

  const handleShowMoreStores = () => {
    const storesAmount = storesToShow === 3 ? stores.length + 1 : 3;
    setStoresToShow(storesAmount);
  };

  return (
    <Container>
      <TextField
        size="small"
        id="store"
        label={intl.formatMessage({
          id: 'userManagement.userForm.shops.store',
          defaultMessage: 'userManagement.userForm.shops.store',
        })}
        value={storeFilter}
        type="text"
        variant="outlined"
        autoComplete="none"
        onChange={handleStoreFilter}
      />
      <StoresContainer>
        {stores
          .filter(
            (item) =>
              item.name.includes(storeFilter) ||
              item.countryCode?.toUpperCase().includes(storeFilter),
          )
          .slice(0, storesToShow)
          .map((store) => (
            <div key={store._id.$oid}>
              <label>
                <Checkbox
                  color="primary"
                  name="store"
                  value={store._id.$oid}
                  onChange={onStoreChange}
                  checked={selectedStores.includes(store._id.$oid)}
                />
                {store.name} {store.countryCode?.toUpperCase()}
              </label>
            </div>
          ))}
      </StoresContainer>
      {storesToShow === 3 && stores?.length > 3 ? (
        <Button size="small" color="primary" onClick={handleShowMoreStores}>
          <FormattedMessage
            id="userManagement.userForm.shops.button.all"
            defaultMessage="View all shops..."
          />
        </Button>
      ) : (
        ''
      )}
    </Container>
  );
};

export default Shops;
