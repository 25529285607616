export enum MessageType {
  TEXT = 'text',
  IMAGE = 'image',
  IMAGE_WITH_TEXT = 'image_with_text',
  VIDEO = 'video',
  STICKER = 'sticker',
  ORDER = 'order',
  PRODUCT_LIST = 'product_list',
  PRODUCT = 'product',
  CART_LIST = 'cart_list',
  SYSTEM = 'system',
  AUTO_REPLY = 'auto_reply',
  VOUCHER = 'voucher',
  REFUND_REQUEST = 'refund_request',
  UNSUPPORTED = 'unsupported',
  ERROR = 'error',
  SKIP = 'skip',
  REACTION = 'reaction',
  SURVEY_INTRO = 'survey_intro',
  SURVEY_QUESTION = 'survey_question',
  SURVEY_ANSWER = 'survey_answer',
  BUNDLE_MESSAGE = 'bundle_message',
  TEMPLATE = 'template',
}

export { MessageType as default };
