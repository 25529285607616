import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { Container, StyledPagination, StyledTableContainer, Title, } from './styles';
import { ErrorBoundaryFallback, ErrorBoundaryHandler } from '../../components/ErrorBoundary';
import Header from '../../components/Header';
import { ErrorBoundary } from 'react-error-boundary';
import Nav from '../../components/Nav';
import { Body, PageContainer } from '../../components/Layout';
import TablePagination from '@mui/material/TablePagination';
// import Checkbox from '@mui/material/Checkbox';
// import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
// import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import getMetrics from '../../api/getMetrics';
import { retrieve, store } from '../../modules/storage';
import { FormattedMessage } from 'react-intl';
import moment from 'moment/moment';
import { logMessage } from '../../logger';

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const handlePagination = (event, value) => {
    logMessage(event);
    onPageChange(event, value - 1);
  };

  return (
    <Box sx={{ display: 'flex', ml: 2.5 }}>
      <StyledPagination
        count={Math.ceil(count / rowsPerPage)}
        siblingCount={0}
        defaultPage={page + 1}
        onChange={handlePagination}
      />
    </Box>
  );
}

const ChatStatistics = () => {
  const [page, setPage] = useState(retrieve.number('chat_statistic_page', 0));
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableSourceData, setTableSourceData] = useState([]);
  const [tableSourceMeta, setTableSourceMeta] = useState({ pagination: { count: 0 } });

  const handleChangePage = (event, newPage) => {
    logMessage(event);
    setPage(newPage);
    store.number('chat_statistic_page', newPage);
    getMetrics(
      (data, meta) => {
        setTableSourceData(data);
        setTableSourceMeta(meta);
      },
      { page: newPage, pp: rowsPerPage },
    );
  };

  useEffect(() => {
    handleChangePage({ some: 'event' }, page);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlers = {
    _id: value => {
      const dateStr = value.substring(0, (4 * 2));
      const dateInt = parseInt('0x' + dateStr, 16);
      const ts = new Date(dateInt * 1000);
      const rndStr = value.substring((4 * 2), (4 * 2) + (5 * 2));
      const rnd = parseInt('0x' + rndStr, 16);
      const idStr = value.substring(value.length - (3 * 2));
      const number = parseInt('0x' + idStr, 16);
      return ts.toLocaleString() + "\n" + rnd + number
    },
    total_number_of_chat: value => value,
    handling_time: value => moment.utc(value * 1000).format('HH:mm:ss'),
    first_response_time: value => moment.utc(value * 1000).format('HH:mm:ss'),
    response_rate: value => String(Math.ceil((1 - value) * 100)) + '%',
    tickets_volume: value => value,
    chat_avg_day: value => value,
    chat_resolved: value => value,
    resolution_rate: value => String(Math.ceil(value * 100)) + '%',
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
        <Header />
      </ErrorBoundary>
      <PageContainer>
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
          <Nav currentStep="dashboard" subStep="chat-statistics" />
        </ErrorBoundary>
        <Body>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
            <Container>
              <Title>Performance tracker</Title>
              <StyledTableContainer>
                <Table aria-label="chat statistics">
                  <TableBody>
                    <TableRow>
                      {/*<TableCell key="key-0" className={'head-cell'}></TableCell>*/}
                      {Object.keys(tableSourceData?.[0] ?? []).map((key, i) => (
                        <TableCell key={`key-${i + 1}`} className={'head-cell'} align="center">
                          <FormattedMessage id={`chatStatistics.table.head.cell.${key}`} />
                        </TableCell>
                      ))}
                    </TableRow>
                    {(rowsPerPage > 0
                        ? tableSourceData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : tableSourceData
                    ).map((row, index) => (
                      <TableRow key={`index-${index}`}>
                        {/*<ActionsContainer>*/}
                        {/*  <IconButton onClick={handleActions} aria-label="actions">*/}
                        {/*    <MenuOutlinedIcon />*/}
                        {/*  </IconButton>*/}
                        {/*  <Checkbox onChange={handleCheckbox} name={row['Name/ID']} />*/}
                        {/*</ActionsContainer>*/}
                        {Object.keys(row).map((key, i) => (
                          <TableCell key={`key-${i}`} align="center">
                            {key in handlers ? handlers[key](row[key]) : row[key]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={12}
                        count={tableSourceMeta?.pagination?.count ?? 0}
                        rowsPerPage={rowsPerPage}
                        page={page ?? 1}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </StyledTableContainer>
            </Container>
          </ErrorBoundary>
        </Body>
      </PageContainer>
    </>
  );
};
export default ChatStatistics;
