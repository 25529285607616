import styled from 'styled-components';
import { Switch } from '@mui/material';

export const AntSwitch = styled(Switch)`
  width: 50px !important;
  height: 30px !important;
  padding: 0 !important;
  display: flex;

  &:active {
    & .MuiSwitch-thumb {
      width: 20px;
    }

    & .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(20px);
    }
  }

  & .MuiSwitch-switchBase {
    padding: 0 !important;

    &.Mui-checked {
      transform: translateX(20px);
      color: #fff;

      & + .MuiSwitch-track {
        opacity: 1;
        background-color: #DEF0FF;
      }
    }
  }

  & .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-left: 5px;
    border-radius: 10px;
    transition: width 0.2s ease-out;
  }

  & .MuiSwitch-track {
    border-radius: 15px;
    opacity: 1;
    background-color: rgba(0, 0, 0, .25);
    box-sizing: border-box;
  }

,
`;
export const LabelSwitch = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 400;
  line-height: 22px;
  color: #050d42;
`;
