import { useIntl } from 'react-intl';
import { StyledTextField } from './styles';

export const FormCopyableTextarea = ({
  input,
  field,
  itemIndex,
  inputValues,
  requiredFields,
  setInputValues,
}) => {
  const intl = useIntl();
  const handleChangeCopyableTextarea = (prop, field, itemIndex) => (value) => {
    let copy = inputValues[field].slice();
    copy.splice(itemIndex, 1, { ...copy[itemIndex], [prop]: value });
    setInputValues({ ...inputValues, [field]: copy });
  };
  return (
    <StyledTextField
      className={'textarea-copyable'}
      id={input.id}
      required={requiredFields[field][input.id]}
      label={intl.formatMessage({ id: input.label, defaultMessage: input.label })}
      value={inputValues[field][itemIndex][input.id] ?? ''}
      type="text"
      variant="outlined"
      autoComplete="none"
      multiline
      rows={4}
      onChange={(event) =>
        handleChangeCopyableTextarea(input.id, field, itemIndex)(event.target.value)
      }
    />
  );
};
export default FormCopyableTextarea;
