import styled from 'styled-components';
import { Box, CardContent } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  color: #050d42;
`;

export const StyledCardContent = styled(CardContent)`
  border-top: 1px dashed #050d42 !important;
  padding: 6px !important;
`

export const StyledBox = styled(Box)`
`

export const StyledLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  vertical-align: middle;
`

export const StyledAutocomplete = styled(Autocomplete)`
  margin-top: 12px;
`

export const Text = styled.div`
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: #050d42;
`;
