import { createContext, useState } from 'react';

export const GlobalHandlersContext = createContext(null);


// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [globalHandlers, setGlobalHandlers] = useState({});


  return (
          <GlobalHandlersContext.Provider value={[globalHandlers, setGlobalHandlers]}>
            {children}
          </GlobalHandlersContext.Provider>
  );
};
