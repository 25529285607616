import { useState, useEffect, useRef } from 'react';
import { Badge } from '@mui/material';
import { COLORS_ARRAY } from '../../constants/ColorsArray';
import Messages from '../../components/Messages';
import { getData, postData } from '../../modules/fetch';
import SendMessage from './SendMessage';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Head,
  InitialsBadge,
  StyledBox,
  Initials,
  InfoItem,
  Name,
  OwnerName,
  Body,
  MessagesEnd,
  Footer,
  Nav,
  NavButton,
  Responses,
  ResponsesContent,
} from './styles';

const SmallChat = ({
  item,
  index,
  newMessage,
  currentUser,
  wampHandlers,
  closeSmallChat,
  responsesData,
  tokenValues,
  handleChatOpen
}: {
  item: any,
  index: number,
  newMessage: any,
  currentUser: any,
  wampHandlers: any,
  closeSmallChat: any,
  responsesData: any,
  tokenValues: any,
  handleChatOpen: any
}) => {
  const [showMessages, setShowMessages] = useState(false);
  const [messageData, setMessageData] = useState([]);
  const [messagesPage, setMessagesPage] = useState(1);
  const [totalMessages, setTotalMessages] = useState(0);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    getMessages(item);
  }, []);

  useEffect(() => {
    if (newMessage.chatId === item._id.$oid) {
      setMessageData([newMessage, ...messageData]);
    }
  }, [newMessage]);

  const getMessages = (item) => {
    if (item?._id?.$oid && item?.entity) {
      return getData(`/messages/listings/${item.entity}/${item._id.$oid}/1,10?sort=time.date:-1`)
        .then((data) => {
          setMessageData(data.data);
          return data;
        })
        .catch((err) => {
          console.error(err);
        }).finally(() => {
        });
    }
  };

  const getMoreMessages = () => {
    getData(
      `/messages/listings/${item.entity}/${item._id.$oid}/${
        messagesPage + 1
      },10?sort=time.date:-1`,
    )
      .then((data) => {
        setTotalMessages(data.meta.pagination.total);
        setMessageData([...messageData, ...data.data]);
        setMessagesPage(messagesPage + 1);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleMessageSend = (messageText, messageType = 'text', entityID = '') => {
    let body: { text: any } | { image: { url: any } } | null;
    const msg = {
      content: messageText,
      body: body,
      entityId: entityID, //"._id" API end point /file return
      type: messageType, //"text" || "image" || "order",
      sendId: item?._id?.$oid + Date.now() + Math.floor(Math.random() * 1000000),
      chatId: item?._id?.$oid,
      title: `${currentUser?.name} ${currentUser?.surname}`,
      from: {
        id: currentUser?._id?.$oid,
        resource: 'user',
      },
      entity: 'customer',
    };
    wampHandlers.message([{ message: msg }]);
    if (item?.entity && item?._id?.$oid) {
      postData(`/messages/send/${item?.entity}/${item?._id?.$oid}`, msg)
        .then(() => {
          scrollToBottom();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    }
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({});
    }, 200);

  };

  const handleShowMessages = () => {
    setShowMessages(true);
    scrollToBottom();
  }

  const applyTokensOnMessage = (message) => {
    let messageBuf = message;
    Object.entries(tokenValues || []).map(
      (token) => (messageBuf = messageBuf?.replaceAll(`[[{"value":"${token[0]}"}]]`, token?.[1])),
    );
    return messageBuf;
  };

  const handleFastResoponseSend = (e) => {
    let fastResponseMessage = responsesData.find(
      (item) => item.title === e.target.textContent,
    )?.message;
    fastResponseMessage = applyTokensOnMessage(fastResponseMessage);
    handleMessageSend(fastResponseMessage);
  };

  return (
    <Container>
      <Head onClick={handleShowMessages}>
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          overlap="circular"
          badgeContent={
            <InitialsBadge>
              {(item.assigneeName &&
                item.assigneeName
                  .split(' ')
                  .map((w) => w.charAt(0).toUpperCase())
                  .join('')) ||
                '?'}
            </InitialsBadge>
          }
        >
          <StyledBox
            sx={{
              backgroundColor: `${COLORS_ARRAY[String(index).slice(-1)]}`,
            }}
          >
            <Initials>
              {item.name
                .split(' ')
                .map((w) => w.charAt(0).toUpperCase())
                .join('')}
            </Initials>
          </StyledBox>
        </Badge>
        <InfoItem>
          <Name>{item.name}</Name>
          <OwnerName>{item.assigneeName ?? ''}</OwnerName>
        </InfoItem>
        <Nav>
          <NavButton
            aria-label="Open chat"
            onClick={(e) => {
              e.stopPropagation();
              handleChatOpen(item);
            }}
          >
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="fullscreen">
              <path d="M3.60051 0.60012V3.62396e-05H0V3.60054H0.600084V1.02444L3.61339 4.03772L4.03769 3.61342L1.0244 0.60012H3.60051Z" fill="white"/>
              <path d="M8.40128 5.40079V7.9769L5.31297 4.8886L4.88867 5.3129L7.97697 8.40121H5.40086V9.0013H9.00137V5.40079H8.40128Z" fill="white"/>
            </svg>
          </NavButton>
          <NavButton
            aria-label="Minifiy chat"
            onClick={(e) => {
              e.stopPropagation();
              setShowMessages(false);
            }}
          >
            <MinimizeIcon />
          </NavButton>
          <NavButton
            aria-label="Close chat"
            onClick={(e) => {
              e.stopPropagation();
              closeSmallChat(item);
            }}
          >
            <CloseIcon />
          </NavButton>
        </Nav>

      </Head>
      {showMessages ? (
        <>
          <Body>
            <Messages
              activeChatIsCustomer={true}
              messages={messageData.slice(0).reverse()}
              currentUserId={item._id.$oid}
              resend={() => {}}
              handleImagePopup={() => {}}
              handleVideoPopup={() => {}}
              customerDataStoreTimezone={{ }}
              getMoreMessages={getMoreMessages}
              totalMessages={totalMessages}
              activeStoreId={item?.store?._id?.$oid ?? null}
            />
            <MessagesEnd ref={messagesEndRef} />
          </Body>
          <Footer>
            <Responses>
              <ResponsesContent>
                {responsesData?.map((item, index) => (
                  <button
                    key={`response-button-${index}`}
                    onClick={handleFastResoponseSend}
                  >
                    <FormattedMessage
                      id="messages.chat.button.label"
                      defaultMessage="{label}"
                      values={{
                        label: item.title,
                      }}
                    />
                  </button>
                ))}
              </ResponsesContent>
            </Responses>
            <SendMessage isSmallChat handleMessageSend={handleMessageSend} />
          </Footer>
        </>
      ) : null}
    </Container>
  );
};

export default SmallChat;
