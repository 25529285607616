import { FormattedMessage } from 'react-intl';
import {
  useAddContactMutation,
  useFetchTemplateQuery,
  useUpdateContactMutation,
} from '../../api/contactsAPI';
import { BackButton, ButtonsContainer, Container, SaveButton } from './styles';
import ContactFormRenderContent from '../../components/ContactFormRenderContent';
import Error from '../../components/Error';

function handleResponseWithErrors(setShowContactPopup) {
  return (response) => {
    if (response) {
      // @ts-ignore
      if (response?.error?.status && response?.error?.status !== 200) {
        // @ts-ignore
        const errors = response?.error?.data?.errors;
        if (errors) {
          const message = errors.map(item => {
            const regex = /(\w+)\]$/g;
            const exec = regex.exec(item.path);
            const field = exec?.[1];
            if (field) {
              let fieldName = String(field).replace(/([-_]\w)/g, g => ' ' + g[1].toUpperCase())
              fieldName = fieldName.slice(0, 1).toUpperCase() + fieldName.slice(1);
              return `${fieldName}: ${item.message}`
            }
          });
          alert('Error' + '\n' + message.join('\n'));
        } else {
          alert('Error' + '\n' + JSON.stringify(response));
        }
      } else {
        // @ts-ignore
        if (response?.data?.meta?.statusCode && response?.data?.meta?.statusCode === 200) {
          setShowContactPopup(false);
        }
      }
    }
  };
}

const ContactForm = ({
  inputValues,
  requiredFields,
  setInputValues,
  setShowContactPopup
}) => {
  const { data: templateContact, error: errorTemplate } = useFetchTemplateQuery(true);
  const [addContact] = useAddContactMutation();
  const [updateContact] = useUpdateContactMutation();

  const handleCancelPopup = () => {
    setInputValues({});
    setShowContactPopup(false);
  };
  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
    inputValues.tags = inputValues?.tags?.map(option => option?.value ?? option);
    inputValues.providers = inputValues?.providers?.map(option => option?.value ?? option);
    if ('isEditable' in inputValues) {
      const id = inputValues?._id?.$oid;
      delete inputValues._id;
      delete inputValues.ownerId;
      delete inputValues.status;
      delete inputValues.createdAt;
      delete inputValues.updatedAt;
      delete inputValues.isEditable;
      await updateContact({
        id: id,
        ...inputValues,
      }).then(handleResponseWithErrors(setShowContactPopup));
    } else {
      await addContact({ contact: {...inputValues} }).then(handleResponseWithErrors(setShowContactPopup));
    }
  };

  return (
    <Container>
      {(errorTemplate && 'error' in errorTemplate && <Error message={errorTemplate.error} />) || (
        <form autoComplete="off" onSubmit={handleContactFormSubmit}>
          <ContactFormRenderContent
            inputValues={inputValues}
            requiredFields={requiredFields}
            setInputValues={setInputValues}
            contactFormData={templateContact?.data}
          />
          <ButtonsContainer>
            <BackButton variant="outlined" onClick={handleCancelPopup}>
              <FormattedMessage id="contacts.popup.button.cancel" />
            </BackButton>
            <SaveButton variant="contained" onClick={handleContactFormSubmit}>
              <FormattedMessage id="contacts.popup.button.save" />
            </SaveButton>
          </ButtonsContainer>
        </form>
      )}
    </Container>
  );
};

export default ContactForm;
