import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalTitle } from '../../components/Modal';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { EmailInput } from '../../components/Input';
import { mediaQueries } from '../../constants/breakpoints';
import Error from '../../components/Error';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import postAccountResetRequest from '../../api/postAccountResetRequest';
import { TailSpin } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

const Container = styled.div`
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.desktop} {
    background-color: #f5f6f8;
  }
`;
const SubText = styled.p`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #c4c4c4;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  button,
  a {
    min-width: 100%;
    margin-bottom: 30px;
    @media ${mediaQueries.desktop} {
      //noinspection CssInvalidPropertyValue
      min-width: 48%;
    }
  }
`;
const Copy = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
`;
const TextFieldContainer = styled.div`
  margin-top: 20px;
`;

const ResetPassword = ({ errorMessage }) => {
  const { email } = useParams();
  const [complete, setComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: email ?? '',
  });
  const intl = useIntl();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState(errorMessage ?? '');

  useEffect(() => {
    if ('email' in values) {
      const trimmedEmail = String(values.email).replaceAll(' ', '');
      setValues({ ...values, email: trimmedEmail });
    }
  }, [values]);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if ('email' in fieldValues) {
      fieldValues.email = String(fieldValues.email).replaceAll(' ', '');
      temp.email = fieldValues.email ? '' : 'This field is required.';

      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ''
          : 'Email is not valid.';
    }

    setErrors({
      ...temp,
    });
  };

  const formIsValid = (fieldValues = values) => {
    const isValid = fieldValues.email && Object.values(errors).every((x) => x === '');
    return isValid;
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    validate();
    const isValid = Object.values(errors).every((x) => x === '') && formIsValid();
    if (isValid) {
      setLoading(true);
      const userEmail = String(values.email).replaceAll(' ', '');
      postAccountResetRequest({ username: userEmail }, setComplete, setServerError, setLoading);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Modal>
          {complete ? (
            <ModalTitle>
              <FormattedMessage
                id="resetPassword.modal.title"
                defaultMessage="Email with reset link sent. Please check your inbox."
              />
            </ModalTitle>
          ) : (
            <>
              <ModalTitle>
                <FormattedMessage
                  id="resetPassword.modal.forgot"
                  defaultMessage="Forgot Your Password?"
                />
              </ModalTitle>
              <SubText>
                <FormattedMessage
                  id="resetPassword.modal.forgot.text"
                  defaultMessage="Enter your e-mail address and we will share a link to create a new password"
                />
              </SubText>
              {serverError && <Error message={serverError} />}
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <TextFieldContainer>
                  <EmailInput
                    label={intl.formatMessage({
                      id: 'settings.general.detail.email',
                      defaultMessage: 'settings.general.detail.email',
                    })}
                    id="email"
                    value={values.email}
                    handleChange={handleChange('email')}
                  />
                </TextFieldContainer>
                <ButtonsContainer>
                  {isLoading ? (
                    <Button component={Link} to="#" size="large" variant="contained" color="primary">
                      <TailSpin color="#fff" width="20" height="20" />
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      color="primary"
                      startIcon={<SendIcon />}
                    >
                      <FormattedMessage id="resetPassword.modal.send" defaultMessage="Send" />
                    </Button>
                  ) }

                  <Copy
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        {
                          id: 'resetPassword.modal.link',
                          defaultMessage: 'resetPassword.modal.link',
                        },
                        {
                          label: intl.formatMessage({
                            id: 'resetPassword.modal.link.label',
                            defaultMessage: 'resetPassword.modal.link.label',
                          }),
                        },
                      ),
                    }}
                  />
                </ButtonsContainer>
              </form>
            </>
          )}
        </Modal>
      </Container>
    </>
  );
};

export default ResetPassword;
