import { useEffect, useState } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { mediaQueries } from '../../constants/breakpoints';
import List from '../../components/UsersList';
import { FormattedMessage, useIntl } from 'react-intl';
import Popup from '../../components/Popup';
import UserForm from './userForm';
import AddIcon from '@mui/icons-material/Add';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 20px;
  background: #fff;
  border-radius: 6px;
  @media ${mediaQueries.desktop} {
    margin: 24px 80px;
  }
`;

const Actions = styled.div`
  display: flex;
  margin: 20px 20px 0;
  flex-direction: column;
  @media ${mediaQueries.desktop} {
    flex-direction: row;
    justify-content: space-between;
    > div {
      flex: 1;
      display: flex;
      flex-direction: row;
    }
  }
`;
const SearchInputContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  flex: 1;
  display: flex;
  margin-top: 10px;
  @media ${mediaQueries.desktop} {
    margin-left: 10px;
    margin-top: 0;
    max-width: 300px;
    margin-bottom: 0;
  }
`;
const Input = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  outline: none;
`;

const Users = ({ data }) => {
  const [showUserPopup, setShowUserPopup] = useState(false);
  const [search, setSearch] = useState('');
  const [usersList, setUsersList] = useState(data);
  const [items, setItems] = useState(data?.data || []);
  const intl = useIntl();

  useEffect(() => {
    setItems(data?.data || []);
    setUsersList(data || []);
  }, [data]);

  useEffect(() => {
    filterBySearch(search);
  }, [usersList]);

  const onChangeSearch = (event) => {
    const value = event.target.value;
    setSearch(value);
    if (value) {
      setItems(filterBySearch(value));
    } else {
      setItems(filterBySearch(''));
    }
  };

  const filterBySearch = (search) => {
    if (search) {
      const regex = new RegExp(search, 'iu');
      return usersList?.data?.filter((item) => {
        return regex.test(item?.name) || regex.test(item?.type) || regex.test(item?.email);
      });
    } else {
      return usersList?.data || [];
    }
  };

  return (
    <>
      {showUserPopup && (
        <Popup
          isPreviewTicket={false}
          setIsPreviewTicket={() => {}}
          handleClose={() => setShowUserPopup(false)}
        >
          <UserForm data={false} handleClosePopup={() => setShowUserPopup(false)} />
        </Popup>
      )}
      <Container>
        <Actions>
          <div>
            <SearchInputContainer>
              <IconButton aria-label="search">
                <SearchIcon fontSize="small" />
              </IconButton>
              <Input
                type="text"
                placeholder={intl.formatMessage({
                  id: 'userManagement.users.input_label',
                  defaultMessage: 'userManagement.users.input_label',
                })}
                onChange={onChangeSearch}
              />
            </SearchInputContainer>
          </div>
          <Button
            variant="contained"
            size="large"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setShowUserPopup(true)}
          >
            <FormattedMessage id="userManagement.users.button" defaultMessage="Add user" />
          </Button>
        </Actions>
        <List data={items} />
      </Container>
    </>
  );
};

export default Users;
