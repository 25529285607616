import styled from 'styled-components';
import { UserPhoto } from '../../Avatar/styles';
import { Content, MessageBox } from '../styles';
import { mediaQueries } from '../../../../../constants/breakpoints';

export const Title = styled.div`
  margin-bottom: 15px;
  span {
    display: inline-block;
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
  .red {
    margin-right: 5px;
    color: red;
  }
  span:nth-child(2) {
    font-weight: 400;
    color: #050d42;
    text-decoration: underline;
  }
`;

export const Copy = styled.div`
  max-width: 420px;
  padding: 8px 16px;
  font-size: 1rem;
  line-height: 18px;
  color: #c4c4c4;
  margin-left: 5px;

  @media ${mediaQueries.mobile} {
    max-width: 300px;
  }
`;

export const SystemMessage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  ${UserPhoto} {
    display: none;
  }

  ${Content} {
    align-items: center !important;
    font-weight: bold;
    font-size: 18px;
    ${MessageBox} {
      flex-direction: column !important;
      align-items: start;
      max-width: 420px;
      padding: 8px 16px;
      border: 1px solid #ecebeb;
      border-radius: 5px;
      word-wrap: break-word;

      @media ${mediaQueries.mobile} {
        max-width: 400px;
      }
    }
    ${Copy} {
      background-color: transparent;
      border: none;
    }
  }

  ${Copy} {
    background-color: transparent;
  }
`;
