import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-bottom: 70px;
  overflow-y: auto;
  @media ${mediaQueries.desktop} {
    width: 100%;
    height: 100%;
    padding: 20px;
  }
`;
