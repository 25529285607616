import { FormattedMessage, useIntl } from 'react-intl';
import { Container, StyledList } from './styles';
import { useContext, useEffect, useRef, useState } from 'react';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import Timestamp from '../Timestamp';
import { getData } from '../../modules/fetch';
import { CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CustomerHistory = ({}) => {
  const listOfKeys = [
    'customer_mark_message_as_read',
    'customer_set_assignee',
    'customer_states_update_handler',
    'tags_update',
    'tickets_create',
  ];
  const [activeChat] = useContext(ActiveChatContext);
  const [history, setHistory] = useState(null);
  const [showOnly, setShowOnly] = useState('all');
  const topRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    getData(`/reporting/tracker/history/by-section/${activeChat._id.$oid}/1,1000`).then((response) => {
      setHistory(response.data);
    });
  }, [activeChat]);

  useEffect(() => {
    setTimeout(() => {
      topRef.current?.scrollIntoView(true);
    }, 1000);
  }, []);

  const getValue = (value: string) => {
    try {
      const json = JSON.parse(value);
      const states = Object.keys(json);
      if (states.indexOf('wasOpened') > -1) {
        return 'chat was opened';
      }
      if (['open', 'escalated', 'followup', 'resolved'].indexOf(states[0]) > -1) {
        if (states[0] !== json[states[0]].state) {
          return states[0] + ' as ' + json[states[0]].state;
        }
        return states[0];
      }
    } catch (e) {
    }
    return;
  };

  const getActionName = record => {
    return intl.formatMessage({
      id: 'history.' + record?.action,
      defaultMessage: String(record?.action).replace('customer_', '').replaceAll('_', ' '),
    })
  }

  const handleShowOnly = (e) => {
    setShowOnly(e.target.value)
  };

  return (
    <Container>
      <h4>
        <FormattedMessage id="history.customerHistory.title" defaultMessage="Comments" />
      </h4>
      <Select
        labelId="showOnly-type-label"
        id="showOnly-type"
        onChange={handleShowOnly}
        value={showOnly}
      >
        <MenuItem key={`menu-item--1`} value={'all'}><FormattedMessage id={'history.all'} defaultMessage={'Show all'} /></MenuItem>
        {listOfKeys.map((key, ci) => {
          return (
            <MenuItem key={`menu-item-${ci}`} value={key}><FormattedMessage id={'history.' + key} defaultMessage={key} /></MenuItem>
          );
        })}
      </Select>
      <StyledList>
        {history && Array.isArray(history) && history?.length > 0 ? (
          history?.map((record, index) => record.action === showOnly || showOnly === 'all' ? (index < history.length - 1) ? (
            <CardContent sx={{ padding: '10px', paddingBottom: '0px !important' }} key={`ticket-${index}`}>
              <Typography gutterBottom variant="body1" component="div">{index + 1}. {getActionName(record)} {getValue(record?.value)}</Typography>
              <Typography variant="body1" color="text.secondary">{record?.userName}</Typography>
              <Typography variant="body2" color="text.secondary">
                <Timestamp showDate={record?.createdAt?.date} customerDataStoreTimezone={activeChat.store?.timezone} />
              </Typography>
            </CardContent>
          ) : (
            <CardContent ref={topRef} sx={{ padding: '10px', paddingBottom: '0px !important' }} key={`ticket-${index}`}>
              <Typography gutterBottom variant="body1" component="div">{index + 1}. {getActionName(record)} {getValue(record?.value)}</Typography>
              <Typography variant="body1" color="text.secondary">{record?.userName}</Typography>
              <Typography variant="body2" color="text.secondary">
                <Timestamp showDate={record?.createdAt?.date} customerDataStoreTimezone={activeChat.store?.timezone} />
              </Typography>
            </CardContent>
          ) : (<></>))
        ) : (
          <h4>
            <FormattedMessage
              id="messages.customerComments.no_tickets"
              defaultMessage="No Comments"
            />
          </h4>
        )}
      </StyledList>
    </Container>
  );
};
export default CustomerHistory;
