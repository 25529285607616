import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';

export const PageContainerInbox = styled.div`
  min-height: calc(100vh - 61px);
  background-color: #fff;
  display: flex;
  max-width: 100vw;
  overflow-x: auto;
  @media ${mediaQueries.desktop} {
    height: calc(100vh - 61px);
  }
  @media ${mediaQueries.mobile} {
    -webkit-overflow-scrolling: touch;
    min-height: calc(100vh - 61px);
  }
`;

export const PageContainerMessages = styled.div`
  min-height: calc(100vh - 61px);
  background-color: #fff;
  display: flex;
  max-width: 100vw;
  @media ${mediaQueries.desktop} {
    height: calc(100vh - 176px);
  }
  @media ${mediaQueries.mobile} {
    -webkit-overflow-scrolling: touch;
    min-height: calc(100vh - 61px);
  }
`;

export const PageContainer = styled.div`
  background-color: #fff;
  display: flex;
  max-width: 100vw;
  overflow-y: auto;
  @media ${mediaQueries.desktop} {
    height: calc(100vh - 61px);
  }
  @media ${mediaQueries.mobile} {
    min-height: calc(100vh - 61px);
  }
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  max-width: 100vw;
  flex-direction: column;
  height: calc(100vh - 61px);
  overflow-y: auto;
  @media ${mediaQueries.mobile} {
  }
  @media ${mediaQueries.tablet} {
    padding-right: 51px;
  }
  @media ${mediaQueries.desktop} {
    padding-right: 0px;
    flex-direction: row;
  }
`;
