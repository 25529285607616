import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledMenuItem } from './styled';
import { logMessage } from '../../logger';

const SubMenu = ({ setAnchorElFolders, handleFollowUpMenuSelection }) => {
  const [anchorElementSubMenu, setAnchorElementSubMenu] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const setAnchorElement = (node) => {
    setAnchorElementSubMenu(node);
  };
  const handleItemClick = (event) => {
    logMessage('event', event);
    if (!anchorElementSubMenu) {
      setAnchorElement(event.currentTarget);
      setSubMenuOpen(true);
    } else {
      setAnchorElementSubMenu(null);
      setSubMenuOpen(false);
    }
  };
  const handleSubItemClick = (value) => {
    handleFollowUpMenuSelection(value);
    setAnchorElementSubMenu(null);
    setAnchorElFolders(null);
    setSubMenuOpen(false);
  };
  const handleSubMenuClose = () => {
    setSubMenuOpen(false);
  };
  return (
    <>
      <StyledMenuItem onClick={handleItemClick}>
        <FormattedMessage id="messages.chat.menu.follow_up" />
      </StyledMenuItem>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -85,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={subMenuOpen}
        anchorEl={anchorElementSubMenu}
        onClose={handleSubMenuClose}
      >
        <MenuItem onClick={() => handleSubItemClick('1 min')}>
          <FormattedMessage
            id="messages.chat.menu.followUp.mins"
            defaultMessage="{minutes} min"
            values={{ minutes: 1 }}
          />
        </MenuItem>
        <MenuItem onClick={() => handleSubItemClick('10 mins')}>
          <FormattedMessage
            id="messages.chat.menu.followUp.mins"
            defaultMessage="{minutes} mins"
            values={{ minutes: 10 }}
          />
        </MenuItem>
        <MenuItem onClick={() => handleSubItemClick('30 mins')}>
          <FormattedMessage
            id="messages.chat.menu.followUp.mins"
            defaultMessage="{minutes} mins"
            values={{ minutes: 30 }}
          />
        </MenuItem>
        <MenuItem onClick={() => handleSubItemClick('1 hour')}>
          <FormattedMessage
            id="messages.chat.menu.followUp.hours"
            defaultMessage="{hours} hour"
            values={{ hours: 1 }}
          />
        </MenuItem>
        <MenuItem onClick={() => handleSubItemClick('2 hours')}>
          <FormattedMessage
            id="messages.chat.menu.followUp.hours"
            defaultMessage="{hours} hours"
            values={{ hours: 2 }}
          />
        </MenuItem>
        <MenuItem onClick={() => handleSubItemClick('4 hours')}>
          <FormattedMessage
            id="messages.chat.menu.followUp.hours"
            defaultMessage="{hours} hours"
            values={{ hours: 4 }}
          />
        </MenuItem>
        <MenuItem onClick={() => handleSubItemClick('24 hours')}>
          <FormattedMessage
            id="messages.chat.menu.followUp.hours"
            defaultMessage="{hours} hours"
            values={{ hours: 24 }}
          />
        </MenuItem>
        <MenuItem onClick={() => handleSubItemClick('48 hours')}>
          <FormattedMessage
            id="messages.chat.menu.followUp.hours"
            defaultMessage="{hours} hours"
            values={{ hours: 48 }}
          />
        </MenuItem>
        <MenuItem onClick={() => handleSubItemClick('72 hours')}>
          <FormattedMessage
            id="messages.chat.menu.followUp.hours"
            defaultMessage="{hours} hours"
            values={{ hours: 72 }}
          />
        </MenuItem>
      </Menu>
    </>
  );
};
export default SubMenu;
