import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Box, Chip, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledAvatarChannel } from './styles';
import { getLogo } from '../../../api/getLogo';
import { getInputDependedValues } from '../../../modules/getinput';
import { logMessage } from '../../../logger';

export const FormAutocompleteIcons = ({ input, inputValues, requiredFields, setInputValues }) => {
  const intl = useIntl();
  const [channel, setChannel] = useState([]);

  useEffect(() => {
    setChannel(getInputDependedValues(input, inputValues));
  }, [input]);

  const handleChangeChannels = (event, value) => {
    logMessage('event', event);
    setChannel(value);
    setInputValues({ ...inputValues, [input.id]: value });
  };

  const optionEqualToValue = (option, value) => {
    if ('_id' in option) {
      if (typeof option._id === 'string') {
        return option._id === value._id;
      } else {
        return option?._id?.$oid === value?._id?.$oid;
      }
    }

    return false;
  };

  const getLabel = (option: any) => {
    if (option?.countryCode)
      return `${option?.name ?? option?.value ?? option?.label} (${option?.countryCode?.toUpperCase()})`;
    return option?.name ?? option?.value ?? option?.label;
  };

  const getOptionLabel = option => option?.name ?? '';

  const getTranslatedLabel = (option: any) => {
    const label = getLabel(option);

    if (label)
      return intl.formatMessage({
        id: label,
        defaultMessage: label
      });

    if (option)
      return intl.formatMessage({
        id: option,
        defaultMessage: option
      });

    return option;
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        id="survey-channels"
        options={input?.options}
        multiple={input?.multiple ?? true}
        limitTags={3}
        autoHighlight
        defaultValue={input?.defaultValue}
        value={channel}
        onChange={handleChangeChannels}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option) => (
          <Box
            key={`option-${option?.name}-${option?.provider}-${Math.random()})`}
            component="li" sx={{ '& > img': { mr: 2 } }} {...props}
          >
            <img width="15" src={getLogo(option?.provider ?? option)} alt="" />
            {getTranslatedLabel(option)}
          </Box>
        )}
        isOptionEqualToValue={optionEqualToValue}
        renderTags={(value: readonly any[], getTagProps) =>
          value?.map((option: any, index: number) => {
            const name = option?.provider ?? option?.value ?? option ?? 'unknwon';
            const label = getTranslatedLabel(option)
            return (
            <Chip
              size="small"
              key={`channel-${index}-${name}-${name}-${Math.random()})`}
              variant="outlined"
              avatar={<StyledAvatarChannel src={getLogo(name)} />}
              label={label}
              deleteIcon={<CloseIcon />}
              {...getTagProps({ index })}
            />
          )}
          )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required={requiredFields[input.id]}
            inputProps={{
              ...params.inputProps,
              required: channel?.length === 0,
            }}
            label={intl.formatMessage({
              id: 'messages.messagesSearch.channels',
              defaultMessage: 'messages.messagesSearch.channels'
            })}
          />
        )}
      />
    </FormControl>
  );
};
export default FormAutocompleteIcons;
