import styled from 'styled-components';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, } from 'react-accessible-accordion';
import ReactPlayer from 'react-player';
import { FormattedMessage, useIntl } from 'react-intl';

const Container = styled.div`
  width: 100%;
  padding: 0 30px;
  height: 100%;
  overflow-y: auto;
`;
const ItemContainer = styled.div`
  display: flex;
`;
const VideoContainer = styled.div`
  margin-right: 10px;
  flex: 0 0 38%;
`;
const Title = styled.div`
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  font-family: Montserrat;
  margin: 60px 0;
`;
const Copy = styled.div`
  font-size: 16px;
  font-family: Montserrat;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.4px;
  line-height: 32px;

  p {
    margin-left: 20px;
  }
`;
const StyledAccordion = styled(Accordion)`
  .accordion__item {
    border: none;
    margin-bottom: 20px;
    box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .accordion__panel {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    position: relative;
    padding: 25px;

    ::before {
      content: "";
      height: 1px;
      position: absolute;
      top: 0;
      left: 25px;
      right: 25px;
      background-color: #E9ECEF;
    }
  }
`;
const StyledAccordionItemHeading = styled(AccordionItemHeading)`
  .accordion__button {
    border-radius: 8px;
    font-family: Montserrat;
    font-size: 16px;
    text-transform: none;
    letter-spacing: 0.4px;
    padding: 18px 25px;

    ::after {
      display: none;
    }
  }

  .accordion__button[aria-expanded=true] {
    background-color: #fff;
  }
`;

const FAQ = ({ faqData }) => {
  const intl = useIntl();
  return (
    <Container>
      <Title>
        <FormattedMessage id="support.faq.title" defaultMessage="User Guide" />
      </Title>
      <StyledAccordion allowZeroExpanded>
        {faqData.map(item => (
          <AccordionItem>
            <StyledAccordionItemHeading>
              <AccordionItemButton>
                <FormattedMessage id={item.title} defaultMessage={item.title} />
              </AccordionItemButton>
            </StyledAccordionItemHeading>
            <AccordionItemPanel>
              <ItemContainer>
                {item.video ? (
                  <VideoContainer>
                    <ReactPlayer
                      url={item.video}
                      playing
                      controls
                      playIcon={<button>
                        <FormattedMessage id="support.faq.title" defaultMessage="Play" />
                      </button>}
                    />
                  </VideoContainer>
                ) : null}
                <Copy dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: item.copy,
                    defaultMessage: item.copy,
                  })
                }}>
                </Copy>
              </ItemContainer>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </StyledAccordion>
    </Container>
  );
};

export default FAQ;
