import { getParamByISO } from 'iso-country-currency';

export const formatPrice = (countryCode, currency, cost) => {
  const minimumFractionDigits = 2;
  const currencyDisplay = 'symbol';

  if (isNaN(cost)) {
    return String(formatPrice(countryCode, currency, 0.00))?.replace(/0([.,])00/, '-$1--') ?? '-.--';
  }

  let language = navigator.language;
  if (String(language).indexOf('-')){
    language = language.split('-')?.[0] ?? language;
  }

  const locale = language + '-' + String(countryCode).toUpperCase();
  try {
    return Intl.NumberFormat(locale, {
      style: 'currency',
      currency: getParamByISO(countryCode, 'currency'), // CNY for Chinese Yen, EUR for Euro
      minimumFractionDigits: minimumFractionDigits,
      currencyDisplay: currencyDisplay,
    }).format(cost);
  } catch (e) {
    try {
      return Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency, // CNY for Chinese Yen, EUR for Euro
        minimumFractionDigits: minimumFractionDigits,
        currencyDisplay: currencyDisplay,
      }).format(cost);
    } catch (e) {
      try {
        // noinspection TypeScriptValidateTypes
        return Intl.NumberFormat({
          // @ts-ignore
          minimumFractionDigits: minimumFractionDigits,
          currencyDisplay: currencyDisplay,
        }).format(cost);
      } catch (e) {
        return `${currency} ${cost}`;
      }
    }
  }
}
