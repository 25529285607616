import Stack from '@mui/material/Stack';
import { AntSwitch, LabelSwitch } from './styles';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { getInputDependedLabel } from '../../../modules/getinput';

export const FormSwitch = ({ input, inputValues, setInputValues, inputLabels, setInputLabels }) => {
  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    setInputValues({ ...inputValues, [input.id]: event.target.checked });
    setInputLabels({ ...inputLabels, [input.id]: event.target.checked ? 'yes' : 'no' });
  };
  const inputDependedLabel = getInputDependedLabel(input, inputValues);
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <AntSwitch
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'ant design' }}
      />
      <LabelSwitch id={`${input.id}Label`}>
        <FormattedMessage id={inputDependedLabel} defaultMessage={inputDependedLabel} />
      </LabelSwitch>
    </Stack>
  );
};
export default FormSwitch;
