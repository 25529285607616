import moment from 'moment-timezone';

const renderDate = (date, timeZoneName) => {
  if (date && timeZoneName) {
    let localDate = moment.utc(date);
    localDate = localDate.tz(timeZoneName);
    let formattedDate = localDate.toDate().toLocaleString('en-GB', { timeZone: timeZoneName });
    return formattedDate;
  }

  return date;
};

export const renderUTCDate = (date, utcOffset) => {
  if (date && utcOffset) {
    let localDate = moment.utc(date);
    return localDate.utcOffset(utcOffset);
  }

  return date;
};

export const fromNow = (date) => {
  if (date.date && date.date[0] === '5') {
    return moment(date.date.valueOf() / 1000).fromNow();
  } else {
    return moment.parseZone(date.date).fromNow();
  }
};

export const fromNowSecondsZone = (seconds) => {
  return moment.parseZone(seconds * 1000).fromNow();
};

export const toFromNow = (seconds, now) => {
  return moment((now + seconds) * 1000).fromNow();
};

export const fromNowSeconds = (seconds) => {
  return moment(seconds * 1000).fromNow();
};

export default renderDate;
