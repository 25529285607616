import { Container } from './styles';
import FormCopyableItem from '../FormCopyableItem';
import { FormattedMessage } from 'react-intl';
import { TitleBlock } from '../FormRow/styles';

export const FormCopyableList = ({
  index,
  formRowIndex,
  inputValues,
  requiredFields,
  setInputValues,
  formRow,
}) => {
  return (
    <Container>
      {inputValues[formRow.id]?.map((_, copyableItemIndex) => {
        return (
          <div key={`copyable-item-${copyableItemIndex}`}>
            <TitleBlock>
              {formRow?.title && (
                <>
                  <FormattedMessage id={formRow?.title} defaultMessage={formRow?.title} />{' '}
                  <span>{copyableItemIndex + 1}</span>
                </>
              )}
            </TitleBlock>
            <FormCopyableItem
              index={index}
              formRowIndex={formRowIndex}
              inputValues={inputValues}
              requiredFields={requiredFields}
              setInputValues={setInputValues}
              formRow={formRow}
              copyableItemIndex={copyableItemIndex}
            />
          </div>
        );
      })}
    </Container>
  );
};
export default FormCopyableList;
