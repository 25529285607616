import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import TextField from '@mui/material/TextField';
import { ModalTitle } from '../../components/Modal';
import Error from '../../components/Error';
import { getData } from '../../modules/fetch';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { EmailInput } from '../../components/Input';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Shops from '../../components/Shops';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { FormattedMessage, useIntl } from "react-intl";
import { useAddUserMutation, useUpdateUserMutation } from '../../api/userAPI';

const Container = styled.div`
  max-width: 400px;
  display: flex;
  margin: 24px 20px;
  justify-content: space-between;
  flex-direction: column;
  @media ${mediaQueries.desktop} {
    margin: 24px 80px;
  }
`;

const TextFieldContainer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;

  > div {
    flex-grow: 1;

    &:nth-child(2) {
      margin-top: 20px;
    }
  }

  @media ${mediaQueries.desktop} {
    flex-direction: row;
    > div:nth-child(2) {
      margin: 0 0 0 15px;
    }
  }
`;

const ButtonsContainer = styled.div`
  margin: 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledInputLabel = styled(InputLabel)`
  div {
    padding: 0 5px;
    background-color: white;
  }
`;

const StyledAccordion = styled(Accordion)`
  border: none;
`;

const StyledAccordionItem = styled(AccordionItem)`
  position: relative;
`;

const StyledAccordionItemHeading = styled(AccordionItemHeading)`
  padding-left: 30px;
  .accordion__button {
    background-color: transparent;
  }
`;

const PlatformCheckbox = styled.div`
  position: absolute;
  left: 0;
  top: 3px;
`;

const UserForm = ({ data, handleClosePopup }) => {
  const [ completeCreate, setCompleteCreate ] = useState(false);
  const [ completeUpdate, setCompleteUpdate ] = useState(false);
  const [ types, setTypes ] = useState([]);
  const [ platforms, setPlatforms ] = useState([]);
  const [ selectedStores, setSelectedStores ] = useState(data?.stores || []);
  const [ values, setValues ] = useState({
    name: data?.name,
    surname: data?.surname,
    email: data?.email,
    type: data?.type,
  });
  const [ serverError, setServerError ] = useState('');
  const intl = useIntl();
  const [ addUser ] = useAddUserMutation();
  const [ updateUser ] = useUpdateUserMutation();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    getData('/users/types')
      .then((data) => {
        setTypes(data.data);
      })
      .catch((err) => {
        console.error(err);
        setServerError(err.message);
      });

    getData('/stores/listings/1,999')
      .then((data) => {
        const providers = data?.data.map(item => item.provider).filter((value, index, self) => self.indexOf(value) === index);
        const shopsData = providers.map(provider => {
          return {
            platform: provider,
            shops: data?.data.filter(shop => shop.provider === provider)
          }
        });
        setPlatforms(shopsData)
      })
      .catch((err) => {
        console.error(err);
        setServerError(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onStoreChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedStores([ ...selectedStores, event.target.value ]);
    } else {
      const index = selectedStores.indexOf(event.target.value);
      setSelectedStores(selectedStores.splice(index, 1));
    }
  };

  const onPlatformChange = (event) => {
    const isChecked = event.target.checked;
    const platformStores = platforms.filter(item => item.platform === event.target.value)[0].shops.map(item => item._id.$oid);
    const differences = platformStores.filter(x => !selectedStores.includes(x));
    const platformDifferences = selectedStores.filter(x => !platformStores.includes(x));
    if (isChecked) {
      setSelectedStores([ ...selectedStores, ...differences]);
    } else {
      setSelectedStores(platformDifferences);
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      user: {
        name: values.name,
        surname: values.surname,
        email: values.email,
        agreement: true,
        stores: selectedStores,
      },
      type: values.type,
    }

    if (data) {
      await updateUser({
        id: data._id.$oid,
        ...userData
      });
      setCompleteUpdate(true);
      handleClosePopup();
    } else {
      await addUser(userData);
      setCompleteCreate(true);
      handleClosePopup();
    }
  };

  return (
    <Container>
      { (completeCreate && (
              <ModalTitle>
                <FormattedMessage id="userManagement.userForm.modal.alert.create" defaultMessage="New user successfully created" />
                <br />
                <FormattedMessage id="userManagement.userForm.modal.alert.sent" defaultMessage="Email with all details was sent" />
        </ModalTitle>)) ||
      (completeUpdate && (
              <ModalTitle>
                <FormattedMessage id="userManagement.userForm.modal.alert.update" defaultMessage="User details updated successfully" />
              </ModalTitle>)) ||
      (
        <>
          { data ? (
                  <ModalTitle>
                    <FormattedMessage id="userManagement.userForm.modal.title.update" defaultMessage="Fill in Form to update User details" />
                  </ModalTitle>
          ) : (
                  <ModalTitle>
                    <FormattedMessage id="userManagement.userForm.modal.title.create" defaultMessage="Fill in Form to add new User" />
                  </ModalTitle>
          )}
          {serverError && <Error message={serverError} />}
          <form autoComplete="off" onSubmit={handleFormSubmit}>
            <TextFieldContainer>
              <TextField
                id="name"
                label={intl.formatMessage({
                  id: 'userManagement.userForm.modal.input_name',
                  defaultMessage: 'userManagement.userForm.modal.input_name',
                })}
                value={values.name}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                autoComplete="none"
                onChange={handleChange('name')}
              />
            </TextFieldContainer>
            <TextFieldContainer>
              <TextField
                id="surname"
                label={intl.formatMessage({
                  id: 'userManagement.userForm.modal.input_surname',
                  defaultMessage: 'userManagement.userForm.modal.input_surname',
                })}
                value={values.surname}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                autoComplete="none"
                onChange={handleChange('surname')}
              />
            </TextFieldContainer>
            <TextFieldContainer>
              <EmailInput
                id="email"
                label={intl.formatMessage({
                  id: 'userManagement.userForm.modal.input_email',
                  defaultMessage: 'userManagement.userForm.modal.input_email',
                })}
                value={values.email}
                autoComplete="none"
                handleChange={handleChange('email')}
              />
            </TextFieldContainer>
            <TextFieldContainer>
              <FormControl variant="outlined">
                <StyledInputLabel id="type-label">
                  <div>
                    <FormattedMessage id="userManagement.userForm.modal.input_role" defaultMessage="User Role" />
                  </div>
                </StyledInputLabel>
                <Select
                  labelId="type-label"
                  id="type"
                  value={values.type}
                  onChange={handleChange('type')}
                >
                  {types.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TextFieldContainer>
            <h4>
              <FormattedMessage id="userManagement.userForm.modal.stores" defaultMessage="Stores" />
            </h4>
            {platforms.map(platform => (
              <StyledAccordion allowZeroExpanded>
                <StyledAccordionItem>
                  <PlatformCheckbox>
                    <Checkbox
                      color="primary"
                      name="platform"
                      value={platform.platform}
                      onChange={onPlatformChange}
                    />
                  </PlatformCheckbox>
                  <StyledAccordionItemHeading>
                    <AccordionItemButton>
                      {platform.platform}
                    </AccordionItemButton>
                  </StyledAccordionItemHeading>
                  <AccordionItemPanel>
                    <Shops
                      stores={platform.shops}
                      onStoreChange={onStoreChange}
                      selectedStores={selectedStores}
                    />
                  </AccordionItemPanel>
                </StyledAccordionItem>
              </StyledAccordion>
            ))}
            <ButtonsContainer>
              <Button size="large" variant="contained" color="primary" type="submit">
                {data ?
                  <FormattedMessage id="userManagement.userForm.modal.update" defaultMessage="Update user" /> :
                  <FormattedMessage id="userManagement.userForm.modal.create" defaultMessage="Create user" />
                }
              </Button>
            </ButtonsContainer>
          </form>
        </>
      )}
    </Container>
  );
};

export default UserForm;
