const checkTags = (filters, customer) => {
  let resultCheckTags = true;
  if (filters?.tags?.length > 0) {
    const checkTags = (acc, tag) =>
      acc + (customer?.tags?.findIndex((item) => item?.text === tag) > -1 ? 1 : 0);
    resultCheckTags = filters?.tags?.reduce(checkTags, 0) > 0;
  }
  return resultCheckTags;
};

const checkDate = (filters, customer) => {
  let resultCheckDate = true;
  if ('startDate' in filters?.date && 'endDate' in filters?.date) {
    resultCheckDate =
      Date.parse(filters?.date?.startDate) <= Date.parse(customer?.message?.time?.date) &&
      Date.parse(customer?.message?.time?.date) <= Date.parse(filters?.date?.endDate);
  }
  return resultCheckDate;
};

function checkPlatforms(filters, customer) {
  return filters?.platforms?.length === 0 ? true : filters?.platforms?.includes(customer?.provider);
}

function checkAssignees(filters, customer) {
  let resultCheckAssignees = true;
  if (filters?.assignee?.length > 0) {
    const checkAssignees = (acc, assignee) =>
      acc + (customer?.assignees?.findIndex((item) => item?.id === assignee) > -1 ? 1 : 0);
    resultCheckAssignees = filters?.assignee?.reduce(checkAssignees, 0) > 0;
  }
  return resultCheckAssignees;
}

function checkShops(filters, customer) {
  return filters?.shops?.length === 0 ? true : filters?.shops?.includes(customer?.storeId);
}

function checkCountries(filters, customer) {
  const regex = new RegExp(filters?.countries?.join('|'), 'i');
  return filters?.countries?.length === 0 ? true : regex.test(customer?.store?.countryCode);
}

function filterByQuery(query, customer, result: boolean) {
  if (query && query?.length > 0) {
    const listAllQueries = query.split(' ');
    const listNames = listAllQueries.filter((item) => !item.startsWith('#'));
    let listTags = listAllQueries.filter((item) => item.startsWith('#'));
    listTags = listTags.map((item) => item.slice(1));
    const resultCheckNames =
      listNames.length === 0
        ? true
        : listNames.reduce(
        (acc, item) => (new RegExp(item, 'gi').test(customer?.name) ? acc + 1 : acc),
        0,
      ) > 0;
    const resultCheckTags =
      listTags.length === 0
        ? true
        : listTags.reduce(
        (acc, item) =>
          customer?.tags.filter((tag) => item === tag.text).length > 0 ? acc + 1 : acc,
        0,
      ) > 0;
    result = result && resultCheckNames && resultCheckTags;
  }
  return result;
}

function checkSurveys(filters, customer) {
  let result = false;
  if (filters?.surveys) {
    switch (filters?.surveys) {
      case 'question':
        result = customer?.survey?.questions?.hasOwnProperty('answer');
        break;
      case 'answer':
        result = !customer?.survey?.questions?.hasOwnProperty('answer');
        break;
    }
  }
  return result;
}

function checkMessages(filters, customer) {
  let result = false;
  if (filters?.messages) {
    switch (filters?.messages) {
      case 'last-customer':
        result = customer?.message?.to?.resource === 'store';
        break;
      case 'last-seller':
        result = customer?.message?.to?.resource === 'customer';
        break;
      case 'auto_reply':
        result = customer?.message?.type === 'auto_reply';
        break;
      case 'inquiry':
        result = customer?.hasCustomerMessage === true;
        break;
      case 'not-inquiry':
        result = customer?.hasSellerMessage === true;
        break;
    }
  }
  return result;
}

export const isCustomerFiltered = (customer, { isFiltering, filters, query, isStateSame }) => {
  let result = true;
  if (isFiltering) {
    const resultCheckTags = checkTags(filters, customer);
    const resultCheckDate = checkDate(filters, customer);
    const resultCheckPlatforms = checkPlatforms(filters, customer);
    const resultCheckAssignees = checkAssignees(filters, customer);
    const resultCheckShops = checkShops(filters, customer);
    const resultCheckCountries = checkCountries(filters, customer);
    const resultCheckSurveys = checkSurveys(filters, customer);
    const resultCheckMessages = checkMessages(filters, customer);
    result =
      resultCheckTags &&
      resultCheckDate &&
      resultCheckPlatforms &&
      resultCheckAssignees &&
      resultCheckShops &&
      resultCheckCountries &&
      resultCheckSurveys &&
      resultCheckMessages;
  }
  result = filterByQuery(query, customer, result);
  if (isStateSame !== null)
    return isStateSame ? result : true;
  else
    return result;
};
