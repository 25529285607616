import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .MuiButtonBase-root.MuiRadio-root.Mui-checked {
    color: #5abb7a;
  }
`;
export const TextLabel = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.5px;
`;
