import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  input[type=time] {
    background-color: #ECEAEA !important;
  }
  margin-top 20px;
`;
export const Title = styled.div`
  font-weight: 500;
  color: #050D42;
  font-size: 14px;
  line-height: 22px;
  min-width: 110px;
`;

export const Label = styled.label`
  display: block;
  margin-right: 5px;
`;

export const Input = styled.input`
  border: none;
  background-color: #ECEAEA !important;
  border-radius: 4px;
  padding: 2px 5px;
  margin-right: 20px;
`;