import { Content, OnTopRightBottomConner } from './styles';

export default ({ client, message, more = null }) => {
  return (
    <Content>
      {client && ''}
      {message?.reaction && (
        <OnTopRightBottomConner>{message?.reaction}</OnTopRightBottomConner>
      )}
      {more}
    </Content>
  );
};
