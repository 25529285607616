import { useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { getLogo } from '../../api/getLogo';

const Container = styled.div``;

const Stores = ({ item, stores }) => {
  const [storesToShow, setStoresToShow] = useState(3);

  const handleShowMoreStores = () => {
    const storesAmount = storesToShow === 3 ? stores.length + 1 : 3;
    setStoresToShow(storesAmount);
  };

  return (
    <Container>
      {item?.stores?.slice(0, storesToShow).map((store, index) => {
        const currentStore = stores?.find((item) => item?._id.$oid === store);

        return currentStore?.name ? (
          <div key={`stores-${index}`}>
            <img width="20" src={getLogo(currentStore?.provider)} alt="" style={{top: '5px', position: 'relative'}}/> {currentStore?.name} {currentStore?.countryCode?.toUpperCase()}
          </div>
        ) : null;
      })}
      {storesToShow === 3 && 'stores' in item && item?.stores?.length > 3 ? (
        <Button size="small" color="primary" onClick={handleShowMoreStores}>
          <FormattedMessage
            id="userManagement.userList.stores.button.all"
            defaultMessage="View all shops..."
          />
        </Button>
      ) : (
        ''
      )}
    </Container>
  );
};

export default Stores;
