import { createContext, useState } from 'react';
import { detailedDiff } from 'deep-object-diff';

export const ActiveChatContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [activeChat, setActiveChatLocal] = useState<any>();
  const [activeChatDiff, setActiveChatDiff] = useState<any>();
  const [lastActiveChatId, setLastActiveChatId] = useState<any>();

  const setActiveChat = (newActiveChat) => {
    const oldActiveChat = activeChat;
    if (oldActiveChat) {
      const diffActiveChat: any = detailedDiff(newActiveChat, oldActiveChat);
      if (Object.keys(diffActiveChat.updated).length > 0) {
        if ('updatedAt' in diffActiveChat.updated) {
          delete diffActiveChat.updated['updatedAt'];
        }
      }
      setActiveChatDiff(diffActiveChat);
    }

    setLastActiveChatId(activeChat?._id?.$oid ?? null);
    setActiveChatLocal(newActiveChat);
  };

  return (
    <ActiveChatContext.Provider
      value={[
        activeChat,
        setActiveChat,
        activeChatDiff,
        setActiveChatDiff,
        lastActiveChatId,
        setLastActiveChatId,
      ]}
    >
      {children}
    </ActiveChatContext.Provider>
  );
};
