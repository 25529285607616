import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import InboxItems from '../../components/InboxItems';
import { mediaQueries, MobileOnly, TabletAndDesktopOnly } from '../../constants/breakpoints';
import { InboxCountsContext } from '../../context/InboxCountsContext';
import { FormattedMessage } from 'react-intl';
import MessagesSearch from '../../components/MessagesSearch';
import { Link, Select, Switch } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AccountDataContext } from '../../context/AccountDataContext';
import { getCounts } from '../../api/getCounts';
import MailIcon from '@mui/icons-material/Mail';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Container = styled.div`
  width: calc(100vw - 5px);
  padding: 10px;
  flex-grow: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${mediaQueries.desktop} {
    max-width: 350px;
    height: 100%;
  }
`;
const SelectContainer = styled.div`
  display: flex;
  height: 20px;
  padding-top: 8px;

  > svg {
    margin-top: -3px;
    color: #c4c4c4;
    font-size: 12px;
  }

  .MuiInputBase-root {
    font-size: 1rem;
    letter-spacing: 0.5px;
    line-height: 14px;
    color: #747f8d;

    svg {
      position: absolute !important;
      right: 0px !important;
      top: -3px !important;
      pointer-events: none;
      color: #9e9c9c;
      font-size: 12px;
    }
  }

  #chats-select {
    position: relative;
    padding: 0 22px 0 8px;
    color: #6f6f6f;
  }

  @media ${mediaQueries.mobile} {
    display: none;
  }
`;
const TopContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search-input-container {
    display: none;
  }
`;
const FoldersContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  @media ${mediaQueries.tablet} {
    display: none;
  }

  .selected {
    color: #006876;
    border-bottom: 2px solid #006876;
  }
`;
const Folder = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none !important;

  svg {
    margin-right: 5px;
    margin-bottom: 3px;
    font-size: 12px;
    color: #006876;
  }
`;
const Title = styled.h5`
  margin-top: 0 !important;
  margin-bottom: 3px !important;
  text-transform: uppercase;
  text-decoration: none !important;
  text-align: center;
  color: #747f8d;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;
const StyledStack = styled(Stack)`
  margin-left: auto;
  @media ${mediaQueries.mobile} {
    margin-left: 0;
  }
`;
const DefaultText = styled.div`
  width: 90%;
  padding: 15px 20px;
  margin: 0 15px;
  position: relative;
  border-bottom: 1px solid #f4f4f4;
  text-align: center;
  font-size: 15px;
`;
const StyledTypography = styled(Typography)`
  color: #6e6e6e;
  font-size: 1rem;
`;
const AntSwitch = styled(Switch)`
  width: 22px !important;
  height: 14px !important;
  padding: 0 !important;
  display: flex;

  &:active {
    & .MuiSwitch-thumb {
      width: 13px;
    }

    & .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(12px);
    }
  }

  & .MuiSwitch-switchBase {
    padding: 0 !important;

    &.Mui-checked {
      transform: translateX(8px);
      color: #fff;

      & + .MuiSwitch-track {
        opacity: 1;
        background-color: #050D42;
      }
    }
  }

  & .MuiSwitch-thumb {
    width: 10px;
    height: 10px;
    margin-top: 2px;
    margin-left: 2px;
    border-radius: 6px;
    transition: width 0.2s ease-out;
  }

  & .MuiSwitch-track {
    border-radius: 6px;
    opacity: 1;
    background-color: rgba(0, 0, 0, .25);
    box-sizing: border-box;
  }

,
`;

const Inbox = ({
  getInbox,
  filters,
  setFilters,
  sort,
  setSort,
  setQuery,
  stores,
  users,
  handleChatOpen,
  isEmptyData,
  openInboxItems,
  newInboxItems,
  inProgressInboxItems,
  resolvedInboxItems,
  followedInboxItems,
  escalatedInboxItems,
  handlePageChange,
  inboxPage,
  currentFolder,
  query,
  openSmallChat,
}: {
  getInbox?: (isReset?: boolean, q?: any, unread?: number) => void;
  filters?: {};
  setFilters?: any;
  sort?: {};
  setSort?: any;
  setQuery?: any;
  stores?: [];
  users?: [];
  handleChatOpen?: (item: any) => void;
  isEmptyData?: boolean;
  openInboxItems?: [];
  newInboxItems?: [];
  inProgressInboxItems?: [];
  resolvedInboxItems?: [];
  followedInboxItems?: [];
  escalatedInboxItems?: [];
  handlePageChange?: (type?: string, value?: string, event?: any, q?: any) => void;
  inboxPage: any;
  currentFolder?: string;
  query?: any;
  openSmallChat: (chatData: any) => void;
}) => {
  const [currentInbox, setCurrentInbox] = useState('open');
  const [isMyChats, setIsMyChats] = useState(false);
  const [listCurrentAssignee, setListCurrentAssignee] = useState([]);
  const [counts, setCounts] = useContext(InboxCountsContext);
  const [currentAccount] = useContext(AccountDataContext);
  const foldersList = [{ open: 'All chats' }, { new: 'New chats' }, { inProgress: 'In progress' }];
  const [activeFolder, setActiveFolder] = useState(null);

  const handleChangeChats = (e) => {
    getInbox(false, '', e.target.value === 'unread' ? 1 : 0);
    getCounts(filters, setCounts, e.target.value === 'unread' ? 1 : 0);
    setCurrentInbox(e.target.value);
  };
  const handleChangeFolders = (folder) => (e) => {
    e.preventDefault();
    setActiveFolder(folder);
    getInbox(false, '', 0);
    getCounts(filters, setCounts, 0);
    setCurrentInbox(folder);
  };
  const handleChangeSwitch = (e) => {
    setIsMyChats(e.target.checked);
    if (e.target.checked) {
      setListCurrentAssignee(filters['assignee']);
      setFilters({ ...filters, assignee: [currentAccount?._id.$oid] });
    } else {
      setFilters({ ...filters, assignee: listCurrentAssignee });
    }
  };
  useEffect(() => {
    getInbox();
    getCounts(filters, setCounts);
  }, [isMyChats]);
  return (
    <Container>
      <TabletAndDesktopOnly>
        <MessagesSearch
          getInbox={getInbox}
          filters={filters}
          setFilters={setFilters}
          sort={sort}
          setSort={setSort}
          setQuery={setQuery}
          stores={stores}
          users={users}
        />
      </TabletAndDesktopOnly>
      {isEmptyData ? (
        <DefaultText>
          <FormattedMessage id="messages.inbox.empty" defaultMessage="Empty" />
        </DefaultText>
      ) : (
        <>
          {currentFolder === 'open' ? (
            <FoldersContainer>
              {foldersList.map((folder, i) => {
                return (
                  <Folder
                    key={`folder-${i}`}
                    underline={'none'}
                    className={activeFolder === Object.keys(folder)[0] ? 'selected' : null}
                    onClick={(event) => handleChangeFolders(Object.keys(folder)[0])(event)}
                  >
                    <MailIcon />
                    <Title>{Object.values(folder)[0]}</Title>
                  </Folder>
                );
              })}
            </FoldersContainer>
          ) : null}
          <TopContainer>
            {currentFolder === 'open' ? (
              <SelectContainer>
                <MailIcon />
                <Select
                  id="chats-select"
                  value={currentInbox}
                  onChange={handleChangeChats}
                  sx={{
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  }}
                  IconComponent={() => <ExpandMoreIcon />}
                >
                  <MenuItem value={'open'}>All chats</MenuItem>
                  <MenuItem value={'new'}>New</MenuItem>
                  <MenuItem value={'inProgress'}>In progress</MenuItem>
                  <MenuItem value={'unread'}>Unread</MenuItem>
                </Select>
              </SelectContainer>
            ) : null}
            <StyledStack direction="row" spacing={1} alignItems="center">
              <StyledTypography>Show my chats</StyledTypography>
              <AntSwitch
                checked={isMyChats}
                onChange={handleChangeSwitch}
                inputProps={{ 'aria-label': 'ant design' }}
              />
            </StyledStack>
            <MobileOnly>
              <MessagesSearch
                getInbox={getInbox}
                filters={filters}
                setFilters={setFilters}
                sort={sort}
                setSort={setSort}
                setQuery={setQuery}
                stores={stores}
                users={users}
              />
            </MobileOnly>
          </TopContainer>
          {currentFolder === 'open' && currentInbox === 'unread' && (
            <InboxItems
              items={openInboxItems}
              handleChatOpen={handleChatOpen}
              stores={stores}
              type="open"
              handlePageChange={handlePageChange}
              currentPage={inboxPage.open}
              amount={counts?.open}
              query={query}
              openSmallChat={openSmallChat}
            />
          )}
          {currentFolder === 'open' && currentInbox === 'open' && (
            <InboxItems
              items={openInboxItems}
              handleChatOpen={handleChatOpen}
              stores={stores}
              type="open"
              handlePageChange={handlePageChange}
              currentPage={inboxPage.open}
              amount={counts?.open}
              query={query}
              openSmallChat={openSmallChat}
            />
          )}
          {currentFolder === 'open' && currentInbox === 'new' && (
            <InboxItems
              items={newInboxItems}
              handleChatOpen={handleChatOpen}
              stores={stores}
              type="new"
              handlePageChange={handlePageChange}
              currentPage={inboxPage.new}
              amount={counts?.new}
              query={query}
              openSmallChat={openSmallChat}
            />
          )}
          {currentFolder === 'open' && currentInbox === 'inProgress' && (
            <InboxItems
              items={inProgressInboxItems}
              handleChatOpen={handleChatOpen}
              stores={stores}
              type="inProgress"
              handlePageChange={handlePageChange}
              currentPage={inboxPage.inProgress}
              amount={counts?.inProgress}
              query={query}
              openSmallChat={openSmallChat}
            />
          )}
          {currentFolder === 'followup' && (
            <InboxItems
              items={followedInboxItems}
              handleChatOpen={handleChatOpen}
              stores={stores}
              type="followed"
              handlePageChange={handlePageChange}
              currentPage={inboxPage.followed}
              amount={counts?.followup}
              query={query}
              openSmallChat={openSmallChat}
            />
          )}
          {currentFolder === 'escalated' && (
            <InboxItems
              items={escalatedInboxItems}
              handleChatOpen={handleChatOpen}
              stores={stores}
              type="escalated"
              handlePageChange={handlePageChange}
              currentPage={inboxPage.escalated}
              amount={counts?.escalated}
              query={query}
              openSmallChat={openSmallChat}
            />
          )}
          {currentFolder === 'resolved' && (
            <InboxItems
              items={resolvedInboxItems}
              handleChatOpen={handleChatOpen}
              stores={stores}
              type="resolved"
              handlePageChange={handlePageChange}
              currentPage={inboxPage.resolved}
              amount={counts?.resolved}
              query={query}
              openSmallChat={openSmallChat}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default Inbox;
