import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import Error from '../../components/Error';
import Button from '@mui/material/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUpdateAccountSettingsMutation } from '../../api/accountAPI';
import TextField from '@mui/material/TextField';
import { AccountDataContext } from '../../context/AccountDataContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 20px;
  background: #fff;
  border-radius: 6px;
  padding: 0 20px;
  @media ${mediaQueries.desktop} {
    padding: 20px 40px;
    margin: 24px 30px;
  }
`;
const StyledBlock = styled.div`
  margin-bottom: 40px;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;
const StyledTitle = styled.div`
  padding: 20px;
  color: #050d42;
  background: #ecf3f9;
  border-radius: 15px 15px 0 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
`;
const StyledBody = styled.div`
  padding: 20px;
  color: #050d42;
`;
const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > div {
    margin-right: 20px;

    :last-child {
      margin-right: 0;
    }
  }

  @media ${mediaQueries.desktop} {
    flex-direction: row;
  }
`;

const TextFieldContainer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;

  > div {
    flex-grow: 1;

    &:nth-child(2) {
      margin-top: 20px;
    }
  }

  @media ${mediaQueries.desktop} {
    flex-direction: column;
    max-width: 50%;
    > div {
      margin: 20px 0 20px 0;
    }
  }
`;

const Buttons = styled.div`
  button {
    width: 100%;
    @media ${mediaQueries.desktop} {
      width: auto;
    }
  }
`;

const Integrations = ({ showDetails, userData }) => {
  const [, setComplete] = useState(false);
  const [values, setValues] = useState({
    openai_key: '',
    openai_prompt: '',
    openai_message_limit: '10',
  });
  const [serverError, setServerError] = useState('');
  const intl = useIntl();
  const [updateAccountSettings] = useUpdateAccountSettingsMutation();
  const [, , , , reloadAccount] = useContext(AccountDataContext);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    renderData(userData);
  }, [userData]);

  const renderData = (user) => {
    setValues({
      openai_key: user?.settings?.integrations?.openai_key ?? '',
      openai_prompt: user?.settings?.integrations?.openai_prompt ?? '',
      openai_message_limit: user?.settings?.integrations?.openai_message_limit ?? '10',
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setServerError('Updating');
    await updateAccountSettings({
      account: {
        settings: {
          integrations: values,
        },
      },
    });
    setServerError('');
    setComplete(true);
    reloadAccount();
  };

  return (
    <Container>
      {serverError && <Error message={serverError} />}

      <form autoComplete="off" onSubmit={handleFormSubmit}>
        {showDetails && (
          <>
            <StyledBlock>
              <StyledTitle>
                <FormattedMessage id="settings.integrations.openai" defaultMessage="OpenAI" />
              </StyledTitle>
              <StyledBody>
                <InputsContainer>
                  <TextFieldContainer>
                    <TextField
                      id="openai_key"
                      label={intl.formatMessage({
                        id: 'settings.integrations.openai_key',
                        defaultMessage: 'OpenAI API key',
                      })}
                      value={values.openai_key}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      autoComplete="none"
                      onChange={handleChange('openai_key')}
                    />
                    <TextField
                      id="openai_prompt"
                      label={intl.formatMessage({
                        id: 'settings.integrations.openai_prompt',
                        defaultMessage: 'OpenAI Prompt',
                      })}
                      value={values.openai_prompt}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      autoComplete="none"
                      onChange={handleChange('openai_prompt')}
                    />
                    <TextField
                      id="openai_message_limit"
                      label={intl.formatMessage({
                        id: 'settings.integrations.openai_message_limit',
                        defaultMessage: 'OpenAI Message last amount limit',
                      })}
                      value={values.openai_message_limit}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      autoComplete="none"
                      onChange={handleChange('openai_message_limit')}
                    />
                  </TextFieldContainer>
                </InputsContainer>
              </StyledBody>
            </StyledBlock>
          </>
        )}
        <Buttons>
          <Button size="large" variant="contained" color="primary" type="submit">
            <FormattedMessage id="settings.integrations.details.button" defaultMessage="Update" />
          </Button>
        </Buttons>
      </form>
    </Container>
  );
};

export default Integrations;
