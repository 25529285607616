import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import Button from '@mui/material/Button';

export const Container = styled.div`
  max-width: 400px;
  display: flex;
  margin: 24px 20px;
  justify-content: space-between;
  flex-direction: column;
  @media ${mediaQueries.desktop} {
    margin: 24px 80px;
  }
`;
export const Text = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
export const BackButton = styled(Button)`
  width: 67px;
  height: 36px;
  padding: 8px 16px;
  background: #fff !important;
  color: #006876 !important;
  border-radius: 20px !important;
  line-height: 1 !important;
  border: 2px solid #006876 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
`;
export const SaveButton = styled(Button)`
  width: 67px;
  height: 36px;
  padding: 8px 16px;
  background: #006876 !important;
  border-color: #006876 !important;
  border-radius: 20px !important;
  line-height: 1 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
`;
