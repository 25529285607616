import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthenticationHeaders } from '../modules/fetch';
import { API } from '../config';

export const surveyApi = createApi({
  reducerPath: 'surveyApi',
  baseQuery: fetchBaseQuery({ baseUrl: API }),
  tagTypes: ['Survey'],
  endpoints: (builder) => ({
    surveys: builder.query({
      query: () => ({
        url: '/surveys/listings/1,100',
        headers: getAuthenticationHeaders('application/json'),
      }),
      providesTags: ['Survey'],
    }),
    fetchTemplateSurvey: builder.query({
      query: () => ({
        url: '/surveys/survey-template',
        headers: getAuthenticationHeaders('application/json'),
      }),
    }),
    addSurvey: builder.mutation({
      query: (survey) => ({
        url: '/surveys/create',
        method: 'POST',
        headers: getAuthenticationHeaders('application/json'),
        body: survey,
      }),
      invalidatesTags: ['Survey'],
    }),
    updateSurvey: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/surveys/update/${id}`,
        method: 'PUT',
        headers: getAuthenticationHeaders('application/json'),
        body: { survey: rest },
      }),
      invalidatesTags: ['Survey'],
    }),
    updateStatusSurvey: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/surveys/update-status/${id}`,
        method: 'PUT',
        headers: getAuthenticationHeaders('application/json'),
        body: rest,
      }),
      invalidatesTags: ['Survey'],
    }),
    deleteSurvey: builder.mutation({
      query: (id) => ({
        url: `/surveys/remove/${id}`,
        method: 'DELETE',
        headers: getAuthenticationHeaders('application/json'),
      }),
      invalidatesTags: ['Survey'],
    }),
  }),
});

export const {
  useSurveysQuery,
  useFetchTemplateSurveyQuery,
  useAddSurveyMutation,
  useUpdateSurveyMutation,
  useUpdateStatusSurveyMutation,
  useDeleteSurveyMutation,
} = surveyApi;
