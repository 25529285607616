import { Content, Message } from '../styles';
import DeliveryStatus from '../../DeliveryStatus';
import LastErrorMessage from '../../LastErrorMessage';
import Avatar from '../../Avatar';
import VoucherItem from './item';
import ErrorMessage from '../../ErrorMessage';

export default ({ client, message, customerDataStoreTimezone, more = null }) => {
  return (
    <Message isClient={client} key={message?._id?.$oid + message?.sendId}>
      <Content>
        <Avatar message={message} isClient={client} />
        <VoucherItem voucher={message?.body?.voucher} />
        {more}
        <DeliveryStatus
          isClient={client}
          message={message}
          customerDataStoreTimezone={customerDataStoreTimezone}
        />
        <LastErrorMessage message={message} />
        <ErrorMessage message={message?.rawResponse?.process_msg ?? ''} />
      </Content>
    </Message>
  );
};
