import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 20px;
  background: #fff;
  border-radius: 6px;
  padding: 0 20px;
  @media ${mediaQueries.desktop} {
    padding: 20px 40px;
    margin: 24px 30px;
  }
`;

export const ButtonTitle = styled.li`
  color: #3c3c3c;
  font-weight: bold;
  border-bottom: 1px solid #9c9c9c;
  width: 100%;
  text-align: center;
  button {
    border: 1px solid #9c9c9c;
    margin-bottom: 10px;
  }
`;

export const ButtonTitleSmall = styled.li`
  color: #3c3c3c;
  font-weight: bold;
  border-bottom: 0px solid #9c9c9c;
  margin-bottom: 0px;
  text-align: center;
  button {
    border: 1px solid #9c9c9c;
    margin-bottom: 0px;
  }
`;

export const Block = styled.div`
  flex-direction: column;
  margin-right: 10px;

  div {
    :first-child {
    }
    :last-child {
    }
  }
`;

export const Title = styled.div`
  color: #3c3c3c;
  font-weight: bold;
  border-bottom: 1px solid #9c9c9c;
`;

export const StyledList = styled.div`
  display: flex;
  flex-flow: row wrap;
  font-size: 1.2rem;

  @media ${mediaQueries.mobile} {
    justify-content: center;
    align-self: center;
    margin: 0 auto 10px auto;
    width: 100%;
  }

`;

export const InnerStyledListRow = styled(List)`
  display: flex;
  flex-flow: row wrap;
  background-color: ghostwhite;
  margin-top: 10px !important;
  margin-right: 10px !important;
  padding: 10px !important;
`;

export const InnerStyledList = styled(List)`
  display: flex;
  flex-flow: column wrap;
  background-color: ghostwhite;
  margin-top: 10px !important;
  margin-right: 10px !important;
  padding: 10px !important;
`;

export const StyledListItem = styled(ListItem)`
  display: flex;
  flex-flow: row wrap;
  letter-spacing: 0.5px;
  align-items: center;
  margin-bottom: 7px;

  div {
    :first-child {
      color: #0e0c0c;
      font-weight: bold;
      width: 100%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
    :last-child {
      color: #3e3c3c;
      width: 100%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin-right: 10px;
      word-break: break-all;
    }
  }
`;

export const ButtonStyledListItem = styled(ListItem)`
  display: flex;
  flex-flow: row wrap;
  letter-spacing: 0.5px;
  align-items: center;
  margin-bottom: 7px;

  div {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: #3e3c3c;
    width: 300px;
    text-transform: uppercase;
    word-break: break-all;
  }
`;

export const StyledListItemText = styled(ListItemText)`
  div{
    :last-child {
      word-wrap: break-word;
    }
  }
`;

export const StyledSubListItemText = styled(ListItemText)`
  :empty {
    content: 'N/A';
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
  
  button {
    margin-right: 10px;
    width: 100%;
    @media ${mediaQueries.desktop} {
    }

    @media ${mediaQueries.mobile} {
      flex-flow: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      margin: 0 auto 10px auto;
      width: 300px;
    }
  }
`;

export const TimezoneButtons = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin: 0 !important;
  
  width: 360px;
  
  button {
    width: 100%;
    @media ${mediaQueries.desktop} {
    }

    @media ${mediaQueries.mobile} {
      flex-flow: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      margin: 0 auto 0 auto;
      width: 360px;
    }
  }
`;

export const TimezoneBlock = styled(List)`
  display: flex;
  flex-flow: column nowrap;
  background-color: ghostwhite;
  margin-top: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;
  
  div {
    button {
      display: contents !important;
    }
  }
`;

export const TimezoneStyledListItem = styled(ListItem)`
  display: flex;
  flex-flow: row wrap;
  letter-spacing: 0.5px;
  align-items: center;
  margin: 0;
  padding: 0;

  div {
    :first-child {
      color: #0e0c0c;
      font-weight: bold;
      width: 100%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin-right: 0;
    }
    :last-child {
      color: #3e3c3c;
      width: 100%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin-right: 0;
      word-break: break-all;
    }
  }
`;

export const ButtonSaveTitleSmall = styled.li`
  color: #3c3c3c;
  font-weight: bold;
  border-bottom: 0 solid #9c9c9c;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  justify-content: end;
  button {
    border: 1px solid #9c9c9c;
    margin-bottom: 0;
  }
`;

export const ButtonSave = styled(Button)`
  margin-left: 10px;
`;
