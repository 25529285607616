import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Box, Chip, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledAvatar } from './styles';
import LIST_ALL_COUNTRIES from '../../../constants/listAllCountries.json';
import { getInputDependedValues } from '../../../modules/getinput';
import InputAdornment from '@mui/material/InputAdornment';
import { logMessage } from '../../../logger';

export const FormAutocompleteCountries = ({ input, inputValues, requiredFields, setInputValues }) => {
  const intl = useIntl();
  const [countries, setCountries] = useState([]);
  const [listCountries,] = useState(LIST_ALL_COUNTRIES);

  useEffect(() => {
    if (inputValues[input?.id]) {
      const inputDependedValues = getInputDependedValues(input, inputValues);
      setCountries(inputDependedValues);
    }
  }, [input]);

  const handleChangeCountryCodes = (event, value) => {
    logMessage('event', event, value);
    setCountries(value);
    setInputValues({
      ...inputValues, [input.id]: value
    });
  };

  const getCountryLabel = (option: any) => {
    if (option?.phone)
      return (option?.label ?? '') + ' (+' + option?.phone + ')';

    return (option?.label ?? '');
  }
  return (
    <FormControl fullWidth>
      <Autocomplete
        id={input?.id}
        options={listCountries}
        multiple={input?.multiple ?? false}
        limitTags={3}
        autoHighlight
        defaultValue={input?.defaultValue}
        value={countries}
        onChange={handleChangeCountryCodes}
        getOptionLabel={getCountryLabel}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
              alt=""
            />
            {option?.label} (+{option?.phone})
          </Box>
        )}
        renderTags={(value: readonly any[], getTagProps) =>
          value?.map((val: any, index: number) => (
            <Chip
              size="small"
              variant="outlined"
              avatar={
                <StyledAvatar src={`https://flagcdn.com/w20/${val?.code?.toLowerCase()}.png`} />
              }
              label={val?.label}
              deleteIcon={<CloseIcon />}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required={requiredFields[input.id]}
            InputProps={{
              ...params.InputProps,
              required: (countries?.length ?? 0) === 0,
              startAdornment: (
                <>
                  <InputAdornment position={'start'}>
                    {inputValues?.[input.id] && <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${inputValues?.[input.id]?.code?.toLowerCase()}.png`}
                      alt=""
                    ></img>}
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }}
            label={intl.formatMessage({
              id: (input?.label ?? 'messages.messagesSearch.countries'),
              defaultMessage: 'messages.messagesSearch.countries',
            })}
          />
        )}
      />
    </FormControl>
  );
};
export default FormAutocompleteCountries;
