import { BackButton, SaveButton, Step, Steps, StyledButton, Title } from './styles';
import { ButtonsContainer } from '../../containers/ReportForm/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import ReportFormRenderContent from '../ReportFormRenderContent';
import {useState} from 'react';

export const FormTemplateSteps = ({
  handleFormSubmit,
  inputValues,
  requiredFields,
  setInputValues,
  inputLabels,
  setInputLabels,
  reportFormData,
  isPreview,
  setIsPreview,
  currentStep,
  setCurrentStep,
  isPreviewTicket,
}) => {
  const intl = useIntl();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleNextStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep + 1);
  };
  const showPreview = (e) => {
    e.preventDefault();
    setIsPreview(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!disableSubmit) {
      setTimeout(async ()=> {
        await handleFormSubmit(e);
        setDisableSubmit(false);
      });
    }
    setDisableSubmit(true);
  };

  return (
    <>
      {isPreview ? (
        <Title>
          <FormattedMessage id="messages.chat.reportForm.title.preview" />
        </Title>
      ) : (
        <>
          <Title
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id: 'messages.chat.reportForm.title.fill',
                  defaultMessage: 'messages.chat.reportForm.title.fill',
                },
                {
                  text: intl.formatMessage({
                    id: 'messages.chat.reportForm.title.fill.text',
                    defaultMessage: 'messages.chat.reportForm.title.fill.text',
                  }),
                },
              ),
            }}
          />
          <Steps>
            {reportFormData.map((step, index) => (
              <Step
                $isActive={index === currentStep}
                $isComplete={index < currentStep}
                $step={index + 1}
                key={`step-${index}`}
              >
                <FormattedMessage id={step.stepName} />
              </Step>
            ))}
          </Steps>
        </>
      )}

      <form autoComplete="off">
        <ReportFormRenderContent
          inputValues={inputValues}
          requiredFields={requiredFields}
          setInputValues={setInputValues}
          inputLabels={inputLabels}
          setInputLabels={setInputLabels}
          reportFormData={reportFormData}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isPreview={isPreview}
          setIsPreview={setIsPreview}
          isPreviewTicket={isPreviewTicket}
        />
        {!isPreviewTicket && (
          <ButtonsContainer>
            {currentStep === reportFormData.length - 1 ? (
              <>
                <BackButton
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentStep(currentStep - 1);
                    setIsPreview(false);
                  }}
                >
                  <FormattedMessage
                    id="messages.chat.reportForm.button.back"
                    defaultMessage="Back"
                  />
                </BackButton>
                {!isPreview && (
                  <StyledButton onClick={showPreview}>
                    <FormattedMessage
                      id="messages.chat.reportForm.button.preview"
                      defaultMessage="Preview"
                    />
                  </StyledButton>
                )}
                <SaveButton type="submit" onClick={handleSubmit} disabled={disableSubmit}>
                  <FormattedMessage
                    id="messages.chat.reportForm.button.save"
                    defaultMessage="Save"
                  />
                </SaveButton>
              </>
            ) : (
              <>
                {currentStep > 0 ? (
                  <BackButton
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    <FormattedMessage
                      id="messages.chat.reportForm.button.back"
                      defaultMessage="Back"
                    />
                  </BackButton>
                ) : (
                  <div></div>
                )}
                <SaveButton onClick={handleNextStep}>
                  <FormattedMessage
                    id="messages.chat.reportForm.button.continue"
                    defaultMessage="Continue"
                  />
                </SaveButton>
              </>
            )}
          </ButtonsContainer>
        )}
      </form>
    </>
  );
};

export default FormTemplateSteps;
