import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Container = styled.div<{ $isPreviewTicket?: boolean }>`
  position: absolute;
  z-index: 1299;
  top: 0;
  width: 100vw;
  height: calc(100vh - 61px);
  display: block;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.$isPreviewTicket
      ? `
      left: calc(380px - 100vw);
  `
      : `
      left: 0;
    `}
`;
const InnerContainer = styled.div`
  display: block;
  align-items: start;
  justify-content: center;
`;
const Shadow = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 0;
  background: rgba(255, 255, 255, 0.5);
`;
const PopupWrapper = styled.div<{ $isMedia?: boolean }>`
  max-width: 600px;
  background: #fff;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.25);
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  margin: auto;
  margin-top: 100px;
  ${(props) =>
    props.$isMedia
      ? `
      min-width: 360px;
    border-radius: 16px;
    padding: 25px;
  `
      : `
      min-width: 900px;
    border-radius: 16px;
    padding: 25px;
    `}
`;

const StyledIconButton = styled(IconButton)`
  position: absolute !important;
  top: 0px !important;
  right: 5px !important;
  z-index: 1;
  svg {
    color: #333;
    font-size: 25px;
  }
`;

export const WhitePopup = ({ isPreviewTicket = false, setIsPreviewTicket = false, isMedia = false, handleClose, ...props }) => {
  return (
    <Container $isPreviewTicket={isPreviewTicket}>
      <Shadow onClick={handleClose} />
      <InnerContainer>
        <PopupWrapper className={'popup-wrapper'} $isMedia={isMedia}>
          <StyledIconButton aria-label="hide" onClick={handleClose}>
            <CloseIcon />
          </StyledIconButton>
          {props.children}
        </PopupWrapper>
      </InnerContainer>
    </Container>
  );
};

export default WhitePopup;
