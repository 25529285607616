import { useContext, useEffect, useState } from 'react';
import { retrieve, store } from '../../modules/storage';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import InboxQuery from '../../components/InboxQuery';
import { InboxCountsContext } from '../../context/InboxCountsContext';
import { IsFilteringContext } from '../../context/IsFilteringContext';
import {
  ButtonContainer,
  Container,
  DateContainer,
  DateInput,
  Filter,
  FiltersContainer,
  FilterTitle,
  Image,
  Label,
  RowContainer,
  SearchContainer,
  SearchInputContainer,
  StyledAvatar,
  StyledAvatarChannel,
  StyledButton,
  StyledCalendarTodayIcon,
  StyledDateRange,
  StyledIconButton,
  StyledMenu,
} from './styles';
import { getCounts } from '../../api/getCounts';
import { Badge, Box, FormControlLabel, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { FormattedMessage, useIntl } from 'react-intl';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import { addDays, format, formatISO } from 'date-fns';
import Button from '@mui/material/Button';
import LIST_ALL_COUNTRIES from '../../constants/listAllCountries.json';
import { getData } from '../../modules/fetch';
import FiltersStruct from '../../constants/FilterStruct';
import Menu from '@mui/material/Menu';
import { getLogo } from '../../api/getLogo';
import { GlobalUpdateQueryContext } from '../../context/GlobalUpdateQueryContext';
import { ListAllCountires } from '../../constants/listAllCountires';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { logMessage } from '../../logger';

const MessagesSearch = ({
  getInbox,
  filters,
  setFilters,
  sort,
  setSort,
  setQuery,
  stores,
  users,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [, setCounts] = useContext(InboxCountsContext);
  const [isFiltering, setIsFiltering] = useContext(IsFilteringContext);
  const [tags, setTags] = useState([]);
  const [filterMessage, setFilterMessage] = useState(null);
  const [filterSurvey, setFilterSurvey] = useState(null);
  const [listAllTags, setListAllTags] = useState([]);
  const [countries, setCountries] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [channels, setChannels] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const intl = useIntl();
  const [date, setDate] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null);
  const [globalUpdateQuery, setGlobalUpdateQuery] = useContext(GlobalUpdateQueryContext);

  const handleFilterMenuOpen = (event) => {
    let filteredCountries = [];
    for (let countryCode of filters.countries) {
      filteredCountries = [
        ...filteredCountries,
        LIST_ALL_COUNTRIES.find((item: ListAllCountires) => item.code === countryCode),
      ];
    }
    let filteredChannels = [];
    for (let channelId of filters.shops) {
      filteredChannels = [...filteredChannels, stores?.find((item) => item._id.$oid === channelId)];
    }
    let filteredAssignee = [];
    for (let assigneeId of filters.assignee) {
      filteredAssignee = [...filteredAssignee, users?.find((item) => item._id.$oid === assigneeId)];
    }
    setCountries(filteredCountries);
    setAssignee(filteredAssignee);
    setChannels(filteredChannels);
    setTags(filters.tags);
    setAnchorEl(event.currentTarget);
  };
  const handleFilterMenuClose = () => {
    handleIsFiltering();
    setAnchorEl(null);
  };
  const handleUpdateQuery = (q) => {
    if (q !== '') setQuery(q);
    getCounts(filters, setCounts);
    getInbox(false, q);
  };

  const handleSort = () => {
    setSort(
      sort['message.time.date'] === -1 ? { 'message.time.date': 1 } : { 'message.time.date': -1 },
    );
  };

  useEffect(() => {
    setGlobalUpdateQuery({ ...globalUpdateQuery, handleUpdateQuery });
  }, []);

  useEffect(() => {
    getInbox(false);
  }, [sort]);

  function handleIsFiltering() {
    function getTotalLength(filtering, filter) {
      return filtering + filter?.length;
    }

    let checkFilters = Object.values(filters).map((item) =>
      item ? (typeof item === 'object' ? Object.keys(item) : item) : null,
    );
    setIsFiltering(Object.values(checkFilters).reduce(getTotalLength, 0) > 0);
  }

  const getListAllTags = () => {
    getData('/tags/listings')
      .then((data) => {
        setListAllTags(data?.data.map((item) => item.text));
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getListAllTags();
    handleIsFiltering();
  }, [filters]);

  useEffect(() => {
    getData('/stores/config')
      .then((data) => {
        data?.data && setPlatforms(Object.keys(data?.data));
      })
      .catch((err) => {
        console.error(err);
      });
    getListAllTags();
    getCounts(filters, setCounts);
  }, []);

  const handlePlatformsChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const currentFilters = [...filters[event.target.name], event.target.value];
      setFilters({ ...filters, [event.target.name]: currentFilters });
    } else {
      const index = filters[event.target.name].indexOf(event.target.value);
      filters[event.target.name].splice(index, 1);
      setFilters({ ...filters });
    }
  };
  const handleChangeCountries = (event, value) => {
    logMessage('event', event);
    setCountries(value);
    setFilters({ ...filters, countries: value.map((item) => item.code) });
  };
  const handleChangeChannels = (event, value) => {
    logMessage('event', event);
    setChannels(value);
    setFilters({ ...filters, shops: value.map((item) => item._id.$oid) });
  };
  const handleChangeAssignee = (event, value) => {
    logMessage('event', event);
    setAssignee(value);
    setFilters({ ...filters, assignee: value.map((item) => item._id.$oid) });
  };
  const handleChangeTags = (event, value) => {
    logMessage('event', event);
    setTags(value);
    setFilters({ ...filters, tags: value });
  };
  const handleChangeFilterMessage = (event) => {
    setFilterMessage(event.target.value);
    setFilters({ ...filters, messages: event.target.value });
  };
  const handleChangeFilterSurvey = (event) => {
    setFilterSurvey(event.target.value);
    setFilters({ ...filters, surveys: event.target.value });
  };

  const handleDateRangeChange = (range: { selection: { startDate: Date; endDate: Date } }) => {
    setDate(range?.selection);
    const startDate = range?.selection?.startDate;
    startDate.setHours(0, 0, 0, 0);
    const endDate = range?.selection?.endDate;
    endDate.setHours(23, 59, 59, 0);
    setFilters({
      ...filters,
      date: {
        startDate: formatISO(startDate),
        endDate: formatISO(endDate),
      },
    });
    if (range?.selection?.startDate !== range?.selection?.endDate) {
      handleCloseDateRange();
    }
  };

  const handleDateFixedRange = (event) => {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setHours(23, 59, 59, 0);
    switch (event.target.name) {
      case 'today':
        startDate.setHours(0, 0, 0, 0);
        setDate({
          startDate: startDate,
          endDate: endDate,
          key: 'selection',
        });
        setFilters({
          ...filters,
          date: {
            startDate: formatISO(startDate),
            endDate: formatISO(endDate),
          },
        });
        break;
      case 'week':
        startDate = addDays(new Date(), -7);
        startDate.setHours(0, 0, 0, 0);
        setDate({
          startDate: startDate,
          endDate: endDate,
          key: 'selection',
        });
        setFilters({
          ...filters,
          date: {
            startDate: formatISO(startDate),
            endDate: formatISO(endDate),
          },
        });
        break;
      case 'month':
        startDate = addDays(new Date(), -30);
        startDate.setHours(0, 0, 0, 0);
        setDate({
          startDate: startDate,
          endDate: endDate,
          key: 'selection',
        });
        setFilters({
          ...filters,
          date: {
            startDate: formatISO(startDate),
            endDate: formatISO(endDate),
          },
        });
        break;
    }
  };
  const handleFiltersReset = () => {
    setCountries([]);
    setAssignee([]);
    setChannels([]);
    setTags([]);
    setDate({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    });
    handleFilterMenuClose();
    setFilters(FiltersStruct);
    store.json('filters', FiltersStruct);
    setIsFiltering(false);
    setSort({});
    getInbox(true);
    getCounts(FiltersStruct, setCounts);
  };

  const handleFiltersSubmit = () => {
    handleIsFiltering();
    getCounts(filters, setCounts);
    store.json('filters', filters);
    handleFilterMenuClose();
    getInbox();
  };
  const handleOpenDateRange = (event) => {
    setAnchorElem(event.currentTarget);
  };

  const handleCloseDateRange = () => {
    setAnchorElem(null);
  };

  return (
    <Container>
      <SearchContainer>
        <SearchInputContainer className={'search-input-container'}>
          <StyledIconButton
            aria-label="search"
            onClick={() => {
              handleUpdateQuery(retrieve.string('inboxQuery'));
            }}
          >
            <SearchIcon sx={{ color: '#E8E8E8' }} fontSize="small" />
          </StyledIconButton>
          <InboxQuery />
        </SearchInputContainer>
        <StyledIconButton onClick={handleSort}>
          <Image src="/img/sorting-icon.svg" />
        </StyledIconButton>
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: '#006876',
            },
          }}
          variant="dot"
          badgeContent=" "
          invisible={!isFiltering}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <StyledIconButton
            aria-label="filters"
            aria-controls="filters-menu"
            aria-haspopup="true"
            onClick={handleFilterMenuOpen}
          >
            <Image src="/img/filter.svg" />
          </StyledIconButton>
        </Badge>
        <StyledMenu
          id="filters-menu"
          className={'filter-menu'}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleFilterMenuClose}
          PaperProps={{
            style: {
              width: 580,
              padding: 20,
            },
          }}
        >
          <FilterTitle bold={true}>
            <FormattedMessage id="messages.messagesSearch.filters" defaultMessage="Filters" />
          </FilterTitle>

          <FiltersContainer>
            <Label bold={true}>
              <FormattedMessage id="messages.messagesSearch.platforms" />
            </Label>
            <RowContainer>
              {Array.isArray(platforms) &&
                platforms?.map((platform, index) => (
                  <Filter key={`inbox-filter-${index}`}>
                    <FormControlLabel
                      color="primary"
                      name="platforms"
                      value={platform}
                      control={<Checkbox sx={{ color: '#6f6f6f' }} />}
                      label={platform}
                      labelPlacement="end"
                      onChange={handlePlatformsChange}
                      checked={filters?.platforms?.includes(platform)}
                    />
                  </Filter>
                ))}
            </RowContainer>
            <RowContainer>
              <Autocomplete
                id="country-select"
                options={LIST_ALL_COUNTRIES}
                multiple
                limitTags={3}
                autoHighlight
                defaultValue={countries}
                value={countries}
                onChange={handleChangeCountries}
                getOptionLabel={(option: any) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2 } }} {...props}>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label}
                  </Box>
                )}
                renderTags={(value: readonly any[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      size="small"
                      variant="outlined"
                      avatar={
                        <StyledAvatar
                          src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                        />
                      }
                      label={option?.label}
                      deleteIcon={<CloseIcon />}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: 'messages.messagesSearch.countries',
                      defaultMessage: 'messages.messagesSearch.countries',
                    })}
                  />
                )}
              />
            </RowContainer>
            <RowContainer>
              <FormControl fullWidth>
                <Autocomplete
                  id="select-assignee"
                  options={users ?? []}
                  multiple
                  limitTags={3}
                  autoHighlight
                  defaultValue={assignee}
                  value={assignee}
                  onChange={handleChangeAssignee}
                  getOptionLabel={(option: any) => `${option.name} ${option.surname}`}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {`${option.name} ${option.surname}`}
                    </Box>
                  )}
                  renderTags={(value: readonly any[], getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        size="small"
                        key={`assignee-${index}`}
                        variant="outlined"
                        label={`${option.name} ${option.surname}`}
                        deleteIcon={<CloseIcon />}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: 'messages.messagesSearch.assignee',
                        defaultMessage: 'messages.messagesSearch.assignee',
                      })}
                    />
                  )}
                />
              </FormControl>
            </RowContainer>
            <RowContainer>
              <FormControl fullWidth>
                <Autocomplete
                  id="channels-select"
                  options={stores ?? []}
                  multiple
                  limitTags={3}
                  autoHighlight
                  defaultValue={channels}
                  value={channels}
                  onChange={handleChangeChannels}
                  getOptionLabel={(option: any) => `${option?.name} ${option?.provider}`}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2 } }} {...props}>
                      <img width="15" src={getLogo(option?.provider)} alt="" />
                      {`${option?.name} (${option?.countryCode?.toUpperCase()})`}
                    </Box>
                  )}
                  renderTags={(value: readonly any[], getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        size="small"
                        key={`channel-${index}`}
                        variant="outlined"
                        avatar={<StyledAvatarChannel src={getLogo(option?.provider)} />}
                        label={`${option?.name} (${option.countryCode.toUpperCase()})`}
                        deleteIcon={<CloseIcon />}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: 'messages.messagesSearch.channels',
                        defaultMessage: 'messages.messagesSearch.channels'
                      })}
                    />
                  )}
                />
              </FormControl>
            </RowContainer>
            <RowContainer>
              <FormControl fullWidth>
                <Autocomplete
                  id="select-tags"
                  options={listAllTags}
                  multiple
                  limitTags={3}
                  autoHighlight
                  defaultValue={tags}
                  value={tags}
                  onChange={handleChangeTags}
                  getOptionLabel={(option: string) => option}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option}
                    </Box>
                  )}
                  renderTags={(value: readonly any[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip
                        size="small"
                        key={`tags-${index}`}
                        variant="outlined"
                        label={option}
                        deleteIcon={<CloseIcon />}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: 'messages.messagesSearch.tags',
                        defaultMessage: 'messages.messagesSearch.tags',
                      })}
                    />
                  )}
                />
              </FormControl>
            </RowContainer>
            <RowContainer>
              <FormControl fullWidth>
                <InputLabel id="select-messages-select-label"><FormattedMessage id="messages.messagesSearch.messages.label" defaultMessage={'Messages'} /></InputLabel>
                <Select
                  labelId="select-messages-select-label"
                  id="select-messages"
                  value={filterMessage}
                  label="Messages"
                  onChange={handleChangeFilterMessage}
                >
                  <MenuItem value={null}><FormattedMessage id="messages.messagesSearch.messages.value.none" defaultMessage={'None'} /></MenuItem>
                  <MenuItem value={'last-customer'}><FormattedMessage id="messages.messagesSearch.messages.value.lastCustomer" defaultMessage={'Last Customer Message'} /></MenuItem>
                  <MenuItem value={'last-seller'}><FormattedMessage id="messages.messagesSearch.messages.value.lastSeller" defaultMessage={'Last Seller Message'} /></MenuItem>
                  <MenuItem value={'auto_reply'}><FormattedMessage id="messages.messagesSearch.messages.value.auto_reply" defaultMessage={'Auto Reply'} /></MenuItem>
                  <MenuItem value={'inquiry'}><FormattedMessage id="messages.messagesSearch.messages.value.inquiry" defaultMessage={'Has messages from customer'} /></MenuItem>
                  <MenuItem value={'not-inquiry'}><FormattedMessage id="messages.messagesSearch.messages.value.not_inquiry" defaultMessage={'Last Seller Message'} /></MenuItem>
                </Select>
              </FormControl>
            </RowContainer>
            <RowContainer>
              <FormControl fullWidth>
                <InputLabel id="select-survey-select-label"><FormattedMessage id="messages.messagesSearch.survey.label" defaultMessage={'Surveys'} /></InputLabel>
                <Select
                  labelId="select-survey-select-label"
                  id="select-survey"
                  value={filterSurvey}
                  label="Surveys"
                  onChange={handleChangeFilterSurvey}
                >
                  <MenuItem value={null}><FormattedMessage id="messages.messagesSearch.survey.value.none" defaultMessage={'None'} /></MenuItem>
                  <MenuItem value={'question'}><FormattedMessage id="messages.messagesSearch.survey.value.question" defaultMessage={'Has question'} /></MenuItem>
                  <MenuItem value={'answer'}><FormattedMessage id="messages.messagesSearch.survey.value.answer" defaultMessage={'Has answer'} /></MenuItem>
                </Select>
              </FormControl>
            </RowContainer>
            <RowContainer>
              <DateContainer>
                <DateInput onClick={handleOpenDateRange}>
                  <StyledCalendarTodayIcon />
                  {format(date?.startDate, 'dd MMM yyyy')} - {format(date?.endDate, 'dd MMM yyyy')}
                </DateInput>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElem}
                  open={Boolean(anchorElem)}
                  onClose={handleCloseDateRange}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    style: {
                      width: 332,
                      height: 292,
                    },
                  }}
                >
                  <StyledDateRange
                    editableDateInputs={true}
                    onChange={handleDateRangeChange}
                    moveRangeOnFirstSelection={false}
                    ranges={[date]}
                    showDateDisplay={false}
                    weekStartsOn={1}
                  />
                </Menu>
              </DateContainer>
              <StyledButton
                name="today"
                variant="contained"
                size="small"
                disableElevation={true}
                onClick={handleDateFixedRange}
              >
                <FormattedMessage id="messages.messagesSearch.today" />
              </StyledButton>
              <StyledButton
                name="week"
                variant="contained"
                size="small"
                disableElevation={true}
                onClick={handleDateFixedRange}
              >
                <FormattedMessage id="messages.messagesSearch.last_week" />
              </StyledButton>
              <StyledButton
                name="month"
                variant="contained"
                size="small"
                disableElevation={true}
                onClick={handleDateFixedRange}
              >
                <FormattedMessage id="messages.messagesSearch.last_month" />
              </StyledButton>
            </RowContainer>
          </FiltersContainer>

          <ButtonContainer>
            <Button
              className={'reset-button'}
              size="small"
              variant="outlined"
              onClick={handleFiltersReset}
            >
              <FormattedMessage id="messages.messagesSearch.button.reset" />
            </Button>
            <Button
              className={'save-button'}
              size="small"
              variant="contained"
              onClick={handleFiltersSubmit}
            >
              <FormattedMessage id="messages.messagesSearch.button.save" />
            </Button>
          </ButtonContainer>
        </StyledMenu>
      </SearchContainer>
    </Container>
  );
};

export default MessagesSearch;
