import React, { useContext, useEffect, useState } from 'react';
import AddNewTag from '../../components/AddNewTag';
import { getData, putData } from '../../modules/fetch';
import CustomerOrders from '../../components/CustomerOrders';
import CustomerProducts from '../../components/CustomerProducts';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import { AddNewIcon, Badgik, CommentIcon, CommentWithBadgikIcon, Container, NavContainer, OrderIcon, ProductIcon, TicketIcon, WorkHistoryIcon } from './styles';
import { GlobalLoadingContext } from '../../context/GlobalLoadingContext';
import CustomerTickets from '../../components/CustomerTickets';
import CustomerComments from '../../components/CustomerComments';
import CustomerHistory from '../../components/CustomerHistory';

const PanelCustomer = ({
                         getInbox,
                         activePanel,
                         activeContent,
                         togglePanels,
                         users,
                         isPreview,
                         setIsPreview,
                         setIsPreviewTicket,
                       }) => {
  const [tags, setTags] = useState([]);
  const [updateTags, setUpdateTags] = useState(false);
  const [activeChat, , activeChatDiff, , lastActiveChatId] = useContext(ActiveChatContext);
  const [, setLoading] = useContext(GlobalLoadingContext);
  useEffect(() => {
    if (activeChat?._id?.$oid !== lastActiveChatId) {
      setTags([]);
      if (
        activeChat?._id?.$oid &&
        activeChatDiff &&
        ('tags' in activeChatDiff.updated || 'tags' in activeChatDiff.added)
      ) {
        setLoading(false);
        getData(`/tags/read/${activeChat?._id?.$oid}`)
          .then((data) => {
            if (data.data.length > 0) {
              setTags(data.data);
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(true);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat]);

  useEffect(() => {
    if (updateTags) {
      setUpdateTags(false);
      putData(`/tags/update/${activeChat?._id?.$oid}`, {
        tags: tags,
      }).catch((err) => {
        console.error(err);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTags]);

  return (
    <Container>
      {activePanel && activeContent === 'tags' && (
        <AddNewTag
          getInbox={getInbox}
          tags={tags}
          setTags={setTags}
          setUpdateTags={setUpdateTags}
          users={users}
        />
      )}
      {activePanel && activeContent === 'orders' && <CustomerOrders />}
      {activePanel && activeContent === 'products' && <CustomerProducts />}
      {activePanel && activeContent === 'tickets' && (
        <CustomerTickets
          isPreview={isPreview}
          setIsPreview={setIsPreview}
          setIsPreviewTicket={setIsPreviewTicket}
        />
      )}
      {activePanel && activeContent === 'comments' && (
        <CustomerComments />
      )}
      {activePanel && activeContent === 'history' && <CustomerHistory />}
      <NavContainer>
        <AddNewIcon
          color={activeContent === 'tags' ? 'primary' : 'inherit'}
          onClick={() => {
            togglePanels('tags');
          }}
        />
        <OrderIcon
          color={activeContent === 'orders' ? 'primary' : 'inherit'}
          onClick={() => {
            togglePanels('orders');
          }}
        />
        <ProductIcon
          color={activeContent === 'products' ? 'primary' : 'inherit'}
          onClick={() => {
            togglePanels('products');
          }}
        />
        <TicketIcon
          color={activeContent === 'tickets' ? 'primary' : 'inherit'}
          onClick={() => {
            togglePanels('tickets');
          }}
        />
        {activeChat?.comments?.length > 0 ? (
          <Badgik badgeContent={activeChat?.comments?.length} color="primary">
            <CommentWithBadgikIcon
              color={activeContent === 'comments' ? 'primary' : 'inherit'}
              onClick={() => {
                togglePanels('comments');
              }}
            />
          </Badgik>
        ) : (
          <CommentIcon
            color={activeContent === 'comments' ? 'primary' : 'inherit'}
            onClick={() => {
              togglePanels('comments');
            }}
          />
        )}
        <WorkHistoryIcon
          color={activeContent === 'history' ? 'primary' : 'inherit'}
          onClick={() => {
            togglePanels('history');
          }}
        />
      </NavContainer>
    </Container>
  );
};
export default React.memo(PanelCustomer);
