import styled from 'styled-components';
import { UserPhoto } from '../Avatar/styles';
import { TextContainer } from './Text/styles';
import { mediaQueries } from '../../../../constants/breakpoints';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const Loader = styled.img`
  width: 20px;
`;
export const MessageBox = styled.div`
  width: 100%;
  max-width: 420px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #ecebeb;
  border-radius: 5px;
  img {
    width: 100%;
    max-width: 400px;
    max-height: 400px;
  }

  @media ${mediaQueries.mobile} {
    max-width: 250px;
  }
`;
export const ItemHead = styled.div`
  background: #ecf3f9;
  padding: 10px 15px;
  border-radius: 5px 5px 0px 0px;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 18px;
  color: #050d42;
`;
export const AuthorName = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  max-width: 400px;
  font-weight: 700;
  word-wrap: break-word;
`;
export const AuthorInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;
export const Message = styled.div<{ isClient: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isClient ? 'start' : 'end')};
  margin-top: 20px;

  ${UserPhoto} {
    display: ${(props) => (props.isClient ? 'block' : 'none')};
  }

  ${Content} {
    align-items: ${(props) => (props.isClient ? 'flex-start' : 'flex-end')};
  }

  ${TextContainer} {
    background-color: ${(props) => (props.isClient ? '#fff' : '#F4F4F4')};
  }
  ${AuthorName} {
    align-self: ${(props) => (props.isClient ? 'start' : 'end')};
    color: ${(props) => (props.isClient ? '#82BA92' : '#050d42')};
  }
  ${AuthorInfo} {
    justify-content: ${(props) => (props.isClient ? 'start' : 'end')};
  }
`;
