import { useContext } from 'react';
import { Body, PageContainer } from '../../components/Layout';
import { Navigate } from 'react-router-dom';
import Header from '../../components/Header';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback, ErrorBoundaryHandler } from '../../components/ErrorBoundary';
import Broadcast from '../../containers/Broadcast';
import { AccountDataContext } from '../../context/AccountDataContext';
import Nav from '../../components/Nav';
import { BroadcastContainer } from './styles';

const BroadcastPage = () => {
  const [currentAccount] = useContext<any>(AccountDataContext);

  return (
    <>
      {currentAccount.viewsLevel > 3 ? (
        <Navigate to="/messages" />
      ) : (
        <>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onError={ErrorBoundaryHandler}
          >
            <Header />
          </ErrorBoundary>
          <PageContainer>
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
              <Nav currentStep="broadcasts" />
            </ErrorBoundary>
            <Body>
              <BroadcastContainer>
                <Broadcast />
              </BroadcastContainer>
            </Body>
          </PageContainer>
        </>
      )}
    </>
  );
};

export default BroadcastPage;
