import { UserPhoto } from './styles';
// import PersonIcon from '@mui/icons-material/Person';
import { StyledAuthorName, AuthorInfo } from './styles';

const Avatar = ({ message, isClient }) => {
  return (
    <AuthorInfo isClient={isClient}>
      {isClient && (
        <UserPhoto>
          {/*<PersonIcon />*/}
        </UserPhoto>
      )}
      <StyledAuthorName isClient={isClient}>{message?.title || ''}{message?.title ? ':' : ''}</StyledAuthorName>
    </AuthorInfo>
  );
};

export default Avatar;
