import styled from 'styled-components';

export const breakpoints = {
  mobile: 768,
  desktop: 836, // iPad Air 2019 portrait
  mediumDesktop: 1200,
  largeDesktop: 1441, // laptop
};

export const mediaQueries = {
  mobile: `(max-width: ${breakpoints.mobile}px)`,
  tablet: `(min-width: ${breakpoints.mobile}px)`,
  desktop: `(min-width: ${breakpoints.desktop}px)`,
  mediumDesktop: `(min-width: ${breakpoints.mediumDesktop}px)`,
  largeDesktop: `(min-width: ${breakpoints.largeDesktop}px)`,
};

export const MobileOnlyShow = styled.div`
  @media ${mediaQueries.mobile} {
    display: block;
  }
`;

export const MobileOnly = styled.div`
  display: block;
  @media ${mediaQueries.tablet} {
    display: none;
  }
`;

export const TabletAndDesktopOnly = styled.div`
  display: none;

  @media ${mediaQueries.tablet} {
    display: block;
    width: 100%;
  }
`;
export const DesktopOnly = styled.div`
  display: none;

  @media ${mediaQueries.desktop} {
    display: block;
    width: 100%;
  }
`;
