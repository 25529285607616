import styled from 'styled-components';
import { UserPhoto } from '../../Avatar/styles';
import { Content, MessageBox } from '../styles';
import { mediaQueries } from '../../../../../constants/breakpoints';

export const Copy = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-weight: 400;
  color: #050d42;
  margin-left: 5px;
  word-break: break-word;
`;
export const Title = styled.div`
  margin-bottom: 15px;
  span {
    display: inline-block;
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
  .red {
    margin-right: 5px;
    color: red;
  }
  span:nth-child(2) {
    font-weight: 400;
    color: #050d42;
    text-decoration: underline;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  ${UserPhoto} {
    display: none;
  }

  ${Content} {
    align-items: center !important;
    font-weight: bold;
    font-size: 18px;
    ${MessageBox} {
      flex-direction: column;
      align-items: start;
      max-width: 420px;
      padding: 8px 16px;
      border: 1px solid #ecebeb;
      border-radius: 5px;

      @media ${mediaQueries.mobile} {
        max-width: 400px;
      }
    }
  }

  ${Copy} {
    background-color: transparent;
  }
`;
