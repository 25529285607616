import styled from 'styled-components';
import { AuthorName } from '../styles';

export const StyledAuthorName = styled(AuthorName)`
  align-self: center !important;
`;
export const Order = styled.div`
  width: 100%;
  max-width: 400px;
  border-radius: 5px;
`;
export const OrderID = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 700;
  line-height: 18px;
  color: #050d42;
`;
export const OrderBody = styled.div`
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  img {
    max-width: 55px;
    max-height: 55px;
    margin-right: 5px;
  }
`;
export const OrderInfo = styled.div`
  margin-left: 5px;
`;
export const OrderTitle = styled.a`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #050d42;
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;
export const OrderSKU = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #c5c5c5;
  margin-top: 5px;
`;
export const OrderPrice = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #050d42;
  margin-top: 10px;
`;
export const OrderTotal = styled.div`
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align: right;
  text-transform: uppercase;
  color: #050d42;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  border-radius: 0 0 5px 5px;
`;
export const OrderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
export const OrderProductID = styled.div`
  font-weight: 500;
  color: #050d42;
  font-size: 1rem;
  letter-spacing: 0.5px;
`;
export const OrderListPrice = styled.div`
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #050d42;
  margin-top: 10px;
`;
