import { fromNow } from '../../modules/renderDate';

const JobStateToString = (jobsState, jobName, storeId, intl) => {

  let state = null;

  if (jobsState?.[jobName]?.[storeId]?.state === 'finished') {
    state = intl.formatMessage({
      id: 'storeManagement.storeList.table.actions.last_update',
      defaultMessage: 'Last update ' + (jobsState?.[jobName]?.[storeId]?.finishedAt?.date ? fromNow(jobsState?.[jobName]?.[storeId]?.finishedAt) : ''),
    }, {
      finishedAt: (jobsState?.[jobName]?.[storeId]?.finishedAt?.date ? fromNow(jobsState?.[jobName]?.[storeId]?.finishedAt) : '')
    })
  }

  if (jobsState?.[jobName]?.[storeId]?.state === 'running') {
    state = intl.formatMessage({
      id: 'storeManagement.storeList.table.actions.running',
      defaultMessage: 'In progress',
    })
  }

  if (jobsState?.[jobName]?.[storeId]?.state === 'start') {
    state = intl.formatMessage({
      id: 'storeManagement.storeList.table.actions.start',
      defaultMessage: 'Prepare to run',
    })
  }

  if (jobsState?.[jobName]?.[storeId]?.state === 'done') {
    state = intl.formatMessage({
      id: 'storeManagement.storeList.table.actions.done',
      defaultMessage: 'Complete',
    })
  }

  if (jobsState?.[jobName]?.[storeId]?.state === 'error') {
    state = intl.formatMessage({
      id: 'storeManagement.storeList.table.actions.error',
      defaultMessage: 'Error',
    }, {
      error: JSON.stringify(jobsState?.[jobName]?.[storeId]?.error ?? '')
    })
  }

  return state;
}

export default JobStateToString;
