import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { mediaQueries } from '../../constants/breakpoints';
import { DirectionsRun, ExitToAppOutlined, VerifiedUserOutlined } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { cleanUp, retrieve, store } from '../../modules/storage';
import { EmailInput } from '../Input';
import { AccountDataContext } from '../../context/AccountDataContext';
import { Link } from 'react-router-dom';

const Container = styled.div`
  position: absolute;
  z-index: 3;
  background: rgba(94, 90, 90, 0.4);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImitierenFormWrapper = styled.div`
  width: 280px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.25);
  position: relative;
  padding: 20px 20px;
  @media ${mediaQueries.desktop} {
    width: 365px;
  }
`;

const StyledIconButton = styled(IconButton)`
  color: #fff;
  position: absolute !important;
  top: -40px;
  right: -40px;

  svg {
    color: #fff;
  }
`;

const StyledRunIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  display: flex;
  top: 7px;
  left: 10px;
  color: white;

  svg {
    color: lightskyblue;
  }
`;

const StyledEmailInput = styled(EmailInput)`
  width: 240px;
`;

const StyledLink = styled(Link)`
  svg {
    color: lightsalmon;
    -webkit-box-reflect: below 0 linear-gradient(transparent, rgba(255, 160, 122, 0.4));
  }
`;

const StyledUsedLink = styled(Link)`
  svg {
    color: yellowgreen;
    -webkit-box-reflect: below 0 linear-gradient(transparent, rgba(154, 205, 50, 0.4));
  }
`;

const Imitieren = ({ ...props }) => {
  const [imitierenEmail, setImitierenEmail] = useState(null);
  const [currentAccount, , , setLoadImitierenAccount] = useContext<any>(AccountDataContext);


  const handleChange = (event) => {
    setImitierenEmail(event.target.value);
  };

  const handleRun = () => {
    store.string('imitieren', imitierenEmail);
    const regex = new RegExp('imitieren=' + imitierenEmail, 'i');
    const href = window.location.href;
    const hrefTo = window.location.search.replace(regex, '').replace(/\?&/, '?');
    if (href === hrefTo) {
      window.location.reload();
    } else {
      window.location.href = hrefTo;
    }
  };

  const handleExit = () => {
    setImitierenEmail(null);
    cleanUp('imitieren');
    window.location.reload();
  };

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  useEffect(() => {
    const emailImitieren = getQueryVariable('imitieren');
    if (currentAccount?.viewsLevel === 0 && emailImitieren) {
      setImitierenEmail(emailImitieren);
      handleRun();
    }
  });

  useEffect(() => {
    setImitierenEmail(retrieve.string('imitieren'));
  }, []);

  useEffect(() => {
    if (imitierenEmail) {
      setLoadImitierenAccount(true);
    }
  }, [imitierenEmail]);

  return (
    <>
      {(currentAccount?.viewsLevel < 1 && currentAccount?.viewsLevel === 0) ||
      (imitierenEmail !== null && imitierenEmail !== undefined && imitierenEmail !== '') ? (
        !props.showFormPopup ? (
          ''
        ) : (
          <>
            <Container>
              <ImitierenFormWrapper>
                <StyledIconButton aria-label="hide" onClick={props.handleSwitchForm}>
                  <CancelIcon fontSize="small" />
                </StyledIconButton>
                <StyledEmailInput
                  id={'Imitieren-Email'}
                  key={'Imitieren-Email'}
                  label={'EMail'}
                  value={imitierenEmail}
                  autoComplete="none"
                  handleChange={handleChange}
                />
                {props.children}
                <StyledRunIconButton aria-label="hide" onClick={handleRun}>
                  <DirectionsRun fontSize="small" />
                </StyledRunIconButton>
                <StyledRunIconButton aria-label="hide" onClick={handleExit}>
                  <ExitToAppOutlined fontSize="small" />
                </StyledRunIconButton>
              </ImitierenFormWrapper>
            </Container>
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export const ImitierenButton = ({ children = null }) => {
  const [showFormPopup, setShowFormPopup] = useState(false);
  const [imitierenEmail] = useState(retrieve.string('imitieren'));
  const [currentAccount] = useContext<any>(AccountDataContext);

  const handleSwitchForm = () => {
    setShowFormPopup(!showFormPopup);
  };

  return (
    <>
      {(currentAccount?.viewsLevel < 1 && currentAccount?.viewsLevel === 0) ||
      (imitierenEmail !== null && imitierenEmail !== undefined && imitierenEmail !== '') ? (
        !showFormPopup ? (
          imitierenEmail ? (
            <StyledUsedLink to="#" onClick={handleSwitchForm}>
              <VerifiedUserOutlined />&nbsp;{children}
            </StyledUsedLink>
          ) : (
            <StyledLink to="#" onClick={handleSwitchForm}>
              <VerifiedUserOutlined />&nbsp;{children}
            </StyledLink>
          )
        ) : (
          <>
            <Imitieren
              showFormPopup={showFormPopup}
              handleSwitchForm={handleSwitchForm}
            ></Imitieren>
            {imitierenEmail ? (
              <StyledUsedLink to="#" onClick={handleSwitchForm}>
                <VerifiedUserOutlined />
              </StyledUsedLink>
            ) : (
              <StyledLink to="#" onClick={handleSwitchForm}>
                <VerifiedUserOutlined />
              </StyledLink>
            )}
          </>
        )
      ) : (
        ''
      )}
    </>
  );
};

export default Imitieren;
