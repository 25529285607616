import { MobileOnly, TabletAndDesktopOnly } from '../../constants/breakpoints';

export const Light = () => <svg width="131" height="22" viewBox="0 0 131 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13.2864 2.50708C12.4684 2.50715 11.6552 2.63399 10.876 2.88311C12.5168 3.42283 13.9453 4.4667 14.9581 5.86594C15.9708 7.26518 16.516 8.94839 16.516 10.6757C16.516 12.403 15.9708 14.0862 14.9581 15.4854C13.9453 16.8847 12.5168 17.9285 10.876 18.4682C11.6553 18.7167 12.4685 18.8429 13.2864 18.8424C17.7333 18.8424 21.3393 15.1784 21.3393 10.6757C21.3393 6.17292 17.7333 2.50708 13.2864 2.50708Z"
    stroke="white" strokeMiterlimit="10" />
  <path
    d="M10.9551 1.69797e-07C8.78839 1.69797e-07 6.67033 0.642506 4.86878 1.84627C3.06722 3.05003 1.66308 4.76098 0.833912 6.76276C0.00474657 8.76455 -0.212201 10.9673 0.210504 13.0923C0.633209 15.2174 1.67658 17.1694 3.20868 18.7015C4.74078 20.2336 6.69279 21.277 8.81787 21.6997C10.943 22.1224 13.1457 21.9055 15.1474 21.0763C17.1492 20.2471 18.8602 18.843 20.0639 17.0414C21.2677 15.2399 21.9102 13.1218 21.9102 10.9551C21.9105 9.51638 21.6273 8.09171 21.0768 6.76246C20.5264 5.43322 19.7194 4.22543 18.7021 3.20811C17.6848 2.19078 16.477 1.38385 15.1477 0.833392C13.8185 0.282936 12.3938 -0.000253147 10.9551 1.69797e-07ZM12.627 18.4681C11.1643 18.4681 9.73454 18.0343 8.51839 17.2217C7.30223 16.4091 6.35436 15.2541 5.79462 13.9028C5.23489 12.5515 5.08844 11.0645 5.37379 9.62998C5.65914 8.19543 6.36347 6.8777 7.39773 5.84345C8.43198 4.8092 9.7497 4.10486 11.1843 3.81951C12.6188 3.53416 14.1058 3.68061 15.4571 4.24035C16.8084 4.80008 17.9634 5.74796 18.776 6.96411C19.5886 8.18027 20.0223 9.61007 20.0223 11.0727C20.0223 13.0341 19.2432 14.9151 17.8563 16.302C16.4694 17.6889 14.5884 18.4681 12.627 18.4681Z"
    fill="url(#paint0_linear_3731_18176)" />
  <path
    d="M23.2852 21.7504C24.4589 21.7504 25.4103 20.799 25.4103 19.6253C25.4103 18.4517 24.4589 17.5002 23.2852 17.5002C22.1116 17.5002 21.1602 18.4517 21.1602 19.6253C21.1602 20.799 22.1116 21.7504 23.2852 21.7504Z"
    fill="url(#paint1_linear_3731_18176)" />
  <path
    d="M39.2186 4.62835C40.5397 4.62339 41.8385 4.96695 42.9834 5.62417C44.1289 6.26118 45.0783 7.19767 45.7297 8.33281C46.3868 9.45357 46.7315 10.7291 46.7283 12.0273C46.734 13.3384 46.3893 14.6274 45.7297 15.7616C45.0906 16.8968 44.1532 17.8367 43.0184 18.4802C41.8676 19.1409 40.5614 19.4845 39.2336 19.4761C38.2633 19.4888 37.3009 19.3017 36.4065 18.9264C35.5121 18.5512 34.705 17.9958 34.0357 17.2952C33.3287 16.5799 32.7735 15.7298 32.4035 14.7956C32.0335 13.8615 31.8563 12.8625 31.8824 11.8585C31.9085 10.8544 32.1374 9.86597 32.5555 8.9522C32.9735 8.03842 33.5721 7.2181 34.3154 6.54033C35.6437 5.29997 37.3989 4.61554 39.2186 4.62835ZM39.2735 7.95938C38.7595 7.94644 38.2486 8.04225 37.7743 8.2405C37.3001 8.43875 36.8734 8.73493 36.5223 9.10955C36.153 9.50232 35.8654 9.96414 35.676 10.4683C35.4866 10.9725 35.3992 11.5092 35.4188 12.0472C35.3711 13.1474 35.7625 14.2217 36.5073 15.0347C36.8595 15.4087 37.2874 15.7037 37.7625 15.9003C38.2377 16.0968 38.7494 16.1905 39.2636 16.1749C39.7811 16.1885 40.2956 16.0925 40.7733 15.8934C41.2509 15.6943 41.6809 15.3965 42.0348 15.0197C42.7438 14.1956 43.1336 13.1456 43.1336 12.0597C43.1336 10.9738 42.7438 9.92373 42.0348 9.09959C41.6795 8.72826 41.2504 8.43502 40.7749 8.23868C40.2994 8.04233 39.788 7.94719 39.2735 7.95938Z"
    fill="url(#paint2_linear_3731_18176)" />
  <path
    d="M49.6211 4.99662H53.1901V6.61621C53.7463 5.98538 54.4304 5.47756 55.198 5.12579C55.9666 4.79137 56.7981 4.62205 57.6376 4.62899C58.4856 4.61973 59.3197 4.84329 60.0471 5.27484C60.7878 5.73641 61.3853 6.39141 61.7739 7.16766C62.2885 6.37795 62.9975 5.73066 63.8343 5.28659C64.6711 4.84251 65.6082 4.61623 66.5577 4.62899C67.5069 4.61198 68.4416 4.86176 69.2533 5.34935C69.9828 5.7728 70.5616 6.40985 70.9098 7.17263C71.2444 7.92446 71.4117 9.14494 71.4117 10.8341V19.091H67.8176V11.932C67.8176 10.3373 67.6168 9.25424 67.2152 8.69285C67.0158 8.41343 66.7485 8.18822 66.4379 8.03801C66.1273 7.8878 65.7836 7.81742 65.4383 7.83338C64.8515 7.82112 64.2763 7.99523 63.7968 8.33019C63.3018 8.69077 62.9262 9.18877 62.7176 9.76099C62.4359 10.7118 62.3171 11.7025 62.3662 12.6921V19.0463H58.7419V12.2549C58.7826 11.3338 58.6879 10.4117 58.4608 9.51755C58.3191 9.02037 58.0244 8.57928 57.6175 8.25567C57.2216 7.97516 56.7439 7.83036 56.2572 7.84332C55.6731 7.83863 55.1035 8.02362 54.6358 8.36993C54.1365 8.73695 53.7576 9.24155 53.5465 9.8206C53.2643 10.7883 53.1439 11.7952 53.1901 12.8014V19.0661H49.6211V4.99662Z"
    fill="url(#paint3_linear_3731_18176)" />
  <path
    d="M88.5746 7.85677L85.5992 9.48047C85.1634 8.96693 84.6136 8.56032 83.9927 8.29245C83.3719 8.02459 82.697 7.90275 82.0207 7.93647C81.4279 7.91032 80.8359 8.0017 80.2792 8.20525C79.7226 8.4088 79.2125 8.72043 78.7789 9.12186C78.3686 9.51267 78.046 9.98477 77.8321 10.5074C77.6182 11.03 77.5178 11.5915 77.5375 12.1551C77.5179 12.7014 77.6142 13.2457 77.8203 13.7527C78.0263 14.2598 78.3375 14.7183 78.7337 15.0987C79.1538 15.484 79.6468 15.7828 80.1844 15.9777C80.7219 16.1726 81.2931 16.2599 81.8649 16.2343C82.5714 16.2701 83.2766 16.1425 83.9248 15.8615C84.5729 15.5806 85.1462 15.154 85.5992 14.6155L88.4138 16.5281C86.8892 18.4938 84.7347 19.4767 81.9503 19.4767C79.4474 19.4767 77.4856 18.7428 76.0649 17.2752C75.2511 16.4618 74.6407 15.4708 74.2828 14.3816C73.9249 13.2924 73.8293 12.1352 74.0037 11.0029C74.1781 9.87048 74.6177 8.79431 75.2872 7.86052C75.9567 6.92673 76.8375 6.16127 77.8592 5.62544C79.1338 4.95531 80.5578 4.61281 82.0006 4.6293C83.3192 4.60776 84.6244 4.89442 85.8103 5.46605C86.9235 6.01463 87.8755 6.83795 88.5746 7.85677Z"
    fill="url(#paint4_linear_3731_18176)" />
  <path
    d="M98.4169 4.62832C99.7375 4.62336 101.036 4.96692 102.18 5.62415C103.327 6.26104 104.278 7.19745 104.931 8.33279C105.588 9.45355 105.932 10.7291 105.929 12.0273C105.932 13.3381 105.588 14.6264 104.931 15.7616C104.292 16.8968 103.355 17.8367 102.22 18.4802C101.07 19.1409 99.7642 19.4845 98.4368 19.476C97.4661 19.4889 96.5032 19.3018 95.6083 18.9266C94.7134 18.5513 93.9057 17.996 93.2357 17.2952C92.5289 16.5799 91.9739 15.7298 91.604 14.7956C91.2342 13.8615 91.057 12.8625 91.0831 11.8585C91.1092 10.8544 91.338 9.86595 91.7559 8.95217C92.1738 8.03839 92.7722 7.21808 93.5152 6.5403C94.8437 5.30082 96.5979 4.61653 98.4169 4.62832ZM98.4768 7.95935C97.9622 7.94634 97.4506 8.04209 96.9757 8.24032C96.5009 8.43856 96.0734 8.73478 95.7215 9.10953C95.3529 9.50254 95.0662 9.96451 94.8777 10.4687C94.6892 10.973 94.6027 11.5095 94.6233 12.0472C94.5737 13.1468 94.9632 14.2211 95.7065 15.0347C96.0688 15.3984 96.4996 15.687 96.9742 15.884C97.4488 16.081 97.9577 16.1824 98.4718 16.1824C98.9858 16.1824 99.4947 16.081 99.9693 15.884C100.444 15.687 100.875 15.3984 101.237 15.0347C101.946 14.2105 102.336 13.1605 102.336 12.0746C102.336 10.9887 101.946 9.93866 101.237 9.11451C100.883 8.74047 100.455 8.44445 99.9795 8.24551C99.5041 8.04656 98.9922 7.94908 98.4768 7.95935Z"
    fill="url(#paint5_linear_3731_18176)" />
  <path
    d="M108.822 4.99619H112.388V6.61583C112.954 5.98437 113.646 5.47672 114.422 5.12537C115.193 4.79097 116.026 4.62166 116.868 4.62855C117.714 4.61943 118.547 4.84302 119.273 5.27441C120.014 5.736 120.612 6.39103 121.001 7.1673C121.528 6.37481 122.246 5.72434 123.09 5.27441C123.923 4.84302 124.851 4.6212 125.792 4.62855C126.731 4.61716 127.655 4.86675 128.458 5.34893C129.186 5.77421 129.763 6.41089 130.111 7.17227C130.446 7.92412 130.613 9.14464 130.613 10.8338V19.091H127.012V11.9318C127.012 10.337 126.811 9.25394 126.414 8.69253C126.211 8.41085 125.938 8.18508 125.622 8.03645C125.305 7.88783 124.956 7.82126 124.607 7.84298C124.02 7.8321 123.445 8.00609 122.964 8.3398C122.471 8.70068 122.096 9.19875 121.89 9.77063C121.606 10.721 121.485 11.7119 121.533 12.7019V19.0562H117.937V12.2547C117.975 11.3337 117.881 10.4119 117.656 9.51726C117.511 9.02039 117.214 8.5798 116.807 8.25534C116.412 7.97415 115.933 7.82928 115.446 7.84298C114.864 7.83828 114.295 8.02333 113.829 8.3696C113.326 8.7391 112.946 9.24971 112.739 9.83522C112.452 10.8021 112.33 11.8095 112.378 12.8161V19.0811H108.822V4.99619Z"
    fill="url(#paint6_linear_3731_18176)" />
  <defs>
    <linearGradient id="paint0_linear_3731_18176" x1="20.4446" y1="5.47659" x2="1.46557" y2="16.4317" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stopColor="white" />
    </linearGradient>
    <linearGradient id="paint1_linear_3731_18176" x1="702.117" y1="416.928" x2="635.9" y2="483.146" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stopColor="white" />
    </linearGradient>
    <linearGradient id="paint2_linear_3731_18176" x1="267.674" y1="2284.76" x2="267.674" y2="2727.1" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stopColor="white" />
      <stop offset="0.21" stopColor="#25AEBF" />
      <stop offset="0.48" stopColor="#209CBB" />
      <stop offset="0.8" stopColor="#177EB5" />
      <stop offset="1" stopColor="#1068B1" />
    </linearGradient>
    <linearGradient id="paint3_linear_3731_18176" x1="1317.62" y1="2225.54" x2="1317.62" y2="2645.97" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stopColor="white" />
      <stop offset="0.21" stopColor="#25AEBF" />
      <stop offset="0.48" stopColor="#209CBB" />
      <stop offset="0.8" stopColor="#177EB5" />
      <stop offset="1" stopColor="#1068B1" />
    </linearGradient>
    <linearGradient id="paint4_linear_3731_18176" x1="1537.29" y1="2284.76" x2="1537.29" y2="2727.1" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stopColor="white" />
      <stop offset="0.21" stopColor="#25AEBF" />
      <stop offset="0.48" stopColor="#209CBB" />
      <stop offset="0.8" stopColor="#177EB5" />
      <stop offset="1" stopColor="#1068B1" />
    </linearGradient>
    <linearGradient id="paint5_linear_3731_18176" x1="2085.08" y1="2284.76" x2="2085.08" y2="2727.1" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stopColor="white" />
      <stop offset="0.21" stopColor="#25AEBF" />
      <stop offset="0.48" stopColor="#209CBB" />
      <stop offset="0.8" stopColor="#177EB5" />
      <stop offset="1" stopColor="#1068B1" />
    </linearGradient>
    <linearGradient id="paint6_linear_3731_18176" x1="3957.27" y1="2225.54" x2="3957.27" y2="2645.97" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stopColor="white" />
      <stop offset="0.21" stopColor="#25AEBF" />
      <stop offset="0.48" stopColor="#209CBB" />
      <stop offset="0.8" stopColor="#177EB5" />
      <stop offset="1" stopColor="#1068B1" />
    </linearGradient>
  </defs>
</svg>;

export const Dark = () => <svg width="131" height="22" viewBox="0 0 131 22" fill="darkblue" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13.2864 2.50708C12.4684 2.50715 11.6552 2.63399 10.876 2.88311C12.5168 3.42283 13.9453 4.4667 14.9581 5.86594C15.9708 7.26518 16.516 8.94839 16.516 10.6757C16.516 12.403 15.9708 14.0862 14.9581 15.4854C13.9453 16.8847 12.5168 17.9285 10.876 18.4682C11.6553 18.7167 12.4685 18.8429 13.2864 18.8424C17.7333 18.8424 21.3393 15.1784 21.3393 10.6757C21.3393 6.17292 17.7333 2.50708 13.2864 2.50708Z"
    strokeMiterlimit="10" />
  <path
    d="M10.9551 1.69797e-07C8.78839 1.69797e-07 6.67033 0.642506 4.86878 1.84627C3.06722 3.05003 1.66308 4.76098 0.833912 6.76276C0.00474657 8.76455 -0.212201 10.9673 0.210504 13.0923C0.633209 15.2174 1.67658 17.1694 3.20868 18.7015C4.74078 20.2336 6.69279 21.277 8.81787 21.6997C10.943 22.1224 13.1457 21.9055 15.1474 21.0763C17.1492 20.2471 18.8602 18.843 20.0639 17.0414C21.2677 15.2399 21.9102 13.1218 21.9102 10.9551C21.9105 9.51638 21.6273 8.09171 21.0768 6.76246C20.5264 5.43322 19.7194 4.22543 18.7021 3.20811C17.6848 2.19078 16.477 1.38385 15.1477 0.833392C13.8185 0.282936 12.3938 -0.000253147 10.9551 1.69797e-07ZM12.627 18.4681C11.1643 18.4681 9.73454 18.0343 8.51839 17.2217C7.30223 16.4091 6.35436 15.2541 5.79462 13.9028C5.23489 12.5515 5.08844 11.0645 5.37379 9.62998C5.65914 8.19543 6.36347 6.8777 7.39773 5.84345C8.43198 4.8092 9.7497 4.10486 11.1843 3.81951C12.6188 3.53416 14.1058 3.68061 15.4571 4.24035C16.8084 4.80008 17.9634 5.74796 18.776 6.96411C19.5886 8.18027 20.0223 9.61007 20.0223 11.0727C20.0223 13.0341 19.2432 14.9151 17.8563 16.302C16.4694 17.6889 14.5884 18.4681 12.627 18.4681Z" />
  <path
    d="M23.2852 21.7504C24.4589 21.7504 25.4103 20.799 25.4103 19.6253C25.4103 18.4517 24.4589 17.5002 23.2852 17.5002C22.1116 17.5002 21.1602 18.4517 21.1602 19.6253C21.1602 20.799 22.1116 21.7504 23.2852 21.7504Z"
  />
  <path
    d="M39.2186 4.62835C40.5397 4.62339 41.8385 4.96695 42.9834 5.62417C44.1289 6.26118 45.0783 7.19767 45.7297 8.33281C46.3868 9.45357 46.7315 10.7291 46.7283 12.0273C46.734 13.3384 46.3893 14.6274 45.7297 15.7616C45.0906 16.8968 44.1532 17.8367 43.0184 18.4802C41.8676 19.1409 40.5614 19.4845 39.2336 19.4761C38.2633 19.4888 37.3009 19.3017 36.4065 18.9264C35.5121 18.5512 34.705 17.9958 34.0357 17.2952C33.3287 16.5799 32.7735 15.7298 32.4035 14.7956C32.0335 13.8615 31.8563 12.8625 31.8824 11.8585C31.9085 10.8544 32.1374 9.86597 32.5555 8.9522C32.9735 8.03842 33.5721 7.2181 34.3154 6.54033C35.6437 5.29997 37.3989 4.61554 39.2186 4.62835ZM39.2735 7.95938C38.7595 7.94644 38.2486 8.04225 37.7743 8.2405C37.3001 8.43875 36.8734 8.73493 36.5223 9.10955C36.153 9.50232 35.8654 9.96414 35.676 10.4683C35.4866 10.9725 35.3992 11.5092 35.4188 12.0472C35.3711 13.1474 35.7625 14.2217 36.5073 15.0347C36.8595 15.4087 37.2874 15.7037 37.7625 15.9003C38.2377 16.0968 38.7494 16.1905 39.2636 16.1749C39.7811 16.1885 40.2956 16.0925 40.7733 15.8934C41.2509 15.6943 41.6809 15.3965 42.0348 15.0197C42.7438 14.1956 43.1336 13.1456 43.1336 12.0597C43.1336 10.9738 42.7438 9.92373 42.0348 9.09959C41.6795 8.72826 41.2504 8.43502 40.7749 8.23868C40.2994 8.04233 39.788 7.94719 39.2735 7.95938Z"
  />
  <path
    d="M49.6211 4.99662H53.1901V6.61621C53.7463 5.98538 54.4304 5.47756 55.198 5.12579C55.9666 4.79137 56.7981 4.62205 57.6376 4.62899C58.4856 4.61973 59.3197 4.84329 60.0471 5.27484C60.7878 5.73641 61.3853 6.39141 61.7739 7.16766C62.2885 6.37795 62.9975 5.73066 63.8343 5.28659C64.6711 4.84251 65.6082 4.61623 66.5577 4.62899C67.5069 4.61198 68.4416 4.86176 69.2533 5.34935C69.9828 5.7728 70.5616 6.40985 70.9098 7.17263C71.2444 7.92446 71.4117 9.14494 71.4117 10.8341V19.091H67.8176V11.932C67.8176 10.3373 67.6168 9.25424 67.2152 8.69285C67.0158 8.41343 66.7485 8.18822 66.4379 8.03801C66.1273 7.8878 65.7836 7.81742 65.4383 7.83338C64.8515 7.82112 64.2763 7.99523 63.7968 8.33019C63.3018 8.69077 62.9262 9.18877 62.7176 9.76099C62.4359 10.7118 62.3171 11.7025 62.3662 12.6921V19.0463H58.7419V12.2549C58.7826 11.3338 58.6879 10.4117 58.4608 9.51755C58.3191 9.02037 58.0244 8.57928 57.6175 8.25567C57.2216 7.97516 56.7439 7.83036 56.2572 7.84332C55.6731 7.83863 55.1035 8.02362 54.6358 8.36993C54.1365 8.73695 53.7576 9.24155 53.5465 9.8206C53.2643 10.7883 53.1439 11.7952 53.1901 12.8014V19.0661H49.6211V4.99662Z"
  />
  <path
    d="M88.5746 7.85677L85.5992 9.48047C85.1634 8.96693 84.6136 8.56032 83.9927 8.29245C83.3719 8.02459 82.697 7.90275 82.0207 7.93647C81.4279 7.91032 80.8359 8.0017 80.2792 8.20525C79.7226 8.4088 79.2125 8.72043 78.7789 9.12186C78.3686 9.51267 78.046 9.98477 77.8321 10.5074C77.6182 11.03 77.5178 11.5915 77.5375 12.1551C77.5179 12.7014 77.6142 13.2457 77.8203 13.7527C78.0263 14.2598 78.3375 14.7183 78.7337 15.0987C79.1538 15.484 79.6468 15.7828 80.1844 15.9777C80.7219 16.1726 81.2931 16.2599 81.8649 16.2343C82.5714 16.2701 83.2766 16.1425 83.9248 15.8615C84.5729 15.5806 85.1462 15.154 85.5992 14.6155L88.4138 16.5281C86.8892 18.4938 84.7347 19.4767 81.9503 19.4767C79.4474 19.4767 77.4856 18.7428 76.0649 17.2752C75.2511 16.4618 74.6407 15.4708 74.2828 14.3816C73.9249 13.2924 73.8293 12.1352 74.0037 11.0029C74.1781 9.87048 74.6177 8.79431 75.2872 7.86052C75.9567 6.92673 76.8375 6.16127 77.8592 5.62544C79.1338 4.95531 80.5578 4.61281 82.0006 4.6293C83.3192 4.60776 84.6244 4.89442 85.8103 5.46605C86.9235 6.01463 87.8755 6.83795 88.5746 7.85677Z"
  />
  <path
    d="M98.4169 4.62832C99.7375 4.62336 101.036 4.96692 102.18 5.62415C103.327 6.26104 104.278 7.19745 104.931 8.33279C105.588 9.45355 105.932 10.7291 105.929 12.0273C105.932 13.3381 105.588 14.6264 104.931 15.7616C104.292 16.8968 103.355 17.8367 102.22 18.4802C101.07 19.1409 99.7642 19.4845 98.4368 19.476C97.4661 19.4889 96.5032 19.3018 95.6083 18.9266C94.7134 18.5513 93.9057 17.996 93.2357 17.2952C92.5289 16.5799 91.9739 15.7298 91.604 14.7956C91.2342 13.8615 91.057 12.8625 91.0831 11.8585C91.1092 10.8544 91.338 9.86595 91.7559 8.95217C92.1738 8.03839 92.7722 7.21808 93.5152 6.5403C94.8437 5.30082 96.5979 4.61653 98.4169 4.62832ZM98.4768 7.95935C97.9622 7.94634 97.4506 8.04209 96.9757 8.24032C96.5009 8.43856 96.0734 8.73478 95.7215 9.10953C95.3529 9.50254 95.0662 9.96451 94.8777 10.4687C94.6892 10.973 94.6027 11.5095 94.6233 12.0472C94.5737 13.1468 94.9632 14.2211 95.7065 15.0347C96.0688 15.3984 96.4996 15.687 96.9742 15.884C97.4488 16.081 97.9577 16.1824 98.4718 16.1824C98.9858 16.1824 99.4947 16.081 99.9693 15.884C100.444 15.687 100.875 15.3984 101.237 15.0347C101.946 14.2105 102.336 13.1605 102.336 12.0746C102.336 10.9887 101.946 9.93866 101.237 9.11451C100.883 8.74047 100.455 8.44445 99.9795 8.24551C99.5041 8.04656 98.9922 7.94908 98.4768 7.95935Z"
  />
  <path
    d="M108.822 4.99619H112.388V6.61583C112.954 5.98437 113.646 5.47672 114.422 5.12537C115.193 4.79097 116.026 4.62166 116.868 4.62855C117.714 4.61943 118.547 4.84302 119.273 5.27441C120.014 5.736 120.612 6.39103 121.001 7.1673C121.528 6.37481 122.246 5.72434 123.09 5.27441C123.923 4.84302 124.851 4.6212 125.792 4.62855C126.731 4.61716 127.655 4.86675 128.458 5.34893C129.186 5.77421 129.763 6.41089 130.111 7.17227C130.446 7.92412 130.613 9.14464 130.613 10.8338V19.091H127.012V11.9318C127.012 10.337 126.811 9.25394 126.414 8.69253C126.211 8.41085 125.938 8.18508 125.622 8.03645C125.305 7.88783 124.956 7.82126 124.607 7.84298C124.02 7.8321 123.445 8.00609 122.964 8.3398C122.471 8.70068 122.096 9.19875 121.89 9.77063C121.606 10.721 121.485 11.7119 121.533 12.7019V19.0562H117.937V12.2547C117.975 11.3337 117.881 10.4119 117.656 9.51726C117.511 9.02039 117.214 8.5798 116.807 8.25534C116.412 7.97415 115.933 7.82928 115.446 7.84298C114.864 7.83828 114.295 8.02333 113.829 8.3696C113.326 8.7391 112.946 9.24971 112.739 9.83522C112.452 10.8021 112.33 11.8095 112.378 12.8161V19.0811H108.822V4.99619Z"
  />
</svg>;

const Logo = () =>
  <div>
    <TabletAndDesktopOnly>
      {Light()}
    </TabletAndDesktopOnly>
    <MobileOnly>
      {Dark()}
    </MobileOnly>
  </div>

export default Logo;

export const Logos = {
  Light: Light,
  Dark: Dark,
};
