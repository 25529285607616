import { FormattedMessage } from 'react-intl';
import {
  Container,
  Title,
  Label,
  Input
} from './styles';

const FormTimeRange = ({
  input,
  field,
  itemIndex,
  inputValues,
  setInputValues
}) => {

  const handleChange = (prop, field, itemIndex, subField) => (event) => {
    let copy = inputValues[field].slice();
    copy.splice(itemIndex, 1, { ...copy[itemIndex], [prop]: { ...copy[itemIndex][prop], [subField]: event.target.value } });
    setInputValues({...inputValues, [field]: copy })
  }

  return (
    <Container>
      <Title><FormattedMessage id={input.label} defaultMessage={input.label} /></Title>
      <Label>From:</Label>
      <Input
        type="time"
        name={`${input.id}-from-${itemIndex}`}
        value={inputValues?.[field]?.[itemIndex]?.[input.id]?.from ?? ''}
        onChange={(event) =>
          handleChange(input.id, field, itemIndex, 'from')(event)
        }
      />
      <Label>Till:</Label>
      <Input
        type="time"
        name={`${input.id}-till-${itemIndex}`}
        value={inputValues?.[field]?.[itemIndex]?.[input.id]?.till ?? ''}
        onChange={(event) =>
          handleChange(input.id, field, itemIndex, 'till')(event)
        }
      />
    </Container>
  );
};
export default FormTimeRange;
