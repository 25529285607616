import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
`;
export const Title = styled.div`
  font-weight: 500;
  color: #050D42;
  font-size: 14px;
  line-height: 22px;
  min-width: 110px;
`;

export const Day = styled.label`
  margin-right: 12px;
  cursor: pointer;
  display: block;
  height: 30px;
  input {
    position: absolute;
    width: 0;
    height: 0;
    &:checked + div {
      background-color: #ECF3F9;
    }
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #000;
    font-weight: 500;
    font-size: 12px;
    background-color: #ECEAEA;
  }
`;