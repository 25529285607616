import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthenticationHeaders } from '../modules/fetch';
import { API } from '../config';

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: fetchBaseQuery({ baseUrl: API }),
  tagTypes: ['Report'],
  endpoints: (builder) => ({
    reports: builder.query({
      query: (id) => ({
        url: `/tickets/customer/${id}/listings/1,100`,
        headers: getAuthenticationHeaders('application/json'),
      }),
      providesTags: ['Report'],
    }),
    fetchTemplateReport: builder.query({
      query: (id) => ({
        url: `/tickets/report-template/${id}`,
        headers: getAuthenticationHeaders('application/json'),
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: ['Report'],
      preferCacheValue: false,
    }),
    addReport: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/tickets/${id}`,
        method: 'POST',
        headers: getAuthenticationHeaders('application/json'),
        body: rest,
      }),
      invalidatesTags: ['Report'],
    }),
    deleteReport: builder.mutation({
      query: (id) => ({
        url: `/tickets/remove/${id}`,
        method: 'DELETE',
        headers: getAuthenticationHeaders('application/json'),
      }),
      invalidatesTags: ['Report'],
    }),
  }),
});

export const {
  useReportsQuery,
  useFetchTemplateReportQuery,
  useAddReportMutation,
  useDeleteReportMutation,
} = reportApi;
