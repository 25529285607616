import { FormattedMessage, useIntl } from 'react-intl';
import { useContext, useEffect, useState } from 'react';
import { GlobalHandlersContext } from '../../context/GlobalHandlersContext';
import { getData } from '../../modules/fetch';
import Button from '@mui/material/Button';
import { Container, Info, PaginationContainer, PerItem, Price, Product, SearchContainer, SKU, StyledInput, StyledPagination } from './style';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { Divider, ListItem, ListItemAvatar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { ProductsContext } from '../../context/ProductsContext';
import { formatPrice } from '../../modules/price';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import { logMessage } from '../../logger';

const CustomerProducts = () => {
  const intl = useIntl();
  const [products, setProducts, productsCounts, setProductsCounts] = useContext<any>(ProductsContext);
  const [query, setQuery] = useState('');
  const [perPage,] = useState(5);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [globalHandlers] = useContext<any>(GlobalHandlersContext);
  const [activeChat] = useContext(ActiveChatContext);

  function loadProducts(query = null, pageNo = 1) {
    let queryVar = '';
    if (query) {
      queryVar = `query=${encodeURIComponent(query)}`;
    }
    getData(`/products/${activeChat?.storeId}/counts/listings?${queryVar}`)
      .then((response) => {
        setProductsCounts(parseInt(response.data));
        setTotalPages(Math.ceil(parseInt(response.data) / perPage));
        getData(`/products/${activeChat?.storeId}/listings/${pageNo},${perPage}?${queryVar}`)
          .then((response) => {
            setProducts(response.data);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    loadProducts(query, page);
  }, [page]);

  const handleUpdateQuery = (e) => {
    if (e.keyCode === 13) {
      loadProducts(query);
    } else {
      setQuery(e.target.value);
    }
  };

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      if (page !== 1) {
        setPage(1);
      } else {
        handleReload();
      }
    }
  };

  const handleSend = (id) => {
    globalHandlers.handleMessageSend('', 'product', id);
  };

  const handleReload = () => {
    loadProducts(query, page);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    logMessage(event);
    setPage(value);
  };

  return (
    <Container>
      <SearchContainer>
        <IconButton onClick={handleReload}>
          <SearchIcon />
        </IconButton>
        <StyledInput value={query} onKeyDown={handleEnterKey} onChange={handleUpdateQuery} placeholder={intl.formatMessage({
          id: 'messages.customerProducts.search.placeholder',
          defaultMessage: 'Search...',
        })} />
      </SearchContainer>
      {products?.length ? (
        <List sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
          overflowY: 'auto',
        }}>
          {products.map((product, index) => (
            <span key={`span-${product?.name}-${index}`}>
              <Divider key={`divider-${product?.name}-${index}`} sx={{ marginLeft: 0 }} variant="inset" component="li" />
              <ListItem key={`list_item-${product?.name}-${index}`}>
                <ListItemAvatar
                  sx={{ marginRight: '10px' }}
                >
                  <Avatar
                    sx={{
                      margin: '10px'
                    }}
                    alt={product?.name}
                    src={product?.images?.[0] || product?.image}
                  />
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => handleSend(product?._id.$oid)}
                  >
                    <FormattedMessage
                      id="messages.customerProducts.details.button.send"
                      defaultMessage="Send"
                    />
                  </Button>
                </ListItemAvatar>
                <ListItemText
                  primary={`${product?.name} ( ${product?.productId} )`}
                  secondary={
                    <Product key={`products-productItems-${index}`}>
                      <Info>
                        {product?.skus.map((sku, skuIndex) => (
                          <span key={`div-product-sku-${skuIndex}`}>
                            <SKU key={`product-sku-${skuIndex}`}>
                              <FormattedMessage
                                id="messages.customerProducts.accordion.sku_ids"
                                defaultMessage=""
                                values={{
                                  skuId: (sku?.skuId || 'n/a'),
                                  available: (sku?.available || 0)
                                }}
                              />
                            </SKU>
                            <Price key={`product-sku-price-${skuIndex}`}>
                              <PerItem>
                                <FormattedMessage
                                  id="messages.customerProducts.accordion.sku"
                                  defaultMessage=""
                                  values={{
                                    price: (formatPrice(product?.store?.countryCode ?? activeChat?.store?.countryCode, sku?.currency || '', sku?.price || '0.00')),
                                    currency: (sku?.currency),
                                    available: (sku?.available || 0)
                                  }}
                                />
                              </PerItem>
                            </Price>
                          </span>
                        ))}
                      </Info>
                    </Product>
                  }
                />
              </ListItem>
            </span>
          ))}
        </List>
      ) : (
        <h4>
          <FormattedMessage id="messages.customerProducts.title" defaultMessage="No Products" />
        </h4>
      )}
      <PaginationContainer>
        {totalPages > 1 ? (
          <>
            <StyledPagination
              count={totalPages}
              page={page}
              siblingCount={0}
              onChange={handlePageChange}
            />
          </>
        ) : null}
        {totalPages > 1 ? (
          <ListItem sx={{ justifyContent: 'center' }}>{(perPage * page) > productsCounts ? productsCounts : (perPage * page)} / {productsCounts}</ListItem>
        ) : null}
      </PaginationContainer>
    </Container>
  );
};
// @ts-ignore
export default CustomerProducts;
// @ts-ignore
