import styled from 'styled-components';
import { AuthorName, ItemHead } from '../styles';

export const StyledAuthorName = styled(AuthorName)`
  align-self: center !important;
`;
export const ProductList = styled.div<{ isAList: boolean }>`
  width: 100%;
  max-width: 400px;
  ${(props) =>
    !props.isAList
      ? `
      border-radius: 5px;
      > div:last-child {
        border-radius: 0 0 5px 5px;
      }
      ${ItemHead} {
        display: block;
        border-radius: 5px 5px 0 0;
      }
    `
      : `
      ${ItemHead} {
        display: none;
      }
    `};
`;

export const ProductListBody = styled.div`
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;

  img {
    max-width: 55px;
    max-height: 55px;
    margin-right: 5px;
  }
`;
export const ProductListInfo = styled.div`
  width: 100%;
  margin-left: 5px;
  max-width: 400px;
`;
export const ProductListTitle = styled.a`
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #050d42;
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;
export const ProductListSKU = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #c5c5c5;
  margin-top: 5px;
`;
export const ProductListPrice = styled.div`
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #050d42;
  margin-top: 10px;
`;
export const ProductWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
export const ProductID = styled.div`
  font-weight: 500;
  color: #050d42;
  font-size: 14x;
  letter-spacing: 0.5px;
`;
