import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import Error from '../../components/Error';
import Button from '@mui/material/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRefreshApiKeyMutation } from '../../api/accountAPI';
import TextField from '@mui/material/TextField';
import { AccountDataContext } from '../../context/AccountDataContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 20px;
  background: #fff;
  border-radius: 6px;
  padding: 0 20px;
  @media ${mediaQueries.desktop} {
    padding: 20px 40px;
    margin: 24px 30px;
  }
`;
const StyledBlock = styled.div`
  margin-bottom: 40px;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;
const StyledTitle = styled.div`
  padding: 20px;
  color: #050d42;
  background: #ecf3f9;
  border-radius: 15px 15px 0 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
`;
const StyledBody = styled.div`
  padding: 20px;
  color: #050d42;
`;
const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > div {
    margin-right: 20px;

    :last-child {
      margin-right: 0;
    }
  }

  @media ${mediaQueries.desktop} {
    flex-direction: row;
  }
`;

const TextFieldContainer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;

  > div {
    flex-grow: 1;

    &:nth-child(2) {
      margin-top: 20px;
    }
  }

  @media ${mediaQueries.desktop} {
    flex-direction: row;
    max-width: 50%;
    > div:nth-child(2) {
      margin: 0 0 0 15px;
    }
  }
`;

const Buttons = styled.div`
  button {
    width: 100%;
    @media ${mediaQueries.desktop} {
      width: auto;
    }
  }
`;

const ApiKey = ({ showDetails, userData }) => {
  const [, setComplete] = useState(false);
  const [values, setValues] = useState({
    api_key: '',
  });
  const [serverError, setServerError] = useState('');
  const intl = useIntl();
  const [refreshApiKey] = useRefreshApiKeyMutation();
  const [, , , , reloadAccount] = useContext(AccountDataContext);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    renderData(userData);
  }, [userData]);

  const renderData = (user) => {
    setValues({
      api_key: user?.settings?.api_key ?? '',
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (window.confirm(intl.formatMessage({
      id: 'settings.integrations.api_key.prompt',
      defaultMessage: 'Are you sure you want to refresh the API key?',
    }))) {
      setServerError('Updating');
      await refreshApiKey({});
      setServerError('');
      setComplete(true);
      reloadAccount();
    }
  };

  return (
    <Container>
      {serverError && <Error message={serverError} />}

      <form autoComplete="off" onSubmit={handleFormSubmit}>
        {showDetails && (
          <>
            <StyledBlock>
              <StyledTitle>
                <FormattedMessage id="settings.integrations.api_key.label" defaultMessage="OmcomAI API Key" />
              </StyledTitle>
              <StyledBody>
                <InputsContainer>
                  <TextFieldContainer>
                    <TextField
                      id="api_key"
                      label={intl.formatMessage({
                        id: 'settings.integrations.api_key.label',
                        defaultMessage: 'Omcom API Key',
                      })}
                      value={values.api_key}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          textTransform: 'uppercase'
                        },
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      autoComplete="none"
                      onChange={handleChange('api_key')}
                    />
                  </TextFieldContainer>
                </InputsContainer>
              </StyledBody>
            </StyledBlock>
          </>
        )}
        <Buttons>
          <Button size="large" variant="contained" color="primary" type="submit">
            <FormattedMessage id="settings.api-key.details.button" defaultMessage="Refresh" />
          </Button>
        </Buttons>
      </form>
    </Container>
  );
};

export default ApiKey;
