import styled from 'styled-components';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { mediaQueries } from '../../constants/breakpoints';
import Popup from '../../components/Popup';
import { Box, MenuItem } from '@mui/material';

export const Container = styled.div`
  width: 100%;
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 70px) !important;
  //max-height: calc(100vh - 70px) !important;
  overflow-y: auto !important;
  @media ${mediaQueries.mobile} {
    //height: calc(100vh - 250px) !important;
    height: 105% !important;
    overflow-y: auto !important;
    position: fixed;
    display: flex;
    margin-top: 0px;
    flex-direction: column;
    padding-bottom: 200px;
    margin-bottom: 300px;
  }
  @media ${mediaQueries.desktop} {
    position: relative;
    height: calc(100% - 30px) !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    margin: 15px;
    margin-left: 0;
    border-radius: 15px;
  }

  .assignee-select {
    border-radius: 15px !important;
    background: #ffffff !important;

    .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select {
      display: flex;
      height: auto;
      min-height: 20px;
      padding: 5px !important;
      padding-left: 5px !important;
      padding-right: 20px !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: transparent !important;
    }
  }
`;
export const SearchContainer = styled.div`
  display: flex;
  justify-content: end;
  border-bottom: 1px solid #ebeff2;
`;
export const ResponsesContainer = styled.div`
  display: flex;
`;
export const MoreButton = styled(Button)`
  min-width: 30px !important;
  border: none !important;
  cursor: pointer !important;
  padding: 0 !important;

  svg {
    color: #000;
    font-size: 30px !important;
  }
`;
export const StyledMenuItem = styled(MenuItem)`
  svg {
    margin-right: 10px;
    color: #aaa;
  }
`;
export const SyncButton = styled(IconButton)`
  svg {
    color: #050d42;
  }

  @media ${mediaQueries.mobile} {
    display: none !important;
  }
`;
export const ArrowsButton = styled(IconButton)`
  @media ${mediaQueries.mobile} {
    display: none !important;
  }
`;
export const StyledButton = styled(Button)`
  padding: 5px 10px !important;
  min-width: 40px !important;
  margin: 10px !important;
  margin-right: 0px !important;
  white-space: nowrap;

  svg {
    @media ${mediaQueries.desktop} {
      margin-right: 5px;
    }
  }

  svg + span {
    display: none;
    @media ${mediaQueries.desktop} {
      display: inline;
    }
  }
`;

export const StyledIconButton = styled.div<{ $activePanel?: boolean }>`
  padding: 5px 10px !important;
  min-width: 40px !important;
  padding: 17px 15px 10px 15px !important;
  white-space: nowrap;
  color: #334d6e !important;
  border-radius: 0 !important;
  @media ${mediaQueries.tablet} {
    display: none;
  }
  ${(props) =>
    props.$activePanel
      ? `
    background-color: #ECF3F9 !important;
  `
      : ''}
`;

export const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
  padding: 20px 15px;
  padding-bottom: 30px;
  @media ${mediaQueries.mobile} {
  }
`;
export const SendContainer = styled.div`
  border-top: 1px solid #ebeff2;
  padding: 10px 10px;
  @media ${mediaQueries.mobile} {
    position: fixed;
    background: white;
    width: 100vw;
    bottom: 0px;
    padding: 5px 5px;
  }
`;
export const SendInputs = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  @media ${mediaQueries.mobile} {
    margin-bottom: 10px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  @media ${mediaQueries.desktop} {
    margin-top: 0;
  }
`;

export const Responses = styled.div`
  overflow-x: auto;
  width: 100%;

  button {
    margin: 0 10px 10px 0;
    padding: 2px 15px;
    text-transform: none;
    font-weight: 700;
    font-size: 1rem;
    white-space: nowrap;
    border: 1px solid #6e6e6e !important;
    border-radius: 8px !important;
    color: #050d42 !important;
  }
`;
export const ResponsesContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  overflow-x: auto;
  @media ${mediaQueries.mobile} {
    flex-direction: row;
    align-items: start;
  }
`;
export const ResponsesIcon = styled(IconButton)`
  margin-bottom: 20px !important;
`;
export const UploadIcon = styled(IconButton)`
  padding: 5px 8px !important;
`;
export const CurrentOrder = styled.div`
  position: relative;
  top: -10px;
`;

export const CurrentOrderName = styled.div`
  color: #2f80ed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  button {
    color: #2f80ed;
  }

  display: none;
  @media ${mediaQueries.desktop} {
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 10px;
`;
export const CopyRight = styled.div`
  @media ${mediaQueries.desktop} {
    padding: 14px;
    color: #c4c4c4;
    font-size: 1rem;
    letter-spacing: 0.5px;
    text-align: center;
  }
  @media ${mediaQueries.mobile} {
    display: none;
  }
`;
export const BackContainer = styled.div`
  margin-left: 0;
  margin-right: auto;
  @media ${mediaQueries.desktop} {
    display: none;
  }
`;
export const HeaderContainer = styled.div`
  @media ${mediaQueries.mobile} {
    top: 61px;
  }
  width: 100%;
  padding: 10px 15px;
  display: flex;
  justify-content: end;
  align-items: center;
  background: #ecf3f9;
`;
export const UserInfo = styled.div`
  display: flex;
  margin-right: auto;
  @media ${mediaQueries.mobile} {
    display: none;
  }
`;
export const StyledFileUpload = styled.input`
  display: inline-flex;
  align-items: center;
  width: 80px;
  position: relative;
  top: 5px;
`;
export const MessagesEnd = styled.div`
  background: transparent;
  height: 0;
  width: 100%;
`;
export const StyledVideo = styled.video`
  display: block;
  width: 100%;
  height: auto;
  max-width: 360px;
  margin: auto;
`;
export const StyledPopup = styled(Popup)`
  padding: 0 !important;
  border-radius: 16px;

  :last-child {
    padding: 0 !important;
  }
`;
export const InfoItem = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding-left: 15px;
`;
export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;
export const SelectInitials = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background: #d314ea;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
`;
export const Initials = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;
export const Name = styled.div`
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #050d42;
`;
export const StoreName = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: #1c2b87;
`;
export const Image = styled.img`
  max-width: 17px;
  max-height: 17px;
  border-radius: 50%;
`;

export const StyledImage = styled.img`
  width: 20px;
  height: 20px;
`;

export const StyledFastResponseButton = styled(Button)`
  overflow-x: hidden;
  min-width: fit-content !important;
`;
