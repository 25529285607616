import PersonIcon from '@mui/icons-material/Person';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import { FormattedMessage } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import ErrorIcon from '@mui/icons-material/Error';
import renderDate from '../../../../../modules/renderDate';
import isJsonString from '../../../../../modules/isJsonString';
import {
  Content,
  Copy,
  Details,
  ImgContainer,
  LastSendError,
  Loader,
  Message,
  MessageBox,
  Order,
  OrderBody,
  OrderHead,
  OrderID,
  OrderInfo,
  OrderPrice,
  OrderSKU,
  OrderTitle,
  Time,
  UserPhoto,
  WasRead,
} from './styles';
import { linkify } from '../../../../../modules/linkify';

const Previous = ({ client, message, resend, handleImagePopup, customerDataStoreTimezone, more = null }) => {
  let isSystemMessage = false;

  let txt = null;
  let img = null;
  let ico = null;
  if (message?.type === 'image') {
    img = {
      imgUrl: message?.content,
      topic: '',
    };
  } else {
    try {
      const messageContent = JSON.parse(message?.content);

      if (messageContent?.activeContent) {
        isSystemMessage = true;
      }
      if (messageContent?.imgUrl !== undefined || messageContent?.iconUrl !== undefined) {
        if (messageContent?.imgUrl !== undefined) {
          img = messageContent;
        } else {
          ico = messageContent;
          if (ico?.title) {
            ico.title = isJsonString(ico.title) ? JSON.parse(ico.title) : ico.title;
            if (typeof ico.title === 'object') {
              ico.title = Object.values(ico.title)[0];
            }
          }
        }
      } else {
        if (messageContent?.text !== undefined) {
          txt = String(messageContent?.text);
        } else {
          if (messageContent?.txt === undefined) {
            txt = String(messageContent);
          } else {
            txt = String(messageContent?.txt);
          }
        }
      }
    } catch (e) {
      txt = message?.content;
    }
  }

  return (
    <Message
      isSystemMessage={isSystemMessage}
      isClient={client}
      key={message?._id?.$oid + message?.sendId}
    >
      <UserPhoto>
        <PersonIcon />
      </UserPhoto>
      <Content>
        <>
          {ico && ico?.orderId && (
            <Order>
              <OrderHead>
                <OrderID>
                  <FormattedMessage
                    id="messages.chat.messages.order_id"
                    defaultMessage="Order ID # {id}"
                    values={{
                      id: ico.orderId,
                    }}
                  />
                </OrderID>
              </OrderHead>
              <OrderBody>
                <img src={ico.iconUrl} alt={ico.title} />
                <OrderInfo>
                  <OrderTitle href={ico.actionUrl} target="_blank">
                    {ico.title}
                  </OrderTitle>
                  <OrderSKU>
                    <FormattedMessage
                      id="messages.chat.messages.sku_id"
                      defaultMessage="SKU: {id}"
                      values={{
                        id: ico.skuId,
                      }}
                    />
                  </OrderSKU>
                  <OrderPrice>
                    {ico.quantity} x {ico.price}
                  </OrderPrice>
                </OrderInfo>
              </OrderBody>
            </Order>
          )}
        </>
        <>
          {ico && ico?.action && ico?.action === 'followCard_follow' && (
            <Order>
              <OrderHead>
                <OrderID>{ico.title}</OrderID>
              </OrderHead>
              <OrderBody>
                <img src={ico.iconUrl} alt={ico.title} />
                <OrderInfo>
                  <OrderID>{ico.title}</OrderID>
                </OrderInfo>
              </OrderBody>
            </Order>
          )}
        </>
        <>
          {img && (
            <ImgContainer onClick={() => handleImagePopup(img.imgUrl)}>
              <img src={img.imgUrl} style={{ maxWidth: 400, maxHeight: 400 }} alt={img.topic} />
            </ImgContainer>
          )}
        </>
        <MessageBox>
          {!message?.isClient && message?.lastSendError && (
            <IconButton aria-label="resend" onClick={() => resend(message?._id?.$oid)}>
              <RefreshIcon fontSize="small" />
            </IconButton>
          )}
          {(!message?.delivery ||
            message?.delivery === 'sending' ||
            message?.delivery === 'sent2api' ||
            message?.delivery === 'sent_to_api') &&
            !client && <Loader color="primary" src={'/img/loader-new.gif'} />}
          {(txt && <Copy dangerouslySetInnerHTML={{ __html: linkify(txt) }} />) ||
            (!(isSystemMessage || img || ico) && (
              <Copy dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
            ))}
        </MessageBox>
        {more}
        <Details>
          {message?.delivery && (
            <WasRead isWasRead={message?.isRead}>
              {message?.delivery === 'error' ? (
                <ErrorIcon />
              ) : (
                message?.delivery !== 'sending' &&
                message?.delivery !== 'saved' &&
                (message?.delivery !== 'sent' && message?.delivery !== 'sent_from_external' ? (
                  <DoneIcon />
                ) : (
                  <DoneAllIcon />
                ))
              )}
            </WasRead>
          )}
          <Time>
            {message?.time?.date &&
            customerDataStoreTimezone?.name &&
            customerDataStoreTimezone?.offset
              ? `${renderDate(message?.time?.date, customerDataStoreTimezone?.name)}, ${
                  customerDataStoreTimezone?.name
                }, UTC ${customerDataStoreTimezone?.offset}`
              : '...'}
          </Time>
        </Details>
        {!message?.isClient && message?.lastSendError && isJsonString(message?.lastSendError) && (
          <LastSendError>
            <FormattedMessage id={JSON.parse(message.lastSendError).message} />
          </LastSendError>
        )}
      </Content>
    </Message>
  );
};

export default Previous;
