import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useIntl } from 'react-intl';
import { getInputDependedOptions } from '../../../modules/getinput';

export const FormAutocomplete = ({ input, inputValues, requiredFields, handleAutocompleteChange }) => {
  const intl = useIntl();

  let label = '';
  try {
    label = intl.formatMessage({ id: input?.label, defaultMessage: input?.label });
  } catch (e) {}

  return (
    <Autocomplete
      id={input.id}
      freeSolo
      options={getInputDependedOptions(input, inputValues)?.map((option) =>
        String(
          intl.formatMessage({
            id: String(option),
            defaultMessage: String(option),
          }),
        ),
      )}
      onInputChange={(event) => handleAutocompleteChange(input.id)(event)}
      onChange={(event) => handleAutocompleteChange(input.id)(event)}
      renderInput={(params) => (
        <TextField {...params} required={requiredFields[input.id]} label={label} />
      )}
    />
  );
};
export default FormAutocomplete;
