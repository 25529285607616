import { useState } from 'react';
import styled from 'styled-components';
import Popup from '../Popup';
import Message from './message';
import Orders from './orders';
import useSortableData from '../../modules/useSortableData';
import { Table, Td, Th, Tr } from '../../components/Table';
import { mediaQueries } from '../../constants/breakpoints';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
  color: #535873;
  @media ${mediaQueries.largeDesktop} {
    overflow-x: hidden;
  }
`;

const OrderItem = ({ item, index, customers }) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <Tr>
      <Td>{index}</Td>
      <Td>{item.provider}</Td>
      <Td>{item.orderId}</Td>
      <Td>
        {item?.customerName
          ? item?.customerName
          : customers?.data?.find((customer) => customer.buyerId === item.orderItems?.[0]?.buyer_id)
            ?.name}
      </Td>
      <Td>
        {showAll ? (
          <>
            {item.orderItems?.map((order) => (
              <div>{order.sku}</div>
            ))}
          </>
        ) : (
          <>
            {item.orderItems?.[0]?.sku}
            <br />
            {item.orderItems?.length > 1 ? (
              <button onClick={() => setShowAll(true)}>
                <FormattedMessage
                  id="customer.customerList.button.more"
                  defaultMessage="Show more ..."
                />
              </button>
            ) : null}
          </>
        )}
      </Td>
      <Td>
        {showAll ? (
          <>
            {item.orderItems?.map((order) => {
              return (
                <div>
                  {order.price?.toFixed(2)} {item.orderItems?.[0]?.currency}
                </div>
              );
            })}
          </>
        ) : (
          <>
            {item.orderItems?.[0]?.price?.toFixed(2)} {item.orderItems?.[0]?.currency}
            <br />
            {item.orderItems?.length > 1 ? (
              <button onClick={() => setShowAll(true)}>
                <FormattedMessage
                  id="customer.customerList.button.more"
                  defaultMessage="Show more ..."
                />
              </button>
            ) : null}
          </>
        )}
      </Td>
      <Td>
        {(
          (item.revenue && item.revenue) ||
          item.orderItems?.reduce((a, b) => {
            return a + b['item_price'];
          }, 0)
        ).toFixed(2)}{' '}
        {item.orderItems?.[0]?.currency}
      </Td>
      <Td>{item.orderItems?.[0]?.created_at}</Td>
    </Tr>
  );
};

const List = ({ data, customers }) => {
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [showOrdersPopup, setShowOrdersPopup] = useState(false);
  const [orders] = useState(false);

  const { items, requestSort, sortConfig } = useSortableData(data);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <>
      {showMessagePopup && (
        <Popup
          isPreviewTicket={false}
          setIsPreviewTicket={() => {
          }}
          handleClose={() => setShowMessagePopup(false)}
        >
          <Message type="message" />
        </Popup>
      )}
      {showNotePopup && (
        <Popup
          isPreviewTicket={false}
          setIsPreviewTicket={() => {
          }}
          handleClose={() => setShowNotePopup(false)}
        >
          <Message type="note" />
        </Popup>
      )}
      {showOrdersPopup && (
        <Popup
          isPreviewTicket={false}
          setIsPreviewTicket={() => {
          }}
          handleClose={() => setShowOrdersPopup(false)}
        >
          <Orders data={orders} />
        </Popup>
      )}
      <Container>
        <Table>
          <Tr>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('id')}
                className={getClassNamesFor('id')}
              >
                #
              </button>
            </Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('platform')}
                className={getClassNamesFor('platform')}
              >
                <FormattedMessage
                  id="customer.customerList.button.platform"
                  defaultMessage="Platform"
                />
              </button>
            </Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('lastOrder')}
                className={getClassNamesFor('lastOrder')}
              >
                <FormattedMessage id="customer.customerList.button.order" defaultMessage="Order#" />
              </button>
            </Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('name')}
                className={getClassNamesFor('name')}
              >
                <FormattedMessage
                  id="customer.customerList.button.customer"
                  defaultMessage="Customer"
                />
              </button>
            </Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('sku')}
                className={getClassNamesFor('sku')}
              >
                <FormattedMessage id="customer.customerList.button.sku" defaultMessage="SKU" />
              </button>
            </Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('revenue')}
                className={getClassNamesFor('revenue')}
              >
                <FormattedMessage
                  id="customer.customerList.button.revenue"
                  defaultMessage="Revenue"
                />
              </button>
            </Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('revenueTotal')}
                className={getClassNamesFor('revenueTotal')}
              >
                <FormattedMessage
                  id="customer.customerList.button.total"
                  defaultMessage="Revenue, Total"
                />
              </button>
            </Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('date')}
                className={getClassNamesFor('date')}
              >
                <FormattedMessage id="customer.customerList.button.date" defaultMessage="Date" />
              </button>
            </Th>
          </Tr>
          {items?.map((item, index) => (
            <OrderItem key={index} item={item} index={index} customers={customers} />
          ))}
        </Table>
      </Container>
    </>
  );
};

export default List;
