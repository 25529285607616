import { useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';

const Container = styled.div``;

const Templates = ({ templates }) => {
  const [itemsToShow, setItemsToShow] = useState(3);

  const handleShowMoreStores = () => {
    const templatesAmount = itemsToShow === 3 ? templates.length + 1 : 3;
    setItemsToShow(templatesAmount);
  };

  return (
    <Container>
      {templates?.slice(0, itemsToShow).map((template, index) => {
        return template?.name ? (
          <div key={`templates-${index}`}>
            <span>{template?.name}</span>
          </div>
        ) : null;
      })}
      {itemsToShow === 3 && templates?.length > 3 ? (
        <Button size="small" color="primary" onClick={handleShowMoreStores}>
          <FormattedMessage
            id="templatesManagement.storeList.table.button.all"
            defaultMessage="View all..."
          />
        </Button>
      ) : (
        ''
      )}
    </Container>
  );
};

export default Templates;
