import { useContext } from 'react';
import { Body, PageContainer } from '../../components/Layout';
import { Navigate } from 'react-router-dom';
import Header from '../../components/Header';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback, ErrorBoundaryHandler } from '../../components/ErrorBoundary';
import Contacts from '../../containers/Contacts';
import { AccountDataContext } from '../../context/AccountDataContext';
import Nav from '../../components/Nav';
import { ContactsContainer } from './styles';

const ContactsPage = () => {
  const [currentAccount] = useContext<any>(AccountDataContext);

  return (
    <>
      {currentAccount.viewsLevel > 3 ? (
        <Navigate to="/messages" />
      ) : (
        <>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onError={ErrorBoundaryHandler}
          >
            <Header />
          </ErrorBoundary>
          <PageContainer>
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
              <Nav currentStep="contacts" />
            </ErrorBoundary>
            <Body>
              <ContactsContainer>
                <Contacts />
              </ContactsContainer>
            </Body>
          </PageContainer>
        </>
      )}
    </>
  );
};

export default ContactsPage;
