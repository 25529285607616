import { createContext, useContext, useEffect, useState } from 'react';
import { retrieve, store } from '../modules/storage';
import jwtDecode from '../modules/jwt';
import { JwtTokenContext } from './JwtTokenContext';
import { getAccountData, getCurrentLevel } from '../security';

export const AccountDataContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [token,] = useContext<any>(JwtTokenContext);
  const [loadImitierenAccount, setLoadImitierenAccount] = useState(false);
  const [currentAccount, setCurrentAccount] = useState({
    jwt: {
      encrypted: '',
      exp: 0,
      iat: 0,
      roles: []
    },
    viewsLevel: retrieve.number('viewsLevel', 255),
    roles: [],
    _id: { $oid: null },
    company: null,
    imitieren: {},
    ...{},
  });

  const isGranted = (attribute, account = null) => {
    if (account === null) account = currentAccount;
    return ('roles' in account && Array.isArray(account.roles)) ? account.roles.find(item => item === attribute) : false;
  }

  const reloadAccount = () => {
    getAccountData((response) => {
      const currentLevel = getCurrentLevel(response);
      response.viewsLevel = currentLevel;
      try {
        let jwtDecoded = token ? jwtDecode(token) : {};
        delete jwtDecoded.encrypted;
        response.jwt = { ...jwtDecoded };
      } catch (e) {
      }
      store.number('viewsLevel', currentLevel)
      store.json('currentAccount', response)
      setCurrentAccount({ ...response });
    });
  }

  useEffect(() => {
    if (token) {
      try {
        const iAm = retrieve.json('currentAccount', null) ?? null;
        if (iAm) {
          setCurrentAccount(iAm);
        } else {
          reloadAccount();
        }
      } catch (e) {
      }
    }
  }, [token]);

  useEffect(() => {
    if (loadImitierenAccount === true) {
      setLoadImitierenAccount(false);
      if (isGranted('ROLE_SUPER_ADMIN', currentAccount)) {
        // retrieve.string('imitieren')
        getAccountData((response) => {
          response.viewsLevel = getCurrentLevel(response);
          const superAccount = { ...currentAccount, imitieren: { ...response } };
          setCurrentAccount({ ...superAccount });
          store.json('currentAccount', superAccount)
        })
      }

    }
  }, [loadImitierenAccount]);

  return (
    <AccountDataContext.Provider value={[currentAccount, setCurrentAccount, loadImitierenAccount, setLoadImitierenAccount, reloadAccount]}>
      {children}
    </AccountDataContext.Provider>
  );
};
