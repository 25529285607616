import isJsonString from '../../../../modules/isJsonString';
import { LastSendError } from './styles';
import { FormattedMessage } from 'react-intl';

const LastErrorMessage = ({ message }) => {
  let parsed = { message: '', details: { response: { msg: '', message: '' } } };
  try {
    parsed = JSON.parse(message.lastSendError);
  } catch (e) {
    parsed = { message: message.lastSendError, details: { response: { msg: '', message: '' } } };
  }
  if (!message?.isClient && message?.lastSendError && isJsonString(message?.lastSendError)) {
    return (
      <>
        <LastSendError>
          <FormattedMessage id={parsed?.message} />
        </LastSendError>
        <LastSendError dangerouslySetInnerHTML={{ __html: (parsed?.details?.response?.msg) }}>
        </LastSendError>
        <LastSendError dangerouslySetInnerHTML={{ __html: (parsed?.details?.response?.message) }}>
        </LastSendError>
      </>
    );
  } else if (message?.rawResponse?.status && Array.isArray(message?.rawResponse?.status)) {
    return (
      <>
        {message?.rawResponse?.status.map((status: any) => {
          return Array.isArray(status.errors) && status.errors?.map((error: any) => (
            <>
              <LastSendError dangerouslySetInnerHTML={{ __html: (error.title ?? '') }}>
              </LastSendError>
            </>
          ))
        })}
      </>
    );
  }
};

export default LastErrorMessage;
