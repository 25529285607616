import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const OnTopRightBottomConner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: end;
  position: relative;
  bottom: -8px;
  right: -8px;
  height: 0;
`;
