import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import General from './general';
import Billing from './billing';
import CurrentPlan from './currentPlan';
import { Navigate, useParams } from 'react-router-dom';
import { useAccountQuery } from '../../api/accountAPI';
import OutOfOffice from './outOfOffice';
import Integrations from './integrations';
import ApiKey from './api-key';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  border-radius: 6px;
  min-height: 100%;
  @media ${mediaQueries.desktop} {
    margin: 24px 0;
  }
`;

const LEVEL2 = ['ROLE_USER', 'ROLE_SUPPORT', 'ROLE_MANAGER', 'ROLE_OWNER'];

const Settings = () => {
  const params = useParams();
  const { data: userData } = useAccountQuery(true);
  const isUserLevel2 = userData?.data?.roles?.some((v) => LEVEL2.includes(v));

  return (
    <Container>
      {!params.page ? (
        <Navigate to="/settings/general" />
      ) : (
        (params.page === 'general' && (<General showDetails={isUserLevel2} userData={userData?.data} />)) ||
        (params.page === 'subscription' && <CurrentPlan userData={userData?.data} />) ||
        (params.page === 'billing' && <Billing userData={userData?.data} />) ||
        (params.page === 'out-of-office' && <OutOfOffice />) ||
        (params.page === 'integrations' && <Integrations showDetails={isUserLevel2} userData={userData?.data} />) ||
        (params.page === 'api-key' && <ApiKey showDetails={isUserLevel2} userData={userData?.data} />)
      )}
    </Container>
  );
};

export default Settings;
