import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';

export const OnHoverNav = styled.div`
  position: absolute;
  top: 7px;
  right: 7px;
  opacity: 0;
  transition: opacity 0.2s;
`;

export const NavButton = styled(IconButton)`
  color: #cbcbcb;

  svg {
    width: 20px;
    height: 20px;
  }
`;
export const CounterMsg = styled.div`
  display: inline-block;
  max-height: 20px;
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
  background-color: #cbcbcb;
  border-radius: 6px;
`;
export const Container = styled.div<{
  isMenuOpen: boolean;
  isActive: boolean;
}>`
  padding: 15px;
  margin: 5px;
  position: relative;
  cursor: pointer;
  transition: background 0.2s;
  border-radius: 12px;

  ${(props) =>
    props.isActive
      ? `
    background: #ECF3F9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    + div {
      border-top-color: transparent;
    }
  `
      : `
    border-top: 1px solid #F4F4F4;
  `}
  ${(props) =>
    props.isMenuOpen
      ? `
    ${StyledCheckbox} {
      display: block !important;
    }
  `
      : ''}
  :first-child {
    border-top: none;
  }

  :hover {
    background: #ecf3f9;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

    ${OnHoverNav} {
      opacity: 1;
    }

    ${CounterMsg} {
      display: block;
    }

    + div {
      border-top-color: transparent;
    }
  }
`;
export const StyledCheckbox = styled(Checkbox)`
  display: none !important;
  margin: -5px 0 0 -12px !important;
`;
export const Info = styled.div`
  display: flex;

  .MuiBadge-badge {
    z-index: 0 !important;
  }
`;
export const Image = styled.img`
  max-width: 15px;
  max-height: 15px;
  border-radius: 50%;
`;
export const Initials = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`;
export const InitialsBadge = styled.div`
  width: 15px;
  height: 15px;
  color: #fff;
  background-color: #050d42;
  border-radius: 50%;
  font-size: 8px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
`;
export const Name = styled.div`
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #050d42;
`;
export const OwnerName = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  line-height: 12px;
  color: #2c3676;
`;
export const Store = styled.div`
  margin-left: 10px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 12px;
  text-transform: lowercase;
  color: #1c2b87;
`;
export const Time = styled.div<{ wasOpened: boolean }>`
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #000;
  display: flex;
  align-items: center;
  ${(props) =>
    props.wasOpened
      ? `
    `
      : `
      color: #2F80ED;
      font-weight: 500;
      ::before {
        content: "";
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #2F80ED;
        margin-right: 5px;
      }
    `}
`;
export const Message = styled.div<{ wasOpened: boolean }>`
  overflow: hidden;
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
  color: #000;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
  margin-bottom: 10px;
  ${(props) =>
    props.wasOpened
      ? `
      font-weight: 400;
    `
      : ``}
`;
export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;
export const InfoItem = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding-left: 15px;
`;
export const Content = styled.div`
  margin-top: 12px;
  display: flex;
`;
export const StoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StoreItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;
