import { FormRow } from '../FormItems/FormRow';
import { AddButton, Container, RowsContainer } from './styles';
import FormCopyableList from '../FormItems/FormCopyableList';
import { FormattedMessage } from 'react-intl';

export const ContactFormRenderContent = ({
  inputValues,
  requiredFields,
  setInputValues,
  contactFormData
}) => {
  const handleAddButton = () => {
    setInputValues({
      ...inputValues,
      settings: [
        ...inputValues.settings,
        {
          weekDays: [],
          timeRange: {
            from: "",
            till: ""
          }
      },
      ],
    });
  };
  return (
    <Container>
      {contactFormData?.map((step, index) => (
        <RowsContainer key={`autoreply-step-${index}`}>
          {step.form.rows.map(
            (formRow, formRowIndex) =>
              ('type' in formRow && formRow?.type === 'copyable' && (
                <div key={`autoreply-row-${formRowIndex}`}>
                  <FormCopyableList
                    key={`report-row-${formRowIndex}`}
                    index={index}
                    formRowIndex={formRowIndex}
                    inputValues={inputValues}
                    requiredFields={requiredFields}
                    setInputValues={setInputValues}
                    formRow={formRow}
                  />
                  <AddButton variant="text" onClick={handleAddButton}>
                    <FormattedMessage id="contact.form.add_button" />
                  </AddButton>
                </div>
              )) || (
                <FormRow
                  key={`autoreply-row-${formRowIndex}`}
                  index={index}
                  inputValues={inputValues}
                  requiredFields={requiredFields}
                  formRowIndex={formRowIndex}
                  setInputValues={setInputValues}
                  inputLabels={{}}
                  setInputLabels={() => {}}
                  formRow={formRow}
                  isPreview={false}
                />
              ),
          )}
        </RowsContainer>
      ))}
    </Container>
  );
};
export default ContactFormRenderContent;
