import { Content, MessageBox } from '../styles';
import { Copy, ErrorMessage } from './styles';
import { FormattedMessage } from 'react-intl';
import { Title } from '../Unsupported/styles';
import isJsonString from '../../../../../modules/isJsonString';

export default ({ message, more = null }) => {
  let messageContent = '';
  if (isJsonString(message?.body?.error?.exception?.message || message?.body?.error)) {
    try {
      messageContent = JSON.parse(message?.body?.error?.exception?.message || message?.body?.error || '')?.message || ''
    } catch (e) {
      messageContent = message?.body?.error?.exception?.message || ''
    }
  } else {
    messageContent = message?.body?.error?.exception?.message || message?.body?.error
  }
  return (
    <ErrorMessage key={message?._id?.$oid + message?.sendId}>
      <Content>
        <MessageBox>
          <Title>
            <span className="red">!</span>
            <span>
              <FormattedMessage id={message?.body?.textId} />:
            </span>
          </Title>
          <Copy>
            <FormattedMessage
              id={messageContent}
              defaultMessage={messageContent}
            />
          </Copy>
          {more}
          {message?.deliveryError && <Copy dangerouslySetInnerHTML={{ __html: message?.deliveryError ?? '' }} />}
          {message?.lastSendError && <Copy dangerouslySetInnerHTML={{ __html: message?.lastSendError ?? '' }} />}
        </MessageBox>
      </Content>
    </ErrorMessage>
  );
};
