import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  color: #050d42;
`;
export const Text = styled.div`
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: #050d42;
`;
