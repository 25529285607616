import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Box, Chip, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledAvatar } from './styles';
import LIST_ALL_COUNTRIES from '../../../constants/listAllCountries.json';
import { getInputDependedOptions, getInputDependedValues } from '../../../modules/getinput';
import { ListAllCountires } from '../../../constants/listAllCountires';
import { logMessage } from '../../../logger';

export const FormAutocompleteFlags = ({ input, inputValues, requiredFields, setInputValues }) => {
  const intl = useIntl();
  const [countries, setCountries] = useState([]);
  const [listCountries, setListCountries] = useState([]);

  const createList = (data) => {
    let list = [];
    for (let countryCode of data) {
      list.push(LIST_ALL_COUNTRIES.find((item: ListAllCountires) => item?.code === countryCode.toUpperCase()));
    }
    return list;
  };
  useEffect(() => {
    if (inputValues[input?.id]?.length > 0) {
      setCountries(createList(getInputDependedValues(input, inputValues)));
    }
    if (input?.options?.length > 0) {
      setListCountries(createList(getInputDependedOptions(input, inputValues)));
    }
  }, [input]);
  const handleChangeCountryCodes = (event, value) => {
    logMessage('event', event);
    setCountries(value);
    setInputValues({ ...inputValues, countries: value?.map((item) => item?.code.toLowerCase()) });
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        id="survey-countries"
        options={listCountries}
        multiple={true}
        limitTags={3}
        autoHighlight
        defaultValue={input?.defaultValue}
        value={countries}
        onChange={handleChangeCountryCodes}
        getOptionLabel={(option: any) => option?.label}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
              alt=""
            />
            {option?.label}
          </Box>
        )}
        renderTags={(value: readonly any[], getTagProps) =>
          value?.map((val: any, index: number) => (
            <Chip
              size="small"
              variant="outlined"
              avatar={
                <StyledAvatar src={`https://flagcdn.com/w20/${val?.code?.toLowerCase()}.png`} />
              }
              label={val?.label}
              deleteIcon={<CloseIcon />}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required={requiredFields[input.id]}
            inputProps={{
              ...params.inputProps,
              required: countries.length === 0,
            }}
            label={intl.formatMessage({
              id: 'messages.messagesSearch.countries',
              defaultMessage: 'messages.messagesSearch.countries',
            })}
          />
        )}
      />
    </FormControl>
  );
};
export default FormAutocompleteFlags;
