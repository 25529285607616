const isJsonString = (str) => {
  try {
      if (typeof str === 'number') {
          throw 'Is number';
      }
      const res = JSON.parse(str);
      if (typeof res === 'number') {
        throw 'Is number: ' + str;
      }
  } catch (e) {
      return false;
  }
  return true;
}

export default isJsonString;
