import styled from 'styled-components';
import { mediaQueries } from '../../../../../constants/breakpoints';
import { AuthorName, ItemHead } from '../styles';

export const StyledAuthorName = styled(AuthorName)`
  align-self: center !important;
`;
export const VoucherList = styled.div<{ isAList: boolean }>`
  max-width: 345px;
  ${(props) =>
    !props.isAList &&
    `
      // border-radius: 10px;
    `};
  ${ItemHead} {
    margin-bottom: 3px;
  }
`;

export const VoucherListBody = styled.div`
  display: flex;
  padding: 10px 13px;
  background: #ecf3f9;
  border-radius: 0px 0px 5px 5px;
  img {
    max-width: 55px;
    max-height: 55px;
    margin-right: 5px;
  }
`;
export const VoucherListInfo = styled.div`
  max-width: 200px;
  @media ${mediaQueries.desktop} {
    max-width: 350px;
  }
`;
export const VoucherListTitle = styled.div`
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #006876;
  :hover {
    text-decoration: none;
  }
`;
export const VoucherListSKU = styled.div`
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 15px;
  color: #a3a3a3;
`;
export const VoucherListPrice = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #4a4747;
`;
export const VoucherListPriceText = styled.div`
  display: inline-block;
  margin-right: 10px;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 15px;
  color: #050d42;
`;
export const VoucherListPriceDate = styled.div`
  display: inline-block;
  font-size: 12px;
  color: #4a4747;
`;
