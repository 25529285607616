import { Content, MessageBox } from '../styles';
import { Copy, Title, UnsupportedMessage } from './styles';
import { FormattedMessage } from 'react-intl';

export default ({ message, more = null }) => {
  return (
    <UnsupportedMessage key={message?._id?.$oid + message?.sendId}>
      <Content>
        <MessageBox>
          <Title>
            <span className="red">!</span>
            <span>
              <FormattedMessage
                id="messages.chat.messages.unsupported"
                defaultMessage="Unsupported message:"
              />
            </span>
          </Title>
          <Copy>
            <FormattedMessage id={message?.body?.textId} />
          </Copy>
        </MessageBox>
        {more}
      </Content>
    </UnsupportedMessage>
  );
};
