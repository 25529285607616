import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import WhitePopup from '../WhitePopup';

export const Container = styled.div`
  position: sticky;
  top: 0px;
  width: 100%;
  height: 61px;
  display: flex;
  align-items: center;
  background-color: #050d42;
  justify-content: space-between;
  padding: 0 0px;
  @media ${mediaQueries.mediumDesktop} {
    padding: 0 30px;
  }
`;
export const InnerContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  @media ${mediaQueries.tablet} {
    display: none;
  }
`;
export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const CoinsContainer = styled.div`
  min-width: 80px;
  max-width: 43%;
  width: 100%;
  
  a {
    margin-left: 40px;
    color: #fff;
    font-weight: bold;

    :hover {
      opacity: 0.7;
    }
  }
`;
export const NameContainer = styled.div`
  min-width: 80px;
  max-width: 43%;
  width: 100%;
  
  display: flex;
  color: #fff;
  margin-left: 40px;
  font-weight: bold;
`;
export const ServiceLinks = styled.div`
  min-width: 80px;

  a {
    color: #fff;
    margin-left: 15px;

    :hover {
      opacity: 0.7;
    }
  }
`;
export const SearchInputContainer = styled.div`
  flex: 1;
  margin-right: 5px;
  background: #fff;
  display: flex;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
`;
export const UserContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;
export const LocationContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;
export const StyledIconButton = styled(IconButton)`
  padding: 0 8px !important;
`;
export const SearchButton = styled(IconButton)`
  position: absolute !important;
  top: 3px;
  right: 0 !important;
  padding: 0 8px !important;
`;
export const MoreButton = styled(Button)`
  min-width: 24px !important;
  border: none !important;
  cursor: pointer !important;
  margin-right: 10px !important;
  padding: 0 !important;

  svg {
    color: #fff;
    font-size: 24px !important;
  }
`;
export const MenuButton = styled.div`
  @media ${mediaQueries.mediumDesktop} {
    display: none;
  }

  button {
    color: #fff;
  }
`;
export const Location = styled.div`
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;
export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const UserName = styled.h4`
  margin: 0 0 5px 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #ffffff;
`;
export const StoreName = styled.h5`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #ffffff;
`;
export const StyledBox = styled(Box)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: transparent;
`;

export const LinkTitle = styled.div`
  position: relative;
  top: -5px;
  
  & svg {
    position: relative;
    top: 6px;
  };
`;

export const Plans = styled.div`
  margin: auto !important;
  width: 100%;
  text-align: center;
`;

export const StyledButtonPlan = styled(Button)`
  background: #006977;
  margin: 10px !important;
`;

export const PopupLayer = styled.div`
  font-weight: bold;
  margin: 20px;
`;

export const StyledPopup = styled(WhitePopup)`
  padding: 0 !important;
  border-radius: 16px !important;
  & .Shadow {
    background: rgba(255, 255, 255, 0.5);
  }

  :last-child {
    padding: 0 !important;
  }
`;
