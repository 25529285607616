import { useContext, useLayoutEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { Modal, ModalTitle } from '../../components/Modal';
import { EmailInput, PasswordInput } from '../../components/Input';
import Button from '@mui/material/Button';
import { mediaQueries } from '../../constants/breakpoints';
import Error from '../../components/Error';
import { FormattedMessage, useIntl } from 'react-intl';
import { store } from '../../modules/storage';
import Header from '../../components/Header';
import postLogin from '../../api/postLogin';
import { TailSpin } from 'react-loader-spinner';
import { JwtTokenContext } from '../../context/JwtTokenContext';
import jwtDecode from '../../modules/jwt';
import { logMessage } from '../../logger';

const Container = styled.div`
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.desktop} {
    background-color: #f5f6f8;
  }
`;
const SubText = styled.p`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #c4c4c4;
`;
const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  flex-direction: column-reverse;

  button,
  a {
    flex: 1;
    margin-bottom: 15px;
  }

  @media ${mediaQueries.desktop} {
    flex-direction: row;
    button,
    a {
      flex: 0 0 48%;
      white-space: nowrap;
    }
  }
`;
// const CheckboxContainer = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 20px 0 20px -10px;
// `;
const Copy = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
`;
const ResetContainer = styled.div`
  margin-top: 40px;
  text-align: center;
`;
const TextFieldContainer = styled.div`
  margin-top: 20px;
`;

const SignIn = () => {
  const [token, setToken, , setTokenParts] = useContext<any>(JwtTokenContext);
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [serverError, setServerError] = useState('');
  const [success, setSuccess] = useState(false);
  const intl = useIntl();

  const validate = (fieldValues = values) => {
    logMessage('validate', values);
    let temp: Record<string, string> = { ...errors };

    if ('email' in fieldValues) {
      fieldValues.email = String(fieldValues.email).replaceAll(' ', '');
      temp.email = fieldValues.email ? '' : 'This field is required.';

      //TODO: enable validation for emails

      // if (fieldValues.email)
      //   temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
      //     ? ""
      //     : "Email is not valid.";
    }

    if ('password' in fieldValues)
      temp.password = fieldValues.password ? '' : 'This field is required.';

    setErrors({
      ...temp,
    });
  };

  const formIsValid = (fieldValues = values) => {
    logMessage('formIsValid', values);
    // isValid
    return (
      fieldValues.email && fieldValues.password && Object.values(errors).every((x) => x === '')
    );
  };

  const handleInputChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmitSuccess = (data) => {
    if (data?.token) {
      store.string('token', data.token);
      const tokenParts = jwtDecode(data.token);
      store.json('tokenParts', tokenParts);
      store.string('uniqueId', tokenParts['uniqueId']);
      setToken(data.token);
      setTokenParts(tokenParts);
      setSuccess(true);
      setLoading(false);
    }
  };

  const handleSubmitError = (err) => {
    console.warn('handleSubmitError,err', err);
    setSuccess(false);
    console.error('Error:::: ' + err);
    setServerError(err.message);
    setLoading(false);
  };

  const handleFormSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    validate();
    const isValid = Object.values(errors).every((x) => x === '') && formIsValid();
    if (isValid) {
      postLogin(
        { username: String(values.email).replaceAll(' ', ''), password: values.password },
        handleSubmitSuccess,
        handleSubmitError,
      ).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (token) {
      setSuccess(true);
    }
  }, [token]);

  return (
    <>
      {success ? (
        <Navigate to="/messages" />
      ) : (
        <>
          <Header />
          <Container>
            <Modal>
              <ModalTitle>
                <FormattedMessage id="signin.modal.title" defaultMessage="Welcome back" />
              </ModalTitle>
              <SubText>
                <FormattedMessage
                  id="signin.modal.text"
                  defaultMessage="Please enter your credentials to access your account"
                />
              </SubText>
              {serverError && <Error message={serverError} />}
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <TextFieldContainer>
                  <EmailInput
                    id="email"
                    value={values.email}
                    label={intl.formatMessage({
                      id: 'signin.modal.email',
                      defaultMessage: 'signin.modal.email',
                    })}
                    error={Boolean(errors['email'])}
                    autoComplete="none"
                    handleChange={handleInputChange('email')}
                    {...(errors['email'] && {
                      error: true,
                      helperText: errors['email'],
                    })}
                  />
                </TextFieldContainer>
                <TextFieldContainer>
                  <PasswordInput
                    id="password"
                    value={values.password}
                    label={intl.formatMessage({
                      id: 'signin.modal.password',
                      defaultMessage: 'signin.modal.password',
                    })}
                    error={Boolean(errors['password'])}
                    autoComplete="none"
                    handleChange={handleInputChange('password')}
                    {...(errors['password'] && {
                      error: true,
                      helperText: errors['password'],
                    })}
                  />
                </TextFieldContainer>

                <ButtonsContainer>
                  <Button component={Link} to="/sign-up" size="large" variant="outlined">
                    <FormattedMessage
                      id="signin.modal.button.create"
                      defaultMessage="Create Account"
                    />
                  </Button>
                  {isLoading ? (
                    <Button component={Link} to="#" size="large" variant="contained" color="primary">
                      <TailSpin color="#fff" width="20" height="20" />
                    </Button>
                  ) : (
                    <Button size="large" variant="contained" color="primary" type="submit">
                      <FormattedMessage id="signin.modal.button.login" defaultMessage="Login" />
                    </Button>
                  ) }
                </ButtonsContainer>
              </form>
              <ResetContainer>
                <Copy
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      {
                        id: 'signin.modal.reset.title',
                        defaultMessage: 'signin.modal.reset.title',
                      },
                      {
                        link: intl.formatMessage({
                          id: 'signin.modal.reset.link',
                          defaultMessage: 'signin.modal.reset.link',
                        }),
                      },
                    ),
                  }}
                />
              </ResetContainer>
            </Modal>
          </Container>
        </>
      )}
    </>
  );
};

export default SignIn;
