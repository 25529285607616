import { useState, createContext } from 'react';

export const NavContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [isNavVisible, setNavVisible] = useState(false);

  return (
    <NavContext.Provider value={[isNavVisible, setNavVisible]}>
      {children}
    </NavContext.Provider>
  );
};
