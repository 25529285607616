import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthenticationHeaders } from '../modules/fetch';
import { API } from '../config';

export const userApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({baseUrl: API}),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    users: builder.query({
      query: () => ({
        url: '/users/listings/1,999',
        headers: getAuthenticationHeaders('application/json'),
      }),
      providesTags: ['User']
    }),
    addUser: builder.mutation({
      query: (user) => ({
        url: '/users/create',
        method: 'POST',
        headers: getAuthenticationHeaders('application/json'),
        body: user
      }),
      invalidatesTags: ['User']
    }),
    updateUser: builder.mutation({
      query: ({id, ...rest}) => ({
        url: `/users/update/${id}`,
        method: 'PUT',
        headers: getAuthenticationHeaders('application/json'),
        body: rest
      }),
      invalidatesTags: ['User']
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/remove/${id}`,
        method: 'DELETE',
        headers: getAuthenticationHeaders('application/json'),
      }),
      invalidatesTags: ['User']
    })
  })
})

export const {
  useUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation
} = userApi
