import Logo, { Logos } from '../Logo';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MoneyIcon from '@mui/icons-material/Money';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Link, useLocation } from 'react-router-dom';
import { ImitierenButton } from '../Imitieren';
import { useContext, useEffect, useState } from 'react';
import { AccountDataContext } from '../../context/AccountDataContext';
import { NavContext } from '../../context/NavContext';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { DesktopOnly } from '../../constants/breakpoints';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CoinsContainer,
  Container,
  ContentContainer,
  InnerContainer,
  LeftContent,
  LinkTitle,
  Location,
  LocationContainer,
  MenuButton,
  MoreButton,
  NameContainer,
  Plans,
  PopupLayer,
  SearchButton,
  SearchInputContainer,
  ServiceLinks,
  StoreName,
  StyledBox,
  StyledButtonPlan,
  StyledIconButton,
  StyledPopup,
  UserContainer,
  UserInfo,
  UserName,
} from './styles';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import { ListItem, Modal } from '@mui/material';
import UserDataNav from '../../containers/UserDataNav';
import InboxQuery from '../InboxQuery';
import { retrieve } from '../../modules/storage';
import SearchIcon from '@mui/icons-material/Search';
import { GlobalUpdateQueryContext } from '../../context/GlobalUpdateQueryContext';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getData } from '../../modules/fetch';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

const Header = () => {
  const [currentAccount] = useContext<any>(AccountDataContext);
  const [isNavVisible, setNavVisible] = useContext<any>(NavContext);
  const location: string = useLocation().pathname;
  const [activeChat] = useContext(ActiveChatContext);
  const [openModal, setOpenModal] = useState(false);
  const [isContactsOpen, setIsContactsOpen] = useState(false);
  const [isOrdersOpen, setIsOrdersOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [globalUpdateQuery] = useContext(GlobalUpdateQueryContext);
  const [showSearch, setShowSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openLinks = Boolean(anchorEl);
  const [plans, setPlans] = useState([]);
  const [plansAmount, setPlansAmount] = useState(0);
  const [openCoinsPopup, setOpenCoinsPopup] = useState(false);
  const [openLimitsPopup, setOpenLimitsPopup] = useState(false);
  const [locationName, setLocationName] = useState(location.replaceAll('/', '_').toLowerCase());

  const openServiceLinks = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeServiceLinks = () => {
    setAnchorEl(null);
  };

  const closeServiceLinksOpenModal = () => {
    setAnchorEl(null);
    handleCoinsPopup();
  };

  const handleCloseModal = () => {
    setOpenLimitsPopup(false);
    setOpenCoinsPopup(false);
    setOpenModal(false);
    setIsContactsOpen(false);
    setIsOrdersOpen(false);
    setIsProductsOpen(false);
  };

  const handleCoinsPopup = () => {
    setOpenCoinsPopup(true);
  };

  const handleOpenLimitsPopup = () => {
    setOpenLimitsPopup(true);
  };

  const confirmTopUp = (topUpId) => () => {
    getData(`/subscriptions/stripe/checkout/${topUpId}`)
      .then((response) => {
        if (response?.data?.url)
          window.location.href = response?.data?.url;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (retrieve.string('token', null)) {
      getData('/subscriptions/listings')
        .then((data) => {
          setPlans(data.data);
          let len = 0;
          data.data.map((plan) => plan.type === 'top_up' && len++);
          setPlansAmount(len);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  useEffect(() => {
    let locationPath = location;
    if (location.startsWith('/messages')) {
      const paths = location.split('/');
      if (paths.length > 3)
        paths.pop();
      locationPath = paths.join('/');
    }

    const locationN = locationPath.replaceAll('/', '_').toLowerCase();
    setLocationName(locationN);
  }, [location]);

  let onePlan = (plans?.[0] ?? { _id: { '$oid': 'none' }, subscriptionTitle: 'none' })

  const PopupCoins = ({ children, ...props }) =>
    <Modal
      key={Math.random()}
      open={openCoinsPopup}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...props}
    >
      <StyledPopup
        key={Math.random()}
        isPreviewTicket={false}
        setIsPreviewTicket={false}
        isMedia={true}
        handleClose={handleCloseModal}
      >
        <PopupLayer>
          <h3><FormattedMessage id={`main.header.location.coins.popup.title`} /></h3>
          <br />
          <FormattedMessage id={`main.header.location.coins.popup.description`} />
          <br />
          <br />
          <Plans>
            {(plans && plansAmount > 1) ? plans.map((plan) => plan.type === 'top_up' &&
              <StyledButtonPlan key={`plan-topup-${plan._id.$oid}`} variant="contained" onClick={confirmTopUp(plan._id.$oid)}>
                <FormattedMessage
                  id={`plan.topup.${plan.subscriptionTitle}`}
                  defaultMessage={plan.subscriptionTitle}
                />
              </StyledButtonPlan>
            ) : (
              <StyledButtonPlan key={`plan-topup-${onePlan._id.$oid}`} color={'primary'} variant="contained" onClick={confirmTopUp(onePlan._id.$oid)}>
                <FormattedMessage
                  id={'plan.topup.one.product'}
                  defaultMessage={onePlan.subscriptionTitle}
                />
              </StyledButtonPlan>
            )}
          </Plans>
          {children}
        </PopupLayer>
      </StyledPopup>
    </Modal>

  const PopupLimits = ({ children, ...props }) => {
    const intl = useIntl();
    return (
      <Modal
        key={Math.random()}
        open={openLimitsPopup}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        {...props}
      >
        <StyledPopup
          key={Math.random()}
          isPreviewTicket={false}
          setIsPreviewTicket={false}
          isMedia={true}
          handleClose={handleCloseModal}
        >
          <PopupLayer>
            <h3><FormattedMessage id={`main.header.location.limits.popup.title`} /></h3>
            <br />
            <FormattedMessage id={`main.header.location.limits.popup.description`} />
            <br />
            <br />
            <Plans>
              <List>
                {Object.keys(currentAccount?.imitieren?.limits ?? currentAccount?.limits ?? {}).map((field, index) =>
                  <ListItem key={'main_header_location_limits_popup_fields-' + index}>
                    <ListItemText
                      primary={intl.formatMessage({
                        id: 'main.header.location.limits.popup.fields.' + field,
                        defaultMessage: field,
                      })}
                      secondary={(currentAccount?.imitieren?.limits?.[field] ?? currentAccount?.limits?.[field] ?? 0) === 0 ? intl.formatMessage({
                        id: 'main.header.location.limits.popup.unlimited',
                        defaultMessage: 'Unlimited',
                      }) : (currentAccount?.imitieren?.limits?.[field] ?? currentAccount?.limits?.[field] ?? 0)} />
                  </ListItem>
                )}
              </List>
            </Plans>
            {children}
          </PopupLayer>
        </StyledPopup>
      </Modal>
    )
  }

  return (
    <>
      {currentAccount?.viewsLevel < 255 ? (
        <Container>
          <PopupCoins>
            <></>
          </PopupCoins>
          <PopupLimits>
            <></>
          </PopupLimits>
          <MenuButton>
            <IconButton aria-label="menu" onClick={() => setNavVisible(!isNavVisible)}>
              {isNavVisible ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </MenuButton>
          <InnerContainer>
            {activeChat ? (
              showSearch ? (
                <SearchInputContainer>
                  <StyledIconButton
                    aria-label="search"
                    onClick={() => {
                      globalUpdateQuery.handleUpdateQuery(retrieve.string('inboxQuery'));
                    }}
                  >
                    <SearchIcon sx={{ color: '#E8E8E8' }} fontSize="small" />
                  </StyledIconButton>
                  <InboxQuery />
                  <StyledIconButton onClick={() => setShowSearch(false)} sx={{ color: '#E8E8E8' }}>
                    <CloseIcon fontSize="small" />
                  </StyledIconButton>
                </SearchInputContainer>
              ) : (
                <UserContainer>
                  <UserInfo onClick={() => setOpenModal(true)}>
                    <UserName>{activeChat?.name}</UserName>
                    <StoreName>{activeChat?.store?.name}</StoreName>
                  </UserInfo>
                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <StyledBox>
                      <UserDataNav
                        isContactsOpen={isContactsOpen}
                        isOrdersOpen={isOrdersOpen}
                        isProductsOpen={isProductsOpen}
                        setIsContactsOpen={setIsContactsOpen}
                        setIsOrdersOpen={setIsOrdersOpen}
                        setIsProductsOpen={setIsProductsOpen}
                      />
                    </StyledBox>
                  </Modal>
                  <SearchButton
                    aria-label="search"
                    onClick={() => setShowSearch(true)}
                    sx={{ top: 12, color: '#E8E8E8' }}
                  >
                    <SearchIcon />
                  </SearchButton>
                </UserContainer>
              )
            ) : (
              <>
                <LocationContainer>
                  {showSearch ? (
                    <SearchInputContainer>
                      <StyledIconButton
                        aria-label="search"
                        onClick={() => {
                          globalUpdateQuery.handleUpdateQuery(retrieve.string('inboxQuery'));
                        }}
                      >
                        <SearchIcon sx={{ color: '#E8E8E8' }} fontSize="small" />
                      </StyledIconButton>
                      <InboxQuery />
                      <StyledIconButton
                        onClick={() => setShowSearch(false)}
                        sx={{ color: '#E8E8E8' }}
                      >
                        <CloseIcon fontSize="small" />
                      </StyledIconButton>
                    </SearchInputContainer>
                  ) : (
                    <>
                      <Location>
                        <FormattedMessage
                          id={`main.header.location.${locationName}`}
                          values={{
                            location: location,
                          }}
                          defaultMessage={location}
                        />
                      </Location>
                      <SearchButton
                        aria-label="search"
                        onClick={() => setShowSearch(true)}
                        sx={{ color: '#E8E8E8' }}
                      >
                        <SearchIcon />
                      </SearchButton>
                    </>
                  )}
                </LocationContainer>
                <MoreButton
                  variant={'outlined'}
                  aria-label="service-links"
                  aria-controls={openLinks ? 'service-links-menu' : undefined}
                  aria-haspopup="true"
                  onClick={openServiceLinks}
                >
                  <MoreVertOutlinedIcon />
                </MoreButton>
                <Menu
                  id="service-links-menu"
                  anchorEl={anchorEl}
                  open={openLinks}
                  onClose={closeServiceLinks}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 35,
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem>
                    <LinkTitle>
                      <Link to="#" onClick={handleOpenLimitsPopup}>
                        <PersonOutlineOutlinedIcon />
                        &nbsp;
                        {currentAccount.name ?? currentAccount?.imitieren?.name ?? ''} {currentAccount.surname ?? currentAccount?.imitieren?.surname ?? ''} {currentAccount.company ?? currentAccount?.imitieren?.company ?? ''}
                      </Link>
                    </LinkTitle>
                  </MenuItem>
                  <MenuItem onClick={closeServiceLinks}>
                    <Link to="/support">
                      <LinkTitle>
                        <HelpOutlineIcon />
                        &nbsp;
                        <FormattedMessage
                          id={`main.header.location.support`}
                          defaultMessage={'Support'}
                        />
                      </LinkTitle>
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={closeServiceLinksOpenModal}>
                    <Link to="#">
                      <LinkTitle>
                        <MoneyIcon />
                        &nbsp;
                        <FormattedMessage
                          id={`main.header.location.coins`}
                          values={{
                            omcoins: Number(currentAccount?.omcoins ?? 0).toFixed(2),
                          }}
                        />
                      </LinkTitle>
                    </Link>
                  </MenuItem>
                  {currentAccount?.viewsLevel === 0 &&
                    <MenuItem onClick={closeServiceLinks}>
                      <LinkTitle>
                        <ImitierenButton>Impersonate</ImitierenButton>
                      </LinkTitle>
                    </MenuItem>
                  }
                </Menu>
              </>
            )}
          </InnerContainer>
          <DesktopOnly>
            <ContentContainer>
              <LeftContent>
                <Link to="/messages">
                  <Logo />
                </Link>
              </LeftContent>
              <CoinsContainer>
                <Link to="#" onClick={handleCoinsPopup}>
                  <FormattedMessage
                    id={`main.header.location.coins`}
                    values={{
                      omcoins: Number(currentAccount?.imitieren?.omcoins ?? currentAccount?.omcoins ?? 0).toFixed(2),
                    }}
                  />
                </Link>
                <Link to="#" onClick={handleOpenLimitsPopup}>
                  <NameContainer>{currentAccount.name ?? currentAccount?.imitieren?.name ?? ''} {currentAccount.surname ?? currentAccount?.imitieren?.surname ?? ''} {currentAccount.company ?? currentAccount?.imitieren?.company ?? ''}</NameContainer>
                </Link>
              </CoinsContainer>
              <ServiceLinks>
                <Link to="/support">
                  <HelpOutlineIcon />
                </Link>
                <ImitierenButton />
              </ServiceLinks>
            </ContentContainer>
          </DesktopOnly>
        </Container>
      ) : (
        <Container>
          <Link to="/sign-in">
            <Logos.Light />
          </Link>
        </Container>
      )}
    </>
  );
};

export default Header;
