import styled from 'styled-components';

export const Details = styled.div`
  display: flex;
  margin-top: 5px;
`;
export const Time = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #7a7a7a;
  text-transform: uppercase;
  margin-top: 3px;
  display: contents;
`;
export const WasRead = styled.div<{ isWasRead: boolean }>`
  margin-right: 5px;

  svg {
    font-size: 14px;
    color: ${(props) => (props.isWasRead ? '#109CF1' : '#7A7A7A')};
  }
`;
