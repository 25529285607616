import styled from 'styled-components';
import { UserPhoto } from '../../Avatar/styles';
import { Content } from '../styles';
import { MessageBox } from '../styles';

export const Copy = styled.div`
  max-width: 420px;
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 400;
  line-height: 18px;
  color: #050d42;
`;

export const Title = styled.div`
  margin-bottom: 15px;
  span {
    display: inline-block;
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
  .red {
    margin-right: 5px;
    color: red;
  }
  span:nth-child(2) {
    font-weight: 400;
    color: #050d42;
    text-decoration: underline;
  }
`;

export const UnsupportedMessage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  ${UserPhoto} {
    display: none;
  }

  ${Content} {
    align-items: center !important;
    font-weight: bold;
    font-size: 18px;
    ${MessageBox} {
      display: block;
      width: 100%;
      padding: 10px 20px;
      max-width: 420px;
      border: 1px solid #ecebeb;
      border-radius: 5px;
    }
    ${Copy} {
      background-color: transparent;
      border: none;
    }
  }

  ${Copy} {
    background-color: transparent;
  }
`;
