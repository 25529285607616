import { getInputDependedOptions, getInputDependedValues } from '../../../modules/getinput';
import { Title } from '../FormTemplateComponents/styles';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import { Container } from '../FormRow/styles';
import { StyledAutocomplete, StyledBox, StyledLabel } from './styles';

export const FormTemplateVariables = ({ input, inputValues, requiredFields, handleChange }) => {
  const value = { ...getInputDependedValues(input, inputValues, {}) };

  const options = getInputDependedOptions(input, inputValues)
  const data = getInputDependedOptions(input, inputValues, [], 'data')

  let header, body;
  let isHeader, isBody;
  if (data) {
    if ('HEADER' in data) {
      header = data.HEADER;
      isHeader = (header && header.length > 0);
    }
    if ('BODY' in data) {
      body = data.BODY;
      isBody = (body && body.length > 0);
    }
  }

  const handleFixChange = (prop) => (event, section, label) => {
    const v = typeof event?.target?.value === 'number' ? event?.target?.textContent : event?.target?.value;
    if (v) {
      value[section] = Object.assign({...value[section]}, { ...{ [label]: v } });
      handleChange(prop)(value);
    }
  }

  function getAutocomplete(section, label) {
    return (<StyledAutocomplete
        id={input.id}
        key={`getAutocomplete-${section}-${label}-${Math.random()}`}
        freeSolo={true}
        value={value?.[section]?.[label] || null}
        options={options}
        isOptionEqualToValue={option => value?.[section]?.[label] ? (option === value?.[section]?.[label]) : false}
        onInputChange={(event) => handleFixChange(input.id)(event, section, label)}
        onChange={(event) => handleFixChange(input.id)(event, section, label)}
        renderInput={(params) => (
          <TextField {...params} required={requiredFields[input.id]} label={label} />
        )}
      />);
  }

  return (
    <>
      <Container>
        <Title>
          {(header || body) && <FormattedMessage id={input?.label} defaultMessage={input?.label} />}
        </Title>
        <StyledBox>
          {isHeader && <label><FormattedMessage id={'template-variables.form.header'} /></label>}
          {isHeader && header?.map(
            label => getAutocomplete('header', label)
          )}
        </StyledBox>
        <StyledBox>
          {isBody && <label><FormattedMessage id={'template-variables.form.body'} /></label>}
          {isBody && body?.map(
            label => getAutocomplete('body', label)
          )}
        </StyledBox>
        {(!isHeader && !isBody) && (
          <StyledBox>
            <StyledLabel><FormattedMessage id={'template-variables.form.no-data'} /></StyledLabel>
          </StyledBox>
        )}
      </Container>
    </>
  )
};
export default FormTemplateVariables;
