const dotN = (target, path) => path.split('.').reduce((o, i) => o?.[i], target);

export const extractDependsOn = (input, inputValues) => {
  let dependsOnValue;
  if (typeof input?.dependsOn === 'string') {
    dependsOnValue = dotN(inputValues, input?.dependsOn);
  } else if (typeof input?.dependsOn === 'object' && Array.isArray(input?.dependsOn)) {
    input?.dependsOn.map(depOn => {
      const v = dotN(inputValues, depOn);
      if (v) {
        dependsOnValue = v;
      }
    })
  }

  return dependsOnValue;
};

export const getInputDependedValues = (input, inputValues, defaultValue: any = []) => {
  if (input?.dependsOn) {
    const dependsOnValue = extractDependsOn(input, inputValues);
    if (dependsOnValue) {
      return inputValues?.[input.id]?.[dependsOnValue] ?? inputValues?.[input.id] ?? defaultValue;
    }

    return defaultValue;
  }

  return inputValues?.[input.id] ?? (typeof inputValues === 'string' ? inputValues : defaultValue);
}

export const getInputDependedOptions = (input, inputValues, defaultValue: any = [], fieldName: string = 'options') => {
  if (input?.dependsOn) {
    const dependsOnValue = extractDependsOn(input, inputValues);
    if (typeof dependsOnValue == 'string') {
      return input?.[fieldName]?.[dependsOnValue] ?? input?.[fieldName] ?? defaultValue;
    }

    return defaultValue;
  }

  return input?.[fieldName] ?? defaultValue;
}

export const getInputDependedLabel = (input, inputValues, defaultValue: any = '', label: string = 'label') => {
  if (input?.dependsOn) {
    const dependsOnValue = extractDependsOn(input, inputValues);
    if (typeof dependsOnValue == 'string') {
      return input?.[label]?.[dependsOnValue] ?? input?.[label] ?? defaultValue;
    }

    return defaultValue;
  }

  return input?.[label] ?? defaultValue;
}
