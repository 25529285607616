interface StorageInterface {
  setItem(key: string, value: any): void;

  getItem(key: string): any | null;

  removeItem(key: string);

  clear();
}

class LocalStorage implements StorageInterface {
  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

abstract class AbstractStorage {
  readonly storage: StorageInterface;
  protected _cache: StorageInterface;

  constructor(storage: StorageInterface, cache: StorageInterface) {
    this.storage = storage;
    this._cache = cache;
  }
}

class MemoryCache implements StorageInterface {
  protected cache: any;

  constructor() {
    this.cache = {};
  }

  getItem(key: string): string | null {
    return this.cache?.[key] || null;
  }

  removeItem(key: string) {
    if (this.cache.hasOwnProperty(key)) {
      delete this.cache[key];
    }
  }

  setItem(key: string, value: string): void {
    this.cache[key] = value;
  }

  clear() {
    this.cache = {};
  }
}

class Write extends AbstractStorage {
  string(name: string, data: string) {
    this._cache.setItem(name, data);
    return this.storage.setItem(name, data)
  };

  number(name: string, data: number) {
    this._cache.setItem(name, data);
    return this.storage.setItem(name, String(data))
  };

  json(name: string, data: any) {
    try {
      this._cache.setItem(name, data);
      return this.storage.setItem(name, JSON.stringify(data));
    } catch (e) {
      return this.storage.setItem(name, data)
    }
  };
}

class Read extends AbstractStorage {
  string(name: string, defaultValue: string | null = ''): string {
    const cache = this._cache.getItem(name);
    return cache || (this.storage.getItem(name) || defaultValue)
  };

  number(name: string, defaultValue: number | null = null): number {
    const cache = this._cache.getItem(name);
    return (cache || ((Number(this.storage.getItem(name) || String(defaultValue))))) as number
  };

  json(name: string, defaultValue: {} | [] | string | null = '{}'): any {
    const cache = this._cache.getItem(name);
    if (cache) {
      return cache;
    }

    const item = this.storage.getItem(name);
    if (typeof defaultValue === 'string') {
      try {
        defaultValue = JSON.parse(defaultValue);
      } catch (e) {
      }
    }
    if (item) {
      try {
        return JSON.parse(item)
      } catch (e) {
      }
    } else {
      return defaultValue;
    }
    return item;
  };
}

class Unset extends AbstractStorage {
  key(name: string) {
    this._cache.removeItem(name);
    return this.storage.removeItem(name)
  };
  clear() {
    this._cache.clear();
    return this.storage.clear()
  };
}

const memoryCache = new MemoryCache();

export const store = new Write(new LocalStorage(), memoryCache);

export const retrieve = new Read(new LocalStorage(), memoryCache);

const unset = new Unset(new LocalStorage(), memoryCache);

export const cleanUp = (key: string) => {
  return unset.key(key);
}
export const clear = () => {
  return unset.clear();
}
