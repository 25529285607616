import { getData } from '../modules/fetch';

const getStoresConfig = (callback) => {
  getData('/stores/config')
    .then((data) => {
      callback(data.data as any);
    })
    .catch((err) => {
      console.error(err);
    });
};

export default getStoresConfig;
