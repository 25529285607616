import { Card, CardActionArea, CardMedia, Link } from '@mui/material';
import {
  StyledCardContent,
  StyledImage,
  StyledLabel,
  StyledPlayBox,
  StyledPlayCircleOutlineIcon,
} from '../../../../FormItems/FormTemplateComponents/styles';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import { StyledBox } from '../../../../FormItems/FormTemplateVariables/styles';
import WhatsappFormattedText from './whatsappFormattedText';

const ViewTemplate = ({ template }) => {
  let header;
  let body;
  let footer;

  if (template?.components) {
    template?.components.map(component => {
      if (component.type === 'HEADER') {
        header = component;
      }
      if (component.type === 'BODY') {
        body = component;
      }
      if (component.type === 'FOOTER') {
        footer = component;
      }
    });
  }

  return (
    <>
      {(header || body || footer) && (
        <Card sx={{ maxWidth: '100%' }}>
          {header && <CardActionArea>
            {header &&
              <StyledCardContent>
                {(header?.format === 'IMAGE') &&
                  (
                    <>
                      <StyledPlayBox>
                        <StyledImage src={header?.link?.url} />
                      </StyledPlayBox>
                    </>
                  )
                }
                {(header?.format === 'VIDEO') &&
                  (
                    <>
                      <StyledPlayBox>
                        <StyledPlayCircleOutlineIcon />
                      </StyledPlayBox>
                    </>
                  )
                }
                {(header?.format === 'DOCUMENT') &&
                  (
                    <>
                      <StyledPlayBox>
                        <CardMedia
                          sx={{
                            maxHeight: '140px',
                            maxWidth: '114px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            margin: 'auto'
                          }}
                          component="img"
                          height="140"
                          image="/img/PDF_file_icon.svg"
                          alt="green iguana"
                        />
                      </StyledPlayBox>
                    </>
                  )
                }
                {(header?.format === 'TEXT') &&
                  (
                    <Typography gutterBottom variant="h5" component="span">
                      <WhatsappFormattedText text={header?.text} />
                    </Typography>
                  )
                }
              </StyledCardContent>
            }
          </CardActionArea>}
          {header && header?.format !== 'TEXT' && (
            <>
              <Link href={header?.link?.url} target={'_blank'}>{header?.link?.name}</Link>
            </>
          )}
          <CardActionArea>
            {body &&
              <StyledCardContent>
                <Typography variant="body2" color="text.secondary" component="span">
                  {(body?.type === 'BODY') && (<WhatsappFormattedText text={body?.text} />)}
                </Typography>
              </StyledCardContent>
            }
          </CardActionArea>
          <CardActionArea>
            {footer &&
              <StyledCardContent>
                {(footer?.type === 'FOOTER') && (<WhatsappFormattedText text={footer?.text} />)}
              </StyledCardContent>
            }
          </CardActionArea>
        </Card>)}
      {(!header && !body && !footer) && (
        <StyledBox>
          <StyledLabel><FormattedMessage id={'template-components.form.no-data'} /></StyledLabel>
        </StyledBox>
      )}
    </>
  );
}

export default ViewTemplate;
