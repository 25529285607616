import { postData } from '../modules/fetch';
import { logMessage } from '../logger';

const postAccountRegister = (requestBody, successCallback, errorCallback, loadingCallback) => {
  postData('/account/register', requestBody)
  .then((data) => {
    logMessage(data);
    successCallback(true);
    loadingCallback(false);
  })
  .catch((err) => {
    console.error(err);
    errorCallback(err.message);
    loadingCallback(false);
  });
}

export default postAccountRegister;
