import { getData } from '../modules/fetch';

const getOrders = (callback) => {
  getData('/orders/listings/1,999')
    .then((data) => {
      callback(data);
    })
    .catch((err) => {
      console.error(err);
    });
}

export default getOrders;
