import { Content, MessageBox } from '../styles';
import { Copy, SystemMessage } from './styles';
import { linkify } from '../../../../../modules/linkify';
import { FormattedMessage } from 'react-intl';
import { Title } from '../Unsupported/styles';

export default ({ intl, message, more = null }) => {

  let previousDeliveryError = { message: '', details: { response: { message: '' } } };
  try {
    previousDeliveryError = JSON.parse(message?.rawResponse?.previousDeliveryError);
  } catch (e) {
    previousDeliveryError = { message: message?.rawResponse?.previousDeliveryError, details: { response: { message: '' } } };
  }

  const previousDeliveryErrorHtml = previousDeliveryError?.message ?? previousDeliveryError?.details?.response?.message ?? '';

  return (
    <SystemMessage key={message?._id?.$oid + message?.sendId}>
      <Content>
        <MessageBox>
          <Title>
            <span className="red">!</span>
            <span>
              <FormattedMessage
                id="messages.chat.messages.system"
                defaultMessage="System message:"
              />
            </span>
          </Title>
          <>
            {message?.body?.textId ? (
              <>
                <Copy dangerouslySetInnerHTML={{
                  __html: linkify(intl.formatMessage({
                    id: message?.body?.textId,
                    defaultMessage: message?.body?.textId,
                  }))
                }}
                />
                {message?.deliveryError && <Copy dangerouslySetInnerHTML={{ __html: message?.deliveryError ?? '' }} />}
                {(message?.lastSendError && (message?.lastSendError !== message?.deliveryError)) &&
                  <Copy dangerouslySetInnerHTML={{ __html: message?.lastSendError ?? '' }} />}
                <Copy dangerouslySetInnerHTML={{ __html: previousDeliveryErrorHtml }} />
              </>
            ) : (
              <Copy dangerouslySetInnerHTML={{ __html: linkify(message?.body?.text.replace(/\r\n|\r|\n/g, '<br />') || '') }} />
            )}
          </>
        </MessageBox>
        {more}
      </Content>
    </SystemMessage>
  );
};
