import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Content, Loader, MessageBox } from '../styles';
import { StyledMessage } from './styles';
import DeliveryStatus from '../../DeliveryStatus';
import LastErrorMessage from '../../LastErrorMessage';
import Avatar from '../../Avatar';
import ErrorMessage from '../../ErrorMessage';
import { BASE_URL } from '../../../../../config';

const Image = ({ client, message, resend, handleImagePopup, customerDataStoreTimezone, more = null }) => {
  return (
    <StyledMessage isClient={client} key={message?._id?.$oid + message?.sendId}>
      <Content>
        <MessageBox>
          {!message?.isClient && message?.lastSendError && (
            <IconButton aria-label="resend" onClick={() => resend(message?._id?.$oid)}>
              <RefreshIcon fontSize="small" />
            </IconButton>
          )}
          {(!message?.delivery ||
              message?.delivery === 'sending' ||
              message?.delivery === 'sent2api' ||
              message?.delivery === 'sent_to_api') &&
            !client && <Loader color="primary" src={'/img/loader-new.gif'} />}
          {/*<ImgContainer onClick={() => handleImagePopup(message?.body?.image?.url)}>*/}
          {message?.body?.image?.url ? (
            <>
              <Avatar message={message} isClient={client} />
              <img
                src={(message?.provider === 'whatsapp' ? BASE_URL : '') + message?.body?.image?.url}
                alt={message?.body?.image?.text || ''}
                onClick={() => handleImagePopup((message?.provider === 'whatsapp' ? BASE_URL : '') + message?.body?.image?.url)}
              />
            </>
          ) : (
            ''
          )}
          {/*</ImgContainer>*/}
          {more}
        </MessageBox>
        <DeliveryStatus
          isClient={client}
          message={message}
          customerDataStoreTimezone={customerDataStoreTimezone}
        />
        <LastErrorMessage message={message} />
        <ErrorMessage message={message?.rawResponse?.process_msg ?? ''} />
      </Content>
    </StyledMessage>
  );
};

export default Image;
