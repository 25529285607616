import { InboxStatesNames } from './InboxStatesNames';

export const InboxStates = [
  InboxStatesNames.OPEN,
  InboxStatesNames.OPEN_NEW,
  InboxStatesNames.OPEN_IN_PROGRESS,
  InboxStatesNames.FOLLOWUP,
  InboxStatesNames.ESCALATED,
  InboxStatesNames.RESOLVED,
];

export { InboxStates as default }
