import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Nav from '../../components/Nav';
import { PageContainer, Body } from '../../components/Layout';
import Settings from '../../containers/Settings';
import { mediaQueries } from '../../constants/breakpoints';
import Header from '../../components/Header';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryHandler, ErrorBoundaryFallback } from '../../components/ErrorBoundary';

const SettingsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 100%;
  overflow-y: auto;
  @media ${mediaQueries.desktop} {
    height: calc(100% - 30px);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    margin: 15px;
    border-radius: 15px;
  }
`;

const SettingsPage = () => {
  const [, setWidth] = useState(window.innerWidth);
  const params = useParams();
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={ErrorBoundaryHandler}
      >
        <Header />
      </ErrorBoundary>
      <PageContainer>
        <ErrorBoundary
          FallbackComponent={ErrorBoundaryFallback}
          onError={ErrorBoundaryHandler}
        >
          <Nav currentStep="settings" subStep={params.page} />
        </ErrorBoundary>
        <Body>
          <SettingsContainer>
            <ErrorBoundary
              FallbackComponent={ErrorBoundaryFallback}
              onError={ErrorBoundaryHandler}
            >
              <Settings />
            </ErrorBoundary>
          </SettingsContainer>
        </Body>
      </PageContainer>
    </>
  );
};

export default SettingsPage;
