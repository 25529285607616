import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import { FormattedMessage } from 'react-intl';
import { Container, Icons, StyledSelect, Table, Td, Th, Tr } from './styles';
import { Image } from './styles';
import { MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { setValuesFormTemplate } from '../../api/setValuesFormTemplate';
import { useFetchTemplateSurveyQuery } from '../../api/surveyAPI';
import Error from '../Error';
import { logMessage } from '../../logger';

const SurveysList = ({
  surveys,
  setInputValues,
  setRequiredFields,
  setCurrentSurveyId,
  setShowSurveyPopup,
  setShowConfirmActivation,
  setShowConfirmInactivation,
  setShowDeleteConfirmation,
  setShowUneditableAlert,
  setIsPreview,
}) => {
  const { data: templateSurvey, error: errorTemplate } = useFetchTemplateSurveyQuery(true);
  const findCurrentSurvey = (id) => {
    return surveys?.find((item) => item?._id?.$oid === id);
  };
  const handleChangeStatus = (id) => (value) => {
    logMessage(value, id);
    setCurrentSurveyId(id);
    value === 'active' ? setShowConfirmActivation(true) : setShowConfirmInactivation(true);
    setShowSurveyPopup(true);
  };
  const handlePreviewPopup = (id) => {
    const find = findCurrentSurvey(id);
    let temp = setValuesFormTemplate(templateSurvey?.data, find);
    setInputValues(temp.inputValues);
    setRequiredFields(temp.requiredFields);
    setIsPreview(true);
    setShowSurveyPopup(true);
  };
  const handleEditPopup = (id) => {
    const find = findCurrentSurvey(id);
    if (find.isEditable) {
      let temp = setValuesFormTemplate(templateSurvey?.data, find);
      setInputValues(temp.inputValues);
      setRequiredFields(temp.requiredFields);
    } else {
      setShowUneditableAlert(true);
    }
    setShowSurveyPopup(true);
  };
  const handleDeletePopup = (id) => {
    setCurrentSurveyId(id);
    setShowDeleteConfirmation(true);
    setShowSurveyPopup(true);
  };

  const handleCheck = (id) => {
    setInputValues(findCurrentSurvey(id));
  };

  return (
    <Container>
      {errorTemplate && 'error' in errorTemplate && <Error message={errorTemplate.error} />}
      <Table>
        <thead>
          <Tr>
            <Th></Th>
            <Th className="surveys-title">
              <FormattedMessage id="surveys.table.column.id" />
            </Th>
            <Th className="surveys-title">
              <FormattedMessage id="surveys.table.column.name" />
            </Th>
            <Th className={`surveys-title surveys-center`}>
              <FormattedMessage id="surveys.table.column.status" />
            </Th>
            <Th className="surveys-title surveys-center">
              <FormattedMessage id="surveys.table.column.replies" />
            </Th>
            <Th className="surveys-title surveys-center">
              <FormattedMessage id="surveys.table.column.scores" />
            </Th>
            <Th className="surveys-title">
              <FormattedMessage id="surveys.table.column.actions" />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {Array.isArray(surveys) &&
            surveys?.map((item, index) => (
              <Tr key={`surveys-row-${index}`}>
                <Td>
                  <Checkbox
                    color="primary"
                    value="agree"
                    onChange={() => handleCheck(item._id.$oid)}
                  />
                </Td>
                <Td className="surveys-id">{parseInt(item._id.$oid.slice(-6), 16)}</Td>
                <Td className="surveys-name">{item?.name}</Td>
                <Td className={`surveys-center surveys-status surveys-${item?.status}`}>
                  <StyledSelect
                    id="select-status-survey"
                    value={item?.status}
                    IconComponent={EditIcon}
                    onChange={(event) => handleChangeStatus(item._id.$oid)(event.target.value)}
                  >
                    <MenuItem value={'draft'} disabled>
                      draft
                    </MenuItem>
                    <MenuItem value={'active'}>active</MenuItem>
                    <MenuItem value={'inactive'}>inactive</MenuItem>
                  </StyledSelect>
                </Td>
                <Td className="surveys-center surveys-replies">{item?.replies}</Td>
                <Td className="surveys-center surveys-scores">{item?.score.toFixed(1)}</Td>
                <Td className="surveys-actions">
                  <Icons>
                    <IconButton aria-label="edit" onClick={() => handlePreviewPopup(item._id.$oid)}>
                      <VisibilityOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleEditPopup(item._id.$oid)}>
                      <Image src="/img/icon_edit.svg" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeletePopup(item?._id?.$oid)}
                    >
                      <Image src="/img/icon_trash_bin.svg" />
                    </IconButton>
                  </Icons>
                </Td>
              </Tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};
export default SurveysList;
