import { Content, ItemHead, Message } from '../styles';
import { CartList, CartTotal } from './styles';
import DeliveryStatus from '../../DeliveryStatus';
import LastErrorMessage from '../../LastErrorMessage';
import Avatar from '../../Avatar';
import Product from '../Product/item';
import { FormattedMessage } from 'react-intl';
import ErrorMessage from '../../ErrorMessage';

export default ({ client, message, customerDataStoreTimezone, more = null }) => {
  let total = 0;
  return (
    <Message isClient={client} key={message?._id?.$oid + message?.sendId}>
      <Content>
        <CartList>
          <Avatar message={message} isClient={client} />
          <ItemHead>
            <FormattedMessage
              id="messages.chat.messages.cart_list_title"
              defaultMessage="Your cart"
            />
          </ItemHead>
          {Array.isArray(message?.body?.cart)
            ? message?.body?.cart?.map((item, index) => {
                total += (item.product?.quantity || 1) * (item.product?.[0]?.price || 0);
                return (
                  <Product
                    product={item.product}
                    isAList={true}
                    isACart={true}
                    key={`${item.product?.productId}-productItem-${index}`}
                    infoPrice={item.product?.[0]?.price || 0}
                    infoQuantity={item.product?.quantity || 1}
                    infoCurrency={item.product?.rawData?.price_info?.[0]?.currency || ''}
                  />
                );
              })
            : ''}
          <CartTotal>
            <FormattedMessage id="messages.chat.messages.cart_total" defaultMessage="Total: " />
            <span>
              {' ' +
                total +
                ' ' +
                (message?.body?.cart?.[0]?.product?.rawData?.price_info?.[0]?.currency || '')}
            </span>
          </CartTotal>
        </CartList>
        {more}
        <DeliveryStatus
          isClient={client}
          message={message}
          customerDataStoreTimezone={customerDataStoreTimezone}
        />
        <LastErrorMessage message={message} />
        <ErrorMessage message={message?.rawResponse?.process_msg ?? ''} />
      </Content>
    </Message>
  );
};
