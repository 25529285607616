import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  border-radius: 6px;
  min-height: 100%;
  @media ${mediaQueries.desktop} {
    margin: 24px 0;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
export const Back = styled.div`
  display: flex;
  width: 50px;
  
  margin-left: 15px;
  margin-top: 15px;
  
  div {
    :first-child {
      justify-content: flex-start;
      width: 80px;
    }
  }

  button {
    width: 50px;
    height: 50px;
  }
`;

export const StoreName = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;
  
  @media ${mediaQueries.desktop} {
    font-size: xxx-large;
  }
  
  @media ${mediaQueries.mobile} {
    margin-top: 27px;
    margin-left: 25px;
    width: 300px;
    font-size: x-large;
  }
`;

