import styled from 'styled-components';
import { mediaQueries } from '../../../../../constants/breakpoints';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 420px;
  border: 1px solid #ecebeb;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 18px;
  color: #050d42;

  @media ${mediaQueries.mobile} {
    max-width: 250px;
  }
`;

export const Copy = styled.div`
  max-width: 400px;
  font-weight: 400;
  word-wrap: break-word;
  @media ${mediaQueries.mobile} {
    max-width: 250px;
  }
`;
