import { FormattedMessage } from 'react-intl';
import { Container, StyledList } from './styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useContext, useState } from 'react';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import Popup from '../Popup';
import ReportForm from '../../containers/ReportForm';
import { useReportsQuery } from '../../api/reportAPI';
import Error from '../Error';
import Timestamp from '../Timestamp';
import { logMessage } from '../../logger';

const CustomerTickets = ({ isPreview, setIsPreview, setIsPreviewTicket }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [activeChat] = useContext(ActiveChatContext);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const { data: listTickets, error: errorFetch } = useReportsQuery(activeChat?._id?.$oid);

  const handleListItemClick = (event, index) => {
    logMessage(event);
    setSelectedIndex(index);
    setSelectedTicket(listTickets?.data?.[index].report);
    setIsPreview(true);
    setShowPopup(true);
  };
  const closePopup = () => {
    setIsPreview(false);
    setIsPreviewTicket(false);
    setShowPopup(false);
  };
  return (
    <Container>
      {showPopup && (
        <Popup
          isPreviewTicket={true}
          setIsPreviewTicket={setIsPreviewTicket}
          handleClose={closePopup}
        >
          {(errorFetch && 'error' in errorFetch && <Error message={errorFetch.error} />) || (
            <ReportForm
              onClose={() => {
              }}
              isPreview={isPreview}
              setIsPreview={setIsPreview}
              isPreviewTicket={true}
              content={selectedTicket}
              customerId={activeChat._id.$oid}
            />
          )}
        </Popup>
      )}
      <h4>
        <FormattedMessage id="messages.customerTickets.title" defaultMessage="Tickets" />
      </h4>
      <StyledList>
        {Array.isArray(listTickets?.data) && listTickets?.data?.length > 0 ? (
          listTickets.data.map((item, index) => (
            <ListItemButton
              key={`ticket-${index}`}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
            >
              <ListItemText>{index + 1}. <Timestamp showDate={item.createdAt.date}
                                                    customerDataStoreTimezone={item.store?.timezone} /> {item.customer.assigneeName}</ListItemText>
            </ListItemButton>
          ))
        ) : (
          <h4>
            <FormattedMessage
              id="messages.customerTickets.no_tickets"
              defaultMessage="No Tickets"
            />
          </h4>
        )}
      </StyledList>
    </Container>
  );
};
export default CustomerTickets;
