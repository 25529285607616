export const COLORS_ARRAY = [
  '#d314ea',
  '#31b9c6',
  '#cb0d88',
  '#9c77f8',
  '#ff4f69',
  '#3e8efc',
  '#25a080',
  '#b20c7d',
  '#2adbfd',
  '#ec2e31',
];
