import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import { Button } from '../../containers/ReportForm/styles';

export const Title = styled.div`
  text-align: left;
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
  color: #050d42;
`;
export const TextFieldContainer = styled.div`
  margin: 10px 0 0;
  display: flex;
  width: 100%;

  :last-child > div {
    margin-right: 0px;
  }

  > div {
    flex-grow: 1;
    flex-wrap: wrap;
    margin-right: 15px;
    text-align: left;

    .MuiInputBase-root.MuiOutlinedInput-root {
      padding-right: 10px !important;
    }

    input {
      background: #fff !important;
      color: #050d42 !important;
      font-size: 1rem;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.5px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 2px solid #666666;
      border-radius: 5px;
    }
  }

  .textarea-copyable {
    margin-right: 0 !important;
  }
`;
export const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  margin: 20px 0px 10px;
  @media ${mediaQueries.desktop} {
    flex-direction: row;
    ::before {
      content: '';
      position: absolute;
      height: 0;
      width: calc(100% - 120px);
      top: 10px;
      left: 60px;
      border-top: 1px dashed #79747e;
    }
  }
`;

export const Step = styled.div<{ $isActive?: boolean; $isComplete?: boolean; $step?: number }>`
  position: relative;
  padding: 10px 0 10px 30px;
  color: #4f4f4f;
  font-size: 1rem;

  ::after {
    content: '${(props) => props.$step}';
    width: 20px;
    height: 20px;
    color: #5abb7a;
    border-radius: 50%;
    border: 1px solid #5abb7a;
    position: absolute;
    top: 7px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    left: 0;
    z-index: 2;
    ${(props) =>
      props.$isComplete
        ? `
        background: #5ABB7A url('/img/icon_check.svg') no-repeat center center;
        font-size: 0px;
      `
        : `background: #fff;`}
  }

  ${(props) =>
    props.$isActive
      ? `
      font-weight: bold;
    `
      : ''} @media ${mediaQueries.desktop} {
    width: 120px;
    text-align: center;
    padding: 40px 0 0 0;
    font-size: 12px;
    ::before {
      content: '';
      width: 40px;
      height: 20px;
      background: #fff;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
    ::after {
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }
`;
export const FormContainer = styled.div<{ $isPreview?: boolean; $isPreviewTicket?: boolean }>`
  ${(props) =>
    props.$isPreview || props.$isPreviewTicket
      ? `
    .step-content-0 .form-row-0 {
      padding-bottom: 10px;
      border-bottom: 1px solid #E8E8E8;
      padding-bottom: 10px;
    }
  `
      : ''}`;

export const SaveButton = styled(Button)`
  color: #fff;
  background: #006876;
`;
export const BackButton = styled(Button)`
  background: #fff;
  color: #006876;
`;
export const StyledButton = styled.button`
  display: block;
  margin-left: auto !important;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-decoration-line: underline;
  background: #fff;
  border: none !important;
  outline: none !important;
  color: #006876 !important;
  cursor: pointer;
`;
