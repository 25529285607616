import { LastError } from './styles';

const ErrorMessage = ({ message }) => {
  return (
    message && (
      <LastError>
        {message}
      </LastError>
    )
  );
};

export default ErrorMessage;
