import { Container, TextLabel } from './styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { getInputDependedOptions } from '../../../modules/getinput';

export const FormRadioGroup = ({ input, value, field, itemIndex, inputValues, setInputValues }) => {
  const intl = useIntl();
  const handleRadioChange = (prop) => (event) => {
    let copy = inputValues[field].slice();
    copy.splice(itemIndex, 1, { ...copy[itemIndex], [prop]: event.target.value });
    setInputValues({ ...inputValues, [field]: copy });
  };
  return (
    <Container>
      <TextLabel>
        <FormattedMessage id="surveys.form.text.answer_type" />
      </TextLabel>
      <RadioGroup
        id={`survey-question-radio-${itemIndex}`}
        row
        aria-labelledby="survey-radio-group"
        name="survey-radio-group"
        value={value}
        onChange={(event) => handleRadioChange(input.id)(event)}
      >
        {getInputDependedOptions(input, inputValues)?.map((item, i) => {
          {
            JSON.stringify(item[Object.keys(item)[0]]);
          }
          return (
            <FormControlLabel
              key={`survey-radio-group-${i}`}
              value={Object.keys(item)[0]}
              control={<Radio checked={value === Object.keys(item)[0]} />}
              label={intl.formatMessage({ id: item[Object.keys(item)[0]], defaultMessage: item[Object.keys(item)[0]] })}
            />
          );
        })}
      </RadioGroup>
    </Container>
  );
};
export default FormRadioGroup;
