import TextField from '@mui/material/TextField';
import { useIntl } from 'react-intl';
import { getInputDependedValues } from '../../../modules/getinput';

export const FormText = ({ input, inputValues, requiredFields, handleChange }) => {
  const intl = useIntl();

  let label = '';
  try {
    label = intl.formatMessage({ id: input?.label, defaultMessage: input?.label });
  } catch (e) {}

  return (
    <TextField
      id={input.id}
      label={label}
      required={requiredFields[input.id]}
      value={getInputDependedValues(input, inputValues, '')}
      type="text"
      variant="outlined"
      autoComplete="none"
      onChange={(event) => handleChange(input.id)(event.target.value)}
    />
  );
};
export default FormText;
