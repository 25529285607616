import { Text } from '../../../../FormItems/FormTemplateComponents/styles';

const WhatsappFormattedText = ({ text }) => {

  const formattedText = text
    .replaceAll(/\n/g, '<br />')
    .replaceAll(/(\*.*?\*)/g, (elem: string) => {
      elem = elem.replaceAll('**', '&#42;');
      elem = elem.replace('*', '<b>');
      elem = elem.replace('*', '</b>');
      return elem;
    })
    .replaceAll(/(_.*?_)/g, (elem: string) => {
      elem = elem.replaceAll('__', '&#95;');
      elem = elem.replace('_', '<i>');
      elem = elem.replace('_', '</i>');
      return elem;
    });

  return (
    <>
      <Text dangerouslySetInnerHTML={{
        __html: formattedText
      }} />
    </>
  );
}

export default WhatsappFormattedText;
