import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Item from './Item';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import Pagination from '@mui/material/Pagination';
import isJsonString from '../../modules/isJsonString';
import { fromNow } from '../../modules/renderDate';
import MessageType from '../../constants/MessageType';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;

const Panel = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const StyledPagination = styled(Pagination)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px 0;
  position: sticky;
  bottom: 0;
  background: #fff;
`;

const InboxItems = ({
  items,
  handleChatOpen,
  stores,
  type,
  handlePageChange,
  currentPage,
  amount,
  query,
  openSmallChat,
}) => {
  const [isMenuOpen] = useState(false);
  const [activeChat] = useContext(ActiveChatContext);
  const [, setTime] = useState(Date.now());
  const pagesAmount = Math.floor(amount / 100) + 1;
  const panelRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const scrollInboxTop = () => {
    if (panelRef.current) {
      panelRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const getOldShortMessage = (item) => {
    let shortMsg: string = '';
    if (item?.message?.content && isJsonString(item?.message?.content)) {
      try {
        if (item?.message?.type === 'image') {
          shortMsg = 'Image';
        } else {
          const json = JSON.parse(item?.message?.content);
          if (json?.imgUrl) {
            shortMsg = 'Image';
          } else {
            shortMsg = json?.txt || json?.text;
          }
        }
      } catch (e) {
        shortMsg = '...';
      }
    } else {
      shortMsg = item?.message?.content;
    }
    shortMsg = shortMsg?.replace(/(.{40})..+/, '$1…');
    try {
      const messageContent = JSON.parse(shortMsg);
      if (messageContent.hasOwnProperty('text') && !isJsonString(messageContent?.text))
        shortMsg = messageContent?.text;
      if (messageContent.hasOwnProperty('txt') && !isJsonString(messageContent?.txt))
        shortMsg = messageContent?.txt;
    } catch (e) {}

    return shortMsg;
  };

  const handleOpenSmallChat = (item) => {
    openSmallChat(item);
  };

  const handleCurrentItem = (item, index) => {
    let teaser: string = '';
    let teaserValues: Record<string, string> = {};
    if (item?.message?.body) {
      teaser = item?.message.type;
      switch (item?.message?.type) {
        case MessageType.AUTO_REPLY:
        case MessageType.SYSTEM:
        case MessageType.ERROR:
        case MessageType.TEXT:
        case MessageType.SURVEY_INTRO:
        case MessageType.SURVEY_QUESTION:
        case MessageType.SURVEY_ANSWER:
          teaserValues = { text: item?.message?.body?.text };
          break;
        case MessageType.REFUND_REQUEST:
        case MessageType.ORDER:
          teaserValues = { id: item?.message?.body?.order?.orderId };
          break;
        case MessageType.PRODUCT:
          teaserValues = { id: item?.message?.body?.product?.productId };
          break;
        case MessageType.VOUCHER:
          teaserValues = { id: item?.message?.body?.voucher?.voucherId };
          break;
        case MessageType.PRODUCT_LIST:
        case MessageType.IMAGE:
        case MessageType.VIDEO:
        case MessageType.STICKER:
        case MessageType.CART_LIST:
        case MessageType.UNSUPPORTED:
        default:
          break;
      }
    }
    let shortMsg: string = '';
    if (item?.message?.type === undefined) shortMsg = String(getOldShortMessage(item));
    let msgTime = '';
    if (item?.message?.time) {
      msgTime = fromNow(item?.message?.time);
    }
    const currentStore = stores?.find((store) => store?._id.$oid === item?.storeId);

    return (
      <Item
        key={`inbox-item-${index}`}
        index={index}
        item={item}
        activeChat={activeChat}
        teaser={teaser}
        teaserValues={teaserValues}
        shortMsg={shortMsg}
        currentStore={currentStore}
        msgTime={msgTime}
        isMenuOpen={isMenuOpen}
        handleChatOpen={handleChatOpen}
        handleOpenSmallChat={handleOpenSmallChat}
      />
    );
  };

  return (
    <Container>
      <Panel ref={panelRef}>
        {items.map((item, index) => handleCurrentItem(item, index))}
        {pagesAmount > 1 ? (
          <StyledPagination
            count={pagesAmount}
            page={currentPage}
            siblingCount={0}
            onChange={(value, event) => {
              handlePageChange(type, event, value, query);
              scrollInboxTop();
            }}
          />
        ) : null}
      </Panel>
    </Container>
  );
};

export default InboxItems;
