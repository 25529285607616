import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { mediaQueries } from '../../constants/breakpoints';
import List from '../../components/StoresList';
import { FormattedMessage, useIntl } from 'react-intl';
import getStoresConfig from '../../api/getStoresConfig';
import WhitePopup from '../../components/WhitePopup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { postData } from '../../modules/fetch';
import Error from '../../components/Error';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 20px;
  background: #fff;
  border-radius: 6px;
  @media ${mediaQueries.desktop} {
    margin: 24px 80px;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin: 20px 20px 0;
`;
const Actions = styled.div`
  display: flex;
  margin: 20px 20px 0;
  flex-direction: column;
  @media ${mediaQueries.desktop} {
    flex-direction: row;
  }
`;
const SearchInputContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  flex: 1;
  display: flex;
  margin-bottom: 10px;
  @media ${mediaQueries.desktop} {
    margin-right: 10px;
    margin-bottom: 0;
    max-width: 300px;
  }
`;
const Input = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  outline: none;
`;

export const PopupLayer = styled.div`
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
`;

const Stores = ({ data }) => {
  const [platform, setPlatform] = useState('all');
  const [storesList, setStoresList] = useState(data);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [registrationPopup, setRegistrationPopup] = useState(false);
  const [storeId, setStoreId] = useState('');
  const [errorRegister, setErrorRegister] = useState(null);
  const [pin, setPin] = useState(0);
  const intl = useIntl();

  const handlePlatformChange = (event) => {
    setPlatform(event.target.value);
    const askPlatform = event.target.value !== 'all' ? event.target.value : '';
    setItems(filterBySearch(search, askPlatform));
  };

  const onChangeSearch = (event) => {
    setSearch(event.target.value);
    setItems(filterBySearch(event.target.value, platform));
  };
  const filterBySearch = (query, askPlatform) => {
    const stores = storesList?.filter((item) => {
      return askPlatform !== '' && askPlatform !== 'all' ? item.provider === askPlatform : true;
    });
    if (query) {
      const regex = new RegExp(query, 'iu');
      return stores?.filter((item) => {
        return regex.test(item?.name) || regex.test(item?.countryCode);
      });
    } else {
      return stores;
    }
  };

  const getStoresConfigSuccess = (config) => {
    const storesListUpdated = data?.map((item) => {
      const currentShop = item.provider;
      const connections = config[currentShop].filter(
        (configItem) => !item.apps[configItem.app_id] && configItem.app_id,
      );
      let connectionMessage = '';
      let connectionStatus = '';
      const total = config[currentShop].length;
      const left = connections.length;
      if (left ===  total) {
        connectionMessage = `not connected (0/${total})`;
        connectionStatus = 'notConnected';
      } else if (left === 0) {
        connectionMessage = `connected (${total}/${total})`;
        connectionStatus = 'connected';
      } else {
        connectionMessage = `in progress (${total - left}/${total})`;
        connectionStatus = 'inProgress';
      }
      const configuration = {
        ...item,
        connectionStatus,
        connectionMessage,
        nextConnectionURL: connections?.[0]?.auth_url || '',
      };

      return configuration;
    });
    setStoresList(storesListUpdated);
    setItems(storesListUpdated);
  };

  useEffect(() => {
    getStoresConfig(getStoresConfigSuccess);
  }, [data]);

  const handleCloseRegistrationPopup = () => {
    setPin(0);
    setErrorRegister(null);
    setRegistrationPopup(false);
  }

  const handleRegistrationPopup = (storeId) => () => {
    setStoreId(storeId);
    setRegistrationPopup(true);
  }

  const handleRegistration = () => {
    postData('/platform/whatsapp/phone/register/' + storeId, { pin })
      .then(() => {
        handleCloseRegistrationPopup();
      }).catch((error) => {
      setErrorRegister(error);
    });
  }

  return (
    <>
      {registrationPopup && (
        <WhitePopup handleClose={handleCloseRegistrationPopup} isMedia={true}>
          <PopupLayer>
            <TextField
              id={'pin'}
              label={'Pin'}
              required={true}
              value={pin}
              type="text"
              variant="outlined"
              autoComplete="none"
              sx={{ width: '300px' }}
              onChange={(event) => setPin(isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value))}
              inputProps={{ maxLength: 6 }}
            />
            <br />
            <Button
              sx={{
                marginTop: '10px',
                width: '300px'
              }}
              onClick={handleRegistration}
              color={'primary'}
              variant={'outlined'}
            >
              <VpnKeyIcon />
              &nbsp;
              <FormattedMessage
                id="storeManagement.stores.pin.register"
                defaultMessage="Complete registration"
              />
            </Button>
            {(errorRegister && 'error' in errorRegister) && <Error message={errorRegister.error} />}
            {(errorRegister && 'message' in errorRegister) && <Error message={errorRegister.message} />}
          </PopupLayer>
        </WhitePopup>
      )}
      <Container>
        <Title>
          <FormattedMessage
            id="storeManagement.stores.title"
            defaultMessage="Your connected stores"
          />
        </Title>
        <Actions>
          <SearchInputContainer>
            <IconButton aria-label="search">
              <SearchIcon fontSize="small" />
            </IconButton>
            <Input
              type="text"
              placeholder={intl.formatMessage({
                id: 'storeManagement.stores.input_search',
                defaultMessage: 'storeManagement.stores.input_search',
              })}
              onChange={onChangeSearch}
            />
          </SearchInputContainer>
          <FormControl variant="outlined">
            <Select
              labelId="platform-label"
              id="platform"
              value={platform}
              onChange={handlePlatformChange}
            >
              <MenuItem value="all">
                <FormattedMessage
                  id="storeManagement.stores.menu_all"
                  defaultMessage="All Platforms"
                />
              </MenuItem>
              <MenuItem value="shopee">
                <FormattedMessage id="storeManagement.stores.menu_shopee" defaultMessage="Shopee" />
              </MenuItem>
              <MenuItem value="lazada">
                <FormattedMessage id="storeManagement.stores.menu_lazada" defaultMessage="Lazada" />
              </MenuItem>
              <MenuItem value="whatsapp">
                <FormattedMessage id="storeManagement.stores.menu_whatsapp" defaultMessage="WhatsApp" />
              </MenuItem>
            </Select>
          </FormControl>
        </Actions>
        <List data={items} handleRegistrationPopup={handleRegistrationPopup} />
      </Container>
    </>
  );
};

export default Stores;
