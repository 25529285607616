import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import Select from '@mui/material/Select';

export const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
  @media ${mediaQueries.desktop} {
    overflow-x: hidden;
  }
`;
export const Icons = styled.div`
  white-space: nowrap;
  svg {
    color: #c5c4ca;
    cursor: pointer;
  }
`;
export const Image = styled.img`
  color: #6f6f6f;
  cursor: pointer;
`;
export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;

export const Tr = styled.tr`
  .surveys-title {
    color: #050d42;
  }

  .surveys-center {
    text-align: center;
  }

  .surveys-name {
    color: #6e6b7b;
  }

  .surveys-active {
    .MuiSelect-select.MuiInputBase-input {
      color: #5abb7a;
    }
  }

  .surveys-draft {
    .MuiSelect-select.MuiInputBase-input {
      color: #ffa800;
    }
  }

  .surveys-inactive {
    .MuiSelect-select.MuiInputBase-input {
      color: #db4437;
    }
  }
`;
export const Th = styled.th`
  background: #f3f2f7;
  padding: 15px 20px;
  border-bottom: 1px solid #e9ecef;
  text-align: left;
  color: #050d42;
  font-size: 1rem;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  font-weight: 500;

  :first-child {
    width: 50px;
  }

  :last-child {
    width: 150px;
  }
`;
export const Td = styled.td`
  padding: 15px 20px;
  border-bottom: 1px solid #e9ecef;

  :first-child {
    width: 50px;
  }

  :last-child {
    width: 150px;
  }
`;
export const StyledSelect = styled(Select)`
  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }

  .MuiButtonBase-root.MuiMenuItem-root {
    background-color: red !important;

    span {
      background-color: red !important;
    }
  }

  svg {
    color: #9e9c9c;
    font-size: 14px !important;
    transform: none !important;
    transition: none !important;
  }
`;
