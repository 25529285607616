import { Card, CardActionArea, CardMedia, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import { extractDependsOn, getInputDependedOptions, getInputDependedValues } from '../../../modules/getinput';
import {
  StyledAddPhotoAlternateOutlinedIcon,
  StyledCardContent,
  StyledImage,
  StyledLabel,
  StyledPlayBox,
  StyledPlayCircleOutlineIcon,
  StyledTailSpin,
  Text,
  Title
} from './styles';
import { FormattedMessage } from 'react-intl';
import { StyledBox } from '../FormTemplateVariables/styles';
import { getError, postImage } from '../../../modules/fetch';
import { FilePicker } from 'react-file-picker';
import { useState } from 'react';

export const FormTemplateComponents = ({ input, inputValues, handleChange }) => {
  // const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const value = { ...getInputDependedValues(input, inputValues, {}) };
  const options = getInputDependedOptions(input, inputValues)

  let header, body, footer;
  if (options?.components) {
    options?.components.map(component => {
      if (component.type === 'HEADER') {
        header = component;
      }
      if (component.type === 'BODY') {
        body = component;
      }
      if (component.type === 'FOOTER') {
        footer = component;
      }
    });
  }

  const handleImportUpload = (file) => {
    const formData = new FormData();
    formData.append('image', file);
    const dependsOnValue = extractDependsOn(input, inputValues);
    const storeId = String(dependsOnValue).split(':')[0];

    setLoading(true);
    postImage(`/files/s3/image/${storeId}?privacy=public`, formData)
      .then((response) => {
        const v = response.data;
        if (v) {
          value['header'] = Object.assign({ ...value['header'] }, { ...{ ['link']: v } });
          handleChange(input.id)(value);
        }
      })
      .catch((responseError) => {
        const exception = getError(responseError.exception);
        alert(exception.details);
        console.error(responseError);
      }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      {(header || body || footer) && (
        <Card sx={{ maxWidth: '100%' }}>
          <Title>
            {(header || body || footer) && <FormattedMessage id={input?.label} defaultMessage={input?.label} />}
          </Title>
          {header && (<label><FormattedMessage id={'template-components.form.header'} defaultMessage="Header" /> ({header?.format})</label>)}
          {header && <CardActionArea>
            {header &&
              <StyledCardContent>
                {(header?.format === 'IMAGE') &&
                  (
                    <>
                      <FilePicker
                        extensions={['jpg', 'jpeg', 'png']}
                        onChange={(FileObject) => handleImportUpload(FileObject)}
                        onError={(errMsg) => console.warn({ errMsg })}
                      >
                        <StyledPlayBox>
                          {loading
                            ? (<StyledTailSpin color="#999" height="125" width="100%" />)
                            : (value?.header?.link?.url ? (
                              <StyledImage src={value?.header?.link?.url} />
                            ) : (
                              <StyledAddPhotoAlternateOutlinedIcon />
                            ))
                          }
                        </StyledPlayBox>
                      </FilePicker>
                    </>
                  )
                }
                {(header?.format === 'VIDEO') &&
                  (
                    <>
                      <FilePicker
                        extensions={['mp4']}
                        onChange={(FileObject) => handleImportUpload(FileObject)}
                        onError={(errMsg) => console.warn({ errMsg })}
                      >
                        <StyledPlayBox>
                          {loading
                            ? (<StyledTailSpin color="#999" height="125" width="100%" />)
                            : (<StyledPlayCircleOutlineIcon />)}
                        </StyledPlayBox>
                      </FilePicker>
                    </>
                  )
                }
                {(header?.format === 'DOCUMENT') &&
                  (
                    <>
                      <FilePicker
                        extensions={['pdf']}
                        onChange={(FileObject) => handleImportUpload(FileObject)}
                        onError={(errMsg) => console.warn({ errMsg })}
                      >
                        <StyledPlayBox>
                          {loading
                            ? (<StyledTailSpin color="#999" height="125" width="100%" />)
                            : (<CardMedia
                              sx={{
                                maxHeight: '140px',
                                maxWidth: '114px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                justifyItems: 'center',
                                margin: 'auto'
                              }}
                              component="img"
                              height="140"
                              image="/img/PDF_file_icon.svg"
                              alt="green iguana"
                            />)}
                        </StyledPlayBox>
                      </FilePicker>
                    </>
                  )
                }
                {(header?.format === 'TEXT') &&
                  (
                    <Typography gutterBottom variant="h5" component="span">
                      <Text>{header?.text}</Text>
                    </Typography>
                  )
                }
              </StyledCardContent>
            }
          </CardActionArea>}
          {header && header?.format !== 'TEXT' && (
            <>
              <label><FormattedMessage id={'template-components.form.link.title'} defaultMessage={'Link'} /></label>
              <br />
              <Link href={value?.header?.link?.url} target={'_blank'}>{value?.header?.link?.name}</Link>
              <br /><br />
            </>
          )}
          <CardActionArea>
            {body && <label><FormattedMessage id={'template-components.form.body'} /></label>}
            {body &&
              <StyledCardContent>
                <Typography variant="body2" color="text.secondary" component="span">
                  {(body?.type === 'BODY') && (<Text>{body?.text}</Text>)}
                </Typography>
              </StyledCardContent>
            }
          </CardActionArea>
          <CardActionArea>
            {footer && <label><FormattedMessage id={'template-components.form.footer'} /></label>}
            {footer &&
              <StyledCardContent>
                {(footer?.type === 'FOOTER') && (<Text>{footer?.text}</Text>)}
              </StyledCardContent>
            }
          </CardActionArea>
        </Card>)}
      {(!header && !body && !footer) && (
        <StyledBox>
          <StyledLabel><FormattedMessage id={'template-components.form.no-data'} /></StyledLabel>
        </StyledBox>
      )}
    </>
  )
};
export default FormTemplateComponents;
