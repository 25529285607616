import styled from 'styled-components';

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  flex: 1;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 420px;
  border: 1px solid #ecebeb;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 18px;
  color: #050d42;
`;
export const Copy = styled.div`
  max-width: 400px;
  font-weight: 400;
  word-wrap: break-word;
`;
export const MessageCopy = styled.div`
  min-width: 100%;
  border-bottom: darkgrey dashed thin;
`;
