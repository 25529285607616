import styled from 'styled-components';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const Container = styled.div`
  width: 274px;
  margin-left: 15px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
`;

export const Head = styled.div`
  width: 100%;
  height: 49px;
  display: flex;
  background-color: #050D42;
  border-radius: 6px 6px 0 0;
  padding: 7px 0 10px 17px;
  cursor: pointer;
`;

export const InitialsBadge = styled.div`
  width: 15px;
  height: 15px;
  color: #fff;
  background-color: #050d42;
  border-radius: 50%;
  font-size: 8px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
`;


export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const Initials = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`;

export const InfoItem = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding-left: 15px;
`;

export const Name = styled.div`
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  color: #fff;
  margin-top: 3px;
`;

export const OwnerName = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  color: #fff;
`;

export const Body = styled.div`
  height: 370px;
  overflow-y: auto;
  padding: 0 10px 10px;
  background: #fff;
`;

export const MessagesEnd = styled.div`
  background: transparent;
  height: 0;
  width: 100%;
`;

export const Footer = styled.div`
  margin: 0 10px 10px;
  padding-top: 10px;
  border-top: 1px solid #f5f5f5;
`;

export const Nav = styled.div`
  display: flex;
  margin-right: 10px;
`;

export const NavButton = styled(IconButton)`
  color: #FFF;
  padding: 8px 2px !important;
  svg {
    width: 20px;
    height: 20px;
    color: #FFF;
    &.fullscreen {
      width: 15px;
      height: 15px;  
    }
  }
`;

export const Responses = styled.div`
  overflow-x: auto;
  width: 100%;

  button {
    margin: 0 10px 10px 0;
    padding: 2px 15px;
    text-transform: none;
    font-weight: 700;
    font-size: 1rem;
    white-space: nowrap;
    border: 1px solid #6e6e6e !important;
    border-radius: 8px !important;
    color: #050d42 !important;
  }
`;
export const ResponsesContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  overflow-x: auto;
  flex-direction: row;
  align-items: start;
  button {
    display: block;
    border: 1px solid #6e6e6e;
    border-radius: 8px;
    color: #050d42;
    background: transparent;
    padding: 5px 15px;
    text-transform: none;
    font-weight: 700;
    font-size: 1rem;
    cursor: pointer;
  }
`;
