export enum EnumInboxStates {
  open = 0,
  new = 1,
  inProgress = 2,
  followup = 3,
  escalated = 4,
  resolved = 5,
}

export { EnumInboxStates as default };
