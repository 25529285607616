import styled from 'styled-components';
import { AuthorName } from '../styles';

export const StyledAuthorName = styled(AuthorName)`
  align-self: center !important;
`;
export const ProductsList = styled.div`
  border-radius: 5px;
  > div:last-child > div {
    border-radius: 0 0 5px 5px;
  }
`;
