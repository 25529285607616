import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Content, Loader, Message } from '../styles';
import { Copy, TextContainer } from './styles';
import { linkify } from '../../../../../modules/linkify';
import DeliveryStatus from '../../DeliveryStatus';
import LastErrorMessage from '../../LastErrorMessage';
import ErrorMessage from '../../ErrorMessage';
import Avatar from '../../Avatar';

export default ({ client, message, resend, customerDataStoreTimezone, more = null }) => {
  return (
    <Message isClient={client} key={message?._id?.$oid + message?.sendId}>
      <Content>
        <TextContainer>
          <div>
            {!message?.isClient && message?.lastSendError && (
              <IconButton aria-label="resend" onClick={() => resend(message?._id?.$oid)}>
                <RefreshIcon fontSize="small" />
              </IconButton>
            )}
            {(!message?.delivery ||
                message?.delivery === 'sending' ||
                message?.delivery === 'sent2api' ||
                message?.delivery === 'sent_to_api') &&
              !client && <Loader color="primary" src={'/img/loader-new.gif'} />}
            {message?.body?.text && (
              <>
                <Avatar message={message} isClient={client} />
                <Copy
                  dangerouslySetInnerHTML={{
                    __html: linkify(message?.body?.text.replace(/\r\n|\r|\n/g, '<br />') || ''),
                  }}
                />
              </>
            )}
          </div>
        </TextContainer>
        {more}
        <DeliveryStatus
          isClient={client}
          message={message}
          customerDataStoreTimezone={customerDataStoreTimezone}
        />
        <LastErrorMessage message={message} />
        <ErrorMessage message={message?.rawResponse?.process_msg ?? ''} />
      </Content>
    </Message>
  );
};
