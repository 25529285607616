import { useContext, useEffect, useState } from 'react';
import Error from '../../components/Error';
import { DataGrid } from '@mui/x-data-grid';
import { FormattedMessage, useIntl } from 'react-intl';
import { useContactsQuery, useDeleteContactMutation, useFetchTemplateQuery, useUpdateContactMutation } from '../../api/contactsAPI';
import { setValuesFormTemplate } from '../../api/setValuesFormTemplate';
import Popup from '../../components/Popup';
import ContactForm from '../../containers/ContactForm';
import {
  BackButton,
  ButtonsContainer,
  Container,
  ModalTitle,
  SaveButton,
  StyledBox,
  StyledButton,
  StyledIconButton,
  Tag,
  Text,
  Title,
  TitleContainer,
  Wrapper
} from './styles'
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getLogo } from '../../api/getLogo';
import { Box } from '@mui/material';
import { FilePicker } from 'react-file-picker';
import { getError, getInNewWindow, postImage } from '../../modules/fetch';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { GlobalLoadingContext } from '../../context/GlobalLoadingContext';
import { logMessage } from '../../logger';

const Contacts = () => {
  const intl = useIntl();
  const [inputValues, setInputValues] = useState({});
  const [currentContactId, setCurrentContactId] = useState(null);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [, setLoading] = useContext(GlobalLoadingContext);
  const { data: templateContact, error: errorTemplate, isLoading } = useFetchTemplateQuery(true);
  const [deleteContact, { error: errorDelete }] = useDeleteContactMutation();
  const { data: contacts, error: errorQuery, refetch: refetchContacts } = useContactsQuery({ page: 1, perPage: 100000 });
  const [updateContact, { error: errorUpdate }] = useUpdateContactMutation();
  const [requiredFields, setRequiredFields] = useState({});

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading])

  const handleFormOpen = () => {
    let temp = setValuesFormTemplate(templateContact?.data, null, () =>
      Object.assign(
        {},
        {},
      ),);
    setInputValues(temp.inputValues);
    setRequiredFields(temp.requiredFields);
    setShowContactPopup(true);
  };
  const closePopupAndClear = () => {
    setCurrentContactId(null);
    setShowDeleteConfirmation(false);
    setShowContactPopup(false);
  };
  // @ts-ignore
  const handleChange = async (id, status) => {
    await updateContact({
      id: id,
      contact: {
        status: status === 'active' ? 'inactive' : 'active',
      },
    });
  }
  const findCurrentContact = (id) => {
    return contacts?.data?.find((item) => item?._id?.$oid === id);
  };
  const handleEditPopup = (id) => {
    const find = { ...findCurrentContact(id), isEditable: true }
    let temp = setValuesFormTemplate(templateContact?.data, find);
    setInputValues(temp.inputValues);
    setRequiredFields(temp.requiredFields);
    setShowContactPopup(true);
  }
  const handleDelete = () => {
    deleteContact(currentContactId);
    closePopupAndClear();
  }

  const handleDeletePopup = (id) => {
    setCurrentContactId(id);
    setShowDeleteConfirmation(true);
    setShowContactPopup(true);
  };

  const handleImportUpload = (file) => {
    const formData = new FormData();
    formData.append('contacts', file);

    setLoading(true);
    postImage(`/contacts/import`, formData)
      .then((response) => {
        logMessage('response', response);
        refetchContacts();
      })
      .catch((responseError) => {
        const exception = getError(responseError.exception);
        alert(intl.formatMessage({ id: exception.details, defaultMessage: exception.details }));
        console.error(responseError);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExport = () => {
    getInNewWindow('/contacts/export');
  };

  return (
    <Wrapper>
      {showContactPopup && (
        <Popup
          isPreviewTicket={false}
          setIsPreviewTicket={() => {
          }}
          handleClose={closePopupAndClear}
        >
          <ModalTitle>
            <FormattedMessage id="contact.form.title" defaultMessage="Contacts" />
          </ModalTitle>
          {(showDeleteConfirmation && (
            <>
              <Text>
                <FormattedMessage id="contacts.modal.delete.confirm" />
              </Text>
              <ButtonsContainer>
                <BackButton variant="outlined" onClick={closePopupAndClear}>
                  <FormattedMessage id="contacts.popup.button.no" />
                </BackButton>
                <SaveButton variant="contained" color="primary" onClick={handleDelete}>
                  <FormattedMessage id="contacts.popup.button.yes" />
                </SaveButton>
              </ButtonsContainer>
            </>
          )) || (
            <ContactForm
              inputValues={inputValues}
              setInputValues={setInputValues}
              setShowContactPopup={setShowContactPopup}
              requiredFields={requiredFields}
            />
          )}
        </Popup>
      )}

      <Container>
        <TitleContainer>
          <Title>
            <FormattedMessage id="contacts.title.label" />
          </Title>
          <StyledBox>
            <FilePicker
              onChange={(FileObject) => handleImportUpload(FileObject)}
              onError={(errMsg) => console.warn({ errMsg })}
            >
              <StyledIconButton size={'small'} color="primary">
                <UploadFileOutlinedIcon fill={'white'} />
              </StyledIconButton>
            </FilePicker>
            &nbsp;
            <StyledIconButton onClick={handleExport} size={'small'}>
              <FileDownloadOutlinedIcon />
            </StyledIconButton>
          </StyledBox>
          <StyledButton variant="contained" onClick={handleFormOpen}>
            <FormattedMessage id="contacts.title.button" />
          </StyledButton>
        </TitleContainer>
        {(errorTemplate && 'error' in errorTemplate && (
            <Error message={errorTemplate.error} />
          )) ||
          (errorUpdate && 'error' in errorUpdate && (
            <Error message={errorUpdate.error} />
          )) ||
          (errorDelete && 'error' in errorDelete && (
            <Error message={errorDelete.error} />
          )) ||
          (errorQuery && 'error' in errorQuery && <Error message={errorQuery.error} />)}

        <Box
          sx={{
            height: 300,
            width: '100%',
            '& .overflowOnIt': {
              overflow: 'overlay !important',
            },
          }}
        >
          <DataGrid
            columns={[
              {
                field: 'firstName',
                headerName: 'First Name',
                flex: 1
              },
              {
                field: 'lastName',
                headerName: 'Last Name',
                flex: 1
              },
              {
                field: 'country',
                headerName: 'Country Code',
                flex: 1,
                renderCell: (cellValues) => {
                  if (cellValues.row?.country?.code)
                    return <>
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${cellValues.row?.country?.code?.toLowerCase()}.png`}
                        alt=""
                      />&nbsp;(+{cellValues.row?.country?.phone})
                    </>;

                  return '';
                }
              },
              {
                field: 'phone',
                headerName: 'Phone',
                flex: 1
              },
              {
                field: 'email',
                headerName: 'Email',
                flex: 1
              },
              {
                field: 'tags',
                headerName: 'Tags',
                flex: 1,
                cellClassName: 'overflowOnIt',
                minWidth: 300,
                renderCell: (cellValues) => {
                  return <>
                    {cellValues.row?.tags?.map((channel) => <Tag
                      key={`channel-${channel?.value ?? channel ?? ''}-${Math.random()}`}>{channel?.label ?? channel ?? ''}</Tag>)}
                  </>;
                }
              },
              {
                field: 'address',
                headerName: 'Address',
                flex: 1
              },
              {
                field: 'gender',
                headerName: 'Gender',
                flex: 1
              },
              {
                field: 'platform',
                headerName: 'Platform',
                flex: 1,
                renderCell: (cellValues) => {
                  return <>
                    {cellValues.row?.platform?.map((channel) => <span key={`channel-${channel?.value ?? channel ?? ''}-${Math.random()}`}>&nbsp;<img
                      width="20" src={getLogo(channel?.value ?? channel ?? '')} alt="" /></span>)}
                  </>;
                }
              },
              {
                field: 'comments',
                headerName: 'Comments',
                flex: 1
              },
              {
                field: 'actions',
                headerName: 'Actions',
                type: 'actions',
                flex: 1,
                renderCell: (cellValues) => {
                  return <>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditPopup(cellValues.row.id)}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeletePopup(cellValues.row.id)}
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  </>;
                }
              },
            ]}
            rows={contacts?.data?.map((contact) => {
              return {
                id: contact._id.$oid,
                firstName: contact.firstName,
                lastName: contact.lastName,
                country: contact.country ?? '',
                phone: contact.phone,
                email: contact.email,
                tags: contact.tags,
                address: contact.address,
                platform: contact.providers,
                gender: contact.gender,
                comments: contact.comments,
              }
            }) ?? []}
            disableSelectionOnClick={true}
            isRowSelectable={() => false}
            autoHeight
          />
        </Box>
      </Container>
    </Wrapper>
  );
};

export default Contacts;
