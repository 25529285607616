import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthenticationHeaders } from '../modules/fetch';
import { API } from '../config';

export const storeApi = createApi({
  reducerPath: 'storesApi',
  baseQuery: fetchBaseQuery({baseUrl: API}),
  tagTypes: ['Stores', 'Config'],
  endpoints: (builder) => ({
    stores: builder.query({
      query: () => ({
        url: '/stores/listings/1,999',
        headers: getAuthenticationHeaders('application/json'),
      }),
      providesTags: ['Stores']
    }),
    updateTimezone: builder.mutation({
      query: ({ id, name }) => ({
        url: `/stores/timezone/${id}`,
        method: 'PATCH',
        headers: getAuthenticationHeaders('application/json'),
        body: { name: name },
      }),
      invalidatesTags: ['Stores'],
    }),
    storesConfig: builder.query({
      query: () => ({
        url: '/stores/config',
        headers: getAuthenticationHeaders('application/json'),
      }),
      providesTags: ['Config']
    }),
    store: builder.query({
      query: (id) => ({
        url: `/stores/read/${id}`,
        headers: getAuthenticationHeaders('application/json'),
      }),
      providesTags: ['Config']
    }),
    deleteStore: builder.mutation({
      query: (id) => ({
        url: `/stores/remove/${id}`,
        method: 'DELETE',
        headers: getAuthenticationHeaders('application/json'),
      }),
      invalidatesTags: ['Stores']
    })
  })
})

export const {
  useStoresQuery,
  useUpdateTimezoneMutation,
  useStoreQuery,
  useStoresConfigQuery,
  useDeleteStoreMutation
} = storeApi
