import { createContext, useState } from 'react';

export const InboxCountsContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [counts, setCounts] = useState({});

  return (
    <InboxCountsContext.Provider value={[counts, setCounts]}>
      {children}
    </InboxCountsContext.Provider>
  );
};
