import styled from 'styled-components';
import { Box, CardContent } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { ProgressBar, TailSpin } from 'react-loader-spinner';

export const StyledPlayBox = styled(Box)`
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin: auto;
    width: 100%;
    font-size: 10rem !important;
`

export const StyledPlayCircleOutlineIcon = styled(PlayCircleOutlineIcon)`
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin: auto;
    width: 100% !important;
    font-size: 10rem !important;
    max-height: 160px;
`

export const StyledAddPhotoAlternateOutlinedIcon = styled(AddPhotoAlternateOutlinedIcon)`
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin: auto;
    width: 100% !important;
    font-size: 10rem !important;
    max-height: 160px;
`

export const StyledProgressBar = styled(ProgressBar)`
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: auto;
  width: 100% !important;
  font-size: 10rem !important;
  max-height: 160px;
`

export const StyledTailSpin = styled(TailSpin)`
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: auto;
  width: 100% !important;
  font-size: 10rem !important;
  max-height: 160px;
`

export const StyledImage = styled.img`
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin: auto;
    width: 100% !important;
    font-size: 10rem !important;
    max-height: 160px;
`

export const StyledLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  vertical-align: middle;
`

export const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  color: #050d42;
`;
export const StyledCardContent = styled(CardContent)`
  border-top: 1px dashed #050d42 !important;
  padding: 6px !important;
`
export const Text = styled.div`
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: #050d42;
`;
