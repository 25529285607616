import MessageType from '../../../constants/MessageType';
import Sticker from './sticker';
import Reaction from './reaction';

export default ({ type, children, ...props }) => {
  switch (type) {
    case MessageType.STICKER:
      return <Sticker item={props['item']} />;
    case MessageType.REACTION:
      return <Reaction item={props['item']} />;
  }
  return <>{children}</>;
};
