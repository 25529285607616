import { ContactsTitle, Container, ContextWrapper, ProductsTitle, StyledButton } from './styles';
import CustomerOrders from '../../components/CustomerOrders';
import { FormattedMessage } from 'react-intl';

const UserDataNav = ({
  isContactsOpen,
  isOrdersOpen,
  isProductsOpen,
  setIsContactsOpen,
  setIsOrdersOpen,
  setIsProductsOpen,
}) => {
  const openContacts = () => {
    setIsContactsOpen(true);
  };
  const openCustomerOrders = () => {
    setIsOrdersOpen(true);
  };
  const openProducts = () => {
    setIsProductsOpen(true);
  };
  return (
    <Container>
      {isContactsOpen ? (
        <ContextWrapper>
          <ContactsTitle>
            <FormattedMessage id="main.header.user_data_nav.contacts.title" />
          </ContactsTitle>
        </ContextWrapper>
      ) : (
        <StyledButton variant="contained" onClick={openContacts}>
          Contact Information
        </StyledButton>
      )}
      {isOrdersOpen ? (
        <ContextWrapper>
          <CustomerOrders />
        </ContextWrapper>
      ) : (
        <StyledButton variant="contained" onClick={openCustomerOrders}>
          Orders
        </StyledButton>
      )}
      {isProductsOpen ? (
        <ContextWrapper>
          <ProductsTitle>
            <FormattedMessage id="main.header.user_data_nav.products.title" />
          </ProductsTitle>
        </ContextWrapper>
      ) : (
        <StyledButton variant="contained" onClick={openProducts}>
          Products
        </StyledButton>
      )}
    </Container>
  );
};
export default UserDataNav;
