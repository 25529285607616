import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  border: 1px solid #f00;
  border-radius: 5px;
  padding: 15px 10px;
  background-color: rgba(255, 0, 0, 0.5);
  margin-bottom: 30px;
`;

const Error = ({ message, ...props }) => {
  return (
    <Container {...props}>
      <FormattedMessage id={message} defaultMessage={message} />
    </Container>
  );
};

export default Error;
