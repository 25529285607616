import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Nav from '../../components/Nav';
import { Body, PageContainer } from '../../components/Layout';
import { mediaQueries } from '../../constants/breakpoints';
import { Navigate } from 'react-router-dom';
import { getAccountData, getCurrentLevel } from '../../security';
import { store } from '../../modules/storage';
import Header from '../../components/Header';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback, ErrorBoundaryHandler } from '../../components/ErrorBoundary';
import { useStoresQuery } from '../../api/storeAPI';
import Templates from '../../containers/Templates';

const TemplatesManagementContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 100%;
  overflow-y: auto;
  @media ${mediaQueries.desktop} {
    height: calc(100% - 30px);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    margin: 15px;
    border-radius: 15px;
  }
`;

const TemplatesManagement = () => {
  const [, setWidth] = useState(window.innerWidth);
  const [, setCurrentUser] = useState();
  const [viewsLevel, setViewsLevel] = useState(-1);

  const {data: stores} = useStoresQuery(true);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    getAccountData((response) => {
      setCurrentUser(response);
      const currentLevel = getCurrentLevel(response);
      store.number('viewsLevel', currentLevel);
      setViewsLevel(currentLevel);
    });

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      {viewsLevel > 3 ? (
        <Navigate to="/messages" />
      ) : (
        <>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onError={ErrorBoundaryHandler}
          >
            <Header />
          </ErrorBoundary>
          <PageContainer>
            <ErrorBoundary
              FallbackComponent={ErrorBoundaryFallback}
              onError={ErrorBoundaryHandler}
            >
              <Nav currentStep="templates" />
            </ErrorBoundary>
            <Body>
              <TemplatesManagementContainer>
                <ErrorBoundary
                  FallbackComponent={ErrorBoundaryFallback}
                  onError={ErrorBoundaryHandler}
                >
                  <Templates data={stores?.data} />
                </ErrorBoundary>
              </TemplatesManagementContainer>
            </Body>
          </PageContainer>
        </>
      )}
    </>
  );
};

export default TemplatesManagement;
