import jwtDecode from '../modules/jwt';

export class ResponseError extends Error {
  protected _response: any;
  protected _exception: object;
  protected _token: string;
  protected _jwt: object;

  constructor(message: any, response: object, exception: null|object, token: null|string) {
    super(message);
    this._response = response;
    this._exception = exception;
    this._token = token;
    this._jwt = jwtDecode(token);
    this.name = 'ResponseError';
  }

  get exception(): object {
    return this._exception;
  }

  set exception(value: object) {
    this._exception = value;
  }

  get cause(): Error {
    return this._response;
  }

  set cause(value: Error) {
    this._response = value;
  }

  get response(): object {
    return this._response;
  }

  set response(value: object) {
    this._response = value;
  }

  get token(): string {
    return this._token;
  }

  set token(value: string) {
    this._token = value;
  }

  get jwt(): object {
    return this._jwt;
  }

  set jwt(value: object) {
    this._jwt = value;
  }
}
