import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useIntl } from 'react-intl';
import moment from 'moment/moment';
import { getInputDependedValues } from '../../../modules/getinput';

export const FormDatetime = ({ input, inputValues, handleDateChange }) => {
  const intl = useIntl();
  const dateInput = (input) => {
    return moment(input?.date ?? input, 'YYYY-MM-DD HH:mm:ss').toDate();
  };

  let label = '';
  try {
    label = intl.formatMessage({ id: input?.label, defaultMessage: input?.label });
  } catch (e) {}

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label={label}
        value={dateInput(getInputDependedValues(input, inputValues, ''))}
        onChange={(newValue) => handleDateChange(input.id)(newValue)}
        renderInput={(params) => <TextField {...params} />}
        inputFormat={'dd/MM/yyyy HH:mm:ss'}
        mask="__/__/____ __:__:__"
      />
    </LocalizationProvider>
  );
};
export default FormDatetime;
