import { FormattedMessage, useIntl } from 'react-intl';
import { Container, FormContainer, StyledList, TextFieldContainer } from './styles';
import AddIcon from '@mui/icons-material/Add';
import { useContext, useEffect, useState } from 'react';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import Timestamp from '../Timestamp';
import TextField from '@mui/material/TextField';
import { postData } from '../../modules/fetch';
import Button from '@mui/material/Button';
import { CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';

const CustomerComments = ({}) => {
  const [activeChat] = useContext(ActiveChatContext);
  const [comments, setComments] = useState(null);
  const [comment, setComment] = useState('');

  const intl = useIntl();

  useEffect(() => {
    setComments(activeChat?.comments);
  }, [activeChat]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    postData(`/${activeChat?.entity}s/comments/add/${activeChat?._id?.$oid}`, {
      'comment': comment,
    }).then(() => {
      setComment('');
    }).catch((err) => {
      console.error(err);
    });
  }

  const handleChange = (comment) => {
    setComment(comment);
  };

  return (
    <Container>
      <h4>
        <FormattedMessage id="messages.customerComments.title" defaultMessage="Comments" />
      </h4>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <FormContainer>
          <TextFieldContainer>
            <TextField
              id="comment"
              label={intl.formatMessage({
                id: 'messages.customerComments.comment',
                defaultMessage: 'Comment',
              })}
              value={comment}
              type="text"
              variant="outlined"
              autoComplete="none"
              multiline
              rows={4}
              onChange={(event) => handleChange(event.target.value)}
            />
          </TextFieldContainer>
          <TextFieldContainer
            style={{ justifyContent: 'end' }}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              type="submit"
            ><AddIcon /></Button>
          </TextFieldContainer>
        </FormContainer>
      </form>
      <StyledList>
        {comments && Array.isArray(comments) && comments?.length > 0 ? (
          comments?.map((comment, index) => (
            <CardContent sx={{padding: '10px', paddingBottom: '0px !important'}} key={`ticket-${index}`}>
              <Typography gutterBottom variant="body1" component="div">{index + 1}. {comment?.comment}</Typography>
              <Typography variant="body1" color="text.secondary">{comment?.userName}</Typography>
              <Typography variant="body2" color="text.secondary">
                <Timestamp showDate={comment?.createdAt?.date} customerDataStoreTimezone={activeChat.store?.timezone} />
              </Typography>
            </CardContent>
          ))
        ) : (
          <h4>
            <FormattedMessage
              id="messages.customerComments.no_tickets"
              defaultMessage="No Comments"
            />
          </h4>
        )}
      </StyledList>
    </Container>
  );
};
export default CustomerComments;
