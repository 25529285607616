export const getLogo = (provider) => {
  switch (String(provider).toLowerCase()) {
    case 'lazada':
      return '/pics/lazada_small.png';
    case 'shopee':
      return '/pics/shopee_small.png';
    case 'whatsapp':
      return '/pics/whatsapp_small.png';
    case 'line':
      return '/pics/line_small.png';
    case 'shopify':
      return '/pics/shopify_small.png';
    case 'facebook_messenger':
      return '/pics/facebook_messenger_small.png';
    default:
      return '/pics/omcom-logo.png';
  }
};
