import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';

export const GlobalMessage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
`;

export const DefaultText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmallChatsContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 65px;
  display: none;
  height: 0;
  align-items: flex-end;
  justify-content: flex-end;
  width: calc(100vw - 65px);
  @media ${mediaQueries.desktop} {
    display: flex;
  }
`;
