import styled from 'styled-components';
import { AuthorName } from '../styles';

export const StyledVideo = styled.video`
  display: block;
  max-width: 300px;
  max-height: 300px;
  height: auto;
`;
export const StyledAuthorName = styled(AuthorName)`
  align-self: center !important;
`;
export const ImgContainer = styled.div`
  cursor: pointer;
`;
