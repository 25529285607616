import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Content, Loader, Message, MessageBox } from '../styles';
import { ImgContainer, StyledVideo } from './styles';
import DeliveryStatus from '../../DeliveryStatus';
import LastErrorMessage from '../../LastErrorMessage';
import Avatar from '../../Avatar';
import ErrorMessage from '../../ErrorMessage';
import { BASE_URL } from '../../../../../config';

const Video = ({ client, message, resend, handleVideoPopup, customerDataStoreTimezone, more = null }) => {
  return (
    <Message isClient={client} key={message?._id?.$oid + message?.sendId}>
      <Content>
        <MessageBox>
          {!message?.isClient && message?.lastSendError && (
            <IconButton aria-label="resend" onClick={() => resend(message?._id?.$oid)}>
              <RefreshIcon fontSize="small" />
            </IconButton>
          )}
          {(!message?.delivery ||
              message?.delivery === 'sending' ||
              message?.delivery === 'sent2api' ||
              message?.delivery === 'sent_to_api') &&
            !client && <Loader color="primary" src={'/img/loader-new.gif'} />}
          {message?.body?.video.url ? (
            <ImgContainer
              onClick={() =>
                handleVideoPopup({
                  poster: message?.body?.video?.thumbUrl ?? '/img/icon-play-button-round.svg',
                  url: ((message?.provider === 'whatsapp' ? BASE_URL : '') + message?.body?.video?.url),
                  autoplay: true,
                })
              }
            >
              <Avatar message={message} isClient={client} />
              {message?.provider === 'whatsapp' ? (
                <StyledVideo
                  controls={false}
                  src={(message?.provider === 'whatsapp' ? BASE_URL : '') + (message?.body?.video?.url || '')}
                  autoPlay
                  muted
                  loop={true}
                />
              ) : (
                <img src={message?.body?.video?.thumbUrl ?? '/img/icon-play-button-round.svg'} style={{ maxWidth: 400, maxHeight: 400 }} />
              )}

            </ImgContainer>
          ) : (
            ''
          )}
        </MessageBox>
        {more}
        <DeliveryStatus
          isClient={client}
          message={message}
          customerDataStoreTimezone={customerDataStoreTimezone}
        />
        <LastErrorMessage message={message} />
        <ErrorMessage message={message?.rawResponse?.process_msg ?? ''} />
      </Content>
    </Message>
  );
};

export default Video;
