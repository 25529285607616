import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import List from '@mui/material/List';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  min-width: 400px;
  flex-grow: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 70px);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  z-index: 5;
  h4 {
    text-align: center;
  }
  @media ${mediaQueries.desktop} {
    position: absolute;
    top: 0;
    right: 51px;
    height: 100%;
  }
`;
export const StyledList = styled(List)`
  @media ${mediaQueries.desktop} {
    overflow-y: scroll;
  }
`;
