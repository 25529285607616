import StoreDetailsComponent from '../../components/StoreDetailsComponent';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useStoreQuery } from '../../api/storeAPI';
import Error from '../../components/Error';
import { Back, Container, Header, StoreName } from './style';
import { useEffect, useState } from 'react';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import IconButton from '@mui/material/IconButton';
import { toTitleCase } from '../../modules/string';

const StoreDetailsContainer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data: store, error: errorQuery } = useStoreQuery(params.id)

  const [currentStore, setCurrentStore] = useState<any>();

  useEffect(() => {
    if (store?.data) {
      const sellerInfo: any = Object.values(store?.data?.apps ?? {}).find((app: any) => 'sellerInfoResponse' in app?.rawResponse);
      const apps = Object.keys(store?.data?.apps ?? {}).reduce((result, name) => ({ ...result, [name]: store?.data.apps[name].auth }), {});
      const appsWas = Object.keys(store?.data?.appsWas ?? {}).reduce((result, name) => ({ ...result, [name]: store?.data.appsWas[name].auth }), {});
      let storeObject: any = {
        _id: store?.data?._id,
        basic: {
          _id: store?.data?._id,
          name: store?.data.name ?? '',
          shortCode: store?.data.shortCode ?? '',
          countryCode: store?.data.countryCode ?? '',
          timezone: store?.data.timezone ?? '',
          location: store?.data.location ?? '',
          sellerId: store?.data.sellerId ?? '',
          email: store?.data.email ?? '',
          provider: store?.data.provider ?? '',
          status: store?.data.status ?? '',
          createdAt: store?.data.createdAt ?? '',
          updatedAt: store?.data.updatedAt ?? '',
        },
      };
      if (store?.data.provider !== 'whatsapp') {
        storeObject = {
          ...storeObject,
          sellerInfo: sellerInfo?.rawResponse?.sellerInfoResponse?.data ?? sellerInfo?.rawResponse?.sellerInfoResponse ?? {},
          api: {
            connectedApplications: apps ?? {},
            wasConnectedApplications: appsWas ?? {},
          },
          errors: {
            refreshError: store?.data.refreshError ?? {},
            appErpImRefreshError: store?.data.appErpImRefreshError ?? {},
            appErpSystemRefreshError: store?.data.appErpSystemRefreshError ?? {},
          }
        }
      }
      setCurrentStore(storeObject);
    }
  }, [store]);

  const handleBack = () => {
    navigate('/stores');
  }

  return (
    <Container>
      {!params.id ? (
        <Navigate to={`/store/${params.id}`} />
      ) : (
        <>
          <Header>
            <Back>
              <IconButton
                aria-label="back"
                onClick={handleBack}
              >
                <ArrowCircleLeftOutlinedIcon fontSize={'large'} />
              </IconButton>
            </Back>
            <StoreName>{toTitleCase(currentStore?.basic?.provider)} {currentStore?.basic?.name} {currentStore?.basic?.countryCode}</StoreName>
          </Header>
          {(errorQuery && 'error' in errorQuery && <Error message={errorQuery.error} />)}
          <StoreDetailsComponent object={currentStore} />
        </>
      )}
    </Container>
  );
};

export default StoreDetailsContainer;
