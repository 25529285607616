import { FormRow } from '../FormItems/FormRow';
import { AddButton, RowsContainer } from './styles';
import FormCopyableList from '../FormItems/FormCopyableList';
import { FormattedMessage } from 'react-intl';
import { ContentContainer, StepContent, StyledEditIcon } from '../ReportFormRenderContent/styles';
import { FormContainer } from '../BroadcastFormSteps/styles';

export const BroadcastFormRenderContent = (
  {
    inputValues,
    requiredFields,
    setInputValues,
    inputLabels,
    setInputLabels,
    broadcastFormData,
    currentStep,
    setCurrentStep,
    isPreview,
    setIsPreview,
    isPreviewTicket,
  }) => {
  const handleAddButton = () => {
    setInputValues({
      ...inputValues,
      settings: [
        ...inputValues.settings,
        {
          weekDays: [],
          timeRange: {
            from: '',
            till: ''
          }
        },
      ],
    });
  };
  return (
    <FormContainer $isPreview={isPreview} $isPreviewTicket={isPreviewTicket}>
      {broadcastFormData?.map((step, index) => (
        <StepContent
          $isPreview={isPreview}
          $isPreviewTicket={isPreviewTicket}
          key={`StepContent-${index}`}
          className={`step-content-${index}`}
        >
          {(isPreview || currentStep === index) && (
            <ContentContainer>
              {isPreview && !isPreviewTicket && (
                <StyledEditIcon
                  fontSize={'small'}
                  onClick={() => {
                    setCurrentStep(index);
                    setIsPreview(false);
                  }}
                />
              )}
              <RowsContainer key={`autoreply-step-${index}`}>
                {step.form.rows.map((formRow, formRowIndex) => (
                    'type' in formRow && formRow?.type === 'copyable' && (
                      <div key={`autoreply-row-${formRowIndex}`}>
                        <FormCopyableList
                          key={`report-row-${formRowIndex}`}
                          index={index}
                          formRowIndex={formRowIndex}
                          inputValues={inputValues}
                          requiredFields={requiredFields}
                          setInputValues={setInputValues}
                          formRow={formRow}
                        />
                        <AddButton variant="text" onClick={handleAddButton}>
                          <FormattedMessage id="broadcast.form.add_button" />
                        </AddButton>
                      </div>
                    ) || (
                      <FormRow
                        key={`autoreply-row-${formRowIndex}`}
                        index={index}
                        inputValues={inputValues}
                        requiredFields={requiredFields}
                        formRowIndex={formRowIndex}
                        setInputValues={setInputValues}
                        inputLabels={inputLabels}
                        setInputLabels={setInputLabels}
                        formRow={formRow}
                        isPreview={isPreview}
                      />
                    )),
                )}
              </RowsContainer>
            </ContentContainer>
          )}
        </StepContent>
      ))}
    </FormContainer>
  );
};
export default BroadcastFormRenderContent;
