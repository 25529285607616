import { logMessage } from '../logger';
import { postData } from '../modules/fetch';

const postAccountResetRequest = (requestBody, successCallback, errorCallback, loadingCallback) => {
  postData('/account/reset/request', requestBody, false, false)
  .then((data) => {
    logMessage(data);
    successCallback(true);
    loadingCallback(false);
  })
  .catch((err) => {
    console.error(err);
    errorCallback(err.message);
    loadingCallback(false);
  });
}

export default postAccountResetRequest;
