import { useContext, useEffect, useState } from 'react';
import Nav from '../../components/Nav';
import Header from '../../components/Header';
import Inbox from '../../containers/Inbox';
import Chat from '../../containers/Chat';
import SmallChat from '../../containers/SmallChat';
import { Body, PageContainer, PageContainerInbox, PageContainerMessages } from '../../components/Layout';
import { getData, postData, putData } from '../../modules/fetch';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import { InboxCountsContext } from '../../context/InboxCountsContext';
import { IsFilteringContext } from '../../context/IsFilteringContext';
import { cleanUp, retrieve, store } from '../../modules/storage';
import EnumInboxStates from '../../constants/EnumInboxStates';
import InboxStates from '../../constants/InboxStates';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import FiltersStruct from '../../constants/FilterStruct';
import { FormattedMessage } from 'react-intl';
import MessageInputRefTag, { MessageInputRefContext } from './MessageInputRefContext';
import { useStoresQuery } from '../../api/storeAPI';
import { useUsersQuery } from '../../api/userAPI';
import { useAccountQuery } from '../../api/accountAPI';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback, ErrorBoundaryHandler } from '../../components/ErrorBoundary';
import { detailedDiff } from 'deep-object-diff';
import { GlobalLoadingContext } from '../../context/GlobalLoadingContext';
import { DefaultText, GlobalMessage, SmallChatsContainer } from './styles';
import ProductsContext from '../../context/ProductsContext';
import { WampHandlersContext } from '../../context/WampHandlersContext';
import MessageType from '../../constants/MessageType';
import { isCustomerFiltered } from './WampFilters';
import { logMessage } from '../../logger';

let alertMessages = [];
const inboxItemsPerPage = 100;
let wampIsSet = false;

const perPage = 10;

const MessagesBlock = (
  messageData: any[],
  currentUser,
  handleChatClose: () => void,
  handleEscalate: () => void,
  handleResolved: () => void,
  handleFollowUp: (duration) => void,
  wampHandlers: {
    message: (msg) => void;
    followup: (msg) => void;
    customer: (msg) => void
  },
  getMoreMessages: () => void,
  totalMessages: number,
  scrollMessages: boolean,
  getInbox: (isReset?: boolean, q?: any, unread?: number) => Promise<Awaited<any>[] | void>,
  findMessageBySendId: (sendId) => any,
  users,
  tokenValues,
  setShowChat: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  openSmallChat: (chatData) => void,
  activeStore: any,
) =>
  <MessageInputRefTag>
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={ErrorBoundaryHandler}
    >
      <ProductsContext>
        <Chat
          messages={messageData.slice(0).reverse()}
          responsesData={currentUser?.data?.fastResponses}
          handleChatClose={handleChatClose}
          currentUser={currentUser?.data}
          handleEscalate={handleEscalate}
          handleResolved={handleResolved}
          handleFollowUp={handleFollowUp}
          wampHandlers={wampHandlers}
          getMoreMessages={getMoreMessages}
          totalMessages={totalMessages}
          scrollMessages={scrollMessages}
          getInbox={getInbox}
          findMessageBySendId={findMessageBySendId}
          users={users?.data}
          tokenValues={tokenValues}
          setShowChat={setShowChat}
          openSmallChat={openSmallChat}
          activeStore={activeStore}
        />
      </ProductsContext>
    </ErrorBoundary>
  </MessageInputRefTag>;

const InboxBlock = (
  inbox,
  getInbox,
  filters,
  setFilters,
  sort,
  setSort,
  setQuery,
  stores,
  users,
  handleChatOpen,
  isEmptyData,
  openInboxItems,
  newInboxItems,
  inProgressInboxItems,
  resolvedInboxItems,
  followedInboxItems,
  escalatedInboxItems,
  handlePageChange,
  inboxPage,
  currentFolder,
  query,
  openSmallChat,
) =>
  <Inbox
    getInbox={getInbox}
    filters={filters}
    setFilters={setFilters}
    sort={sort}
    setSort={setSort}
    setQuery={setQuery}
    stores={stores?.data}
    users={users?.data}
    key={`inbox-item-${inbox.length}-${currentFolder}`}
    handleChatOpen={handleChatOpen}
    isEmptyData={isEmptyData}
    openInboxItems={openInboxItems}
    newInboxItems={newInboxItems}
    inProgressInboxItems={inProgressInboxItems}
    resolvedInboxItems={resolvedInboxItems}
    followedInboxItems={followedInboxItems}
    escalatedInboxItems={escalatedInboxItems}
    handlePageChange={handlePageChange}
    inboxPage={inboxPage}
    currentFolder={currentFolder}
    query={query}
    openSmallChat={openSmallChat}
  />;

const Messages = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // States
  const [inboxPage, setInboxPage] = useState<any>({
    open: 1,
    new: 1,
    inProgress: 1,
    followup: 1,
    escalated: 1,
    resolved: 1,
  });
  const [query, setQuery] = useState<string>(retrieve.string('inboxQuery'));
  const [inbox, updateInbox] = useState<any>([[], [], [], [], [], []]);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [smallChats, setSmallChats] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [, setLoading] = useContext<any>(GlobalLoadingContext);
  const [activeChat, setActiveChat, , , lastActiveChatId] = useContext<any>(ActiveChatContext);
  const [counts, setCounts] = useContext<any>(InboxCountsContext);
  const [messageDataSent, setMessageDataSent] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [messagesPage, setMessagesPage] = useState(1);
  const [totalMessages, setTotalMessages] = useState(0);
  const [scrollMessages, triggerScrollMessages] = useState(false);
  const [showConnectStoreMessage, setShowConnectStoreMessage] = useState(false);
  const messageInputRef = useContext(MessageInputRefContext);
  const [isFiltering] = useContext<any>(IsFilteringContext);
  const [newSmallChatMessage, setNewSmallChatMessage] = useState({});
  const [openInboxItems, setOpenInboxItems] = useState<any>([]);
  const [newInboxItems, setNewInboxItems] = useState<any>([]);
  const [inProgressInboxItems, setInProgressInboxItems] = useState<any>([]);
  const [resolvedInboxItems, setResolvedInboxItems] = useState<any>([]);
  const [followedInboxItems, setFollowedInboxItems] = useState<any>([]);
  const [escalatedInboxItems, setEscalatedInboxItems] = useState<any>([]);
  const [filters, setFilters] = useState(retrieve.json('filters', FiltersStruct));
  const [sort, setSort] = useState({ 'message.time.date': -1 });
  const [wampCustomer, setWampCustomer] = useState<any>();
  const [isEmptyData, setIsEmptyData] = useState(false);
  // const [{ setWampHealthCheck }, { relaunchWampSetup, setRelaunchWampSetup }] =
  //   useContext<any>(HealthCheckContext);
  const [runReloadActiveMessagesAndMarkChat, setRunReloadActiveMessagesAndMarkChat] =
    useState(false);
  const [tokenValues, setTokenValues] = useState(retrieve.json('TokenValues', '{}'));
  const [activeStore, setActiveStore] = useState<any>();

  const [, setWampHandlers] = useContext<any>(WampHandlersContext);

  // Redux Queries
  const { data: stores } = useStoresQuery(true);
  const { data: users } = useUsersQuery(true);
  const { data: currentUser } = useAccountQuery(true);
  // const alert = useAlert();

  useEffect(() => {
    if (activeChat && stores) {
      const currentStore = stores?.data.find((store) => store?._id?.$oid === activeChat?.storeId)
      if (currentStore)
        setActiveStore(currentStore);
    }
  }, [activeChat]);

  // Listeners
  useEffect(() => {
    setRunReloadActiveMessagesAndMarkChat(false);
    getInbox().then((inboxes) => {
      let aChat;
      if (params?.folder) {
        if (params?.chatId) {
          aChat = inboxes[EnumInboxStates?.[params?.folder]].find((item) => item?._id?.$oid === params?.chatId);
        }
        const currentFolder = params.folder;
        let states = [];
        if (aChat?.states) {
          states = Object.keys(aChat?.states);
        } else if (activeChat?.states) {
          states = Object.keys(activeChat?.states);
        }
        if (states.indexOf(currentFolder) < 0) {
          setShowChat(false);
        }
        if (aChat) {
          handleChatOpen(aChat);
        }
        return aChat;
      }
      return null;
    });
    getTokenValues();
    if (!wampIsSet) {
      setWampHandlers(wampHandlers);
      wampIsSet = true;
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeChat?._id?.$oid !== lastActiveChatId) {
      getTokenValues();
    }
  }, [activeChat]);

  useEffect(() => {
    if (activeChat?._id?.$oid !== lastActiveChatId) {
      if (inbox[EnumInboxStates[params.folder]]?.length === 0) {
        setIsEmptyData(true);
      } else {
        setIsEmptyData(false);
      }
    }
  }, [activeChat, location]);

  useEffect(() => {
    if (wampCustomer) {
      const allow1 = Array.isArray(wampCustomer.sessions) ? false : Object.keys(wampCustomer.sessions).length > 0;
      const allow2 = wampCustomer.lastSessionId !== null;
      const allow3 = Array.isArray(wampCustomer.message) ? false : Object.keys(wampCustomer.message).length > 0;
      if (!(allow1 || allow2 || allow3)) {
        return;
      }
      if ('deletedAt' in wampCustomer) {
        updateInbox(
          inbox.map((items) => {
            if (Array.isArray(items)) {
              return items.filter((item) => item?._id.$oid !== wampCustomer?._id.$oid);
            }
          }),
        );
        if (activeChat?._id.$oid === wampCustomer?._id.$oid) {
          setActiveChat(null);
        }
        return;
      }
      if (activeChat?._id?.$oid === wampCustomer?._id?.$oid) {
        setActiveChat(wampCustomer);
      } else {
        logMessage('wampCustomer', wampCustomer);
      }
      handleCustomerUpdate(wampCustomer);
    }
  }, [wampCustomer]);

  // useEffect(() => {
  //   if (relaunchWampSetup) {
  //     // destroyWampHandler(wamps);
  //     // setupWampHandler(wampHandlers).then((wamps) => setWamps(wamps));
  //     getMessages(activeChat);
  //     setTimeout(() => {
  //       setWampHealthCheck((setWampHealthCheck) => {
  //         return {
  //           date:
  //             typeof setWampHealthCheck.date === 'string' ? setWampHealthCheck.date : Math.random(),
  //         };
  //       });
  //     }, 1000);
  //   }
  //   setRelaunchWampSetup(false);
  // }, [relaunchWampSetup]);

  useEffect(() => {
    filterByFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inbox]);

  useEffect(() => {
    const currentFolder = params.folder;
    let states = [];
    if (activeChat?.states) {
      states = Object.keys(activeChat?.states);
    }
    if (states.indexOf(currentFolder) < 0) {
      setShowChat(false);
    }
  }, [params.folder]);

  useEffect(() => {
    if (runReloadActiveMessagesAndMarkChat) {
      setRunReloadActiveMessagesAndMarkChat(false);
      reloadActiveChatMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runReloadActiveMessagesAndMarkChat]);

  // Helpers
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const filterByFolders = () => {
    setOpenInboxItems(inbox[EnumInboxStates.open]);
    setNewInboxItems(inbox[EnumInboxStates.new]);
    setInProgressInboxItems(inbox[EnumInboxStates.inProgress]);
    setFollowedInboxItems(inbox[EnumInboxStates.followup]);
    setEscalatedInboxItems(inbox[EnumInboxStates.escalated]);
    setResolvedInboxItems(inbox[EnumInboxStates.resolved]);
  };

  const getTokenValues = (update: boolean = true) => {
    if (activeChat?.entity && update) {
      const orderId = Object.keys(activeChat?.currentOrderItems || {})?.[0] || '';
      setLoading(false);
      getData(
        `/tokens/data/${activeChat?.entity}/${activeChat?._id?.$oid}?{orderItemId}=${orderId}`,
      )
        .then((data) => {
          store.json('TokenValues', data.data);
          setTokenValues(data.data);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(true);
        });
    }
  };

  // Inbox
  const markInboxRead = (chosenItem) => {
    if (chosenItem && chosenItem?.entity && chosenItem?._id) {
      setLoading(false);
      putData(`/${chosenItem.entity}s/update/${chosenItem._id.$oid}/disabled`, {
        [chosenItem.entity]: {
          wasOpened: true,
        },
      })
        .then((response) => {
          updateInbox([
            ...inbox.map((items) =>
              items.map((item) =>
                item?._id.$oid === response.data?._id.$oid
                  ? { ...item, wasOpened: response.data.wasOpened }
                  : item,
              ),
            ),
          ]);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(true);
        });
    }
  };

  const handleChatOpen = (item) => {
    setShowChat(true);
    setSmallChats(smallChats.filter((chat) => chat._id.$oid !== item._id.$oid));
    store.json('activeChat', item);
    if (item?._id?.$oid && item?.entity) {
      navigate(`/messages/${params.folder}/${item?._id?.$oid}`);
      setActiveChat(item);
      setRunReloadActiveMessagesAndMarkChat(true);
    }
  };

  const getInbox = (isReset = false, q = null, unread = 0) => {
    const filtersData = isReset
      ? {}
      : {
        sort: { ...sort },
        filter: { ...filters },
      };
    q || (q = retrieve.string('inboxQuery'));
    return Promise.all(
        InboxStates.map((status) =>
          postData(
            `/chats/listings/${inboxPage[status] ?? 1},${inboxItemsPerPage}/${status}?` +
            (q ? `query=${encodeURIComponent(q)}&` : '') +
            (unread ? `unread=1` : ''),
            filtersData,
          ).then((res) => res),
        ),
      )
      .then((data) => {
        let result;
        let reduce = data.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.data?.length;
        }, 0);
        if (reduce < 1) {
          result = [[], [], [], [], [], []];
          updateInbox(result);
          setIsEmptyData(true);
          return;
        } else {
          setIsEmptyData(false);
        }
        const inboxItems = data.map((item) => item.data);
        if (inboxItems.reduce((prev, current) => prev + current.length, 0) === 0 && q === null) {
          setShowConnectStoreMessage(true);
        }
        if (inboxItems.reduce((prev, current) => prev + current.length, 0) > 0) {
          result = inboxItems.map((item) => item.map((obj) => ({ ...obj, selected: false })));
          updateInbox(result);
        }

        return result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handlePageChange = (type, value, event, q = null) => {
    logMessage({ event });
    postData(
      `/chats/listings/${value},${inboxItemsPerPage}/${type}` +
      (q ? `?query=${encodeURIComponent(q)}` : ''),
      {
        sort: { ...sort },
        filter: { ...filters },
      },
    )
      .then((data) => {
        const inboxIndex = InboxStates.indexOf(type);
        let newItems = inbox;
        newItems[inboxIndex] = data.data.map((obj) => ({ ...obj, selected: false }));
        updateInbox([...newItems]);
      })
      .catch((err) => {
        console.error(err);
      });

    setInboxPage({ ...inboxPage, [type]: value });
  };

  const handleFollowUp = (duration) => {
    const isFollowUp = activeChat?.states?.followup;
    const currentState = isFollowUp
      ? { open: { state: 'inProgress' } }
      : { followup: { state: 'followup', duration: duration } };
    if (activeChat?.entity === undefined && activeChat?._id?.$oid === undefined) {
      return;
    }
    putData(`/${activeChat?.entity}s/states/update/${activeChat?._id.$oid}`, {
      states: currentState,
    })
      .then((data) => {
        setActiveChat(data.data);
        // run in a problem with customer state (not updated)
        // setRunReloadActiveMessagesAndMarkChat(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleResolved = () => {
    const isResolved = !!activeChat?.states?.resolved;
    const currentState = isResolved
      ? { open: { state: 'inProgress' } }
      : { resolved: { state: 'resolved' } };
    if (activeChat?.entity === undefined && activeChat?._id?.$oid === undefined) {
      return;
    }
    putData(`/${activeChat?.entity}s/states/update/${activeChat?._id.$oid}`, {
      states: currentState,
    })
      .then((data) => {
        if (data.data?.states?.resolved?.state === 'resolved') {
          setActiveChat(null);
        } else {
          setActiveChat(data.data);
          setRunReloadActiveMessagesAndMarkChat(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleEscalate = () => {
    const isEscalated = !!activeChat?.states?.escalated;
    const currentState = isEscalated
      ? { open: { state: 'inProgress' } }
      : { escalated: { state: 'escalated' } };
    if (activeChat?.entity === undefined && activeChat?._id?.$oid === undefined) {
      return;
    }
    putData(`/${activeChat?.entity}s/states/update/${activeChat?._id.$oid}`, {
      states: currentState,
    })
      .then((data) => {
        setActiveChat(data.data);
        // run in a problem with customer state (not updated)
        //setRunReloadActiveMessagesAndMarkChat(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateMessagesIsRead = (customer) => {
    if (
      customer?._id?.$oid === activeChat?._id?.$oid &&
      customer?.lastReadMessageId !== activeChat?.lastReadMessageId
    ) {
      let stillUpdate = true;
      const msgData = messageData
        .slice(0)
        .reverse()
        .map((message) => {
          if (stillUpdate) {
            message.isRead = true;
          }
          if (message?._id?.$oid === customer.lastReadMessageId) {
            stillUpdate = false;
          }
          return message;
        })
        .reverse();
      setMessageData(msgData);
    }
  };

  const handleCustomerUpdate = (customer) => {
    try {
      let inboxBuf = inbox;
      let indexFolder = null;
      let indexFolderMessageIdIsSame = null;
      let existsItem = null;

      // Find inbox folder with exising customer
      inboxBuf.forEach((folder, i) => {
        const foundItem = folder.find((item) => item?._id?.$oid === customer?._id?.$oid);
        if (foundItem) {
          indexFolderMessageIdIsSame =
            foundItem?.message?._id?.$oid === customer.message?._id?.$oid;
          indexFolder = i;
          existsItem = foundItem;
        }
      });

      let customerState = '';
      if (customer?.states) {
        customerState = EnumInboxStates[Object.keys(customer?.states)?.[0] || 'open'];
      }

      if (customerState === indexFolder) {
        return;
      }

      if (customerState === indexFolder && indexFolderMessageIdIsSame) {
        return;
      }

      let countsBuf = counts;

      // New customer
      if (indexFolder === null) {
        if (isCustomerFiltered(customer, { isFiltering, filters, query, isStateSame: null })) {
          inboxBuf[EnumInboxStates.open].unshift(customer);
          inboxBuf[EnumInboxStates.new].unshift(customer);
          countsBuf.open = countsBuf.open + 1;
        }
      }
      // Existing customer
      else {
        const existsStates = Object.keys(existsItem?.states ?? {});
        const customerStates = Object.keys(customer?.states);
        const isStateSame = existsStates.filter((value) => customerStates.includes(value)).length > 0;
        if (isCustomerFiltered(customer, { isFiltering, filters, query, isStateSame })) {
          const diffActiveChat: any = detailedDiff(customer, existsItem);
          let updated = false;
          let added = false;
          if (Object.keys(diffActiveChat.updated).length > 0) {
            if ('updatedAt' in diffActiveChat.updated) {
              delete diffActiveChat.updated['updatedAt'];
            }
            if (Object.keys(diffActiveChat.updated).length > 0) {
              if ('message' in diffActiveChat.updated || 'states' in diffActiveChat.updated) {
                updated = true;
              }
            }
          }
          if (Object.keys(diffActiveChat.added).length > 0) {
            if ('message' in diffActiveChat.updated) {
              added = true;
            }
          }
          const needToRefresh = added || updated || !isStateSame;
          if (needToRefresh) {
            // If state open
            if (indexFolder < 3) {
              countsBuf.open = countsBuf.open - 1;
            } else {
              countsBuf[Object.keys(countsBuf)[indexFolder]] =
                countsBuf[Object.keys(countsBuf)[indexFolder]] - 1;
            }
            // Remove customer from previous folder
            inboxBuf = inbox.map((items) =>
              items.filter((item) => item?._id.$oid !== customer?._id.$oid),
            );
            // Add customer to the new folder according to states field
            if (customer?.states) {
              if (customer?.states?.open) {
                inboxBuf[EnumInboxStates.open].unshift(customer);
                inboxBuf[EnumInboxStates[customer?.states?.open?.state]]?.unshift(customer);
                countsBuf.open = countsBuf.open + 1;
                if (activeChat?._id?.$oid !== lastActiveChatId && lastActiveChatId !== null) {
                  navigate('/messages/open');
                }
              } else {
                inboxBuf[EnumInboxStates[Object.keys(customer?.states)?.[0]]].unshift(customer);
                countsBuf[Object.keys(customer?.states)?.[0]] =
                  countsBuf[Object.keys(customer?.states)?.[0]] + 1;
              }
            }
          } else {
            inboxBuf = inbox.map((items) =>
              items.map((item) => (item?._id.$oid !== customer?._id.$oid ? item : customer)),
            );
          }
        }
      }
      // sort inbox items by message date
      inboxBuf.map((items) => {
        return items.sort((a, b) => {
          if (b?.message?.time?.date && a?.message?.time?.date)
            return new Date(b.message.time.date).valueOf() - new Date(a.message.time.date).valueOf();
          return 0;
        });
      });
      updateInbox(inboxBuf);
      setCounts(countsBuf);
      updateMessagesIsRead(customer);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChatClose = () => {
    setShowChat(false);
  };

  useEffect(() => {
    if (showChat === false) {
      setActiveChat(null);
      cleanUp('activeChat');
      cleanUp('ActiveChatId');
    }
  }, [showChat])

  // Messages
  const getMessages = (item) => {
    if (item?._id?.$oid && item?.entity) {
      const msgs = retrieve.json(item._id.$oid, []);
      if (msgs.length > 0) {
        setLoading(false);
        setMessageData(msgs);
        setMessagesPage(Math.ceil(msgs.length / perPage));
      }

      return getData(`/messages/listings/${item.entity}/${item._id.$oid}/1,${perPage}`)
        .then((data) => {
          setTotalMessages(data.meta.pagination.total);
          setMessageData(data.data);
          setMessagesPage(Math.ceil(data.data.length / perPage));
          triggerScrollMessages(!scrollMessages);
          store.json(item._id.$oid, data.data);
          return data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(true);
        });
    }
  };

  const getMoreMessages = () => {
    getData(
      `/messages/listings/${activeChat?.entity}/${activeChat?._id.$oid}/${
        messagesPage + 1
      },10`,
    )
      .then((data) => {
        if (data?.data && data?.data.length > 0) {
          setMessageData([...messageData, ...data.data]);
          setMessagesPage(messagesPage + 1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const reloadActiveChatMessages = () => {
    if (activeChat?._id?.$oid) {
      if (messageInputRef && messageInputRef?.current) {
        messageInputRef?.current?.focus();
      }
      store.string('ActiveChatId', activeChat?._id?.$oid);
      store.json('activeChat', activeChat);
      getMessages(activeChat);
      markInboxRead(activeChat);
    }
  };

  const findMessageBySendId = (sendId) => {
    return messageDataSent?.find((item) => item?.sendId === sendId);
  };

  const setMessageToActiveChat = (message) => {
    try {
      setMessageData((messageData: any) => {
        if (message?.type === MessageType.REACTION) {
          const messageId = message?.body?.reaction?.messageId ?? null;
          if (messageId) {
            const foundReactionTo = messageData.find((item) => item?._id?.$oid === messageId);
            foundReactionTo.reactionFromProviderMessageId = message?.rawResponse?.reaction?.message_id;
            foundReactionTo.reaction = message?.body?.reaction?.reaction;
            return messageData.map((item) => (item?._id?.$oid === messageId ? foundReactionTo : item));
          }
        }
        if (message?.sendId && message?._id?.$oid) {
          const foundSendId = messageData.find((item) => item?.sendId === message?.sendId);
          if (foundSendId) {
            setMessageDataSent((messageDataSent) => [
              { _id: message?._id, sendId: message?.sendId },
              ...messageDataSent,
            ]);
            return messageData.map((item) => (item?.sendId === message?.sendId ? message : item));
          }
        } else if (message?.sendId) {
          setMessageDataSent([message, ...(messageDataSent ?? [])]);
        }

        if (message?._id?.$oid) {
          const found = messageData.find((item) => item?._id?.$oid === message?._id?.$oid);
          if (found) {
            triggerScrollMessages(!scrollMessages);
            return messageData.map((item) =>
              item?._id?.$oid === message?._id?.$oid ? message : item,
            );
          }
        }

        return [message, ...messageData];
      });
      updateInbox((inbox) => {
        return inbox.map((items) =>
          items.map((item) => {
            if (item._id.$oid === message.chatId) {
              item.message = message;
              item.resolved = [];
            }
            return item;
          }),
        );
      });
      if (activeChat?._id?.$oid) {
        store.json(activeChat?._id?.$oid, messageData);
      }
      triggerScrollMessages(!scrollMessages);
    } catch (error) {
      console.error(error);
    }
  };

  const setMessageToNonActiveChat = (message) => {
    try {
      setNewSmallChatMessage(message);
      updateInbox((inbox) => {
        return inbox.map((items) =>
          items.map((item) => {
            if (item?._id.$oid === message.chatId) {
              item.message = message;
              item.resolved = [];
            }
            return item;
          }),
        );
      });

      const foundAlertMsg = alertMessages.find((item) => item?._id.$oid === message?._id?.$oid);
      if (!foundAlertMsg && message.hasOwnProperty('_id') && message?._id.hasOwnProperty('$oid')) {
        alertMessages.push(message);
        // message for popup should be here:
        let shortMsg = message;
        if (message?.content) {
          shortMsg = message?.content;
        }
        try {
          const messageContent = JSON.parse(shortMsg);
          if (messageContent.hasOwnProperty('txt')) shortMsg = messageContent.txt;
        } catch (e) {
        }
        let alertMessage = shortMsg;
        alertMessage =
          (message?.titleFrom || '') + ' -> ' + (message?.titleTo || '') + ': ' + alertMessage;
        logMessage(alertMessage);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Small Chats
  const openSmallChat = (chatData) => {
    if (smallChats.filter((chat) => chat._id.$oid === chatData._id.$oid).length === 0) {
      setSmallChats([...smallChats, chatData]);
    }
  };
  const closeSmallChat = (chatData) => {
    setSmallChats(smallChats.filter((chat) => chat._id.$oid !== chatData._id.$oid));
  };

  // Wamp
  const wampHandlers = {
    message: (message: any) => {
      if (message && message?.message) {
        if (message.message.chatId === retrieve.string('ActiveChatId')) {
          setMessageToActiveChat(message.message);
        } else {
          setMessageToNonActiveChat(message.message);
        }
      }
    },
    followup: (message: any) => {
      if (message && message?.followup) {
        // alert.show(message?.message);
      }
    },
    customer: (message: any) => {
      if (message && message?.customer) {
        if (!('states' in message.customer)) {
          // TODO: HACK used for fix of bug related to wrong database structure
          message.customer.states = {};
          if ('resolved' in message.customer) {
            message.customer.states.resolved = message.customer?.resolved;
          } else {
            message.customer.states.open = {
              state: 'new',
              createdAt: message.customer?.updatedAt,
            };
          }
          if ('followup' in message.customer) {
            message.customer.states.followup = message.customer?.followup;
          }
        }
        if (Object.keys(message.customer).length > 0) {
          setWampCustomer(message.customer);
        }
      } else {
        console.error('wamp[handler]', inbox);
      }
    },
  };

  return (
    <>
      {!params.folder ? (
        <Navigate to="/messages/open" />
      ) : (
        <>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
            <Header />
          </ErrorBoundary>
          {(width > 768) ? (
            <PageContainer>
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
                <Nav currentStep="messages" subStep={params.folder} />
              </ErrorBoundary>
              <Body>
                <ErrorBoundary
                  FallbackComponent={ErrorBoundaryFallback}
                  onError={ErrorBoundaryHandler}
                >
                  {InboxBlock(inbox, getInbox, filters, setFilters, sort, setSort, setQuery, stores, users, handleChatOpen, isEmptyData, openInboxItems, newInboxItems, inProgressInboxItems, resolvedInboxItems, followedInboxItems, escalatedInboxItems, handlePageChange, inboxPage, params?.folder, query, openSmallChat)}
                  {showChat ? MessagesBlock(messageData, currentUser, handleChatClose, handleEscalate, handleResolved, handleFollowUp, wampHandlers, getMoreMessages, totalMessages, scrollMessages, getInbox, findMessageBySendId, users, tokenValues, setShowChat, openSmallChat, activeStore) : (
                    <DefaultText>
                      <div>
                        <FormattedMessage
                          id="messages.default.text"
                          defaultMessage="Please choose Inbox chat"
                        />
                      </div>
                    </DefaultText>
                  )}
                  {inbox.reduce((prev, current) => prev + current.length, 0) > 0 && (
                    <SmallChatsContainer>
                      {smallChats.map((smallChat, index) => (
                        <SmallChat
                          key={`smallChat-${index}`}
                          item={smallChat}
                          index={index}
                          newMessage={newSmallChatMessage}
                          currentUser={currentUser?.data}
                          wampHandlers={wampHandlers}
                          closeSmallChat={closeSmallChat}
                          responsesData={currentUser?.data?.fastResponses}
                          tokenValues={tokenValues}
                          handleChatOpen={handleChatOpen}
                        />
                      ))}
                    </SmallChatsContainer>
                  )}
                </ErrorBoundary>
              </Body>
            </PageContainer>
          ) : (showChat ?
              <PageContainerMessages>
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
                  <Nav currentStep="messages" subStep={params.folder} />
                </ErrorBoundary>
                <Body>
                  <ErrorBoundary
                    FallbackComponent={ErrorBoundaryFallback}
                    onError={ErrorBoundaryHandler}
                  >
                    {MessagesBlock(messageData, currentUser, handleChatClose, handleEscalate, handleResolved, handleFollowUp, wampHandlers, getMoreMessages, totalMessages, scrollMessages, getInbox, findMessageBySendId, users, tokenValues, setShowChat, openSmallChat, activeStore)}
                  </ErrorBoundary>

                </Body>
              </PageContainerMessages>
              :
              <PageContainerInbox>
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
                  <Nav currentStep="messages" subStep={params.folder} />
                </ErrorBoundary>
                <Body>
                  <ErrorBoundary
                    FallbackComponent={ErrorBoundaryFallback}
                    onError={ErrorBoundaryHandler}
                  >
                    {InboxBlock(inbox, getInbox, filters, setFilters, sort, setSort, setQuery, stores, users, handleChatOpen, isEmptyData, openInboxItems, newInboxItems, inProgressInboxItems, resolvedInboxItems, followedInboxItems, escalatedInboxItems, handlePageChange, inboxPage, params?.folder, query, openSmallChat)}
                  </ErrorBoundary>

                </Body>
              </PageContainerInbox>
          )}
        </>
      )}
      {showConnectStoreMessage && (
        <PageContainer>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
            <Nav currentStep="messages" subStep={params.folder} />
          </ErrorBoundary>
          <Body>
            <GlobalMessage>
              <FormattedMessage id="messages.global.greeting" defaultMessage="Hi, " />
              {currentUser?.data?.name}!<br />
              <FormattedMessage
                id="messages.global.text"
                defaultMessage="Please connect your first store to start the conversation"
              />
            </GlobalMessage>
          </Body>
        </PageContainer>
      )}
    </>
  )
}

export default Messages;
