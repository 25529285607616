import styled from 'styled-components';
import Button from '@mui/material/Button';

export const Container = styled.div`
  display: flex;
`;
export const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const AddButton = styled(Button)`
  font-size: 1rem !important;
  margin-top: 15px !important;
  color: #006876 !important;
  text-transform: capitalize !important;
`;
export const FormContainer = styled.div<{ $isPreview?: boolean; $isPreviewTicket?: boolean }>`
  ${(props) =>
  props.$isPreview || props.$isPreviewTicket
    ? `
    .step-content-0 .form-row-0 {
      padding-bottom: 10px;
      border-bottom: 1px solid #E8E8E8;
      padding-bottom: 10px;
      width: 100%;
    }
  `
    : ''}`;

