import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  @media ${mediaQueries.desktop} {
  }
`;

export const Tr = styled.tr``;
export const Th = styled.th`
  background: #f3f2f7;
  padding: 15px 20px;
  border-bottom: 1px solid #e9ecef;
  text-align: left;
  color: #535873;
  font-size: 1rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  button {
    background: transparent;
    border: none;
    color: #535873;
    font-size: 1rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 0;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    padding-right: 25px;
    &.ascending {
      ::before {
        display: none;
      }
    }
    &.descending {
      ::after {
        display: none;
      }
    }
    ::after {
      transform: rotate(180deg);
      right: 2px !important;
      top: auto !important;
      bottom: -5px;
    }
    ::before,
    ::after {
      content: '';
      opacity: 0.5;
      position: absolute;
      right: 0;
      width: 16px;
      height: 14px;
      top: -5px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'%3E%3Cdefs%3E%3CclipPath%3E%3Cpath fill='%2300f' fill-opacity='.514' d='m-7 1024.36h34v34h-34z'/%3E%3C/clipPath%3E%3CclipPath%3E%3Cpath fill='%23aade87' fill-opacity='.472' d='m-6 1028.36h32v32h-32z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cpath d='m345.44 248.29l-194.29 194.28c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744l171.91-171.91-171.91-171.9c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.29 194.28c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373' transform='matrix(-.00013-.03541.03541-.00013 3.02 19.02)' fill='%234d4d4d'/%3E%3C/svg%3E");
    }
  }
`;
export const Td = styled.td`
  padding: 15px 20px;
  border-bottom: 1px solid #e9ecef;
`;
