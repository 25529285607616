import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Content, Loader, Message } from '../styles';
import { Copy, InnerContainer, TextContainer } from './styles';
import DeliveryStatus from '../../DeliveryStatus';
import LastErrorMessage from '../../LastErrorMessage';
import Avatar from '../../Avatar';
import ErrorMessage from '../../ErrorMessage';

export default ({ client, message, resend, customerDataStoreTimezone, more = null }) => {
  return (
    <Message isClient={client} key={message?._id?.$oid + message?.sendId}>
      <Content>
        <InnerContainer>
          {!message?.isClient && message?.lastSendError && (
            <IconButton aria-label="resend" onClick={() => resend(message?._id?.$oid)}>
              <RefreshIcon fontSize="small" />
            </IconButton>
          )}
          {(!message?.delivery ||
            message?.delivery === 'sending' ||
            message?.delivery === 'sent2api' ||
            message?.delivery === 'sent_to_api') &&
            !client && <Loader color="primary" src={'/img/loader-new.gif'} />}
          <TextContainer>
            <Avatar message={message} isClient={client} />
            <div>
              {message?.body?.text && (
                <Copy
                  dangerouslySetInnerHTML={{
                    __html: message?.body?.text.replace(/\r\n|\r|\n/g, '<br />') || '',
                  }}
                />
              )}
            </div>
            {more}
          </TextContainer>
        </InnerContainer>
        <DeliveryStatus
          isClient={client}
          message={message}
          customerDataStoreTimezone={customerDataStoreTimezone}
        />
        <LastErrorMessage message={message} />
        <ErrorMessage message={message?.rawResponse?.process_msg ?? ''} />
      </Content>
    </Message>
  );
};
