import styled from 'styled-components';

export const MixedTagsContainer = styled.div`
  .rta {
    :before {
      content: 'Message';
      position: absolute;
      z-index: 999;
      font-size: 12px;
      top: -4px;
      left: 10px;
      height: 10px;
      padding: 0 5px;
      color: rgba(0, 0, 0, 0.54);
      background: white;
    }
  }
  .rta__textarea {
    min-height: 100px;
    position: relative;
    border-radius: 4px;
    padding: 10px;
    border-color: rgba(0, 0, 0, 0.23);
    font-family: 'Roboto', 'Helvetica Neue', sans-serif !important;
    color: rgb(51, 77, 110);
    font-size: 16px;
    :hover {
      border-color: rgba(0, 0, 0, 0.87);
    }
    :focus {
      border: 1px solid #3f51b5;
    }
  }
  .rta__autocomplete {
    position: absolute;
    z-index: 9;
    background-color: #fff;
    display: block;
    margin-top: 1em;
  }
`;

export const Tokens = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const TokensInner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  max-width: 0;
  margin-top: 15px;
  padding-bottom: 10px;
  button {
    margin-right: 10px;
    flex: 1 0 auto;
  }
`;
