import { createContext, useState } from 'react';

export const GlobalUpdateQueryContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [globalUpdateQuery, setGlobalUpdateQuery] = useState({});

  return (
    <GlobalUpdateQueryContext.Provider value={[globalUpdateQuery, setGlobalUpdateQuery]}>
      {children}
    </GlobalUpdateQueryContext.Provider>
  );
};
