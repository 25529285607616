import { Container } from './styles';
import FormElement from '../FormElement';

export const FormCopyableItem = ({
  index,
  formRowIndex,
  inputValues,
  requiredFields,
  setInputValues,
  formRow,
  copyableItemIndex,
}) => {
  return (
    <Container>
      {formRow.children.rows?.map((row, rowIndex) => {
        return (
          <FormElement
            key={`copyable-element-${rowIndex}`}
            index={index}
            input={row?.children?.[0]?.[0] || row?.children?.[0]}
            inputValues={inputValues}
            requiredFields={requiredFields}
            formRowIndex={formRowIndex}
            formRowElementIndex={rowIndex}
            setInputValues={setInputValues}
            inputLabels={{}}
            setInputLabels={() => {}}
            formRow={formRow}
            copyableItemIndex={copyableItemIndex}
            isPreview={false}
          />
        );
      })}
    </Container>
  );
};
export default FormCopyableItem;
