import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media ${mediaQueries.desktop} {
    max-width: 900px;
  }
`;
export const Center = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  align-items: center;
`;
export const ButtonsContainer = styled.div`
  margin: 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    margin: 0 10px;
  }
`;
export const Button = styled.button`
  width: 115px;
  padding: 7px 0;
  border: 2px solid #006876;
  border-radius: 40px;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
`;
