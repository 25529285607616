import { FormattedMessage } from 'react-intl';
import {
  Container,
  Title,
  Day
} from './styles';

const FormWeekDays = ({
  input,
  field,
  itemIndex,
  inputValues,
  setInputValues
}) => {
  const handleChange = (prop, field, itemIndex) => (event) => {
    let copy = inputValues[field].slice();
    if (event.target.checked) {
      copy.splice(itemIndex, 1, { ...copy[itemIndex], [prop]: [ ...copy[itemIndex][prop], event.target.value ] });
    } else {
      copy.splice(itemIndex, 1, { ...copy[itemIndex], [prop]: copy[itemIndex][prop].filter(e => e !== event.target.value) });
    }
    setInputValues({...inputValues, [field]: copy })
  }

  return (
    <Container>
      <Title><FormattedMessage id={input.label} defaultMessage={input.label} /></Title>
      <Day htmlFor={`${input.id}-mon-${itemIndex}`}>
        <input
          name={input.id}
          id={`${input.id}-mon-${itemIndex}`}
          type="checkbox"
          value="Mon"
          checked={inputValues?.[field]?.[itemIndex]?.[input.id]?.includes('Mon')}
          onChange={(event) =>
            handleChange(input.id, field, itemIndex)(event)
          }
        />
        <div><FormattedMessage id={input.options.Mon} defaultMessage={input.options.Mon} /></div>
      </Day>
      <Day htmlFor={`${input.id}-tue-${itemIndex}`}>
        <input
          name={`${input.id}-${itemIndex}`}
          id={`${input.id}-tue-${itemIndex}`}
          type="checkbox"
          value="Tue"
          checked={inputValues?.[field]?.[itemIndex]?.[input.id]?.includes('Tue')}
          onChange={(event) =>
            handleChange(input.id, field, itemIndex)(event)
          }
        />
        <div><FormattedMessage id={input.options.Tue} defaultMessage={input.options.Tue} /></div>
      </Day>
      <Day htmlFor={`${input.id}-wed-${itemIndex}`}>
      <input
          name={`${input.id}-${itemIndex}`}
          id={`${input.id}-wed-${itemIndex}`}
          type="checkbox"
          value="Wed"
          checked={inputValues?.[field]?.[itemIndex]?.[input.id]?.includes('Wed')}
          onChange={(event) =>
            handleChange(input.id, field, itemIndex)(event)
          }
        />
        <div><FormattedMessage id={input.options.Wed} defaultMessage={input.options.Wed} /></div>
      </Day>
      <Day htmlFor={`${input.id}-thu-${itemIndex}`}>
      <input
          name={`${input.id}-${itemIndex}`}
          id={`${input.id}-thu-${itemIndex}`}
          type="checkbox"
          value="Thu"
          checked={inputValues?.[field]?.[itemIndex]?.[input.id]?.includes('Thu')}
          onChange={(event) =>
            handleChange(input.id, field, itemIndex)(event)
          }
        />
        <div><FormattedMessage id={input.options.Thu} defaultMessage={input.options.Thu} /></div>
      </Day>
      <Day htmlFor={`${input.id}-fri-${itemIndex}`}>
        <input
          name={`${input.id}-${itemIndex}`}
          id={`${input.id}-fri-${itemIndex}`}
          type="checkbox"
          value="Fri"
          checked={inputValues?.[field]?.[itemIndex]?.[input.id]?.includes('Fri')}
          onChange={(event) =>
            handleChange(input.id, field, itemIndex)(event)
          }
        />
        <div><FormattedMessage id={input.options.Fri} defaultMessage={input.options.Fri} /></div>
      </Day>
      <Day htmlFor={`${input.id}-sat-${itemIndex}`}>
        <input
          name={`${input.id}-${itemIndex}`}
          id={`${input.id}-sat-${itemIndex}`}
          type="checkbox"
          value="Sat"
          checked={inputValues?.[field]?.[itemIndex]?.[input.id]?.includes('Sat')}
          onChange={(event) =>
            handleChange(input.id, field, itemIndex)(event)
          }
        />
        <div><FormattedMessage id={input.options.Sat} defaultMessage={input.options.Sat} /></div>
      </Day>
      <Day htmlFor={`${input.id}-sun-${itemIndex}`}>
        <input
          name={`${input.id}-${itemIndex}`}
          id={`${input.id}-sun-${itemIndex}`}
          type="checkbox"
          value="Sun"
          checked={inputValues?.[field]?.[itemIndex]?.[input.id]?.includes('Sun')}
          onChange={(event) =>
            handleChange(input.id, field, itemIndex)(event)
          }
        />
        <div><FormattedMessage id={input.options.Sun} defaultMessage={input.options.Sun} /></div>
      </Day>
    </Container>
  );
};
export default FormWeekDays;
