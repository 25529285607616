import { useState } from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import Error from '../../components/Error';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  useFetchTemplateQuery,
  useAutoRepliesQuery,
  useUpdateStatusAutoReplyMutation,
  useDeleteAutoReplyMutation
} from '../../api/autoReplyAPI';
import { setValuesFormTemplate } from '../../api/setValuesFormTemplate';
import Popup from '../../components/Popup';
import AutoReplyForm from '../../containers/OutOfOfficeForm';
import { getLogo } from '../../api/getLogo';

const Wrapper = styled.div`
  min-height: calc(100% - 70px);
  .popup-wrapper {
    min-width: 550px;
    max-width: 550px;
    padding: 0;

    @media ${mediaQueries.mobile} {
      min-width: 360px;
      max-width: 360px;
    }
    > div {
      max-width: 100%;
      margin: 24px;
    }
  }
  .MuiDataGrid-cell {
    outline: none !important;
  }
`;

const Container = styled.div`
  height: 100%;
  padding: 0 20px 20px;
  @media ${mediaQueries.desktop} {
  }
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h1`
  font-weight: 700;
  font-size: 16px;
  line-height: 0px;
  text-transform: uppercase;
  color: #050d42;
`;
const StyledButton = styled(Button)`
  width: 138px;
  height: 30px;
  background: #006876 !important;
  border-radius: 40px !important;
`;
const ModalTitle = styled.h3`
  margin-left: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #050d42;
`;
const Tag = styled.div`
  background: #F5FBFF;
  border: 1px solid #6E6E6E;
  border-radius: 6px;
  color: #050D42;
  font-size: 12px;
  line-height: 14px;
  padding: 3px 6px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  img {
    width: 15px;
    margin-right: 4px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const Text = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
`;
const BackButton = styled(Button)`
  width: 67px;
  height: 36px;
  padding: 8px 16px;
  background: #fff !important;
  color: #006876 !important;
  border-radius: 20px !important;
  line-height: 1 !important;
  border: 2px solid #006876 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
`;
const SaveButton = styled(Button)`
  width: 67px;
  height: 36px;
  padding: 8px 16px;
  background: #006876 !important;
  border-color: #006876 !important;
  border-radius: 20px !important;
  line-height: 1 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
`;

const OutOfOffice = () => {
  const [inputValues, setInputValues] = useState({});
  const [currentAutoReplyId, setCurrentAutoReplyId] = useState(null);
  const [showAutoReplyPopup, setShowAutoReplyPopup] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { data: templateAutoReply, error: errorTemplate } = useFetchTemplateQuery(true);
  const [deleteAutoReply, { error: errorDelete }] = useDeleteAutoReplyMutation();
  const { data: autoReplies, error: errorQuery } = useAutoRepliesQuery(true);
  const [updateStatusAutoReply, { error: errorUpdate }] = useUpdateStatusAutoReplyMutation();
  const [requiredFields, setRequiredFields] = useState({});
  const handleFormOpen = () => {
    let temp = setValuesFormTemplate(templateAutoReply?.data, null, () =>
    Object.assign(
      {},
      {
        weekDays: [],
        timeRange: {
          from: "",
          till: ""
        }
      },
    ),);
    setInputValues(temp.inputValues);
    setRequiredFields(temp.requiredFields);
    setShowAutoReplyPopup(true);
  };
  const closePopupAndClear = () => {
    setCurrentAutoReplyId(null);
    setShowDeleteConfirmation(false);
    setShowAutoReplyPopup(false);
  };
  const handleStatusChange = async (id, status) => {
    await updateStatusAutoReply({
      id: id,
      autoReply: {
        status: status === 'active' ? 'inactive' : 'active',
      },
    });
  }
  const findCurrentAutoReply = (id) => {
    return autoReplies?.data?.find((item) => item?._id?.$oid === id);
  };
  const handleEditPopup = (id) => {
    const find = { ...findCurrentAutoReply(id), isEditable: true}
    let temp = setValuesFormTemplate(templateAutoReply?.data, find);
    setInputValues(temp.inputValues);
    setRequiredFields(temp.requiredFields);
    setShowAutoReplyPopup(true);
  }
  const handleDelete = () => {
    deleteAutoReply(currentAutoReplyId);
    closePopupAndClear();
  }

  const handleDeletePopup = (id) => {
    setCurrentAutoReplyId(id);
    setShowDeleteConfirmation(true);
    setShowAutoReplyPopup(true);
  };

  return (
    <Wrapper>
      {showAutoReplyPopup && (
        <Popup
          isPreviewTicket={false}
          setIsPreviewTicket={() => {}}
          handleClose={closePopupAndClear}
        >
          <ModalTitle>
            <FormattedMessage id="auto-reply.form.title" defaultMessage="Out of office autoreply" />
          </ModalTitle>
          {(showDeleteConfirmation && (
            <>
              <Text>
                <FormattedMessage id="auto-reply.modal.delete.confirm" />
              </Text>
              <ButtonsContainer>
                <BackButton variant="outlined" onClick={closePopupAndClear}>
                  <FormattedMessage id="auto-reply.popup.button.no" />
                </BackButton>
                <SaveButton variant="contained" color="primary" onClick={handleDelete}>
                  <FormattedMessage id="auto-reply.popup.button.yes" />
                </SaveButton>
              </ButtonsContainer>
            </>
          )) || (
            <AutoReplyForm
              inputValues={inputValues}
              setInputValues={setInputValues}
              setShowAutoReplyPopup={setShowAutoReplyPopup}
              requiredFields={requiredFields}
            />
          )}
        </Popup>
      )}

      <Container>
        <TitleContainer>
          <Title>
            <FormattedMessage id="auto-reply.title.label" />
          </Title>
          <StyledButton variant="contained" onClick={handleFormOpen}>
            <FormattedMessage id="auto-reply.title.button" />
          </StyledButton>
        </TitleContainer>
        {(errorTemplate && 'error' in errorTemplate && (
          <Error message={errorTemplate.error} />
        )) ||
        (errorUpdate && 'error' in errorUpdate && (
          <Error message={errorUpdate.error} />
        )) ||
        (errorDelete && 'error' in errorDelete && (
          <Error message={errorDelete.error} />
        )) ||
        (errorQuery && 'error' in errorQuery && <Error message={errorQuery.error} />)}

        {autoReplies?.data?.length ? (
          <DataGrid
            columns={[
              {
                field: 'Name',
                flex: 1
              },
              {
                field: 'Status',
                flex: 1,
                renderCell: (cellValues) => {
                  return <Switch
                    checked={cellValues.row.Status !== 'inactive' }
                    onChange={() => handleStatusChange(cellValues.row.id, cellValues.row.Status) }
                    inputProps={{ 'aria-label': 'status' }}
                  />
                }
              },
              {
                field: 'Platform',
                flex: 1,
                renderCell: (cellValues) => {
                  return <>
                          {cellValues.row.Platform.map((platform) => <span key={`channel-${platform}-${Math.random()}`}>&nbsp;<img width="20" src={getLogo(platform)} alt="" /></span>)}
                        </>;
                }
              },
              {
                field: 'Store',
                flex: 1,
                renderCell: (cellValues) => {
                  return <>
                          {cellValues.row.Store.map((channel) => <Tag key={`channel-${channel.name}-${Math.random()}`}><img src={`https://flagcdn.com/w20/${channel?.countryCode?.toLowerCase()}.png`} alt="" />{channel.name}</Tag>)}
                        </>;
                }
              },
              {
                field: 'Actions',
                flex: 1,
                renderCell: (cellValues) => {
                  return <>
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleEditPopup(cellValues.row.id)}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDeletePopup(cellValues.row.id)}
                          >
                            <DeleteOutlineIcon fontSize="small" />
                          </IconButton>
                        </>;
                }
              },
            ]}
            rows={autoReplies?.data?.map((autoReply) => {
              return {
                id: autoReply._id.$oid,
                Name: autoReply.name,
                Platform: autoReply.platforms,
                Status: autoReply.status,
                Store: autoReply.channels
              }
            })}
            disableSelectionOnClick={true}
            isRowSelectable={() => false}
            autoHeight
          />
        ) : null}
      </Container>
    </Wrapper>
  );
};

export default OutOfOffice;
