import styled from 'styled-components';
import Avatar from '@mui/material/Avatar';

export const StyledAvatar = styled(Avatar)`
  width: 20px !important;
  height: 15px !important;
  margin-left: 10px !important;
  border-radius: 0 !important;

  span {
    font-size: 1rem;
  }
`;
export const StyledAvatarChannel = styled(StyledAvatar)`
  width: 15px !important;
`;
