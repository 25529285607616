import { FormattedMessage } from 'react-intl';
import { Container, StyledAccordionItemHeading, StyledAccordionItemPanel } from './styles';
import { Accordion, AccordionItem, AccordionItemButton } from 'react-accessible-accordion';
import { FormRow } from '../FormRow';

export const FormAccordion = ({
  row,
  index,
  title,
  inputValues,
  requiredFields,
  setInputValues,
  inputLabels,
  setInputLabels,
  isPreview,
}) => {
  return (
    <Container>
      <Accordion allowZeroExpanded>
        <AccordionItem>
          <StyledAccordionItemHeading>
            <AccordionItemButton>
              <FormattedMessage id={title} defaultMessage={title} />
            </AccordionItemButton>
          </StyledAccordionItemHeading>
          <StyledAccordionItemPanel>
            {row.map((innerRow, innerRowIndex) => (
              <FormRow
                key={`accordion-row${innerRowIndex}`}
                index={index}
                inputValues={inputValues}
                requiredFields={requiredFields}
                formRowIndex={innerRowIndex}
                setInputValues={setInputValues}
                inputLabels={inputLabels}
                setInputLabels={setInputLabels}
                formRow={innerRow}
                isPreview={isPreview}
              />
            ))}
          </StyledAccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </Container>
  );
};
export default FormAccordion;
