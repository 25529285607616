import { createContext, useState } from 'react';

export const ProductsContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [products, setProducts] = useState([]);
  const [productsCounts, setProductsCounts] = useState(0);

  return (
    <ProductsContext.Provider value={[products, setProducts, productsCounts, setProductsCounts]}>
      {children}
    </ProductsContext.Provider>
  );
};
