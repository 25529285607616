import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { mediaQueries } from '../../constants/breakpoints';

const Container = styled.div`
  position: absolute;
  z-index: 3;
  background: rgba(94, 90, 90, 0.4);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const AlertWrapper = styled.div`
  width: 280px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.25);
  position: relative;
  padding: 20px 20px 0;
  @media ${mediaQueries.desktop} {
    width: 325px;
  }
`;

const StyledIconButton = styled(IconButton)`
  color: #fff;
  position: absolute !important;
  top: -40px;
  right: -40px;
  svg {
    color: #fff;
  }
`;

const Alert = ({ handleClose, ...props }) => {
  return (
    <Container>
      <AlertWrapper>
        <StyledIconButton aria-label="hide" onClick={handleClose}>
          <CancelIcon fontSize="small" />
        </StyledIconButton>
        {props.children}
      </AlertWrapper>
    </Container>
  );
};

export default Alert;
