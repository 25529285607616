import { postData } from '../modules/fetch';
import { logMessage } from '../logger';

const postAccountResetConfirm = (requestBody, successCallback, errorCallback) => {
  postData('/account/reset/confirm', requestBody)
  .then((data) => {
    logMessage(data);
    successCallback(true);
  })
  .catch((err) => {
    console.error(err);
    errorCallback(err.message);
  });
}

export default postAccountResetConfirm;
