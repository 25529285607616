import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { mediaQueries } from '../../constants/breakpoints';

const Container = styled.div<{ $isPreviewTicket?: boolean }>`
  position: absolute;
  z-index: 11;
  top: 0;
  width: 100vw;
  height: calc(100vh - 61px);
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.$isPreviewTicket
      ? `
      left: calc(380px - 100vw);
  `
      : `
      left: 0;
    `}
`;
const InnerContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
`;
const Shadow = styled.div`
  position: absolute;
  max-height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 0;
  background: rgba(37, 35, 35, 0.5);
`;
const PopupWrapper = styled.div`
  max-width: 900px;
  background: #fff;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.25);
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  min-width: 900px;
  border-radius: 16px;
  padding: 25px;
  @media ${mediaQueries.mobile} {
    min-width: 360px;
  }
`;

const StyledIconButton = styled(IconButton)`
  margin-top: -35px !important;
  @media ${mediaQueries.mobile} {
    margin-right: -40px !important;
    right: 40px;
    top: -10px;
  }
  svg {
    color: #fff;
    font-size: 25px;
  }
`;

export const Popup = ({ isPreviewTicket, setIsPreviewTicket, handleClose, ...props }) => {
  return (
    <Container $isPreviewTicket={isPreviewTicket}>
      <Shadow onClick={handleClose} />
      <InnerContainer>
        <PopupWrapper className={'popup-wrapper'}>
          {props.children}
        </PopupWrapper>
        <StyledIconButton aria-label="hide" onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>
      </InnerContainer>
    </Container>
  );
};

export default Popup;
