import { useEffect, useState } from 'react';
import { Center, Container } from './styles';
import FormTemplateSteps from '../../components/FormTemplateSteps';
import { useAddReportMutation, useFetchTemplateReportQuery, } from '../../api/reportAPI';
import Error from '../../components/Error';
import { setValuesFormTemplate } from '../../api/setValuesFormTemplate';
import { FormattedMessage } from 'react-intl';
import { standardJsonResponseSync } from '../../modules/fetch';
import { logMessage } from '../../logger';

export const ReportForm = ({
                             onClose,
                             isPreview,
                             setIsPreview,
                             isPreviewTicket,
                             content,
                             customerId,
                           }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [inputValues, setInputValues] = useState({});
  const [requiredFields, setRequiredFields] = useState({});
  const [inputLabels, setInputLabels] = useState({});
  const [error, setError] = useState('unknown.error');
  const { data: templateReport, error: errorTemplate, isError: isErrorTemplate } = useFetchTemplateReportQuery(customerId);
  const [addReport] = useAddReportMutation();

  useEffect(() => {
    if (isErrorTemplate) {
      // @ts-ignore
      logMessage('result', errorTemplate.data, { json: () => errorTemplate.data }, { status: errorTemplate.status, json: () => errorTemplate.data });
      // @ts-ignore
      let response = { ...errorTemplate };
      let result;
      try {
        result = standardJsonResponseSync(response)
        setError('unknown.error');
      } catch (ResponseError) {
        console.warn('ResponseError', ResponseError);
        setError(ResponseError.message)
      }

      logMessage('result', result);
    }
  }, [isErrorTemplate]);

  useEffect(() => {
    if (content && Object.keys(content).length !== 0) {
      setInputValues(content);
      setInputLabels(content);
    } else {
      let temp = setValuesFormTemplate(templateReport?.data ?? {});
      setInputValues(temp.inputValues);
      setRequiredFields(temp.requiredFields);
      setInputLabels(temp.inputValues);
    }
  }, [templateReport]);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const report = Object.assign({}, inputValues, inputLabels);
    await addReport({
      id: customerId,
      ticket: {
        report: report,
      },
    }).then((response) => {
      if (response) {
        // @ts-ignore
        if (response?.error?.status && response?.error?.status !== 200) {
          // @ts-ignore
          const errors = response?.error?.data?.errors;
          if (errors) {
            const message = errors.map(item => {
              const regex = /(\w+)\]$/g;
              const exec = regex.exec(item.path);
              const field = exec?.[1];
              if (field) {
                let fieldName = String(field).replace(/([-_]\w)/g, g => ' ' + g[1].toUpperCase())
                fieldName = fieldName.slice(0, 1).toUpperCase() + fieldName.slice(1);
                return `${fieldName}: ${item.message}`
              }
            });
            alert('Error' + '\n' + message.join('\n'));
          } else {
            alert('Error' + '\n' + JSON.stringify(response));
          }
        } else {
          // @ts-ignore
          if (response?.data?.meta?.statusCode && response?.data?.meta?.statusCode === 200) {
            onClose();
          }
        }
      }
    });
  };

  return (
    <Container>
      {!templateReport?.data && (
        <>
          <Center><FormattedMessage id={'modal.loading'} defaultMessage={'Loading...'} /></Center>
        </>
      )}
      {(isErrorTemplate && <Error message={error ?? 'unknown.error'} />) ||
        (templateReport?.data?.length ? (
          <FormTemplateSteps
            inputValues={inputValues}
            requiredFields={requiredFields}
            setInputValues={setInputValues}
            inputLabels={inputLabels}
            setInputLabels={setInputLabels}
            handleFormSubmit={handleFormSubmit}
            reportFormData={templateReport?.data}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            isPreview={isPreview}
            setIsPreview={setIsPreview}
            isPreviewTicket={isPreviewTicket}
          />
        ) : null)}
    </Container>
  );
};
export default ReportForm;
