import styled from 'styled-components';
import { AuthorName, ItemHead } from '../styles';

export const StyledAuthorName = styled(AuthorName)`
  align-self: center !important;
`;
export const RefundRequest = styled.div`
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  ${ItemHead} {
    background: #fff;
    border-bottom: 1px solid #e6e6e6;
  }
`;
export const RefundRequestID = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #050d42;
  span {
    display: inline-block;
    margin-left: 5px;
  }
`;
export const RefundRequestReason = styled.div`
  padding: 10px 20px;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #050d42;
  span {
    display: inline-block;
    margin-left: 5px;
  }
`;
export const RefundRequestBody = styled.div`
  display: flex;
  padding: 10px 20px;

  img {
    max-width: 55px;
    max-height: 55px;
    margin-right: 5px;
  }
`;
export const RefundRequestInfo = styled.div`
  width: 100%;
  margin-left: 5px;
  max-width: 400px;
`;
export const RefundRequestTitle = styled.a`
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #050d42;
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;
export const RefundRequestSKU = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #c5c5c5;
  margin-top: 5px;
`;
export const RefundRequestWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
export const RefundRequestWrapDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 5px 20px 10px;
  border-top: 1px solid #e6e6e6;
`;
export const RefundRequestPrice = styled.div`
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #050d42;
  margin-top: 10px;
`;
export const RefundRequestDate = styled.div`
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #050d42;
  margin-top: 10px;
  span {
    display: inline-block;
    margin-left: 5px;
  }
`;
