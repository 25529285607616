import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useIntl } from 'react-intl';

export const FormAutocompleteLabel = ({ input, inputValues, requiredFields, handleAutocompleteChange }) => {
  const intl = useIntl();

  let label = '';
  try {
    label = intl.formatMessage({ id: input?.label ?? input?.name ?? input?.id, defaultMessage: input?.label ?? input?.name ?? input?.id });
  } catch (e) {}

  const value = input.options.find((option) => option.label === inputValues?.[input.id]?.label)

  const handleValue = (event) => {
    const val = typeof event?.target?.value === 'number' ? event?.target?.textContent : event?.target?.value ?? event;
    let item: any = null;
    if (val === null && value !== null) {
      item = value
    } else {
      item = input.options.find((option) => option.label === val)
    }
    handleAutocompleteChange(input.id)(item)
  }


  return (
    <Autocomplete
      id={input.id}
      options={input.options}
      value={value ?? null}
      onInputChange={(event) => handleValue(event)}
      onChange={(event) => handleValue(event)}
      renderInput={(params) => (
        <TextField {...params} required={requiredFields[input.id]} label={label} />
      )}
    />
  );
};
export default FormAutocompleteLabel;
