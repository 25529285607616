import { ButtonsContainer, CancelButton, Container, SendButton, Text, Title } from './styles';
import { FormattedMessage } from 'react-intl';
import { StyledInputLabel } from '../FormItems/FormSelect/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const SurveysSelect = ({
  surveysList,
  selectedSurvey,
  setSelectedSurvey,
  setShowSurveysPopup,
  handleSelectedSurveySend,
}) => {
  const handleChangeSelect = (event) => {
    setSelectedSurvey(event.target.value);
  };
  return (
    <Container>
      <Title>
        <FormattedMessage id="messages.chat.surveysSelect.title" />
      </Title>
      <Text>
        <FormattedMessage id="messages.chat.surveysSelect.text" />
      </Text>
      <FormControl variant="outlined" fullWidth>
        <StyledInputLabel id={'SurveysSelectLabel'}>
          <FormattedMessage id="messages.chat.surveysSelect.surveys" />
        </StyledInputLabel>
        <Select
          labelId={'SurveysSelectLabel'}
          id={'SurveysSelect'}
          value={selectedSurvey ?? ''}
          onChange={handleChangeSelect}
        >
          {surveysList?.map((option, index) => (
            <MenuItem key={`surveys-select-${index}`} value={option?._id?.$oid}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ButtonsContainer>
        <CancelButton variant="outlined" onClick={() => setShowSurveysPopup(false)}>
          <FormattedMessage id="messages.chat.surveysSelect.cancel" />
        </CancelButton>
        <SendButton variant="contained" onClick={handleSelectedSurveySend}>
          <FormattedMessage id="messages.chat.surveysSelect.send" />
        </SendButton>
      </ButtonsContainer>
    </Container>
  );
};
export default SurveysSelect;
