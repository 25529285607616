import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, ModalTitle } from '../../components/Modal';
import { PasswordInput } from '../../components/Input';
import Button from '@mui/material/Button';
import { mediaQueries } from '../../constants/breakpoints';
import Error from '../../components/Error';
import Header from '../../components/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import postAccountResetConfirm from '../../api/postAccountResetConfirm';

const Container = styled.div`
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.desktop} {
    background-color: #f5f6f8;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  button {
    min-width: 100%;
    margin-bottom: 30px;
    @media ${mediaQueries.desktop} {
      //noinspection CssInvalidPropertyValue
      min-width: 48%;
    }
  }
`;
const Copy = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
`;
const TextFieldContainer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  > div {
    flex-grow: 1;
    &:nth-child(2) {
      margin-top: 20px;
    }
  }
  @media ${mediaQueries.desktop} {
    flex-direction: row;
    > div:nth-child(2) {
      margin: 0 0 0 15px;
    }
  }
`;
const Note = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  text-align: left;
  margin-top: 15px;
`;

const CreatePassword = () => {
  const [submitted, setSubmitted] = useState(false);
  const [verifyCode, setVerifyCode] = useState<string>();
  const [values, setValues] = useState({
    password: '',
    password_confirm: '',
  });
  const [serverError, setServerError] = useState('');
  const intl = useIntl();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setVerifyCode(urlParams.get('code'));
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const [errors, setErrors] = useState({});

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if ('password' in fieldValues)
      temp.password = fieldValues.password ? '' : 'This field is required.';

    if ('password_confirm' in fieldValues)
      temp.password_confirm = fieldValues.password_confirm ? '' : 'This field is required.';

    setErrors({
      ...temp,
    });
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.password &&
      fieldValues.password_confirm &&
      Object.values(errors).every((x) => x === '');
    return isValid;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    validate();
    const isValid = Object.values(errors).every((x) => x === '') && formIsValid();
    if (isValid) {
      const postBody = {
        resetCode: verifyCode,
        passwordUpdate: {
          password: values.password,
          password_confirm: values.password_confirm,
        },
      };
      postAccountResetConfirm(postBody, setSubmitted, setServerError);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Modal>
          {submitted ? (
            <>
              <ModalTitle>
                <FormattedMessage
                  id="createPassword.modal.success"
                  defaultMessage="New password successfully saved"
                />
              </ModalTitle>
              <ButtonsContainer>
                <Copy
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      {
                        id: 'createPassword.modal.link_login',
                        defaultMessage: 'createPassword.modal.link_login',
                      },
                      {
                        label: intl.formatMessage({
                          id: 'createPassword.modal.link_login.label',
                          defaultMessage: 'createPassword.modal.link_login.label',
                        }),
                      },
                    ),
                  }}
                />
              </ButtonsContainer>
            </>
          ) : (
            <>
              <ModalTitle>
                <FormattedMessage
                  id="createPassword.modal.new_password"
                  defaultMessage="Enter your new password"
                />
              </ModalTitle>
              {serverError && <Error message={serverError} />}
              <form autoComplete="off" onSubmit={handleFormSubmit}>
                <TextFieldContainer>
                  <PasswordInput
                    id="password"
                    value={values.password}
                    error={errors['password']}
                    autoComplete="none"
                    handleChange={handleChange('password')}
                    label={intl.formatMessage({
                      id: 'createPassword.form.new_password',
                      defaultMessage: 'createPassword.form.new_password',
                    })}
                    {...(errors['password'] && {
                      error: true,
                      helperText: errors['password'],
                    })}
                  />
                </TextFieldContainer>
                <TextFieldContainer>
                  <PasswordInput
                    id="password_confirm"
                    value={values.password_confirm}
                    error={errors['password_confirm']}
                    autoComplete="none"
                    handleChange={handleChange('password_confirm')}
                    label={intl.formatMessage({
                      id: 'createPassword.form.repeat_password',
                      defaultMessage: 'createPassword.form.repeat_password',
                    })}
                    {...(errors['password_confirm'] && {
                      error: true,
                      helperText: errors['password_confirm'],
                    })}
                  />
                </TextFieldContainer>
                <Note>
                  <FormattedMessage
                    id="createPassword.form.note"
                    defaultMessage="Note: Protect your data confidentiality. Do not share your password with anyone for any reason."
                  />
                </Note>
                <ButtonsContainer>
                  <Button size="large" variant="contained" color="primary" type="submit">
                    <FormattedMessage
                      id="createPassword.form.submit"
                      defaultMessage="Submit new password"
                    />
                  </Button>
                  <Copy
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        {
                          id: 'createPassword.modal.link_login',
                          defaultMessage: 'createPassword.modal.link_login',
                        },
                        {
                          label: intl.formatMessage({
                            id: 'createPassword.modal.link_login.label',
                            defaultMessage: 'createPassword.modal.link_login.label',
                          }),
                        },
                      ),
                    }}
                  />
                </ButtonsContainer>
              </form>
            </>
          )}
        </Modal>
      </Container>
    </>
  );
};

export default CreatePassword;
