import styled from 'styled-components';
import Button from '@mui/material/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Title = styled.h4`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #050d42;
`;
export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #050d42;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
export const CancelButton = styled(Button)`
  width: 67px;
  height: 36px;
  padding: 8px 16px;
  background: #fff !important;
  color: #006876 !important;
  border-radius: 20px !important;
  line-height: 1 !important;
  border: 2px solid #006876 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
`;
export const SendButton = styled(Button)`
  width: 67px;
  height: 36px;
  padding: 8px 16px;
  background: #006876 !important;
  border-color: #006876 !important;
  border-radius: 20px !important;
  line-height: 1 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
`;
