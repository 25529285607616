import { useState, useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { MessageContext } from '../../context/MessageContext';
import { FormattedMessage } from 'react-intl';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import '@webscopeio/react-textarea-autocomplete/style.css';
import { MixedTagsContainer, Tokens, TokensInner } from './styles';

const Container = styled.div`
  border-top: 1px solid #ebeff2;
  padding: 0 20px;
`;
const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  font-size: 1rem;
  color: #999;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
`;
const StyledButton = styled(Button)`
  margin-left: 20px !important;
`;
const Response = ({
  data,
  index,
  tokens,
  handleSend,
  handleDelete,
  handleUpdate,
  onClose,
  ...props
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(data.message);
  const rtaRef = useRef<any>();
  const onMessageChange = useCallback((e) => {
    setMessage(e.target.value);
  }, []);
  const [, setInboxMessage] = useContext(MessageContext);
  const handleSave = () => {
    handleUpdate(index, message);
    setShowMessage(false);
  };
  const handleMessageSend = () => {
    handleSend(message);
    onClose();
  };
  const handleMessageCopy = () => {
    setInboxMessage(message);
    onClose();
  };
  const handleAddTag = (token) => {
    const tokenString = `[[{"value":"${token}"}]]`;
    const caretPosition = rtaRef.current.getCaretPosition();
    const newMessage = message.slice(0, caretPosition) + tokenString + message.slice(caretPosition);
    setMessage(newMessage);
  };
  const Item = ({ entity: { name } }) => <div>{name}</div>;
  return (
    <Container {...props}>
      <Inner>
        <Title>
          <FormattedMessage
            id="messages.chat.responses.response.title"
            defaultMessage="{title}"
            values={{
              title: data.title,
            }}
          />
        </Title>
        <div>
          <IconButton
            color={showMessage ? 'primary' : 'default'}
            aria-label="edit"
            onClick={() => setShowMessage(!showMessage)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton aria-label="copy" onClick={handleMessageCopy}>
            <ContentCopyIcon />
          </IconButton>
          <IconButton aria-label="menu" onClick={() => handleDelete(index)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
          <IconButton aria-label="send" onClick={handleMessageSend}>
            <SendIcon fontSize="small" />
          </IconButton>
        </div>
      </Inner>
      {showMessage && (
        <>
          <MixedTagsContainer>
            <ReactTextareaAutocomplete
              onChange={onMessageChange}
              loadingComponent={() => <span>Loading</span>}
              value={message}
              ref={rtaRef}
              trigger={{
                '@': {
                  dataProvider: () => {
                    const output = tokens.map((token) => {
                      return { name: token, char: ` [[{"value":"${token}"}]]` };
                    });
                    return output;
                  },
                  component: Item,
                  output: (item) => {
                    return item.char;
                  },
                },
              }}
            />
          </MixedTagsContainer>
          <Tokens>
            <TokensInner>
              {tokens.map((token) => (
                <Button variant="outlined" color="primary" onClick={() => handleAddTag(token)}>
                  {token}
                </Button>
              ))}
            </TokensInner>
          </Tokens>
          <Buttons>
            <Button size="small" onClick={() => setShowMessage(false)}>
              <FormattedMessage
                id="messages.chat.responses.response.cancel"
                defaultMessage="Cancel"
              />
            </Button>
            <StyledButton variant="contained" size="small" color="primary" onClick={handleSave}>
              <FormattedMessage id="messages.chat.responses.response.save" defaultMessage="Save" />
            </StyledButton>
          </Buttons>
        </>
      )}
    </Container>
  );
};

export default Response;
