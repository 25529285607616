import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthenticationHeaders } from '../modules/fetch';
import { API } from '../config';

export const autoReplyApi = createApi({
  reducerPath: 'autoReplyApi',
  baseQuery: fetchBaseQuery({ baseUrl: API }),
  tagTypes: ['AutoReply'],
  endpoints: (builder) => ({
    autoReplies: builder.query({
      query: () => ({
        url: '/auto-reply/listings/1,100',
        headers: getAuthenticationHeaders('application/json'),
      }),
      providesTags: ['AutoReply'],
    }),
    fetchTemplate: builder.query({
      query: () => ({
        url: '/auto-reply/template ',
        headers: getAuthenticationHeaders('application/json'),
      }),
    }),
    addAutoReply: builder.mutation({
      query: (autoReply) => ({
        url: '/auto-reply/create',
        method: 'POST',
        headers: getAuthenticationHeaders('application/json'),
        body: autoReply,
      }),
      invalidatesTags: ['AutoReply'],
    }),
    updateAutoReply: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/auto-reply/update/${id}`,
        method: 'PUT',
        headers: getAuthenticationHeaders('application/json'),
        body: { autoReply: rest },
      }),
      invalidatesTags: ['AutoReply'],
    }),
    updateStatusAutoReply: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/auto-reply/update-status/${id}`,
        method: 'PUT',
        headers: getAuthenticationHeaders('application/json'),
        body: rest,
      }),
      invalidatesTags: ['AutoReply'],
    }),
    deleteAutoReply: builder.mutation({
      query: (id) => ({
        url: `/auto-reply/remove/${id}`,
        method: 'DELETE',
        headers: getAuthenticationHeaders('application/json'),
      }),
      invalidatesTags: ['AutoReply'],
    }),
  }),
});

export const {
  useAutoRepliesQuery,
  useFetchTemplateQuery,
  useAddAutoReplyMutation,
  useUpdateAutoReplyMutation,
  useUpdateStatusAutoReplyMutation,
  useDeleteAutoReplyMutation,
} = autoReplyApi;
