import { FormRow } from '../FormItems/FormRow';
import { FormContainer } from '../FormTemplateSteps/styles';
import { ContentContainer, StepContent, StyledEditIcon } from './styles';

export const ReportFormRenderContent = ({
  inputValues,
  requiredFields,
  setInputValues,
  inputLabels,
  setInputLabels,
  reportFormData,
  currentStep,
  setCurrentStep,
  isPreview,
  setIsPreview,
  isPreviewTicket,
}) => {
  return (
    <FormContainer $isPreview={isPreview} $isPreviewTicket={isPreviewTicket}>
      {reportFormData.map((step, index) => (
        <StepContent
          $isPreview={isPreview}
          $isPreviewTicket={isPreviewTicket}
          key={`StepContent-${index}`}
          className={`step-content-${index}`}
        >
          {(isPreview || currentStep === index) && (
            <ContentContainer>
              {isPreview && !isPreviewTicket && (
                <StyledEditIcon
                  fontSize={'small'}
                  onClick={() => {
                    setCurrentStep(index);
                    setIsPreview(false);
                  }}
                />
              )}
              {step.form.rows.map((formRow, formRowIndex) => (
                <FormRow
                  key={`report-row-${formRowIndex}`}
                  index={index}
                  inputValues={inputValues}
                  requiredFields={requiredFields}
                  formRowIndex={formRowIndex}
                  setInputValues={setInputValues}
                  inputLabels={inputLabels}
                  setInputLabels={setInputLabels}
                  formRow={formRow}
                  isPreview={isPreview}
                />
              ))}
            </ContentContainer>
          )}
        </StepContent>
      ))}
    </FormContainer>
  );
};
export default ReportFormRenderContent;
