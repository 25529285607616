import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import { mediaQueries } from '../../constants/breakpoints';
import InputLabel from '@mui/material/InputLabel';

export const StoreManagementContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 100%;
  overflow-y: auto;
  @media ${mediaQueries.desktop} {
    height: calc(100% - 30px);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    margin: 15px;
    border-radius: 15px;
  }
`;
export const StyledInputLabel = styled(InputLabel)`
  padding: 0 5px !important;
  background-color: white !important;
`;
export const Title = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 20px;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  margin-top: 20px;
  button {
    flex: 1;
    margin-bottom: 15px;
  }
  @media ${mediaQueries.desktop} {
    flex-direction: row;
    button,
    a {
      flex: 0 0 48%;
      white-space: nowrap;
    }
  }
`;
export const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

