import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';

export const StyledInputLabel = styled(InputLabel)`
  background: #fff;
  padding: 0 5px !important;
`;

export const StyledListSubheader = styled(ListSubheader)`
  font-size: large !important;
  color: black !important;
`
export const Text = styled.div`
`;
