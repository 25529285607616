import { Container, StyledRow, TitleBlock } from './styles';
import { FormattedMessage } from 'react-intl';
import FormAccordion from '../FormAccordion';
import FormElement from '../FormElement';

export const FormRow = ({
  index,
  inputValues,
  requiredFields,
  inputLabels,
  formRowIndex,
  setInputValues,
  setInputLabels,
  formRow,
  isPreview,
}) => {
  return (
    <Container>
      {formRow?.title && formRow?.type !== 'accordion' && (
        <TitleBlock>
          {formRow?.title && <FormattedMessage id={formRow?.title} defaultMessage={formRow?.title} />}
        </TitleBlock>
      )}
      <StyledRow key={`FormRow-${index}-${formRowIndex}`} className={`form-row-${formRowIndex}`}>
        {formRow?.children && 'rows' in formRow?.children ? (
          <>
            {formRow?.type === 'accordion' ? (
              <FormAccordion
                row={formRow?.children?.rows}
                title={formRow?.title}
                index={index}
                inputValues={inputValues}
                requiredFields={requiredFields}
                setInputValues={setInputValues}
                inputLabels={inputLabels}
                setInputLabels={setInputLabels}
                isPreview={isPreview}
              />
            ) : (
              <FormRow
                formRow={formRow?.children?.rows}
                index={index}
                inputValues={inputValues}
                requiredFields={requiredFields}
                formRowIndex={formRowIndex}
                setInputValues={setInputValues}
                inputLabels={inputLabels}
                setInputLabels={setInputLabels}
                isPreview={isPreview}
              />
            )}
          </>
        ) : (
          <>
            {Array.isArray(formRow?.children) &&
              formRow.children.map((input, formRowElementIndex) => (
                <FormElement
                  key={`element-${formRowElementIndex}`}
                  index={index}
                  input={input}
                  inputValues={inputValues}
                  requiredFields={requiredFields}
                  formRowIndex={formRowIndex}
                  formRowElementIndex={formRowElementIndex}
                  setInputValues={setInputValues}
                  inputLabels={inputLabels}
                  setInputLabels={setInputLabels}
                  formRow={formRow}
                  copyableItemIndex={null}
                  isPreview={isPreview}
                />
              ))}
          </>
        )}
      </StyledRow>
    </Container>
  );
};
