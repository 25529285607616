import { useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styled from 'styled-components';
import Alert from '../Alert';
import Button from '@mui/material/Button';
import { mediaQueries } from '../../constants/breakpoints';
import { Table, Td, Th, Tr } from '../Table';
import useSortableData from '../../modules/useSortableData';
import Checkbox from '@mui/material/Checkbox';
import Error from '../../components/Error';
import Popup from '../../components/Popup';
import UserForm from '../../containers/Users/userForm';
import Stores from './stores';
import { FormattedMessage } from 'react-intl';
import { useDeleteUserMutation } from '../../api/userAPI';
import { useStoresQuery } from '../../api/storeAPI';
import { AccountDataContext } from '../../context/AccountDataContext';

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
  @media ${mediaQueries.desktop} {
    overflow-x: hidden;
  }
`;
const AlertTitle = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 20px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  margin-top: 20px;

  button {
    flex: 1;
    margin-bottom: 15px;
  }

  @media ${mediaQueries.desktop} {
    flex-direction: row;
    button,
    a {
      flex: 0 0 48%;
      white-space: nowrap;
    }
  }
`;

const Icons = styled.div`
  white-space: nowrap;
`;

export default function DataGridDemo({ data }) {
  const [currentItemId, setCurrentItemId] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [serverError] = useState('');
  const [showUserPopup, setShowUserPopup] = useState(false);
  const [currentAccount, ] = useContext(AccountDataContext);
  const { data: stores } = useStoresQuery(true);

  const [deleteUser] = useDeleteUserMutation();

  const { items, requestSort, sortConfig } = useSortableData(data || []);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  const handleSettings = (id) => {
    setCurrentItemId(id);
    setShowUserPopup(true);
  };
  const handleDeletePopup = (id) => {
    setShowDeletePopup(true);
    setCurrentItemId(id);
  };
  const handleDelete = () => {
    deleteUser(currentItemId);
    setShowDeletePopup(false);
  };
  const handleCheck = (id) => {
    console.debug('/components/UsersList/index.js:handleCheck', id);
  };

  return (
    <>
      {showDeletePopup && (
        <Alert handleClose={() => setShowDeletePopup(false)}>
          <AlertTitle>
            <FormattedMessage
              id="userManagement.usersList.alert.title"
              defaultMessage="Are you sure you want to delete this user?"
            />
          </AlertTitle>
          {serverError && <Error message={serverError} />}
          <ButtonsContainer>
            <Button variant="outlined" onClick={() => setShowDeletePopup(false)}>
              <FormattedMessage id="userManagement.usersList.button.no" defaultMessage="No" />
            </Button>
            <Button variant="contained" color="primary" onClick={handleDelete}>
              <FormattedMessage id="userManagement.usersList.button.yes" defaultMessage="Yes" />
            </Button>
          </ButtonsContainer>
        </Alert>
      )}
      {showUserPopup && (
        <Popup
          isPreviewTicket={false}
          setIsPreviewTicket={() => {
          }}
          handleClose={() => setShowUserPopup(false)}
        >
          <UserForm
            data={items.find((item) => item?._id.$oid === currentItemId)}
            handleClosePopup={() => setShowUserPopup(false)}
          />
        </Popup>
      )}
      <Container>
        <Table>
          <thead>
          <Tr>
            <Th></Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('name')}
                className={getClassNamesFor('name')}
              >
                <FormattedMessage
                  id="userManagement.usersList.table.name"
                  defaultMessage="Name"
                />
              </button>
            </Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('type')}
                className={getClassNamesFor('type')}
              >
                <FormattedMessage
                  id="userManagement.usersList.table.role"
                  defaultMessage="Role"
                />
              </button>
            </Th>
            <Th>
              <button
                type="button"
                onClick={() => requestSort('email')}
                className={getClassNamesFor('email')}
              >
                <FormattedMessage
                  id="userManagement.usersList.table.email"
                  defaultMessage="Email"
                />
              </button>
            </Th>
            <Th>
              <FormattedMessage
                id="userManagement.usersList.table.stores"
                defaultMessage="Stores"
              />
            </Th>
            <Th>
              <FormattedMessage
                id="userManagement.usersList.table.actions"
                defaultMessage="Actions"
              />
            </Th>
          </Tr>
          </thead>
          <tbody>
          {items.map((item, index) => {
            if (item._id?.$oid === currentAccount?._id || item._id?.$oid === currentAccount?.imitieren?._id?.$oid) {
              return (
                <></>
              );
            }

            return (
            <Tr key={`users-list-${index}`}>
              <Td>
                <Checkbox
                  color="primary"
                  value="agree"
                  onChange={() => handleCheck(item?._id?.$oid)}
                />
              </Td>
              <Td>{item?.name}</Td>
              <Td>{item?.type}</Td>
              <Td>{item?.email}</Td>
              <Td>
                <Stores item={item} stores={stores?.data} />
              </Td>
              <Td>
                <Icons>
                  <IconButton
                    aria-label="settings"
                    onClick={() => handleSettings(item?._id?.$oid)}
                  >
                    <SettingsIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeletePopup(item?._id?.$oid)}
                  >
                    <DeleteOutlineIcon fontSize="small" />
                  </IconButton>
                </Icons>
              </Td>
            </Tr>
          )
          })}
          </tbody>
        </Table>
      </Container>
    </>
  );
}
