import { useIntl } from 'react-intl';
import { getInputDependedValues } from '../../../modules/getinput';
import MaskedField from '../../CommonCustomField/MaskedField';
import { logMessage } from '../../../logger';

export const FormNumber = ({ input, inputValues, requiredFields, handleChange }) => {
  const intl = useIntl();

  const handleDataChange = (event) => {
    logMessage('handleDataChange:: event.target.value', event.target.value);
    handleChange(input.id)(event.target.value);
  }

  let label = '';
  try {
    label = intl.formatMessage({ id: input?.label, defaultMessage: input?.label });
  } catch (e) {}

  return (
    <MaskedField
      id={input.id}
      label={label}
      required={requiredFields[input.id]}
      value={(getInputDependedValues(input, inputValues, '')).toString()}
      onChange={handleDataChange}
      mask={'00'}
    />
  );
};
export default FormNumber;
