import styled from 'styled-components';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const StylesTextField = styled(TextField)`
  width: 100%;
`;

export const EmailInput = ({ id, value, handleChange, label = 'settings.general.details.email', ...props }) => {

  const trimWhitespaces = (event) => {
    event.target.value = String(event.target.value).replaceAll(' ', '');
    handleChange(event)
  }

  return (
    <StylesTextField
      id={id}
      name={id}
      value={value}
      onChange={trimWhitespaces}
      label={label}
      variant="outlined"
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MailOutlineIcon />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
