import { FormRow } from '../FormItems/FormRow';
import { AddButton, Container, RowsContainer } from './styles';
import FormCopyableList from '../FormItems/FormCopyableList';
import { FormattedMessage } from 'react-intl';

export const SurveyFormRenderContent = ({
  inputValues,
  requiredFields,
  setInputValues,
  surveyFormData,
}) => {
  const handleAddQuestion = () => {
    setInputValues({
      ...inputValues,
      questions: [
        ...inputValues.questions,
        {
          id: inputValues.questions.length + 1,
          questionText: '',
          answerType: '1-5',
        },
      ],
    });
  };
  return (
    <Container>
      {surveyFormData.map((step, index) => (
        <RowsContainer key={`survey-step-${index}`}>
          {step.form.rows.map(
            (formRow, formRowIndex) =>
              ('type' in formRow && formRow?.type === 'copyable' && (
                <div key={`survey-row-${formRowIndex}`}>
                  <FormCopyableList
                    key={`report-row-${formRowIndex}`}
                    index={index}
                    formRowIndex={formRowIndex}
                    inputValues={inputValues}
                    requiredFields={requiredFields}
                    setInputValues={setInputValues}
                    formRow={formRow}
                  />
                  <AddButton variant="text" onClick={handleAddQuestion}>
                    <FormattedMessage id="surveys.form.add_question" />
                  </AddButton>
                </div>
              )) || (
                <FormRow
                  key={`report-row-${formRowIndex}`}
                  index={index}
                  inputValues={inputValues}
                  requiredFields={requiredFields}
                  formRowIndex={formRowIndex}
                  setInputValues={setInputValues}
                  inputLabels={{}}
                  setInputLabels={() => {}}
                  formRow={formRow}
                  isPreview={false}
                />
              ),
          )}
        </RowsContainer>
      ))}
    </Container>
  );
};
export default SurveyFormRenderContent;
