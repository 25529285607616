import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Nav from '../../components/Nav';
import { PageContainer, Body } from '../../components/Layout';
import Customers from '../../containers/Customer/list';
// import CustomersStats from '../../containers/Customer/stats';
import { mediaQueries } from '../../constants/breakpoints';
import { Navigate } from 'react-router-dom';
import { AccountDataContext } from '../../context/AccountDataContext';
import Header from '../../components/Header';
import getOrders from '../../api/getOrders';
import getCustomers from '../../api/getCustomers';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryHandler, ErrorBoundaryFallback } from '../../components/ErrorBoundary';

const CustomersContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 100%;
  overflow-y: auto;
  @media ${mediaQueries.desktop} {
    height: calc(100% - 30px);
    box-shadow: 0 0 10px 0 rgba(0,0,0, 0.15);
    margin: 15px;
    border-radius: 15px;
  }
`;

const Orders = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [customersData, setCustomersData] = useState();
  const [ordersData, setOrdersData] = useState();
  const [ , , viewsLevel ] = useContext<any>(AccountDataContext);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    getOrders(setOrdersData);
    getCustomers(setCustomersData);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      {viewsLevel > 3 ? (
        <Navigate to="/messages" />
      ) : (
        <>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onError={ErrorBoundaryHandler}
          >
            <Header />
          </ErrorBoundary>
          <PageContainer>
            <ErrorBoundary
              FallbackComponent={ErrorBoundaryFallback}
              onError={ErrorBoundaryHandler}
            >
              <Nav currentStep="customers" />
            </ErrorBoundary>
            <Body>
              <CustomersContainer>
                {/* <CustomersStats /> */}
                <ErrorBoundary
                  FallbackComponent={ErrorBoundaryFallback}
                  onError={ErrorBoundaryHandler}
                >
                  <Customers
                    isDesktop={!!(width > 768)}
                    data={ordersData}
                    customers={customersData}
                  />
                </ErrorBoundary>
              </CustomersContainer>
            </Body>
          </PageContainer>
        </>
      )}
    </>
  );
};

export default Orders;
