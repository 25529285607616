// import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Nav from '../../components/Nav';
import { PageContainer, Body } from '../../components/Layout';
import SupportReports from '../../containers/SupportReports';
import { mediaQueries } from '../../constants/breakpoints';
import Header from '../../components/Header';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryHandler, ErrorBoundaryFallback } from '../../components/ErrorBoundary';

const CustomersContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  max-width: 100%;
  overflow-y: auto;
  @media ${mediaQueries.desktop} {
    height: calc(100% - 30px);
    margin: 15px;
  }
`;

const Reports = () => {
  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={ErrorBoundaryHandler}
      >
        <Header />
      </ErrorBoundary>
      <PageContainer>
        <ErrorBoundary
          FallbackComponent={ErrorBoundaryFallback}
          onError={ErrorBoundaryHandler}
        >
          <Nav currentStep="dashboard" subStep="reports" />
        </ErrorBoundary>
        <Body>
          <CustomersContainer>
            <ErrorBoundary
              FallbackComponent={ErrorBoundaryFallback}
              onError={ErrorBoundaryHandler}
            >
              <SupportReports />
            </ErrorBoundary>
          </CustomersContainer>
        </Body>
      </PageContainer>
    </>
  );
};

export default Reports;
