import TextField from '@mui/material/TextField';
import { IMaskMixin } from 'react-imask';

const MaskedField = IMaskMixin(({ inputRef, defaultValue, ...otherProps }) => (
    // @ts-ignore
    <TextField
      inputRef={inputRef}
      variant="outlined"
      {...otherProps}
    />
  )
);

export { MaskedField };
export default MaskedField;
