import { getData } from '../modules/fetch';

const getAccountRead = (callback) => {
  getData('/account/read')
    .then((data) => {
      callback(data);
    })
    .catch((err) => {
      console.error(err);
    });
}

export default getAccountRead;
