import { createContext, useState } from 'react';

export const IsFilteringContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [isFiltering, setIsFiltering] = useState(false);

  return (
    <IsFilteringContext.Provider value={[isFiltering, setIsFiltering]}>
      {children}
    </IsFilteringContext.Provider>
  );
};
