import styled from 'styled-components';

export const Loading = styled.div`
  align-items: center;
  text-align: center;
  font-weight: bold;
  min-width: 100%;
`;
export const Copy = styled.div`
  max-width: 420px;
  border: 1px solid #ecebeb;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 18px;
  color: #000;
  word-wrap: break-word;
  margin-left: 5px;
`;
export const Details = styled.div`
  display: flex;
  margin-top: 5px;
`;
export const Time = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #7a7a7a;
  text-transform: uppercase;
  margin-top: 3px;
`;
export const MessageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const LastSendError = styled.div`
  display: block;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #f00;
  text-transform: uppercase;
  margin-top: 3px;
`;
export const WasRead = styled.div<{ isWasRead: boolean }>`
  margin-right: 5px;

  svg {
    font-size: 14px;
    color: ${(props) => (props.isWasRead ? '#109CF1' : '#7A7A7A')};
  }
`;
export const UserPhoto = styled.div`
  margin-right: 10px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const Message = styled.div<{ isClient: boolean; isSystemMessage: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isClient ? 'flex-start' : 'flex-end')};
  margin-top: 20px;

  ${UserPhoto} {
    display: ${(props) => (props.isClient ? 'block' : 'none')};
  }

  ${Content} {
    align-items: ${(props) => (props.isClient ? 'flex-start' : 'flex-end')};
    ${(props) =>
      props.isSystemMessage &&
      `
      align-items: center !important;
      font-weight: bold;
      font-size: 18px;
      background-color: #ECEBEB;
      ${Copy} {
        background-color: transparent;
        border: none;
      }
    `}
  }

  ${Copy} {
    background-color: ${(props) => (props.isClient ? '#fff' : '#ECEBEB')};
  }
`;
export const Order = styled.div`
  border-radius: 10px;
`;
export const OrderHead = styled.div`
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  padding: 9px 15px;
`;
export const OrderID = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #000;
`;
export const OrderBody = styled.div`
  display: flex;
  padding: 11px 6px 17px;

  img {
    max-width: 55px;
    max-height: 55px;
    margin-right: 5px;
  }
`;
export const OrderInfo = styled.div``;
export const OrderTitle = styled.a`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #5c5670;
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;
export const OrderSKU = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #c4c4c4;
  margin-top: 5px;
`;
export const OrderPrice = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #4a4747;
  margin-top: 10px;
`;
export const Loader = styled.img`
  width: 20px;
`;
export const ImgContainer = styled.div`
  cursor: pointer;
`;
