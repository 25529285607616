import { useContext, useEffect, useState } from 'react';
import Nav from '../../components/Nav';
import Inbox from './inbox';
import Chat from '../../containers/Chat';
import { Body, PageContainer } from '../../components/Layout';
// import { setupWampHandler } from '../../modules/wamp';
import { getData, putData } from '../../modules/fetch';
import { useAlert } from 'react-alert';
import { ActiveChatContext } from '../../context/ActiveChatContext';
import { retrieve, store } from '../../modules/storage';
import FAQ from './faq';
import { faqData } from './faqData';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import getAccountRead from '../../api/getAccountRead';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback, ErrorBoundaryHandler } from '../../components/ErrorBoundary';

let alertMessages = [];

const Support = () => {
  let params = useParams();
  const [isFAQ, setFAQ] = useState<boolean>(params.page !== 'chat');

  const [inbox, updateInbox] = useState<any>([]);
  const [showChat, setShowChat] = useState(params.page === 'chat');
  const [activeChat, setActiveChat] = useContext<any>(ActiveChatContext);
  const [, setCustomerData] = useState<any>();
  const [fastResponses, setFastResponses] = useState();
  const [messageData, setMessageData] = useState([]);
  const [messagesPage, setMessagesPage] = useState(1);
  const [totalMessages, setTotalMessages] = useState(0);
  const [scrollMessages, triggerScrollMessages] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const alert = useAlert();

  const [openInboxItems] = useState<any>([]);

  const initActiveChat = () => {
    if (activeChat === undefined) {
      const savedActiveChat = retrieve.json('activeChat', openInboxItems?.[0] || {});
      store.json('activeChat', savedActiveChat);
      setActiveChat(savedActiveChat);
    }
  };

  const getAccountDataSuccess = (data) => {
    setCurrentUser(data.data);
    setFastResponses(data.data.fastResponses);
  };

  const markInboxRead = (choosedItem) => {
    if (choosedItem && choosedItem?.entity) {
      inbox.map((item) => item._id.$oid === choosedItem?._id.$oid && (item.wasOpened = true));
      updateInbox([...inbox]);
      putData(`/${choosedItem?.entity}s/update/${choosedItem?._id.$oid}/disabled`, {
        [choosedItem.entity]: {
          wasOpened: true,
        },
      }).catch((err) => {
        console.error(err);
      });
    }
  };

  const handleChatOpen = (item) => {
    setFAQ(item === 'faq');
    setShowChat(true);
    store.json('activeChat', item);
    setActiveChat(item);
    markInboxRead(item);
  };

  useEffect(() => {
    getInbox();
    getAccountRead(getAccountDataSuccess);
  }, []);

  const getInbox = () => {
    getData('/chats/listings/1,9999')
      .then((data) => {
        data?.data && updateInbox(data.data.map((obj) => ({ ...obj, selected: false })));
        initActiveChat();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const findMessageBySendId = (sendId) => {
    return messageData.find((item) => item?.sendId === sendId);
  };

  const getMessages = (item) => {
    if (item?._id?.$oid && item?.entity) {
      if (messageData === null) {
        let msgs = retrieve.json(item._id.$oid, []);
        if (msgs) {
          setMessageData(msgs);
        }
      }
      getData(`/messages/listings/${item?.entity}/${item?._id.$oid}/1,10?sort=time.date:-1`)
        .then((data) => {
          setTotalMessages(data.meta.total);
          triggerScrollMessages(!scrollMessages);
          setMessageData(data.data);
          store.json(item?._id.$oid, data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const getMoreMessages = () => {
    getData(
      `/messages/listings/${activeChat?.entity}/${activeChat?._id.$oid}/${
        messagesPage + 1
      },10?sort=time.date:-1`,
    )
      .then((data) => {
        setMessageData([...messageData, data.data]);
        setMessagesPage(messagesPage + 1);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getChatInfo = (item) => {
    if (item?._id?.$oid && item?.entity) {
      getData(`/${item?.entity}s/read/${item?._id.$oid}`)
        .then((data) => {
          console.log({ data });
          setCustomerData(data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (activeChat?._id?.$oid) {
      store.string('ActiveChatId', activeChat?._id?.$oid);
      store.json('activeChat', activeChat);
      getChatInfo(activeChat);
      getMessages(activeChat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat]);

  const setMessageToActiveChat = (message) => {
    setMessageData((messageData: any) => {
      const found = messageData.find((item) => item?._id?.$oid === message?._id?.$oid);
      if (found) {
        return messageData.map((item) => (item?._id?.$oid === message?._id?.$oid ? message : item));
      } else {
        if (message?.sendId) {
          const foundSendId = messageData.find((item) => item?.sendId === message?.sendId);
          if (foundSendId) {
            return messageData.map((item) => (item?.sendId === message?.sendId ? message : item));
          } else {
            return [...messageData, message];
          }
        } else {
          return [...messageData, message];
        }
      }
    });
    updateInbox((inbox) => {
      return inbox.map((item) => {
        if (item?._id.$oid === message.chatId) {
          item.message = message;
          item.resolved = [];
        }
        return item;
      });
    });
    if (activeChat?._id?.$oid) {
      store.json(activeChat?._id?.$oid, messageData);
    }
  };

  const setMessageToNonActiveChat = (message) => {
    updateInbox((inbox) => {
      return inbox.map((item) => {
        if (item?._id.$oid === message.chatId) {
          item.message = message;
          item.resolved = [];
        }
        return item;
      });
    });

    const foundAlertMsg = alertMessages.find((item) => item?._id.$oid === message?._id?.$oid);
    if (!foundAlertMsg && message.hasOwnProperty('_id') && message._id.hasOwnProperty('$oid')) {
      alertMessages.push(message);
      // message for popup should be here:
      let shortMsg = '';
      shortMsg = message;
      if (message?.content) {
        shortMsg = message?.content;
      }
      try {
        const messageContent = JSON.parse(shortMsg);
        if (messageContent.hasOwnProperty('txt')) shortMsg = messageContent.txt;
      } catch (e) {}
      let alertMessage = shortMsg;
      alertMessage =
        (message?.titleFrom || '') + ' -> ' + (message?.titleTo || '') + ': ' + alertMessage;
      alert.show(alertMessage, {
        onClose: () => {
          alertMessages = alertMessages.map((item) =>
            item &&
            item.hasOwnProperty('_id') &&
            item._id.hasOwnProperty('$oid') &&
            item._id.$oid !== message._id.$oid
              ? item
              : null,
          );
        },
      });
    }
  };

  const wampHandlers = {
    message: (msg) => {
      if (msg?.[0]?.message) {
        const message = msg?.[0]?.message;

        if (message.chatId === retrieve.string('ActiveChatId')) {
          setMessageToActiveChat(message);
        } else {
          setMessageToNonActiveChat(message);
        }
      }
    },
  };

  useEffect(() => {
    // setupWampHandler(wampHandlers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
        <Header />
      </ErrorBoundary>
      <PageContainer>
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
          <Nav currentStep="support" />
        </ErrorBoundary>
        <Body>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
            <Inbox handleChatOpen={handleChatOpen} isFAQ={isFAQ} />
          </ErrorBoundary>
          {isFAQ ? (
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
              <FAQ faqData={faqData} />
            </ErrorBoundary>
          ) : (
            <>
              {inbox.length && (
                <>
                  {showChat && (
                    <ErrorBoundary
                      FallbackComponent={ErrorBoundaryFallback}
                      onError={ErrorBoundaryHandler}
                    >
                      <Chat
                        messages={messageData}
                        responsesData={fastResponses}
                        handleChatClose={() => setShowChat(false)}
                        currentUser={currentUser}
                        handleEscalate={() => {}}
                        handleResolved={() => {}}
                        handleFollowUp={() => {}}
                        wampHandlers={wampHandlers}
                        getMoreMessages={getMoreMessages}
                        totalMessages={totalMessages}
                        scrollMessages={scrollMessages}
                        getInbox={getInbox}
                        findMessageBySendId={findMessageBySendId}
                        users={[]}
                        tokenValues={[]}
                        setShowChat={() => {}}
                        openSmallChat={() => {}}
                        activeStore={() => {}}
                      />
                    </ErrorBoundary>
                  )}
                </>
              )}
            </>
          )}
        </Body>
      </PageContainer>
    </>
  );
};

export default Support;
