import { createContext, useEffect, useState } from 'react';
import { retrieve, store } from '../modules/storage';

export const MessageContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [inboxMessage, setInboxMessage] = useState(retrieve.string('messageInputValue'));

  useEffect(() => {
    store.string('messageInputValue', inboxMessage);
  }, [inboxMessage]);

  return (
    <MessageContext.Provider value={[inboxMessage, setInboxMessage]}>
      {children}
    </MessageContext.Provider>
  );
};
