import { Content, ItemHead, Message } from '../styles';
import {
  RefundRequest,
  RefundRequestBody,
  RefundRequestDate,
  RefundRequestID,
  RefundRequestInfo,
  RefundRequestPrice,
  RefundRequestReason,
  RefundRequestSKU,
  RefundRequestTitle,
  RefundRequestWrap,
  RefundRequestWrapDate,
} from './styles';
import DeliveryStatus from '../../DeliveryStatus';
import LastErrorMessage from '../../LastErrorMessage';
import Avatar from '../../Avatar';
import { FormattedMessage } from 'react-intl';
import { getParamByISO } from 'iso-country-currency';
import moment from 'moment/moment';
import ErrorMessage from '../../ErrorMessage';

export default ({ client, message, customerDataStoreTimezone, more = null }) => {
  return (
    <Message isClient={client} key={message?._id?.$oid + message?.sendId}>
      <Content>
        <RefundRequest>
          <Avatar message={message} isClient={client} />
          <ItemHead>
            <RefundRequestID>
              <FormattedMessage
                id="messages.chat.messages.refund_request_id"
                defaultMessage="{title} # {id}"
                values={{
                  title: message?.body?.title ?? '',
                  id: message?.body?.order?.orderId ?? '',
                }}
              />
            </RefundRequestID>
          </ItemHead>
          {Array.isArray(message?.body?.order?.orderItems)
            ? message?.body?.order?.orderItems.map((item, index) => (
                <RefundRequestBody key={`${message?.body?.order?.orderId}-orderItem-${index}`}>
                  {item?.image ? <img src={item?.image} /> : ''}
                  <RefundRequestInfo>
                    <RefundRequestTitle href={item?.image || ''} target="_blank">
                      {item?.name || ''}
                    </RefundRequestTitle>
                    <RefundRequestSKU>
                      <FormattedMessage
                        id="messages.chat.messages.sku_id"
                        defaultMessage="SKU: {id}"
                        values={{
                          id: item?.skuId ?? '',
                        }}
                      />
                    </RefundRequestSKU>
                    <RefundRequestWrap>
                      <RefundRequestID>
                        <b>
                          <FormattedMessage
                            id="messages.chat.messages.product_id"
                            defaultMessage="Product ID:"
                          />
                        </b>
                        <span>{item?.skuId || ''}</span>
                      </RefundRequestID>
                      <RefundRequestPrice>
                        {`${item?.quantity || 1} x ${item?.price || 0} ${
                          item?.currency ??
                          getParamByISO(message?.body?.order?.store?.countryCode, 'currency')
                        }`}
                      </RefundRequestPrice>
                    </RefundRequestWrap>
                  </RefundRequestInfo>
                </RefundRequestBody>
              ))
            : ''}
          <RefundRequestWrapDate>
            <RefundRequestID>
              <b>
                <FormattedMessage id="messages.chat.messages.order" defaultMessage="Order:" />
              </b>
              <span>{message?.body?.order?.orderId || ''}</span>
            </RefundRequestID>
            <RefundRequestDate>
              <b>
                <FormattedMessage id="messages.chat.messages.date" defaultMessage="Date:" />
              </b>
              <span>
                {message?.body?.order?.createdAt?.date
                  ? moment(message?.body?.order?.createdAt?.date).format('DD.MM.YYYY')
                  : ''}
              </span>
            </RefundRequestDate>
          </RefundRequestWrapDate>
          <RefundRequestReason>
            <b>
              <FormattedMessage
                id="messages.chat.messages.refund_reason"
                defaultMessage="Refund reason:"
              />
            </b>
            <span>{message?.body?.order?.refundReason || ''}</span>
          </RefundRequestReason>
        </RefundRequest>
        {more}
        <DeliveryStatus
          isClient={client}
          message={message}
          customerDataStoreTimezone={customerDataStoreTimezone}
        />
        <LastErrorMessage message={message} />
        <ErrorMessage message={message?.rawResponse?.process_msg ?? ''} />
      </Content>
    </Message>
  );
};
