import styled from 'styled-components';
import { Table, Tr, Th, Td } from '../../components/Table';
import useSortableData from '../../modules/useSortableData';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  width: 100%;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    color: #060607;
    margin-right: 10px;
  }
`;
const Member = styled.div`
  display: inline-block;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #187835;
  font-weight: 500;
  background: rgba(47, 194, 125, 0.4);
  padding: 5px 12px;
  border-radius: 15px;
`;
const Logo = styled.div`
  width: 33px;
  height: 33px;
  margin-right: 11px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Orders = ({ data, ...props }) => {
  const { items, requestSort, sortConfig } = useSortableData(data);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
    <Container {...props}>
      <Head>
        <h3>
          <FormattedMessage id="customer.customerList.orders.user" defaultMessage="Name Surname" />
        </h3>
        <Member>
          <FormattedMessage id="customer.customerList.orders.member" defaultMessage="Top Buyer" />
        </Member>
      </Head>
      <Table>
        <Tr>
          <Th>
            <button
              type="button"
              onClick={() => requestSort('platform')}
              className={getClassNamesFor('platform')}
            >
              <FormattedMessage
                id="customer.customerList.orders.button.platform"
                defaultMessage="Platform"
              />
            </button>
          </Th>
          <Th>
            <button
              type="button"
              onClick={() => requestSort('order')}
              className={getClassNamesFor('order')}
            >
              <FormattedMessage
                id="customer.customerList.orders.button.order"
                defaultMessage="Order #"
              />
            </button>
          </Th>
          <Th>
            <button
              type="button"
              onClick={() => requestSort('total')}
              className={getClassNamesFor('total')}
            >
              <FormattedMessage
                id="customer.customerList.orders.button.total"
                defaultMessage="Total"
              />
            </button>
          </Th>
          <Th>
            <button
              type="button"
              onClick={() => requestSort('sku')}
              className={getClassNamesFor('sku')}
            >
              <FormattedMessage id="customer.customerList.orders.button.sku" defaultMessage="SKU" />
            </button>
          </Th>
          <Th>
            <button
              type="button"
              onClick={() => requestSort('date')}
              className={getClassNamesFor('date')}
            >
              <FormattedMessage
                id="customer.customerList.orders.button.date"
                defaultMessage="Date"
              />
            </button>
          </Th>
        </Tr>
        {items.map((item) => (
          <Tr>
            <Td>
              <Logo>
                <img src={item.platform} alt="" />
              </Logo>
            </Td>
            <Td>{item.order}</Td>
            <Td>$ {item.total}</Td>
            <Td>{item.sku}</Td>
            <Td>{item.date}</Td>
          </Tr>
        ))}
      </Table>
    </Container>
  );
};

export default Orders;
