import { Container, Text, Title } from './styles';
import { FormattedMessage } from 'react-intl';
import { StyledImage } from '../FormTemplateComponents/styles';

export const FormLabel = ({ input, inputValues, inputLabels }) => {
  const id = (inputLabels?.[input.id] === null || inputLabels?.[input.id] === '')
    ? (inputValues?.[input.id] ?? '-')
    : (inputLabels?.[input.id] ?? inputValues?.[input.id]);
  return (
    <Container>
      <Title>
        <FormattedMessage id={input.label} defaultMessage={input.label} />
      </Title>
      <Text>
        {id
          ? <>
            {(typeof id === 'object' && Array.isArray(id) && id.length > 0 ? id.map((item, i) =>
                <>
                  <FormattedMessage id={item?.value ?? item?.label ?? item ?? '-'}
                                    defaultMessage={item?.value ?? item?.label ?? item ?? '-'}
                  />{id.length - 1 === i ? <></> : (<>,&nbsp;</>)}
                </>
              ) : (
                typeof id === 'object'
                  ? (id?.header?.link?.url
                      ? <StyledImage src={inputValues?.[input.id]?.header?.link?.url} />
                      : ('date' in id)
                        ? <>{id.date}</>
                        : ('header' in id || 'body' in id || 'footer' in id)
                          ? <><pre>{JSON.stringify(id, null, 2)}</pre></>
                          : (id.label ?? id.name ?? id.value ?? false
                            ? <FormattedMessage id={(id.label ?? id.name ?? id.value ?? '-') + (id.countryCode ?? '')}
                                                defaultMessage={(id.label ?? id.name ?? id.value ?? '-') + (id.countryCode ?? '')} />
                            : '-')
                  )
                  : <FormattedMessage id={id ?? '-'} defaultMessage={id ?? '-'} />
              )
            )}
          </>
          : '-'}
      </Text>
    </Container>
  );
};
export default FormLabel;
