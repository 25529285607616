import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Content, Loader, MessageBox } from '../styles';
import { StyledMessage } from './styles';
import DeliveryStatus from '../../DeliveryStatus';
import LastErrorMessage from '../../LastErrorMessage';
import ErrorMessage from '../../ErrorMessage';
import ViewTemplate from './view';
import { logMessage } from '../../../../../logger';

const Template = ({ client, message, resend, customerDataStoreTimezone, store, messageTemplate, more = null }) => {

  logMessage('store', store)

  return (
    <StyledMessage isClient={client} key={message?._id?.$oid + message?.sendId}>
      <Content>
        <MessageBox>
          {!message?.isClient && message?.lastSendError && (
            <IconButton aria-label="resend" onClick={() => resend(message?._id?.$oid)}>
              <RefreshIcon fontSize="small" />
            </IconButton>
          )}
          {(!message?.delivery ||
              message?.delivery === 'sending' ||
              message?.delivery === 'sent2api' ||
              message?.delivery === 'sent_to_api') &&
            !client && <Loader color="primary" src={'/img/loader-new.gif'} />}
          <ViewTemplate template={messageTemplate} />
        </MessageBox>
        {more}
        <DeliveryStatus
          isClient={client}
          message={message}
          customerDataStoreTimezone={customerDataStoreTimezone}
        />
        <LastErrorMessage message={message} />
        <ErrorMessage message={message?.rawResponse?.process_msg ?? ''} />
      </Content>
    </StyledMessage>
  );
};

export default Template;
