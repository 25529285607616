import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthenticationHeaders } from '../modules/fetch';
import { API } from '../config';

export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: fetchBaseQuery({ baseUrl: API }),
  tagTypes: ['Contacts'],
  endpoints: (builder) => ({
    contacts: builder.query({
      query: ({ page = 1, perPage = 100000 }) => ({
        url: `/contacts/listings/${page ?? 1},${perPage ?? 100}`,
        headers: getAuthenticationHeaders('application/json'),
      }),
      providesTags: ['Contacts'],
    }),
    fetchTemplate: builder.query({
      query: () => ({
        url: '/contacts/template',
        headers: getAuthenticationHeaders('application/json'),
      }),
    }),
    addContact: builder.mutation({
      query: (contact) => ({
        url: '/contacts/create',
        method: 'POST',
        headers: getAuthenticationHeaders('application/json'),
        body: contact,
      }),
      invalidatesTags: ['Contacts'],
    }),
    updateContact: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/contacts/update/${id}`,
        method: 'PUT',
        headers: getAuthenticationHeaders('application/json'),
        body: { contact: rest },
      }),
      invalidatesTags: ['Contacts'],
    }),
    deleteContact: builder.mutation({
      query: (id) => ({
        url: `/contacts/remove/${id}`,
        method: 'DELETE',
        headers: getAuthenticationHeaders('application/json'),
      }),
      invalidatesTags: ['Contacts'],
    }),
  }),
});

export const {
  useContactsQuery,
  useFetchTemplateQuery,
  useAddContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
} = contactApi;
