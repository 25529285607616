import { useEffect, useState } from 'react';
import Error from '../../components/Error';
import { DataGrid } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import {
  useAddBroadcastMutation,
  useBroadcastQuery,
  useDeleteBroadcastMutation,
  useFetchTemplateQuery,
  useUpdateBroadcastMutation
} from '../../api/broadcastAPI';
import { setValuesFormTemplate } from '../../api/setValuesFormTemplate';
import Popup from '../../components/Popup';
import { BackButton, ButtonsContainer, Container, SaveButton, StyledButton, Tag, Text, Title, TitleContainer, Wrapper } from './styles'
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box } from '@mui/material';
import BroadcastFormSteps from '../../components/BroadcastFormSteps';

const utc0 = 'Atlantic/Azores, UTC-0';
const Broadcast = () => {
  const [isPreview, setIsPreview] = useState(false);
  const [isPreviewTicket,] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [inputValues, setInputValues] = useState(
    {
      _id: { '$oid': '' },
      name: '',
      templateUrn: '',
      scheduleStartDate: { date: '' },
      timezone: { value: 0, label: utc0 },
      tagsInclude: '',
      tagsExclude: '',
      templateComponents: [],
      templateVariables: []
    });
  const [inputLabels, setInputLabels] = useState(
    {
      _id: { '$oid': '' },
      name: '',
      templateUrn: '',
      scheduleStartDate: { date: '' },
      timezone: { value: 0, label: utc0 },
      tagsInclude: '',
      tagsExclude: '',
      templateComponents: [],
      templateVariables: []
    });
  const [currentBroadcastId, setCurrentBroadcastId] = useState(null);
  const [showBroadcastPopup, setShowBroadcastPopup] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { data: templateBroadcast, error: errorTemplate } = useFetchTemplateQuery(true);
  const [deleteBroadcast, { error: errorDelete }] = useDeleteBroadcastMutation();
  const { data: broadcast, error: errorQuery } = useBroadcastQuery({ page: 1, perPage: 100000 });
  const [updateBroadcast, { error: errorUpdate }] = useUpdateBroadcastMutation();
  const [addBroadcast, { error: errorAdd }] = useAddBroadcastMutation();
  const [requiredFields, setRequiredFields] = useState({});

  const handleFormOpen = () => {
    let temp = setValuesFormTemplate(templateBroadcast?.data, null, () =>
      Object.assign(
        {
          _id: { '$oid': '' },
          name: '',
          templateUrn: '',
          scheduleStartDate: { date: '' },
          timezone: { value: 0, label: utc0 },
          tagsInclude: '',
          tagsExclude: '',
          templateComponents: [],
          templateVariables: []
        },
        {
          _id: { '$oid': '' },
          name: '',
          templateUrn: '',
          scheduleStartDate: { date: '' },
          timezone: { value: 0, label: utc0 },
          tagsInclude: '',
          tagsExclude: '',
          templateComponents: [],
          templateVariables: []
        },
      ),);
    setInputValues(temp.inputValues);
    setRequiredFields(temp.requiredFields);
    setShowBroadcastPopup(true);
  };

  const closePopupAndClear = () => {
    setCurrentBroadcastId(null);
    setShowDeleteConfirmation(false);
    setShowBroadcastPopup(false);
  };

  // @ts-ignore
  const handleChange = async (id, status) => {
    await updateBroadcast({
      id: id,
      broadcast: {
        status: status === 'active' ? 'inactive' : 'active',
      },
    });
  }

  const findCurrentBroadcast = (id) => {
    return broadcast?.data?.find((item) => item?._id?.$oid === id);
  };

  const handleEditPopup = (id) => {
    const find = { ...findCurrentBroadcast(id), isEditable: true }
    let temp = setValuesFormTemplate(templateBroadcast?.data, find);
    setInputValues(temp.inputValues);
    setInputLabels({
      ...temp?.inputValues,
      templateUrn: temp?.inputValues?.template?.name + ` (${temp.inputValues?.template?.countryCode ?? temp.inputValues?.template?.language})`
    });
    setRequiredFields(temp.requiredFields);
    setShowBroadcastPopup(true);
  }

  const handleDelete = () => {
    deleteBroadcast(currentBroadcastId);
    closePopupAndClear();
  }

  const handleDeletePopup = (id) => {
    setCurrentBroadcastId(id);
    setShowDeleteConfirmation(true);
    setShowBroadcastPopup(true);
  };

  const handleResponseWithErrors = setShowBroadcastPopup => (response) => {
    if (response) {
      // @ts-ignore
      if (response?.error?.status && response?.error?.status !== 200) {
        // @ts-ignore
        const errors = response?.error?.data?.errors;
        if (errors) {
          const message = errors.map(item => {
            const regex = /(\w+)\]$/g;
            const exec = regex.exec(item.path);
            const field = exec?.[1];
            if (field) {
              let fieldName = String(field).replace(/([-_]\w)/g, g => ' ' + g[1].toUpperCase())
              fieldName = fieldName.slice(0, 1).toUpperCase() + fieldName.slice(1);
              return `${fieldName}: ${item.message}`
            }
          });
          alert('Error' + '\n' + message.join('\n'));
        } else {
          alert('Error' + '\n' + JSON.stringify(response));
        }
      } else {
        // @ts-ignore
        if (response?.data?.meta?.statusCode && response?.data?.meta?.statusCode === 200) {
          setShowBroadcastPopup(false);
        }
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const record = {
      name: inputValues.name,
      scheduleStartDate: inputValues.scheduleStartDate,
      timezone: inputValues.timezone,
      templateUrn: inputValues.templateUrn,
      tagsInclude: inputValues.tagsInclude,
      tagsExclude: inputValues.tagsExclude,
      templateComponents: inputValues.templateComponents ?? [],
      templateVariables: inputValues.templateVariables ?? []
    };

    if ('isEditable' in inputValues) {
      const id = inputValues?._id?.$oid;
      await updateBroadcast({ id: id, ...record, }).then(handleResponseWithErrors(setShowBroadcastPopup));
    } else {
      await addBroadcast({ broadcast: record }).then(handleResponseWithErrors(setShowBroadcastPopup));
    }
  };

  useEffect(() => {
    if (showBroadcastPopup) {
      setCurrentStep(0);
      setIsPreview(false);
    }
  }, [showBroadcastPopup]);

  return (
    <Wrapper>
      {showBroadcastPopup && (
        <Popup
          isPreviewTicket={false}
          setIsPreviewTicket={() => {
          }}
          handleClose={closePopupAndClear}
        >
          {(showDeleteConfirmation && (
            <>
              <Text>
                <FormattedMessage id="broadcast.modal.delete.confirm" />
              </Text>
              <ButtonsContainer>
                <BackButton variant="outlined" onClick={closePopupAndClear}>
                  <FormattedMessage id="broadcast.popup.button.no" />
                </BackButton>
                <SaveButton variant="contained" color="primary" onClick={handleDelete}>
                  <FormattedMessage id="broadcast.popup.button.yes" />
                </SaveButton>
              </ButtonsContainer>
            </>
          ) || (
            <>
              {(errorTemplate && 'error' in errorTemplate && <Error message={errorTemplate.error} />) ||
                (templateBroadcast?.data?.length ? (
                  <BroadcastFormSteps
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                    requiredFields={requiredFields}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    isPreview={isPreview}
                    setIsPreview={setIsPreview}
                    isPreviewTicket={isPreviewTicket}
                    broadcastFormData={templateBroadcast?.data}
                    inputLabels={inputLabels}
                    setInputLabels={setInputLabels}
                    handleFormSubmit={handleFormSubmit}
                  />
                ) : null)}
            </>
          ))}
        </Popup>
      )}

      <Container>
        <TitleContainer>
          <Title>
            <FormattedMessage id="broadcast.title.label" />
          </Title>
          <StyledButton variant="contained" onClick={handleFormOpen}>
            <FormattedMessage id="broadcast.title.button" />
          </StyledButton>
        </TitleContainer>
        {(errorTemplate && 'error' in errorTemplate && (
            <Error message={errorTemplate.error} />
          )) ||
          (errorAdd && 'error' in errorAdd && (
            <Error message={errorAdd.error} />
          )) ||
          (errorUpdate && 'error' in errorUpdate && (
            <Error message={errorUpdate.error} />
          )) ||
          (errorDelete && 'error' in errorDelete && (
            <Error message={errorDelete.error} />
          )) ||
          (errorQuery && 'error' in errorQuery && <Error message={errorQuery.error} />)}

        <Box
          sx={{
            height: 300,
            width: '100%',
            '& .overflowOnIt': {
              overflow: 'overlay !important',
            },
          }}
        >
          <DataGrid
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                flex: 1
              },
              {
                field: 'store',
                headerName: 'Channel',
                flex: 1
              },
              {
                field: 'template',
                headerName: 'Template',
                flex: 1
              },
              {
                field: 'scheduleStartDate',
                headerName: 'Scheduled Start Date',
                flex: 1
              },
              {
                field: 'timezone',
                headerName: 'Timezone',
                flex: 1
              },
              {
                field: 'tagsInclude',
                headerName: 'Tags Include',
                flex: 1,
                cellClassName: 'overflowOnIt',
                minWidth: 300,
                renderCell: (cellValues) => {
                  return <>
                    {cellValues.row?.tagsInclude?.map((channel) => <Tag
                      key={`channel-${channel?.value ?? channel ?? ''}-${Math.random()}`}>{channel?.label ?? channel ?? ''}</Tag>)}
                  </>;
                }
              },
              {
                field: 'tagsExclude',
                headerName: 'Tags Exclude',
                flex: 1,
                cellClassName: 'overflowOnIt',
                minWidth: 300,
                renderCell: (cellValues) => {
                  return <>
                    {cellValues.row?.tagsExclude?.map((channel) => <Tag
                      key={`channel-${channel?.value ?? channel ?? ''}-${Math.random()}`}>{channel?.label ?? channel ?? ''}</Tag>)}
                  </>;
                }
              },
              {
                field: 'price',
                headerName: 'Price',
                flex: 1,
              },
              {
                field: 'status',
                headerName: 'Status',
                flex: 1,
              },
              {
                field: 'actions',
                headerName: 'Actions',
                type: 'actions',
                flex: 1,
                renderCell: (cellValues) => {
                  return <>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditPopup(cellValues.row.id)}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeletePopup(cellValues.row.id)}
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  </>;
                }
              },
            ]}
            rows={broadcast?.data?.map((broadcast) => {
              const newVar = {
                id: broadcast._id.$oid,
                name: broadcast.name,
                store: (broadcast.store?.name ?? ''),
                template: (broadcast.template?.name ?? ''),
                scheduleStartDate: broadcast.scheduleStartDate?.date ?? broadcast.scheduleStartDate ?? '',
                timezone: broadcast.timezone?.label ?? { value: 0, label: utc0 }?.label ?? '',
                tagsInclude: broadcast.tagsInclude,
                tagsExclude: broadcast.tagsExclude,
                status: broadcast.status ?? '',
                price: broadcast.price ?? ''
              };
              return newVar
            }) ?? []}
            disableSelectionOnClick={true}
            isRowSelectable={() => false}
            autoHeight
          />
        </Box>
      </Container>
    </Wrapper>
  );
};

export default Broadcast;
