import { FormattedMessage } from 'react-intl';
import {
  useAddSurveyMutation,
  useFetchTemplateSurveyQuery,
  useUpdateSurveyMutation,
} from '../../api/surveyAPI';
import { BackButton, ButtonsContainer, Container, SaveButton } from './styles';
import SurveyFormRenderContent from '../../components/SurveyFormRenderContent';
import Error from '../../components/Error';

const SurveyForm = ({
  inputValues,
  requiredFields,
  setInputValues,
  setShowSurveyPopup,
  isPreview,
}) => {
  const { data: templateSurvey, error: errorTemplate } = useFetchTemplateSurveyQuery(true);
  const [addSurvey] = useAddSurveyMutation();
  const [updateSurvey] = useUpdateSurveyMutation();

  const handleCancelPopup = () => {
    setInputValues({});
    setShowSurveyPopup(false);
  };
  const handleSurveyFormSubmit = async (e) => {
    e.preventDefault();
    if ('isEditable' in inputValues) {
      await updateSurvey({
        id: inputValues?._id?.$oid,
        ...inputValues,
      });
    } else {
      await addSurvey({ survey: inputValues });
    }
    setShowSurveyPopup(false);
  };

  return (
    <Container>
      {(errorTemplate && 'error' in errorTemplate && <Error message={errorTemplate.error} />) || (
        <form autoComplete="off" onSubmit={handleSurveyFormSubmit}>
          <SurveyFormRenderContent
            inputValues={inputValues}
            requiredFields={requiredFields}
            setInputValues={setInputValues}
            surveyFormData={templateSurvey?.data}
          />
          {!isPreview && (
            <ButtonsContainer>
              <BackButton variant="outlined" onClick={handleCancelPopup}>
                <FormattedMessage id="surveys.popup.button.cancel" />
              </BackButton>
              <SaveButton variant="contained" type={'submit'}>
                <FormattedMessage id="surveys.popup.button.save" />
              </SaveButton>
            </ButtonsContainer>
          )}
        </form>
      )}
    </Container>
  );
};

export default SurveyForm;
