export const processRow = (data, inputValues, requiredFields, copyableDefaults) => (row) => {
  if (row?.children) {
    if ('rows' in row?.children) {
      if (row.type === 'copyable') {
        inputValues[row.id] = [];
        requiredFields[row.id] = [];
        // const result =
        row.children.rows.map(processRow(row, inputValues[row.id], requiredFields[row.id], copyableDefaults));
        if (copyableDefaults) {
          inputValues[row.id].push(copyableDefaults(row, inputValues, requiredFields));
        }
      } else {
        return row.children.rows.map((innerRow) => {
          return innerRow.children.map((input) => {
            requiredFields[input.id] = input.required ?? false;
            return (inputValues[input.id] = data[input.id] ?? input.defaultValue ?? null);
          });
        });
      }
    } else {
      return row.children.map((input) => {
        requiredFields[input.id] = input.required ?? false;
        return (inputValues[input.id] = data[input.id] ?? input.defaultValue ?? null);
      });
    }
  } else {
    return row.children.map((input) => {
      requiredFields[input.id] = input.required ?? false;
      return (inputValues[input.id] = data[input.id] ?? input.defaultValue ?? null);
    });
  }
};

export const setValuesFormTemplate = (template, data = null, copyableDefaults = null): any => {
  let inputValues = {};
  let requiredFields = {};
  Object.values(template)?.map((step: any) =>
    step.form.rows.map(processRow(template, inputValues, requiredFields, copyableDefaults)),
  );

  if (data && 'isEditable' in data) {
    inputValues = data;
  }

  return { inputValues, requiredFields };
};
