import { createContext, useEffect, useState } from 'react';
import { retrieve } from '../modules/storage';
import { WithoutToken } from '../security';

export const FBAccountDataContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  // @ts-ignore
  const [loginStatus, setLoginStatus] = useState({ status: '', authResponse: null, accessToken: '' });
  const [fbAccessToken, setFbAccessToken] = useState<string>(retrieve.string('fbAccessToken', ''));
  // @ts-ignore
  const [FBApi, setFBApi] = useState(null);

  const fbInit = () => {
    // @ts-ignore
    if (FB) {
      // @ts-ignore
      FB.init({
        appId: '5098984906889107', // Facebook App ID
        cookie: true, // enable cookies
        xfbml: false, // parse social plugins on this page
        localStorage: true,
        version: 'v15.0', //Graph API version
      });
      // @ts-ignore
      // console.log('FB.init', FB);
    } else {
      // @ts-ignore
      FB = null;
    }

    // @ts-ignore
    setFBApi(FB);

    // @ts-ignore
    return FB;
  }

  const fbGetLoginStatus = (fbApi) => {
    // @ts-ignore
    fbApi.getLoginStatus(function (response) {
      // console.log('-----------------FB.getLoginStatus: { response }', { response });
      setLoginStatus(response);
    });
  }

  useEffect(() => {
    const found = WithoutToken.some((element) => {
      return window.location.pathname.indexOf(element) !== -1;
    });
    if (!found) {
      // console.log('useEffect: loginStatus -> fbAccessToken', loginStatus);
      if (fbAccessToken === '') {
        // console.log('useEffect: loginStatus -> fbAccessToken: YES ');
        setFbAccessToken(loginStatus?.authResponse?.accessToken ?? '');
      }
      // console.log('useEffect: loginStatus -> fbAccessToken: done ');
    }
  }, [loginStatus]);

  return (
    <FBAccountDataContext.Provider
      value={[loginStatus, setLoginStatus, { FBApi, setFBApi, fbInit, fbGetLoginStatus, fbAccessToken, setFbAccessToken }]}>
      {children}
    </FBAccountDataContext.Provider>
  );
};
