import styled from 'styled-components';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import Pagination from '@mui/material/Pagination';

export const StyledTableContainer = styled(TableContainer)`
  width: 100%;
`;
export const Container = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  .head-cell {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    background: #f2f2f2;
  }
  td {
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
`;
export const ActionsContainer = styled(TableCell)`
  display: flex;
`;
export const Title = styled.h4`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #050d42;
`;

export const StyledPagination = styled(Pagination)`
  ul {
    flex-wrap: nowrap;
  }
`;
