import Button from '@mui/material/Button';
import {
  Container,
  Title,
  Message
} from './styles';

export const ErrorBoundaryHandler = (error: Error, info: {componentStack: string}) => {
  console.error('{error, info}', {error, info});
}

export const ErrorBoundaryFallback = ({error}) => {
  return (
    <Container role="alert">
      <Title>error</Title>
      <Message>{error.message}</Message>
      <Button
        variant="contained"
        color="primary"
        onClick={() => document.location.reload()}>
          Reload page
        </Button>
    </Container>
  );
}
