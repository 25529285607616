import { retrieve } from '../modules/storage';
import { postData } from '../modules/fetch';

export const getCounts = (filters, setCounts, unread = 0) => {
  const q = retrieve.string('inboxQuery');
  postData(
    '/chats/counts/listings?' +
      (q ? `query=${encodeURIComponent(q)}&` : '') +
      (unread ? `unread=1` : ''),
    {
      filter: { ...filters },
    },
  )
    .then((data) => {
      setCounts({
        open: data.data.open,
        new: data.data.open.new,
        inProgress: data.data.open.inProgress,
        followup: data.data.followup,
        escalated: data.data.escalated,
        resolved: data.data.resolved,
      });
    })
    .catch((err) => {
      console.error(err);
    });
};
