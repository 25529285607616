import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { Details, WasRead } from './styles';
import Timestamp from '../../../Timestamp';

const DeliveryStatus = ({ isClient, message, customerDataStoreTimezone }) => {
  return (
    <Details key={'delivery-status-' + message?._id?.$oid + message?.sendId}>
      {!isClient && message?.delivery && (
        <WasRead isWasRead={message?.isRead}>
          {message?.delivery === 'error' ? (
            <ErrorIcon />
          ) : (
            message?.delivery !== 'sending' &&
            message?.delivery !== 'saved' &&
            (message?.delivery !== 'sent' && message?.delivery !== 'sent_from_external' ? (
              <DoneIcon />
            ) : (
              <DoneAllIcon />
            ))
          )}
        </WasRead>
      )}
      <Timestamp showDate={message?.time?.date} customerDataStoreTimezone={customerDataStoreTimezone} />
    </Details>
  );
};

export default DeliveryStatus;
