import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { mediaQueries } from '../../constants/breakpoints';
import TemplatesStoresList from '../../components/TemplatesStoresList';
import { FormattedMessage, useIntl } from 'react-intl';
import getStoresConfig from '../../api/getStoresConfig';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 20px;
  background: #fff;
  border-radius: 6px;
  @media ${mediaQueries.desktop} {
    margin: 24px 80px;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin: 20px 20px 0;
`;
const Actions = styled.div`
  display: flex;
  margin: 20px 20px 0;
  flex-direction: column;
  @media ${mediaQueries.desktop} {
    flex-direction: row;
  }
`;
const SearchInputContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  flex: 1;
  display: flex;
  margin-bottom: 10px;
  @media ${mediaQueries.desktop} {
    margin-right: 10px;
    margin-bottom: 0;
    max-width: 300px;
  }
`;
const Input = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  outline: none;
`;

const Templates = ({ data }) => {
  const [platform, setPlatform] = useState('whatsapp');
  const [storesList, setStoresList] = useState(data);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const intl = useIntl();

  const handlePlatformChange = (event) => {
    setPlatform(event.target.value);
    const askPlatform = event.target.value !== 'all' ? event.target.value : '';
    setItems(filterBySearch(search, askPlatform));
  };

  const onChangeSearch = (event) => {
    setSearch(event.target.value);
    setItems(filterBySearch(event.target.value, platform));
  };
  const filterBySearch = (query, askPlatform) => {
    const stores = storesList?.filter((item) => {
      return askPlatform !== '' && askPlatform !== 'all' ? item.provider === askPlatform : true;
    });
    if (query) {
      const regex = new RegExp(query, 'iu');
      return stores?.filter((item) => {
        return regex.test(item?.name) || regex.test(item?.countryCode);
      });
    } else {
      return stores;
    }
  };

  const getStoresConfigSuccess = (config) => {
    const storesListUpdated = data?.map((item) => {
      const currentShop = item.provider;
      const connections = config[currentShop].filter(
        (configItem) => !item.apps[configItem.app_id] && configItem.app_id,
      );
      let connectionMessage = '';
      let connectionStatus = '';
      const total = config[currentShop].length;
      const left = connections.length;
      if (left === total) {
        connectionMessage = `not connected (0/${total})`;
        connectionStatus = 'notConnected';
      } else if (left === 0) {
        connectionMessage = `connected (${total}/${total})`;
        connectionStatus = 'connected';
      } else {
        connectionMessage = `in progress (${total - left}/${total})`;
        connectionStatus = 'inProgress';
      }
      return {
        ...item,
        connectionStatus,
        connectionMessage,
        nextConnectionURL: connections?.[0]?.auth_url || '',
      };
    });
    setStoresList(storesListUpdated);
    setItems(filterBySearch(search, platform));
  };

  useEffect(() => {
    getStoresConfig(getStoresConfigSuccess);
  }, [data]);

  useEffect(() => {
    setItems(filterBySearch(search, platform));
  }, [storesList]);

  return (
    <>
      <Container>
        <Title>
          <FormattedMessage
            id="templatesManagement.stores.title"
            defaultMessage="Your connected stores"
          />
        </Title>
        <Actions>
          <SearchInputContainer>
            <IconButton aria-label="search">
              <SearchIcon fontSize="small" />
            </IconButton>
            <Input
              type="text"
              placeholder={intl.formatMessage({
                id: 'templatesManagement.stores.input_search',
                defaultMessage: 'templatesManagement.stores.input_search',
              })}
              onChange={onChangeSearch}
            />
          </SearchInputContainer>
          <FormControl variant="outlined">
            <Select
              labelId="platform-label"
              id="platform"
              value={platform}
              defaultValue="whatsapp"
              onChange={handlePlatformChange}
            >
              <MenuItem value="whatsapp">
                <FormattedMessage id="templatesManagement.stores.menu_whatsapp" defaultMessage="WhatsApp" />
              </MenuItem>
            </Select>
          </FormControl>
        </Actions>
        <TemplatesStoresList data={items} />
      </Container>
    </>
  );
};

export default Templates;
