import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';

export const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
  @media ${mediaQueries.desktop} {
    overflow-x: hidden;
  }
`;
export const Logo = styled.div`
  width: 40px;
  height: 40px;
  margin: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const AlertTitle = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 20px;
`;
export const Text = styled.span<{status: string}>`
  margin-right: 15px;
  ${(props) => props.status === 'notConnected' && `color: #E8684A;`};
  ${(props) => props.status === 'inProgress' && `color: #FFA800;`};
  ${(props) => props.status === 'connected' && `color: #5ABB7A;`};
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  margin-top: 20px;

  button {
    flex: 1;
    margin-bottom: 15px;
  }

  @media ${mediaQueries.desktop} {
    flex-direction: row;
    button,
    a {
      flex: 0 0 48%;
      white-space: nowrap;
    }
  }
`;
