import { createContext, useState } from 'react';
import { manuallyDecrementPromiseCounter, usePromiseTracker } from 'react-promise-tracker';
import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';
import { mediaQueries } from '../constants/breakpoints';

export const GlobalLoadingContext = createContext(null);

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 61px;
  width: calc(100% - 10px);
  @media ${mediaQueries.desktop} {
    justify-content: center;
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [loading, setLoadingHere] = useState(true);
  const { promiseInProgress } = usePromiseTracker();

  const setLoading = (value) => {
    if (value === false) {
      manuallyDecrementPromiseCounter();
    }
    setLoadingHere(value);
  }

  const LoadingIndicatorTag = () => {
    return (
      promiseInProgress && (
        <Container>
          <TailSpin color="#fff" height="25" width="25" />
        </Container>
      )
    );
  };


  return (
    <GlobalLoadingContext.Provider value={[loading, setLoading]}>
      <LoadingIndicatorTag key={'global-loading'} />
      {children}
    </GlobalLoadingContext.Provider>
  );
};
