import styled from 'styled-components';
import { mediaQueries } from '../../../constants/breakpoints';

export const Container = styled.div``;
export const StyledRow = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  @media ${mediaQueries.desktop} {
    flex-direction: row;
  }
`;
export const TitleBlock = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #000;
`;
