import styled from 'styled-components';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { mediaQueries } from '../../constants/breakpoints';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays, format } from 'date-fns';
import Button from '@mui/material/Button';
import { getInNewWindow } from '../../modules/fetch';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  max-width: 90%;
  width: 100%;
  margin: 0px auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  //position: relative;
  //top: 50%;
  //transform: translateY(-50%);
  @media ${mediaQueries.tablet} {
    max-width: 548px;
  }

  .rdrDayPassive .rdrDayNumber span {
    color: transparent;
  }
`;

const Heading = styled.div`
  background-color: #ecf3f9;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
`;

const Copy = styled.div`
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  margin: 30px 0;
  max-width: 370px;
  color: #000;
`;

const DateContainer = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

const DateInput = styled.div`
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 4px;
  padding: 8px 12px;
  color: #000;
  font-size: 1rem;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 16px;
    position: relative;
    top: -1px;
  }
`;

const StyledCalendarTodayIcon = styled(CalendarTodayIcon)`
  margin-right: 5px;
`;

const StyledDateRange = styled(DateRange)`
  position: absolute;
  left: 0;
  top: 35px;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px 30px;
`;

const ExportChatsContainers = ({storeId}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: addDays(new Date(), -6),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleDateRangeChange = (range) => {
    setDate([range.selection]);
    if (range.selection.startDate !== range.selection.endDate) {
      setShowCalendar(false);
    }
  };
  const handleGenerate = () => {
    getInNewWindow(
      '/stores/export/' + storeId,
      `dateFrom=${format(date[0].startDate, 'yyyy-MM-dd')} 00:00:00` +
      `&dateTo=${format(date[0].endDate, 'yyyy-MM-dd')} 23:59:59`,
    );
  };

  return (
    <>
      <Container>
        <Heading>
          <Title>
            <FormattedMessage
              id="store.export_chats.title.export_store_chats"
              defaultMessage="Export Store Chats"
            />
          </Title>
        </Heading>
        <Actions>
          <Copy>
            <FormattedMessage
              id="store.export_chats.title.actions"
              defaultMessage="Please select the date range to export the chats"
            />
          </Copy>
          <DateContainer>
            <DateInput onClick={() => setShowCalendar(!showCalendar)}>
              <StyledCalendarTodayIcon />
              {format(date[0].startDate, 'd MMMM y')} - {format(date[0].endDate, 'd MMMM y')}
            </DateInput>
            {showCalendar && (
              <StyledDateRange
                editableDateInputs={true}
                onChange={handleDateRangeChange}
                moveRangeOnFirstSelection={false}
                ranges={date}
                showDateDisplay={false}
              />
            )}
          </DateContainer>
          <Button variant="contained" color="primary" onClick={handleGenerate}>
            <FormattedMessage id="store.export_chats.actions.button" defaultMessage="Export" />
          </Button>
        </Actions>
      </Container>
    </>
  );
};

export default ExportChatsContainers;
