import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import Button from '@mui/material/Button';

export const MainContainer = styled.div`
  display: flex;
  flex: 1;

  .popup-wrapper {
    min-width: 550px;
    max-width: 550px;
    padding: 0;

    > div {
      max-width: 100%;
      margin: 24px;
    }
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 15px;
  background: #fff;
  border-radius: 6px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;
export const Title = styled.h1`
  font-weight: 700;
  font-size: 16px;
  line-height: 0px;
  text-transform: uppercase;
  color: #050d42;
`;
export const StyledButton = styled(Button)`
  width: 138px;
  height: 30px;
  background: #006876 !important;
  border-radius: 40px !important;
`;
export const Total = styled.div`
  display: flex;
  justify-content: center;
`;
export const TotalItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 100px;
  border-right: 1px solid #cccccc;

  :last-child {
    border-right: none;
  }

  @media ${mediaQueries.desktop} {
    flex-basis: 200px;
  }
`;
export const TotalItemNumber = styled.div`
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.5px;
  color: #000;
`;
export const TotalItemText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
`;
export const ModalTitle = styled.h3`
  margin-left: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #050d42;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
export const BackButton = styled(Button)`
  width: 67px;
  height: 36px;
  padding: 8px 16px;
  background: #fff !important;
  color: #006876 !important;
  border-radius: 20px !important;
  line-height: 1 !important;
  border: 2px solid #006876 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
`;
export const SaveButton = styled(Button)`
  width: 67px;
  height: 36px;
  padding: 8px 16px;
  background: #006876 !important;
  border-color: #006876 !important;
  border-radius: 20px !important;
  line-height: 1 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
`;
export const Text = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
`;
