import styled from 'styled-components';
import { mediaQueries } from '../../constants/breakpoints';
import Button from '@mui/material/Button';
import { Fab } from '@mui/material';

export const Wrapper = styled.div`
  min-height: calc(100% - 70px);
  .popup-wrapper {
    min-width: 550px;
    max-width: 550px;
    padding: 0;

    > div {
      max-width: 100%;
      margin: 24px;
    }
  }
  .MuiDataGrid-cell {
    outline: none !important;
  }
`;

export const Container = styled.div`
  height: 100%;
  padding: 0 20px 20px;
  @media ${mediaQueries.desktop} {
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
export const Title = styled.h1`
  font-weight: 700;
  font-size: 16px;
  line-height: 0px;
  text-transform: uppercase;
  color: #050d42;
`;
export const StyledButton = styled(Button)`
  width: 138px;
  height: 30px;
  background: #006876 !important;
  border-radius: 40px !important;
`;
export const StyledIconButton = styled(Fab)`
  height: 30px;
  background: #006876 !important;
  //color: white;
`;
export const StyledBox = styled.div`
  height: 30px;
  justify-content: right;
  align-items: flex-end;
  display: inline !important;
  & div {
    display: inline !important;
  }
  & svg {
    fill: white !important;
  }
`;
export const ModalTitle = styled.h3`
  margin-left: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #050d42;
`;
export const Tag = styled.div`
  background: #F5FBFF;
  border: 1px solid #6E6E6E;
  border-radius: 6px;
  color: #050D42;
  font-size: 12px;
  line-height: 14px;
  padding: 3px 6px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  img {
    width: 15px;
    margin-right: 4px;
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
export const Text = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
`;
export const BackButton = styled(Button)`
  width: 67px;
  height: 36px;
  padding: 8px 16px;
  background: #fff !important;
  color: #006876 !important;
  border-radius: 20px !important;
  line-height: 1 !important;
  border: 2px solid #006876 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
`;
export const SaveButton = styled(Button)`
  width: 67px;
  height: 36px;
  padding: 8px 16px;
  background: #006876 !important;
  border-color: #006876 !important;
  border-radius: 20px !important;
  line-height: 1 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
`;
