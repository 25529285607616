import { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import { mediaQueries } from '../../constants/breakpoints';
import List from '../../components/CustomersList';

import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import picker from 'react-day-picker/moment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { logMessage } from '../../logger';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 20px;
  background: #fff;
  border-radius: 6px;
  @media ${mediaQueries.desktop} {
    margin: 24px 80px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0;
  @media ${mediaQueries.desktop} {
    flex-direction: row;
  }
`;
const Action = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
const ActionSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
`;
const DateRangeContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 10px;
  @media ${mediaQueries.desktop} {
    margin-left: 10px;
    margin-top: 0;
    max-width: 300px;
    margin-bottom: 0;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .DayPicker-Day {
    border-radius: 0 !important;
  }
  .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .DayPickerInput-Overlay {
    width: 550px;
  }
  .DayPickerInput {
    display: block;
    input {
      border: none;
      font-size: 16px;
      padding: 5px 10px;
      margin: 0 10px;
      width: 120px;
      text-align: center;
    }
  }
  .input-to .DayPickerInput-Overlay {
    margin-left: -198px;
  }
`;

const Title = styled.h3`
  font-size: 22px;
  color: #535873;
  margin: 10px 10px 10px 0;
`;

const SearchInputContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  flex: 1;
  display: flex;
  margin-top: 10px;
  @media ${mediaQueries.desktop} {
    margin-left: 10px;
    margin-top: 0;
    width: 100%;
    max-width: 300px;
    margin-bottom: 0;
  }
`;
const Input = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  outline: none;
`;

const Customers = ({ isDesktop, data, customers }) => {
  logMessage({ isDesktop, data, customers });

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const toRef = useRef<DayPickerInput>();

  const DATE_FORMAT = 'DD/MM/YYYY';

  const showFromMonth = useCallback(() => {
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      toRef.current?.getDayPicker()?.showMonth(from);
    }
  }, [from, to]);

  useEffect(() => {
    showFromMonth();
  }, [toRef, to, showFromMonth]);

  const handleFromChange = (from) => {
    console.debug('/containers/Customer/index.js:handleFromChange', from);
    setFrom(from);
  };

  const handleToChange = (to) => {
    setTo(to);
  };
  const modifiers = { start: from, end: to };
  return (
    <>
      <Container key={'container' + data?.meta?.requestUniqueId}>
        <Actions>
          {/* <FormControl variant="outlined">
            <Select labelId="orders-label" id="orders" value={order} onChange={handleOrderChange}>
              <MenuItem value="all">All Orders</MenuItem>
              <MenuItem value="last">Last Orders</MenuItem>
            </Select>
          </FormControl> */}
          <Action>
            <Title>All orders</Title>
            <DateRangeContainer>
              <DayPickerInput
                value={from}
                placeholder="From"
                format={DATE_FORMAT}
                formatDate={picker.formatDate}
                parseDate={picker.parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { after: to },
                  toMonth: to,
                  modifiers,
                  numberOfMonths: 2,
                  onDayClick: () => toRef.current.getInput().focus(),
                }}
                onDayChange={handleFromChange}
              />{' '}
              —{' '}
              <span className="input-to">
                <DayPickerInput
                  ref={toRef}
                  value={to}
                  placeholder="To"
                  format={DATE_FORMAT}
                  formatDate={picker.formatDate}
                  parseDate={picker.parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from },
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 2,
                  }}
                  onDayChange={handleToChange}
                />
              </span>
            </DateRangeContainer>
          </Action>
          <ActionSearch>
            <SearchInputContainer>
              <IconButton aria-label="search">
                <SearchIcon fontSize="small" />
              </IconButton>
              <Input type="text" placeholder="Search by customer, platform, tag, etc." />
            </SearchInputContainer>
          </ActionSearch>
        </Actions>
        <List data={data?.data} customers={customers} />
      </Container>
    </>
  );
};

export default Customers;
