import { Content, ItemHead, Message } from '../styles';
import { Order, OrderBody, OrderID, OrderInfo, OrderListPrice, OrderProductID, OrderSKU, OrderTitle, OrderTotal, OrderWrap, } from './styles';
import DeliveryStatus from '../../DeliveryStatus';
import LastErrorMessage from '../../LastErrorMessage';
import Avatar from '../../Avatar';
import { FormattedMessage } from 'react-intl';
import { getParamByISO } from 'iso-country-currency';
import ErrorMessage from '../../ErrorMessage';

export default ({ client, message, customerDataStoreTimezone, more = null }) => {
  return (
    <Message isClient={client} key={message?._id?.$oid + message?.sendId}>
      <Content>
        <Order>
          <Avatar message={message} isClient={client} />
          <ItemHead>
            <OrderID>
              <FormattedMessage
                id="messages.chat.messages.product_list"
                defaultMessage="Your order # {id}"
                values={{
                  id: message?.body?.order?.orderId ?? '',
                }}
              />
            </OrderID>
          </ItemHead>
          {Array.isArray(message?.body?.order?.orderItems)
            ? message?.body?.order?.orderItems?.map((orderItem, index) => (
                <OrderBody key={`${message?.body?.order?.orderId}-orderItem-${index}`}>
                  {orderItem?.imageUrl && (
                    <img src={orderItem?.imageUrl} alt={orderItem?.name || ''} />
                  )}
                  {orderItem?.image && (
                    <img src={orderItem?.image} alt={orderItem?.name || ''} />
                  )}
                  <OrderInfo>
                    {orderItem?.imageUrl && (
                      <OrderTitle href={orderItem?.imageUrl} target="_blank">
                        {orderItem?.name || ''}
                      </OrderTitle>
                    )}
                    <OrderSKU>
                      <FormattedMessage
                        id="messages.chat.messages.sku_id"
                        defaultMessage="SKU: {id}"
                        values={{
                          id: (orderItem?.sku || orderItem?.skuId)  ?? '',
                        }}
                      />
                    </OrderSKU>
                    <OrderWrap>
                      <OrderProductID>
                        <FormattedMessage
                          id="messages.chat.messages.product_id"
                          defaultMessage="Product ID:"
                        />
                        <span>{orderItem?.id || orderItem?.orderItemId}</span>
                      </OrderProductID>
                      <OrderListPrice>
                        {`${message?.body?.order?.orderItemsSummary?.[index]?.amount || orderItem?.quantity || 1} x ${
                          (message?.body?.order?.orderItemsSummary?.[index]?.price || orderItem?.price) ?? 0
                        } ${
                          orderItem?.currency ??
                          getParamByISO(message?.body?.order?.store?.countryCode, 'currency') ??
                          ''
                        }`}
                      </OrderListPrice>
                    </OrderWrap>
                  </OrderInfo>
                </OrderBody>
              ))
            : ''}
          <OrderTotal>
            <FormattedMessage id="messages.chat.messages.order_total" defaultMessage="Total: " />
            <span>
              {' ' +
                ((message?.body?.order?.revenue || message?.body?.order?.price) ?? '') +
                ' ' +
                (message?.body?.order?.currency ?? '')}
            </span>
          </OrderTotal>
        </Order>
        {more}
        <DeliveryStatus
          isClient={client}
          message={message}
          customerDataStoreTimezone={customerDataStoreTimezone}
        />
        <LastErrorMessage message={message} />
        <ErrorMessage message={message?.rawResponse?.process_msg ?? ''} />
      </Content>
    </Message>
  );
};
