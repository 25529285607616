import { createContext, useContext, useEffect, useState } from 'react';
import { setupWampHandler } from '../modules/wamp';
import { JwtTokenContext } from './JwtTokenContext';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

export const WampHandlersContext = createContext(null);

const ErrorMessage = styled.div`
  display: block;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 3px;
  border: 1px solid lightcoral;
  border-radius: 5px;
  background-color: lightcoral;
  z-index: 999;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
`;

const ErrorMessageSystem = styled.div`
  display: block;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 3px;
  border: 1px solid darkred;
  border-radius: 5px;
  background-color: darkred;
  z-index: 1001;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [allWampHandlers, setAllWampHandlers] = useState({});
  const [wampHandlers, setWampHandlers] = useState({});
  const [message, setMessage] = useState<any>({});
  const [checkMessage, setCheckMessage] = useState<any>({});
  const [checkWampConnection, setCheckWampConnection] = useState<any>(0);
  const [topicMilliseconds, setTopicMilliseconds] = useState<any>({});
  const [tryTimes, setTryTimes] = useState<any>({});
  const [token] = useContext<any>(JwtTokenContext);

  const [lostConnection, setLostConnection] = useState<any>(false);
  const [lostConnectionPrev, setLostConnectionPrev] = useState<any>(false);
  const [showPopupLostConnection, setShowPopupLostConnection] = useState<any>(false);

  const [lostConnectionSystem, setLostConnectionSystem] = useState<any>(false);
  const [lostConnectionPrevSystem, setLostConnectionPrevSystem] = useState<any>(false);
  const [showPopupLostConnectionSystem, setShowPopupLostConnectionSystem] = useState<any>(false);

  let wampIsSet = false;
  let showTimeoutLostConnection = null;
  let hideTimeoutLostConnection = null;
  let showTimeoutLostConnectionSystem = null;
  let hideTimeoutLostConnectionSystem = null;

  // Wamp
  const globalWampHandlers = {
    // @ts-ignore
    __byType: (msg, kwargs = {}, ed = {}, sub = { topic: any }) => {
      try {
        if (msg?.[0] && msg?.[0]?.type && msg?.[0]?.type === 'ping') {
          if (topicMilliseconds?.[sub?.topic] && msg[0].pong !== topicMilliseconds?.[sub?.topic]) {
            setCheckMessage(value => {
              return { ...value, [sub?.topic]: msg[0].pong };
            });
          } else {
            setCheckMessage(value => {
              return { ...value, [sub?.topic]: 0 };
            });
            setTryTimes(value => {
              return { ...value, [sub?.topic]: 0 };
            });
          }

          return;
        }
        if (msg?.[0] && msg?.[0]?.type && allWampHandlers) {
          if ('health_check' in msg[0]) {
            return;
          }
          setMessage(msg[0]);
        }
      } catch (e) {
        console.error('Can\'t call WAMP Handler with message', msg);
      }
    },
  };

  const showLostConnectionSystem = () => {
    setLostConnectionSystem(true);
  };

  // const hideLostConnectionSystem = () => {
  //   setLostConnectionSystem(false);
  // };

  let setIntervalPingIds = {};
  let setIntervalCheckWampConnectionIds = {};
  // @ts-ignore
  const openConnection = ({ connectionWamp, session, host, realm, channel, handle }) => {
    console.log('openConnection', { connectionWamp, session, host, realm, channel, handle });
    setLostConnection(false);
    setLostConnectionSystem(false);
    if (setIntervalPingIds[channel] && setIntervalPingIds[channel] !== null) {
      clearInterval(setIntervalPingIds[channel]);
      setIntervalPingIds[channel] = null;
    }
    if (setIntervalCheckWampConnectionIds[channel] && setIntervalCheckWampConnectionIds[channel] !== null) {
      clearInterval(setIntervalCheckWampConnectionIds[channel]);
      setIntervalPingIds[channel] = null;
    }
    setIntervalPingIds[channel] = setInterval(() => {
      let ms = (new Date()).getTime();
      try {
        connectionWamp?.session?.call('publish_to_realm', ['system', channel, { type: 'ping', pong: ms }]);
        setTopicMilliseconds(value => {
          return { ...value, [channel]: ms };
        });
        setCheckMessage(value => {
          return { ...value, [channel]: ms };
        });
      } catch (e) {
        console.warn('WAMP broken', e);
        setLostConnection(true);
      }
    }, 5000);
    setIntervalCheckWampConnectionIds[channel] = setInterval(() => {
      setCheckWampConnection(new Date().getTime());
    }, 4999);
  };

  const initialize = async () => {
    if (token) {
      if (!wampIsSet) {
        // noinspection JSIgnoredPromiseFromCall
        const installed = await setupWampHandler(globalWampHandlers, {
          onStart: null,
          onOpen: openConnection,
          onClose: showLostConnectionSystem,
          onUserError: showLostConnectionSystem,
          onInternalError: showLostConnectionSystem,
        });
        let topic = '';
        let handler = null;
        let promises = [];
        for (const k in installed) {
          topic = Object.keys(installed[k]?.['system'])?.[0];
          handler = installed[k]?.['system']?.[topic];
          promises.push(handler.promise);
        }

        wampIsSet = true;
      }
    }
  };

  useEffect(() => {
    if (checkWampConnection > 0) {
      let lostStateCount = 0;
      const checkMessageKeys = Object.keys(checkMessage);
      const tryTimesToSet = { ...tryTimes };
      checkMessageKeys.map(topic => {
        if (topic in checkMessage && checkMessage?.[topic] > 0) {
          const value = tryTimes[topic] ?? 0;
          const newTryTimesValue = value + 1;
          if (newTryTimesValue > 2) {
            console.warn('WAMP broken, tryTimes', newTryTimesValue, tryTimes);
            lostStateCount++;
          }
          tryTimesToSet[topic] = newTryTimesValue;
        } else {
          tryTimesToSet[topic] = 0;
        }
      });
      setTryTimes(tryTimesToSet);
      setLostConnection(checkMessageKeys.length > 0 && lostStateCount >= checkMessageKeys.length);
    }
  }, [checkWampConnection]);

  useEffect(() => {
    if (lostConnectionPrev !== lostConnection) {
      setLostConnectionPrev(lostConnection);
      if (lostConnection) {
        if (showTimeoutLostConnection === null) {
          showTimeoutLostConnection = setTimeout(() => {
            if (lostConnection) {
              setShowPopupLostConnection(true);
            }
            showTimeoutLostConnection = null;
          }, 5000);
        }
      } else {
        if (hideTimeoutLostConnection === null) {
          hideTimeoutLostConnection = setTimeout(() => {
            if (!lostConnection) {
              setShowPopupLostConnection(false);
            }
            hideTimeoutLostConnection = null;
          }, 5000);
        }
      }
    }
  }, [lostConnection]);

  useEffect(() => {
    if (lostConnectionPrevSystem !== lostConnectionSystem) {
      setLostConnectionPrevSystem(lostConnectionSystem);
      if (lostConnectionSystem) {
        if (showTimeoutLostConnectionSystem === null) {
          showTimeoutLostConnectionSystem = setTimeout(() => {
            if (lostConnectionSystem) {
              setShowPopupLostConnectionSystem(true);
            }
            showTimeoutLostConnectionSystem = null;
          }, 5000);
        }
      } else {
        if (hideTimeoutLostConnectionSystem === null) {
          hideTimeoutLostConnectionSystem = setTimeout(() => {
            if (!lostConnectionSystem) {
              setShowPopupLostConnectionSystem(false);
            }
            hideTimeoutLostConnectionSystem = null;
          }, 5000);
        }
      }
    }
  }, [lostConnectionSystem]);

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    initialize();
  }, [token]);

  useEffect(() => {
    setAllWampHandlers({ ...allWampHandlers, ...wampHandlers })
  }, [wampHandlers]);

  useEffect(() => {
    if (allWampHandlers?.[message?.type]) {
      allWampHandlers?.[message?.type](message);
    }
  }, [message]);

  return (
    <WampHandlersContext.Provider value={[wampHandlers, setWampHandlers]}>
      {(!showPopupLostConnectionSystem && showPopupLostConnection) && <ErrorMessage key={'lost_connection'} onClick={() => window.location.reload()}><FormattedMessage
        id="error.wamp.lost.connection"
        defaultMessage="Connection lost, click to refresh"
      /></ErrorMessage>}
      {showPopupLostConnectionSystem && <ErrorMessageSystem key={'lost_connection_system'} onClick={() => window.location.reload()}><FormattedMessage
        id="error.wamp.lost.connection"
        defaultMessage="Connection lost, click to refresh"
      /></ErrorMessageSystem>}
      {children}
    </WampHandlersContext.Provider>
  );
};
