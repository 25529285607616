import { useEffect, useState } from 'react';
import Nav from '../../components/Nav';
import { Body, PageContainer } from '../../components/Layout';
import Alert from '../../components/Alert';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { getDataByUrl } from '../../modules/fetch';
import { Navigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { FormattedMessage } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback, ErrorBoundaryHandler } from '../../components/ErrorBoundary';
import { useStoresConfigQuery } from '../../api/storeAPI';
import Facebook from '../../components/Facebook';
import Popup from '../../components/Popup';
import { ButtonsContainer, StoreManagementContainer, StyledFormControl, StyledInputLabel, Title, } from './style';
import StoreDetailsContainer from '../../containers/StoreDetailsContainer';
import JobsStateContext from '../../context/JobsStateContext';
import { logMessage } from '../../logger';

const Store = () => {
  const params = useParams();
  const [, setWidth] = useState(window.innerWidth);
  const [showConnectPopup, setShowConnectPopup] = useState(false);
  const [showFacebookPopup, setShowFacebookPopup] = useState(false);
  const [storeAuthUrl, setStoreAuthUrl] = useState('');
  const [viewsLevel] = useState(-1);

  logMessage('params.id', params.id);
  const { data: storesConfig } = useStoresConfigQuery(true);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const handleStoreSelect = (e) => {
    setStoreAuthUrl(e.target.value);
  };

  const handleRedirect = () => {
    // const url = addStoresData.map(shop => shop.find(item => item.name === storeAuthUrl));
    if (storeAuthUrl.includes('whatsapp_app')) {
      setShowFacebookPopup(true);
    } else {
      getDataByUrl(storeAuthUrl)
        .then((data) => {
          window.location = data.data.redirect;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  return (
    <>
      {viewsLevel > 3 ? (
        <Navigate to="/messages" />
      ) : (
        <JobsStateContext>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onError={ErrorBoundaryHandler}
          >
            <Header />
          </ErrorBoundary>
          <PageContainer>
            {showFacebookPopup && (
              <Popup handleClose={() => setShowFacebookPopup(false)} isMedia={false} isPreviewTicket={false} setIsPreviewTicket={false}>
                <Facebook />
              </Popup>
            )}
            {showConnectPopup && (
              <Alert handleClose={() => setShowConnectPopup(false)}>
                <Title>
                  <FormattedMessage
                    id="storeManagement.popup.title"
                    defaultMessage="What kind of store do you want to connect?"
                  />
                </Title>
                <StyledFormControl variant="outlined">
                  <StyledInputLabel id="store-type-label">
                    <FormattedMessage
                      id="storeManagement.popup.select_title"
                      defaultMessage="Select the store type"
                    />
                  </StyledInputLabel>
                  <Select
                    labelId="store-type-label"
                    id="store-type"
                    onChange={handleStoreSelect}
                    value={storeAuthUrl}
                  >
                    {Object.values(storesConfig?.data).map((config, ci) => {
                      return (
                        <MenuItem key={`menu-item-${ci}`} value={config[0].auth_url}>{config[0].provider_name}</MenuItem>
                      );
                    })}
                  </Select>
                </StyledFormControl>
                <ButtonsContainer>
                  <Button variant="outlined" onClick={() => setShowConnectPopup(false)}>
                    <FormattedMessage
                      id="storeManagement.popup.button.cancel"
                      defaultMessage="Cancel"
                    />
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleRedirect}>
                    <FormattedMessage
                      id="storeManagement.popup.button.next"
                      defaultMessage="Next"
                    />
                  </Button>
                </ButtonsContainer>
              </Alert>
            )}
            <ErrorBoundary
              FallbackComponent={ErrorBoundaryFallback}
              onError={ErrorBoundaryHandler}
            >
              <Nav currentStep="store" />
            </ErrorBoundary>
            <Body>
              <StoreManagementContainer>
                <ErrorBoundary
                  FallbackComponent={ErrorBoundaryFallback}
                  onError={ErrorBoundaryHandler}
                >
                  <StoreDetailsContainer />
                </ErrorBoundary>
              </StoreManagementContainer>
            </Body>
          </PageContainer>
        </JobsStateContext>
      )}
    </>
  );
};

export default Store;
