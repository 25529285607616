import styled from 'styled-components';
import { Button } from '@mui/material';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ContextWrapper = styled.div`
  width: 100%;
  padding-bottom: 15px;
  position: absolute;
  top: -500px;
  left: 0;
  bottom: 0;
  background-color: #ffffff !important;
  z-index: 5;
  overflow-y: auto;
`;
export const ContactsTitle = styled.h4`
  margin: 10px;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: #000;
`;
export const ProductsTitle = styled.h4`
  margin: 10px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000;
`;
export const StyledButton = styled(Button)`
  width: 90%;
  height: 50px;
  margin-bottom: 10px !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  border-radius: 0 !important;
  text-transform: capitalize !important;
`;
