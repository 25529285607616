import { StyledInputLabel, StyledListSubheader } from './styles';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { getInputDependedOptions, getInputDependedValues } from '../../../modules/getinput';
import { useEffect, useState } from 'react';

export const FormSelect = ({
                             input,
                             inputValues,
                             requiredFields,
                             inputLabels,
                             setInputValues,
                             setInputLabels,
                           }) => {
  let group = '';
  const intl = useIntl();
  const [defaultValue, setDefaultValue] = useState(input?.defaultValue);
  const [meta, setMeta] = useState({ defaultValue: input?.defaultValue });
  const [dependedValues, setDependedValues] = useState(getInputDependedValues(input, inputValues, ''));
  const [dependedOptions, setDependedOptions] = useState(getInputDependedOptions(input, inputValues));

  const handleChangeSelect = (prop) => (event) => {
    setInputValues({
      ...inputValues,
      [prop]: event.target.value,
    });
    const inputDependedOptions = getInputDependedOptions(input, inputValues, {});
    const foundElement = inputDependedOptions?.find((item) => Object.keys(item)[0] === event.target.value)[event.target.value];
    const formatMessage = intl.formatMessage({ id: foundElement, defaultMessage: foundElement, });
    setInputLabels({ ...inputLabels, [prop]: formatMessage, });
  };

  useEffect(() => {
  }, [input]);

  useEffect(() => {
    let inputDependedValues = getInputDependedValues(input, inputValues, '');
    const inputDependedOptions = Array.from(getInputDependedOptions(input, inputValues) ?? []);
    const filteredDependedOptions = inputDependedOptions.filter(item => !(typeof item === 'object' && '_meta' in item) && item);

    let found = !!inputValues[input.id];
    const inputValue = inputValues[input.id];
    if (inputValue && typeof filteredDependedOptions === 'object' && filteredDependedOptions.length > 0) {
      // @ts-ignore
      found = filteredDependedOptions.find(item => {
        // @ts-ignore
        return inputValue in item;
      });
      if (!found) {
        setInputValues({
          ...inputValues,
          [input.id]: null,
        });
      }
    }

    setDependedOptions(filteredDependedOptions);
    setDependedValues(inputDependedValues);

    const isMeta = inputDependedOptions?.[0] && (typeof inputDependedOptions?.[0] === 'object' && '_meta' in inputDependedOptions?.[0]);
    // @ts-ignore
    const metaData = isMeta ? { ...(inputDependedOptions?.[0]._meta) } : { defaultValue: input?.defaultValue };
    setMeta(metaData);

  }, [inputValues]);

  useEffect(() => {
    let found = !!inputValues[input.id];
    if (!dependedValues) {
      if (inputValues[input.id] && typeof input?.options === 'object') {
        if (Array.isArray(input?.options)) {
          found = input?.options?.find(item => {
            const inputValue = inputValues[input.id];
            const keys = Object.keys(item);
            return inputValue in keys;
          });
        }
      }
    }
    if (!found) {
      setDependedValues(meta?.defaultValue ?? '');
    }
    setDefaultValue(meta?.defaultValue ?? '');
  }, [meta]);

  return (
    <FormControl variant="outlined" fullWidth>
      <StyledInputLabel id={`${input.id}Label`}>
        <FormattedMessage id={input.label} defaultMessage={input.label} />
      </StyledInputLabel>
      <Select
        labelId={`${input.id}Label`}
        id={input.id}
        defaultValue={defaultValue}
        value={dependedValues}
        onChange={(event) => handleChangeSelect(input.id)(event)}
        disabled={!!input.disabled}
        required={requiredFields[input.id]}
      >
        {Array.from(dependedOptions)?.map((option: any, index) => (
          (!(typeof option === 'object' && '_meta' in option)) && (
            (typeof option === 'object' && 'group' in option) ? (
              (group = '30px !important') &&
              <StyledListSubheader key={`list-subheader-${index}`}>{option.group}</StyledListSubheader>
            ) : (
              <MenuItem sx={{ paddingLeft: group }} key={`menu-item-${index}`} value={Object.keys(option)[0] ?? ''}>
                {input.id === 'contactedTimezone' ? (
                  <>{Object.values(option)[0]}</>
                ) : (
                  <FormattedMessage
                    id={String(Object.values(option)[0])}
                    defaultMessage={String(Object.values(option)[0])}
                  />
                )}
              </MenuItem>
            )))
        )}
      </Select>
    </FormControl>
  );
};
export default FormSelect;
