import { createContext, useEffect, useState } from 'react';
import { retrieve } from '../modules/storage';

export const JwtTokenContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [token, setToken] = useState(null);
  const [tokenParts, setTokenParts] = useState(null);

  useEffect(() => {
    const jwtToken = retrieve.string('token', null);
    const jwtTokenParts = retrieve.json('tokenParts', null);
    setToken(jwtToken);
    setTokenParts(jwtTokenParts);
  }, []);

  return (
    <JwtTokenContext.Provider value={[token, setToken, tokenParts, setTokenParts]}>
      {children}
    </JwtTokenContext.Provider>
  );
};
