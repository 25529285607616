import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthenticationHeaders } from '../modules/fetch';
import { API } from '../config';

export const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: fetchBaseQuery({ baseUrl: API }),
  tagTypes: ['Account'],
  endpoints: (builder) => ({
    account: builder.query({
      query: () => ({
        url: '/account/read',
        headers: getAuthenticationHeaders('application/json'),
      }),
      providesTags: ['Account'],
    }),
    updateAccount: builder.mutation({
      query: (body) => ({
        url: `/account/update`,
        method: 'PUT',
        headers: getAuthenticationHeaders('application/json'),
        body: body,
      }),
      invalidatesTags: ['Account'],
    }),
    refreshApiKey: builder.mutation({
      query: () => ({
        url: `/account/refresh/api_key`,
        method: 'POST',
        headers: getAuthenticationHeaders('application/json'),
      }),
      invalidatesTags: ['Account'],
    }),
    updateAccountSettings: builder.mutation({
      query: (body) => ({
        url: `/account/settings/update`,
        method: 'PUT',
        headers: getAuthenticationHeaders('application/json'),
        body: body,
      }),
      invalidatesTags: ['Account'],
    }),
  }),
});

export const {
  useAccountQuery,
  useUpdateAccountMutation,
  useRefreshApiKeyMutation,
  useUpdateAccountSettingsMutation,
} = accountApi;
