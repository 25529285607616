import styled from 'styled-components';
import { Accordion } from 'react-accessible-accordion';
import { mediaQueries } from '../../constants/breakpoints';
import Pagination from '@mui/material/Pagination';
import { Input } from '@mui/material';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 360px;
  flex-grow: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 5;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  padding: 5px;

  h4 {
    text-align: center;
  }

  @media ${mediaQueries.desktop} {
    position: absolute;
    top: 0;
    right: 51px;
    height: 100%;
  }

  @media ${mediaQueries.mobile} {
    padding-right: 50px;
  }
`;

export const SearchContainer = styled.div`
  width: 100% !important;
`;

export const PaginationContainer = styled.div`
  width: 100% !important;
`;

export const StyledInput = styled(Input)`
  width: calc(100% - 40px) !important;
`;

export const StyledAccordion = styled(Accordion)`
  .accordion__item {
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.06);
    margin: 7px;
    border-radius: 6px;
    overflow: hidden;
  }

  .accordion__button {
    background-color: #ecebeb;
    border-radius: 6px 6px 0 0;
    text-transform: none;
    padding: 10px 18px;
  }
`;

export const AccordionTitle = styled.div`
  h5 {
    margin: 0;
    font-size: 1rem;
  }

  svg {
    font-size: 12px;
    color: #24afc1;
    margin-right: 5px;
  }
`;

export const ProductDetails = styled.div`
  margin-top: 20px;

  div {
    display: flex;
    margin-bottom: 7px;
    align-items: center;
    font-size: 1rem;
    letter-spacing: 0.5px;
    color: #4a4747;

    svg {
      font-size: 16px;
      color: #9e9c9c;
      margin-right: 10px;
    }
  }
`;

export const Product = styled.span`
  display: flex;
  align-items: flex-start;
  padding-bottom: 10px;
`;
export const Img = styled.img`
  max-width: 55px;
  max-height: 55px;
  margin-right: 5px;
`;
export const Info = styled.span``;
export const Title = styled.a`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #5c5670;
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;
export const SKU = styled.span`
  color: #c4c4c4;
  font-size: 1rem;
  letter-spacing: 0.5px;
  margin-top: 3px;
`;
export const Price = styled.span`
  display: flex;
  justify-content: space-between;
  color: #4a4747;
  font-size: 1rem;
  letter-spacing: 0.5px;
  margin-top: 10px;
`;
export const PerItem = styled.span`
  margin-right: 10px;
`;
export const Total = styled.div`
  font-weight: bold;
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;

  button {
    margin: 0 10px;
  }
`;
export const Item = styled.div`
  display: flex;
  font-size: 1rem;
  letter-spacing: 0.5px;
  align-items: center;
  margin-bottom: 7px;

  div {
    :first-child {
      color: #9e9c9c;
      width: 50%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin-right: 10px;

      svg {
        font-size: 10px;
        margin-right: 5px;
      }
    }
  }
`;

export const StyledPagination = styled(Pagination)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px 0;
  position: sticky;
  bottom: 0;
  background: #fff;
`;
